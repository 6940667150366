export class DoctorRelease {
    AdmissionId: number;
    Mrn: string;
    AdmissionNumber: number;
    PatientFullName: string;
    PatientOtherFullName: string;
    SectionTitle: string;
    SectionForeignTitle: string;
    AdmissionDoctorFullName: string;
    AdmissionDoctorOtherFullName: string;
    Status: number;
    AdmissionDoctor: number | null;
}
