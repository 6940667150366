import { SelectItem } from 'primeng/api';
import { ErrorCode } from '../enums';

export class ErrorCodeLookup {

    static get(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Failed', value: ErrorCode.Failed },
            { label: 'Success', value: ErrorCode.Success },
        ];
    }
}
