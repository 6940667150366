import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class PrintersetupService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    get = (): Observable<any> => {
        return this._http.get<any>(`${environment.order}/PrinterSetup`);
    }


    getByUser = (): Observable<any> => {
        return this._http.get<any>(`${environment.order}/PrinterSetup/GetByUser`);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.order}/PrinterSetup`, data);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(`${environment.order}/PrinterSetup`, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${environment.order}/PrinterSetup?id=${id}`);
    }
}
