import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable';
import { ControlApiConfiguration } from '../apiConfiguration';
import { PermissionViewModel } from '../../../models/control/permission-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PasswordPolicyService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byQuery: string;

    results: any;

    constructor(private http: HttpClient, private configuration: ControlApiConfiguration, private router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.passwordPolicy.url;
    }

    get = (): Observable<any> => {
        return this.http.get<any>(this.url, { withCredentials: true });
    }

    add = (data): Observable<any> => {
        return this.http.post<any>(this.url, data, { withCredentials: true });
    }

    update = (data): Observable<any> => {
        return this.http.put<any>(this.url, data, { withCredentials: true });
    }
}
