import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
@Injectable()
export class VoucherService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.voucher.url;
        this.postUrl = this.actionUrl + _configuration.voucher.url;
        this.putUrl = this.actionUrl + _configuration.voucher.url;
    }

    public get = (): Observable<any> => {
        return this._http.get<any>(this.getUrl);
    }

    public post(data: any) {
        return this._http.post<any>(this.putUrl, JSON.stringify(data));
    }
}