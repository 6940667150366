import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorPatientStatementService {

    constructor(private _http: HttpClient) { }

    EligibilityAPI = (orderId: number, actionId: string, patientId: number, encounterId: number, patientType: number, orderSourceId: number): Observable<any> => {
        return this._http.get<any>(`${environment.httpAggregatorApi}PatientStatement/EligibilityAPI/${orderId}/${actionId}/${patientId}/${encounterId}/${patientType}/${orderSourceId}`)
    }

    AddCloseInvoiceManagement =(statements:any): Observable<any> =>{
        return this._http.post<any>(`${environment.httpAggregatorApi}CloseInvoiceManagement/AddToQueue`,statements) 
    }

    getQueueCloseInvoiceManagement =(): Observable<any> =>{
        return this._http.get<any>(`${environment.httpAggregatorApi}CloseInvoiceManagement/GetQueue`);
    }

    cancelQueueCloseInvoiceManagement =(data): Observable<any> =>{
        return this._http.put<any>(`${environment.httpAggregatorApi}CloseInvoiceManagement/CancelQueue`, data);
    }
}