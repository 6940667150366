import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PricingCategoryService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.pricingCategory.url;
        this.postUrl = this.actionUrl + configuration.pricingCategory.url;
        this.putUrl = this.actionUrl + configuration.pricingCategory.url;
        this.deleteUrl = this.actionUrl + configuration.pricingCategory.url;
    }

    //TODO: handle paging and filter
    public get = (): Observable<any> => {
        return this.http.get<any>(this.getUrl);
    }

    public post(data: any) {
        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }

    public put(data: any) {
        return this.http.put<any>(this.putUrl, JSON.stringify(data));
    }

    public delete(id: number) {
        return this.http.delete(this.deleteUrl + "?id=" + id);
    }
}