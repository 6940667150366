import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { BaseComponent } from "app/shared/infrastructure";
@Component({
    selector: "notifications-media-body-cmp",
    styleUrls: ["./notifications-media-body.scss"],
    templateUrl: "./notifications-media-body.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsMediaBodyComponent extends BaseComponent implements OnInit {
    @Input() note: any;
        public constructor() { super();}
    ngOnInit(): void {
        this.setCreatedTitle();
    }

    modules: any[] = [
        { code: "PAT", bColor: "palette-Pink-500" },
        { code: "ADM", bColor: "palette-Purple-500" },
        { code: "LAB", bColor: "palette-Red-500" },
        { code: "RCM", bColor: "light-strong palette-Brown-400" },
        { code: "NRS", bColor: "light-strong palette-Teal-500" },
        { code: "PHR", bColor: "dark palette-Amber-700" },
        { code: "OPR", bColor: "palette-Blue-Grey-500" },
        { code: "APT", bColor: "dark palette-Green-A200" },
    ];

    setCreatedTitle() {
        const createdDate = new Date(this.note.createdDate);
        let diffTime = {
            days: Math.floor((this.currentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24)),
            hours: Math.floor(((this.currentDate.getTime() - createdDate.getTime()) % (1000 * 3600 * 24)) / (1000 * 3600)),
            minutes: Math.floor(((this.currentDate.getTime() - createdDate.getTime()) % (1000 * 3600)) / (1000 * 60)),
        };
        if (diffTime.days > 0) return (this.note.createdTitle = `${diffTime.days} day${diffTime.days > 1 ? 's' : ''} ago`);
        else if (diffTime.hours > 0) return (this.note.createdTitle = `${diffTime.hours} hour${diffTime.hours > 1 ? 's' : ''} ago`);
        else if (diffTime.minutes > 0) return (this.note.createdTitle = `${diffTime.minutes} minute${diffTime.minutes > 1 ? 's' : ''} ago`);
        else return (this.note.createdTitle = `Just Now`);
    }
}
