import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { NormalResultService } from '../../services';
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'lab-result-cmp',
    styleUrls: ['./lab-result.scss'],
    templateUrl: './lab-result.html'
})
export class LabResultComponent extends BaseComponent implements OnInit {

    labResults: any[] = [];
    columns = [
        { field: 'TestName', header: 'Test Name' },
        { field: 'Result', header: 'Results' },
        { field: 'ReleaseDateFormat', header: 'Release Date' },
        { field: 'StaffName', header: 'Release By' },
    ];

    ngOnInit() {
    }

    constructor(private normalResultService: NormalResultService, private _datePipe: DatePipe) {
        super();
    }

    getLabNormalResults(patientId: number, encounterId: number) {
        this.labResults = [];
        this.normalResultService.getLabResults(patientId, encounterId)
            .subscribe((data: any[]) => {
                if (!data) { return; }
                this.labResults = data;
                this.labResults.map(m => {
                    m.ReleaseDateFormat = this._datePipe.transform(m.ReleaseDate, 'dd/MM/yyyy');
                })
            }, error => this.showError(error));
    }
    
    onFilterDate(event, field, dt: Table) {
        const selectedDate = this._datePipe.transform(event, 'dd/MM/yyyy');
        dt.filter(selectedDate, field, 'equals')
    }
}