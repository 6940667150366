import { KitSetup } from './kitSetup';
import { Lookup } from './lookup';
import { ResultList } from './resultList';
import { Section } from './section';
import { SpecimenTransaction } from './specimenTransaction';

export class NormalResult {

    Id: number;
    OrderId: number;
    SpecimenNo: number;
    ItemNo: number;
    Status: number;
    SectionId: number;
    AccessionNo: string;
    UnitId?: number;
    ResultType?: number;
    ResultListNo?: number;
    Result: string;
    NormalRanges: string;
    KitNo?: number;
    MachineId?: number;
    IsComplete?: boolean;
    IsCalculated?: boolean;
    CompleteDate?: Date;
    CompleteBy?: number;
    IsRelease?: boolean;
    ReleaseDate?: Date;
    ReleaseBy?: number;
    RejectDate?: Date;
    RejectBy?: number;
    RejectReason?: number;
    PatientId: number;
    SpecimenTransId?: number;
    TestNormalRangeId?: number;
    CreatedBy: number;
    CreatedDate: Date;
    IsConfirm: boolean;

    KitSetup: KitSetup;
    ResultList: ResultList;
    Section: Section;
    UnitLookup: Lookup;
    SpecimenTransaction: SpecimenTransaction;
    PreviousResult: NormalResult = null;
    ListDetailTitle: string;
    ListDetailForeignTitle: string;
    ListDetailValue: number;
    StaffTitle: string;
    Comment: string;
    ReleaseByDescription: string;
    IsInfectious: boolean;
    SiSymbol: string;
    ConventionalSymbol: string;
    ThreeHrsPercentage: number;
    SixHrsPercentage: number;
    hasSavedResult: boolean = false;
    FacilityId: number;
    constructor() { }
}
