export class OutPatientSearchResult {

    Id: number;
    PatientCode: string ;
    FirstName: string;
    FatherName: string;
    MidName: string;
    LastName: string;
    OtherFirstName: string;
    OtherFatherName: string;
    OtherMidName: string;
    OtherLastName: string;
    BirthDate: Date;

    constructor() { }
}
