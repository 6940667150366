import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { Lookup } from '../../../models/lab/lookup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class LabLookupService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.lookup.url;
    }

    get = (): Observable<Lookup[]> => {
        return this._http.get<any>(this._url)
    }

    getByCategory = (gategoryId): Observable<Lookup[]> => {
        const url = `${this._url}?categoryId=${gategoryId}`;
        return this._http.get<any>(url)
    }

    insert = (data: Lookup): Observable<any> => {
        return this._http.post<any>(this._url, data)
    }

    update = (data: Lookup): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
