import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { TestNormalRange } from '../../../models/lab/test-normal-range';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class TestNormalRangeService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.testNormalRange.url;
    }

    insert = (data: TestNormalRange): Observable<number> => {
        return this._http.post<any>(this.url, data)
    }

    update = (data: TestNormalRange): Observable<any> => {
        return this._http.put<any>(this.url, data);
    }
}
