import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorOrderService {

    constructor(private _http: HttpClient) { }

    createOrder = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}Order/CreateOrder`, data);
    }
}