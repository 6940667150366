import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { FailedInvoiceViewModel } from 'app/shared/models/queryModel/failed-invoice-view-model';
import { FailedDoctorFeesViewModel } from 'app/shared/models/queryModel/failed-doctor-fees-view-model';

@Injectable()
export class ErpFailedTransactionService extends ServiceBase {

    private actionUrl: string;
    private _doctorFeesWithInvalidVendor: string;
    private _invoicesWithEmptyArAccount: string;
    private _refreshVendorCode: string;
    private _refreshArAccountNumber: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;

        this._doctorFeesWithInvalidVendor = this.actionUrl + _configuration.erpFailedTransaction.doctorFeesWithInvalidVendor;
        this._invoicesWithEmptyArAccount = this.actionUrl + _configuration.erpFailedTransaction.invoicesWithEmptyArAccount;
        this._refreshVendorCode = this.actionUrl + _configuration.erpFailedTransaction.refreshVendorCode;
        this._refreshArAccountNumber = this.actionUrl + _configuration.erpFailedTransaction.refreshArAccountNumber;
    }

    public getDoctorFeesWithInvalidVendor = (rowNumber: number, pageIndex: number, invoiceNo?: string): Observable<FailedDoctorFeesViewModel[]> => {
        const invoiceNoQuery = invoiceNo ? `&&INVOICE_NUMBER=${invoiceNo}` : ``;
        return this._http.get<any>(`${this._doctorFeesWithInvalidVendor}?ROW_NUMBER=${rowNumber}&&PAGE_INDEX=${pageIndex}` + invoiceNoQuery);
    }

    public getInvoicesWithEmptyArAccount = (rowNumber: number, pageIndex: number, invoiceNo?: string): Observable<FailedInvoiceViewModel[]> => {
        const invoiceNoQuery = invoiceNo ? `&&INVOICE_NUMBER=${invoiceNo}` : ``;
        return this._http.get<any>(`${this._invoicesWithEmptyArAccount}?ROW_NUMBER=${rowNumber}&&PAGE_INDEX=${pageIndex}` + invoiceNoQuery);
    }

    refreshVendorCode = (actionId: number, invoiceNumber: string) => {
        return this._http.put<any>(`${this._refreshVendorCode}?actionId=${actionId}&&invoiceNumber=${invoiceNumber}`, null);
    }

    refreshArAccountNumber = (invoiceNumber: string) => {
        return this._http.put<any>(`${this._refreshArAccountNumber}?invoiceNumber=${invoiceNumber}`, null);
    }
}
