import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class ClaimPatientAttachmentService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    getBySearch = (filter, page: number = 0, rows: number = 10): Observable<any> => {
        let queryString = `?page=${page}&rows=${rows}`;
        queryString += `&claimId=${filter.claimId || ''}`;
        queryString += `&patientCode=${filter.patientCode || ''}&patientTypeId=${filter.patientTypeId || ''}`;
        queryString += `&doctorId=${filter.doctorId || ''}&sectionId=${filter.sectionId || ''}`;
        queryString += `&statusId=${filter?.statusId ? filter?.statusId : filter?.statusId === 0 ? 0 : ''}`;
        queryString += `&fromDate=${filter.fromDate?.toISOString() || ''}&toDate=${filter.toDate?.toISOString() || ''}`;
        queryString += `&payerId=${filter.payerId || ''}&coverageContractId=${filter.coverageContractId || ''}`;
        queryString += `&coverageClassId=${filter.coverageClassId || ''}`;

        return this._http.get<any>(`${environment.queryModel}ClaimPatientAttachment/BySearch` + queryString);
    }

    downloadBySearch = (filter, ids): Observable<any> => {
        let queryString = `?claimId=${filter.claimId || ''}`;
        queryString += `&patientCode=${filter.patientCode || ''}&patientTypeId=${filter.patientTypeId || ''}`;
        queryString += `&doctorId=${filter.doctorId || ''}&sectionId=${filter.sectionId || ''}`;
        queryString += `&statusId=${filter?.statusId ? filter?.statusId : filter?.statusId === 0 ? 0 : ''}`;
        queryString += `&fromDate=${filter.fromDate?.toISOString() || ''}&toDate=${filter.toDate?.toISOString() || ''}`;
        queryString += `&payerId=${filter.payerId || ''}&coverageContractId=${filter.coverageContractId || ''}`;
        queryString += `&coverageClassId=${filter.coverageClassId || ''}`;

        return this._http.post<any>(`${environment.queryModel}ClaimPatientAttachment/DownloadBySearch` + queryString, { Ids: ids });
    }
}
