export class SpecimenTest {

    OrderId: number;
    OrderDate?: Date;
    SpecimenNo: number;
    AccessionNumber: string;
    PatientCode: string;
    PatientName: string;
    OtherPatientName: string;
    ResultType: number;
    CultureResult?: number;
    Status: number;
    CollectBy: number;
    CollectDate?: Date;
    ReceivedBy: number;
    ReceivedDate?: Date;
    LabSectionId?: number;
    LabSectionTitle: string;
    LabSectionForeignTitle: string;
    OrderDoctorName: string;
    OrderDoctorOtherName: string;
    SpecimenTypeTitle: string;
    SpecimenTypeForeignTitle: string;
    TestTitle: string;
    TestForeignTitle: string;
    ReleaseBy: number;
    ReleaseDate?: Date;
    OrderComments: string;
    SectionId?: number;
    ItemId: number;
    RowsCount: number;

    CancelBy ?: number;
    RejectBy ?: number;
    AcceptBy ?: number;

    CancelDate ?: Date;
    RejectDate ?: Date;
    AcceptDate ?: Date;
    SpecimenTransactionId: number;
}

