export class StatementCancelationInfo {
    PatientId: number;
    PatientTypeId: number;
    EncounterId: number;
    Note: string;
    FundId: number;
    PatientStatementId: number;
    StatementTransactionId: number;
    OrderId?: number;
    OrderSourceId?: number;
}