import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { SelectItem, } from 'primeng/api';

import { BaseComponent } from 'app/shared/infrastructure';

// Services
import { LabSectionService } from 'app/shared/services/lab/labSection';
import { LookUpService } from 'app/shared/services/core/lookup';
import { CodeService } from 'app/shared/services';

// Models
import { LabOrderSpecimenTestSearchModel } from 'app/shared/models/queryModel/lab-order-specimen-test-search';
import { Section } from 'app/shared/models/lab/section';
import { Control } from 'app/shared/models/core/control';
import { TestResultType } from 'app/shared/models/lab/enums/test-result-type';
import { TestResultTypesLookup } from 'app/shared/models/lab/lookups/test-result-types-lookup';
import { ExistResultStatusLookup } from 'app/shared/models/lab/lookups/exist-result-status-lookup';

@Component({
    selector: 'advance-search-lab-order-specimen-test-cmp',
    styleUrls: ['./advanceSearchLabOrderSpecimenTest.scss'],
    templateUrl: './advanceSearchLabOrderSpecimenTest.html',
})

export class AdvanceSearchLabOrderSpecimenTest extends BaseComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();

    labOrderSpecimenTestSearchModel: LabOrderSpecimenTestSearchModel = new LabOrderSpecimenTestSearchModel();

    _refreshInformation: LabOrderSpecimenTestSearchModel;
    @Input() set refreshInformation(value: LabOrderSpecimenTestSearchModel) {
        this._refreshInformation = value;
        if (this._refreshInformation) {
            this.labOrderSpecimenTestSearchModel = new LabOrderSpecimenTestSearchModel();
            this.labOrderSpecimenTestSearchModel = value;
        }
    }

    labSections: SelectItem[];
    doctorStaff: SelectItem[];
    growthRates: SelectItem[];
    testResultTypes: SelectItem[];
    existResultStatus: SelectItem[];

    testResultTypeEnum: typeof TestResultType = TestResultType;

    ngOnInit() {
        this.getLabSections();
        this.getDoctorStaff();
        this.getGrowthRateLookup();
        this.testResultTypes = TestResultTypesLookup.getLookup();
        this.existResultStatus = ExistResultStatusLookup.getLookup();
    }

    constructor(private labSectionService: LabSectionService,
        private lookUpService: LookUpService,
        private codeService: CodeService) {
        super();
    }

    sendSearchCriteria() {
        this.onSearch.emit(this.labOrderSpecimenTestSearchModel);
    }

    getDoctorStaff() {
        this.lookUpService.getDoctorStaff()
            .subscribe((data: Control[]) => {
                this.doctorStaff = data.map(function (value) {
                    return { label: value.Title, value: value.Id };
                });
            });

    }

    getLabSections() {
        this.labSectionService.getAll()
            .subscribe((data: Section[]) => {
                this.labSections = data.map(function (value) {
                    return { label: value.Title, value: value.Id };
                });
            });
    }

    getGrowthRateLookup() {
        this.codeService.getActiveCodesByGroupKey('GROWTH_RATE')
            .subscribe((data: any[]) => {
                this.growthRates = [];
                if (data && data.length > 0) {
                    this.growthRates.push({ label: 'All', value: null });
                    data.map(st => {
                        this.growthRates.push({ label: st.Title, value: st.Id });
                    });
                }
            },
                error => {
                    this.showError(error);
                });
    }

    resetCultureDdl() {
        if (this.labOrderSpecimenTestSearchModel &&
            this.labOrderSpecimenTestSearchModel.ResultTypeId &&
            this.labOrderSpecimenTestSearchModel.ResultTypeId != TestResultType.Culture) {
            this.labOrderSpecimenTestSearchModel.CultureResultId = null;
        }
    }

    setResultTypeCulture() {
        if (this.labOrderSpecimenTestSearchModel &&
            this.labOrderSpecimenTestSearchModel.CultureResultId) {
            this.labOrderSpecimenTestSearchModel.ResultTypeId = TestResultType.Culture;
        }
    }
}
