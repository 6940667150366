import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable'
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class SmartConfigService extends ServiceBase {


    constructor(private _http: HttpClient) {
        super();
    }

    Pages(pageIndex): Observable<any> {
        return this._http.get<any>(`${this.CoretUrl()}/SmartConfiguration?pageNo=1&rowNo=10`);
    }

    getAllFormsByPageUrl(pageUrl): Observable<any> {
        return this._http.post<any>(`${this.CoretUrl()}SmartConfiguration/GetSmartForms`, { PageUrl: pageUrl });
    }

    save(page: any) {

        return this._http.post<any>(`${this.CoretUrl()}/SmartConfiguration`, page);
    }

    delete(id: number) {
        return this._http.delete(this.CoretUrl() + '/SmartConfiguration?id=' + id);
    }

    CoretUrl() {
        return environment.core;
    }
}