import { ReturnRequest } from 'app/shared/models';

export class OrderActionReturn {
    OrderActionId: number;
    ProductId: number;
    ProductCode: string;
    ProductTitle: string;
    HospitalPharmacyTitle: string;
    PatientFullName: string;
    Quantity: number;
    ReturnQty: number;
    ReturnReason: string;
    DoseUnitId?: number;
    ItemUnitId?: number;
    CreatedDate: Date;
    ReturnStatus: string;
    OrderId: number;
    ItemUnitDescription: string;
    ReturnRequests: ReturnRequest[];
    constructor() { }
}
