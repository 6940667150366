import { Component, EventEmitter, Output } from '@angular/core';

import { SapAccountReceivableInvoiceSearch } from 'app/shared/models/orders-and-billing/sap-account-receivable-invoice-search.model';
import { DealingTypeLookup } from 'app/shared/models/orders-and-billing/lookup/dealing-type-lookup';
import { PatientTypesLookup } from 'app/shared/models/orders-and-billing/lookup/patient-types-lookup';
import { PayerBase } from 'app/shared/models/orders-and-billing/payer';
import { PayerService } from 'app/shared/services/order-and-billing/payer/payer.service';
import { BaseComponent } from 'app/shared/infrastructure/base.component';
import { SelectItem } from "primeng/api";


@Component({
    selector: 'advance-search-sap-account-receivable-invoice-cmp',
    templateUrl: './advance-search-sap-account-receivable-invoice.html'
})

export class AdvanceSearchSapAccountReceivableInvoiceComponent extends BaseComponent {
    @Output() onSearch = new EventEmitter<any>();

    sapAccountReceivableInvoiceSearch: SapAccountReceivableInvoiceSearch;

    patientTypes: SelectItem[];
    dealingTypes: SelectItem[];
    payers: PayerBase[];

    constructor(private payerService: PayerService) {
        super();

        this.dealingTypes = DealingTypeLookup.getLookup();
        this.patientTypes = PatientTypesLookup.getLookup();
        this.sapAccountReceivableInvoiceSearch = new SapAccountReceivableInvoiceSearch();
        let today = new Date();
        this.sapAccountReceivableInvoiceSearch.fromDate = today;
        this.sapAccountReceivableInvoiceSearch.toDate = today;
    }

    search() {
        this.onSearch.emit(this.sapAccountReceivableInvoiceSearch);
    }

    filterPayerServices(event) {
        this.clearPayerFieldsOfSelectedService(null);
        const query = event.query;
        this.payerService.getByQuery(query)
            .subscribe((data: PayerBase[]) => {
                this.payers = [];
                this.payers = data;
            },
                error => this.showError(error));
    }

    checkIfValidPayerService(event) {
        if (!this.sapAccountReceivableInvoiceSearch.payerId) {
            // User didn't select any payer,clear the field
            event.target.value = null;
        }
    }

    setPayerService(event: PayerBase) {
        this.sapAccountReceivableInvoiceSearch.payerId = event.Id;
    }

    clearPayerFieldsOfSelectedService(event) {
        this.sapAccountReceivableInvoiceSearch.payerId = null;
    }

}
