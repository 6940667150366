import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PatientVitalSign } from 'app/shared/models/core/patient-vital-sign';
import { PatientVitalSignInfoDto } from 'app/shared/models/core/patient-vital-sign-info-dto';

@Injectable()
export class PatientVitalSignService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private latestThreeByEncounterUrl: string;
    private patientVitalSignInfoUrl: string;

    constructor(private http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientVitalSign.url;
        this.latestThreeByEncounterUrl = this.actionUrl + _configuration.patientVitalSign.latestThreeByEncounter;
        this.patientVitalSignInfoUrl = this.actionUrl + _configuration.patientVitalSign.patientVitalSignInfo;
    }

    getLatestThreeByEncounter = (patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        return this.http.get<any>(this.latestThreeByEncounterUrl
            + '?patientId=' + patientId
            + '&encounterId=' + encounterId
            + '&patientTypeId=' + patientTypeId);
    }

    add(patientVitalSign: PatientVitalSign) {
        return this.http.post<any>(this.url, JSON.stringify(patientVitalSign));
    }

    update(patientVitalSign: PatientVitalSign) {
        return this.http.put<any>(this.url, JSON.stringify(patientVitalSign));
    }

    getPatientVitalSignInfo = (patientId: number): Observable<any> => {
        return this.http.get<any>(`${this.patientVitalSignInfoUrl}?patientId=${patientId}`
        );
    }
}
