import { SelectItem } from 'primeng/api';
import { IssueCloseManagementType } from '../enums';

export class IssueCloseManagementTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Re-Calculate', value: IssueCloseManagementType.ReCalculate },
            { label: 'Issue', value: IssueCloseManagementType.Issue },
            { label: 'Both', value: IssueCloseManagementType.Both }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }

    static getAll(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Re-Calculate', value: IssueCloseManagementType.ReCalculate },
            { label: 'Issue', value: IssueCloseManagementType.Issue },
            { label: 'Both', value: IssueCloseManagementType.Both }];
    }
}
