import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { ItemBasicInfo } from 'app/shared/models/orders-and-billing/item-basic-info';
import { BaseComponent } from 'app/shared/infrastructure/base.component';
import { ItemService } from 'app/shared/services/order-and-billing';
import { ItemSearchFilter } from 'app/shared/models/orders-and-billing/item-search-filter';
import { ItemCategory } from 'app/shared/models/orders-and-billing/enums/item-category';

@Component({
    selector: 'ba-item-code-cmp',
    styleUrls: ['./item-code.scss'],
    templateUrl: './item-code.html'
})

export class ItemCodeComponent extends BaseComponent implements OnInit {

    @Input() itemCategory: ItemCategory;
    @Output() onSetResult = new EventEmitter<any>();
    services: ItemBasicInfo[];
    selectedService: ItemBasicInfo;

    constructor(private itemService: ItemService) {
        super();
    }

    ngOnInit() {
    }

    filterServices(event) {

        const itemSearchFilter: ItemSearchFilter = new ItemSearchFilter();
        itemSearchFilter.Description = event.query;
        itemSearchFilter.CategoryId = this.itemCategory;
        itemSearchFilter.IsChargable = true;
        this.itemService.getLeafAsLookup(itemSearchFilter)
            .subscribe((data: ItemBasicInfo[]) => {
                this.services = data;
            },
                error => this.showError(error));
    }

    setService(event: ItemBasicInfo) {
        this.onSetResult.emit({ data: event });
    }
}
