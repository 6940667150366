import { SelectItem } from 'primeng/api';
import { RouteSeverity } from 'app/shared/models/medispan/enums/route-severity';

export class RouteSeverityLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Contraindicated', value: RouteSeverity.Contraindicated },
            { label: 'Not Recommended', value: RouteSeverity.NotRecommended },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Contraindicated', value: RouteSeverity.Contraindicated },
            { label: 'Not Recommended', value: RouteSeverity.NotRecommended },
        ];
    }
}
