export class MobileAccountAvailability {

    IsExist: boolean;
    NeedConfirmation: boolean;
    Error: boolean;
    PatientName: string;
    ForeignPatientName: string;
    ErrorMessage: string
    constructor() {
    }
}