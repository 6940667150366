import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PayerSessionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getActive: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.payerSession.url;
        this._getActive = this.actionUrl + _configuration.payerSession.active;
    }

    get = (payerId, contractId): Observable<any> => {
        return this._http.get<any>(`${this.url}/${payerId}/${contractId}`);
    }

    getActive = (payerId, contractId): Observable<any> => {
        return this._http.get<any>(`${this._getActive}/${payerId}/${contractId}`);
    }

    public add(data: any) {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    public update(data: any) {
        return this._http.put<any>(`${this.url}`, JSON.stringify(data));
    }
}