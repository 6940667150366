import {
    LabLookupService,
    LabSectionService,
    LabApiConfiguration,
    ResultListService,
    ListDetailsService,
    SectionTemplateService,
    LablookupCategoryService,
    SpecimenRejectionReasonService,
    ExternalLaboratoryService,
    TestsDefinitionService,
    AntibioticService,
    LabkitSetupService,
    MicrobiologyPanelService,
    GroupTestsService,
    LabMicrobiologyPanelAntibioticService,
    CommentTemplateService,
    TestNormalRangeService,
    NumericListTextResultEntryScreenService,
    ResultFormulaTestsService,
    SpecimenTransactionService,
    SpecimenTransactionStatusService,
    TextResultsTemplateService,
    SentExternalLaboratoryService,
    TextResultsService,
    SensitiveMicroBiologyAntiService,
    SensitiveResultService,
    SensitiveMicroBiologyService,
    PreviousResultService,
    TestsInstructionService,
    EncounterNormalResultService,
    TestsMappingService,
    LabFavoriteListService,
    ReportTemplateService,
    NormalResultService
} from './services';

export const NGA_LAB_SERVICES = [
    LabLookupService,
    LabSectionService,
    LabApiConfiguration,
    ResultListService,
    ListDetailsService,
    SectionTemplateService,
    LablookupCategoryService,
    SpecimenRejectionReasonService,
    ExternalLaboratoryService,
    TestsDefinitionService,
    AntibioticService,
    LabkitSetupService,
    MicrobiologyPanelService,
    GroupTestsService,
    LabMicrobiologyPanelAntibioticService,
    CommentTemplateService,
    TestNormalRangeService,
    NumericListTextResultEntryScreenService,
    ResultFormulaTestsService,
    SpecimenTransactionService,
    SpecimenTransactionStatusService,
    TextResultsTemplateService,
    SentExternalLaboratoryService,
    TextResultsService,
    SensitiveMicroBiologyAntiService,
    SensitiveResultService,
    SensitiveMicroBiologyService,
    PreviousResultService,
    TestsInstructionService,
    EncounterNormalResultService,
    TestsMappingService,
    LabFavoriteListService,
    ReportTemplateService,
    NormalResultService
];
