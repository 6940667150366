import {CoverageContract} from './coverage-contract';
export class PayerBase {
    Id: number = 0;
    Title: string = "";
    ForeignTitle?: string = null;
    PayerAddress?: string = null;
    VatRegistrationNo?: string = null;

    StreetName?: string = null;
    DistrictName?: string = null;
    BuildingNo?: string = null;
    ZipCode?: string = null;
    AdditionalNo?: string = null;
    TelNo?: string = null;
    FaxNo?: string = null;
}
export class Payer extends PayerBase {
     PayerType: number;
     CountryId: number;
     CityId: number;
     ContactPerson: string;
     ContactNumber: number;
     ContactEmail: string;
     ARId: string;
     Status: boolean;
     OrgId: number;
     ParentId: number;
     CoverageContracts:CoverageContract[];
     PayerContactPeople: any[];
     PayerAddress: string;
     VatRegistrationNo: string;
     PayerTemplateId: number; 

     StreetName: string;
     OtherStreetName: string;
     DistrictName: string;
     OtherDistrictName: string;
     BuildingNo: string;
     ZipCode: string;
     AdditionalNo: string;
     TelNo: string;
     FaxNo: string;
     PriceListId?: number;
}