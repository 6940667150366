import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ExternalLaboratory } from '../../../models/lab/externalLaboratory';
import { ServiceBase } from 'app/shared/infrastructure';
import { ItemAndCost } from 'app/shared/models/lab/item-and-cost';
import { CostByCode } from 'app/shared/models/lab/cost-by-code.model';
import { LabItem } from 'app/shared/models/lab/labItem';
import { LabItemChecking } from 'app/shared/models/lab/labItemChecking';

@Injectable()
export class ExternalLaboratoryService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    private _getExtrnalLabItemCost: string;
    private _getTestDefinitionItems: string;
    private _addExtrnalLabItemCost: string;
    private _getExternalLabAllItems: string;
    private _checkExternalLabItem: string;
    private _checkExternalLabItems: string;
    private _removeExtrnalLabItemCost: string;
    private _editExternalLabItems: string;


    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.externalLaboratory.url;
        this._getExtrnalLabItemCost = this.actionUrl + _configuration.externalLaboratory.getExtrnalLabItemCost;
        this._getTestDefinitionItems = this.actionUrl + _configuration.testDefinition.getTestDefinitionItems;
        this._addExtrnalLabItemCost = this.actionUrl + _configuration.externalLaboratory.addExtrnalLabItemCost;
        this._getExternalLabAllItems = this.actionUrl + _configuration.externalLaboratory.getExternalLabAllItems;
        this._checkExternalLabItem = this.actionUrl + _configuration.externalLaboratory.checkExternalLabItem;
        this._checkExternalLabItems = this.actionUrl + _configuration.externalLaboratory.checkExternalLabItems;
        this._removeExtrnalLabItemCost = this.actionUrl + _configuration.externalLaboratory.removeExtrnalLabItemCost;
        this._editExternalLabItems = this.actionUrl + _configuration.externalLaboratory.editExtrnalLabItemCost;
    }

    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getById = (id): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.get<any>(url)
    }

    add = (data: ExternalLaboratory): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data))
    }

    put = (id: number, data: ExternalLaboratory): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.put<any>(url, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }

    getExternalLabAllItems = (id): Observable<any> => {
        const url = `${this._getExternalLabAllItems}?externalLabId=${id}`;
        return this._http.get<any>(url);
    }

    getTestDefinitionItems = (item): Observable<any> => {
        const url = `${this._getTestDefinitionItems}?itemCode=${item}`;
        return this._http.get<any>(url);
    }

    addExtrnalLabItemCost = (data: ItemAndCost): Observable<any> => {
        return this._http.post<any>(this._addExtrnalLabItemCost, JSON.stringify(data));
    }

    getExtrnalLabItemCost = (_costBycode: CostByCode): Observable<any> => {
        const url = `${this._getExtrnalLabItemCost}?externalLabId=${_costBycode.id}&code=${_costBycode.code}`;
        return this._http.get<any>(url);
    }

    checkExternalLabItem = (id: number): Observable<any> => {
        const url = `${this._checkExternalLabItem}?itemId=${id}`;
        return this._http.get<any>(url);
    }
    checkExternalLabItems = (labItemChecking: LabItemChecking): Observable<any> => {
        const url = `${this._checkExternalLabItems}`;
        return this._http.post<any>(url, JSON.stringify(labItemChecking));
    }

    removeExtrnalLabItemCost = (id: number): Observable<any> => {
        const url = `${this._removeExtrnalLabItemCost}`;
        return this._http.post<any>(url, JSON.stringify(id));
    }

    editExtrnalLabItemCost = (data: ItemAndCost): Observable<any> => {
        return this._http.post<any>(this._editExternalLabItems, JSON.stringify(data));
    }
}
