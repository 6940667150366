export class CashBoxReceviedTransferRequestViewModel {

    Mrn: string;
    PatientFullName: string;
    PatientTypeId: number;
    PaymentCodeId: number;
    PaymenTypeId: number;
    constructor() {

    }
}
