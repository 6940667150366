export class ReportConstants {
   /* ================
   * Admission Reports
   * ================
   */

   static admissionConsentEnglish: string = '/Admission_ConsentEn'; // admission station
   static admissionConsent = '/Admission_Doctor_FeeSheet_Consent'; // admission station , new admission
   static sectionActivitiesReport: string = '/Admission_HospitalSectionReport'; // component
   static admissionLabel = '/Admission_Labels'; // admission station , new admission, hospital room
   static monthlyAdmissionsStatisticsReport: string = '/Admission_MonthlyStatistics'; // component
   static admissionPatientInfo = '/Admission_PatientInformation'; // admission station , new admission
   static admissionReport = '/Admission_Patients'; // component
   static admissionReportGroup = '/Admission_Patients_Grouping'; // component , hospital room
   static admissionReportByReason = '/Admission_Patients_By_Reason'; // component , hospital room
   static patientTransfer: string = '/Admission_PatientTransfer'; // transfer , accommodation
   static dischargeReport: string = '/Admission_Discharge_Form'; // billing_transaction 
   static admissionChangeDoctor: string = '/Admission_ChangeDoctor';
   static admissionPatientLabel = '/Admission_Patinet_label';
   static admissionPatientDiagnosis: string = '/Admission_PatientDiagnosis'; // component
   static admissionWristbandLabel = '/Admission_Wristband_Label'; // admission station , new admission, hospital room

   /* ================
   * Emergency Reports
   * ================
   */


   /* ================
   * Outpatient Reports
   * ================
   */


   /* ================
   * Lab Reports
   * ================
   */

   static labCultureResults = '/Lab_CultureResult'; // order specimen test search, sensitivity result, lab micro.
   static laboratoryTestsReport: string = '/Lab_LaboratoryTestsReport'; // Component
   static microOrganismResults: string = '/Lab_CultureResult'; // Component
   static labPreResults = '/Lab_PreviousResults'; // workbench
   static labResults = '/Lab_Results'; // workbench, numeric result , ......
   static labResultsSi = '/Lab_Results_Si'; // workbench, numeric result si , ......
   static labBarCodeSpecimen: string = '/Lab_Specimen_BarCode'; // patient latest order item , patient order management 
   static testDefinitionReport: string = '/Lab_TestDefinition'; // test definition component
   static sectionRevenueByDoctor = '/Lab_SectionRevenueByDoctor'; // component
   static labPathology = '/Lab_Pathology'; // component
   static labPatientLaboratoryVisit = '/LAB_PATIENT_LABORATORY_VISIT'; // component
   static specimenReport: string = '/Lab_SpecimenReport'; //Component
   static labNurseSpecimenURLReport: string = '/LabNurseSpecimen'; //Component
   static labSpecimensReport = '/Lab_Specimens';

   /* ================
   * Radiology Reports
   * ================
   */

   static radiologyItemReport: string = '/Radiology_RadiologyItem';// component

   /* ================
    * Billing Reports
    * ================
    */

   static cashierDispatch: string = '/Billing_CashierDispatch'; // tracking main fund 
   static cashierReceiptVoucher: string = '/Billing_CashierReceiptVoucher'; // tracking main fund 
   static cashInvoiceAr: string = '/Billing_CashInvoiceAr'; // billing transaction 
   static cashInvoiceDetailsAr: string = '/Billing_CashInvoiceDetailsAr';// billing transaction 
   static cashInvoiceDetailsArSummary: string = '/Billing_CashInvoiceDetailsArSummary';// billing transaction 
   static cashInvoiceDetailsEn: string = '/Billing_CashInvoiceDetailsEn';// billing transaction 
   static cashInvoiceEn: string = '/Billing_CashInvoiceEn';// billing transaction
   static claimDetails: string = '/Billing_Claim_Details';// claim detail tracking
   static claimContractDiscount: string = '/Billing_ClaimContractualDiscount';// claim management tracking
   static claimDrFees: string = '/Billing_ClaimDrFeesDetails';// claim management tracking
   static creditInvoiceAr: string = '/Billing_CreditInvoiceAr';// billing transaction 
   static creditInvoiceDetailsAr: string = '/Billing_CreditInvoiceDetailsAr';// billing transaction 
   static creditInvoiceDetailsArSummary: string = '/Billing_CreditInvoiceDetailsArSummary';// billing transaction 
   static creditInvoiceDetailsEn: string = '/Billing_CreditInvoiceDetailsEn';// billing transaction 
   static creditInvoiceEn: string = '/Billing_CreditInvoiceEn';// billing transaction 
   static disclosureIssuedInvoice: string = '/Billing_DisclosureIssuedInvoice';//Component
   static disclosureUnissuedInvoice: string = '/Billing_DisclosureUnissuedInvoice';//Component
   static statementOfAccountAr: string = '/Billing_StatementOfAccounts';// billing transaction 
   static transactionPayment = '/Billing_TransactionPayment';// billing transaction 
   static transactionRefund = '/Billing_TransactionRefund';// billing transaction 
   static priceListItem: string = '/Billing_PriceListItem';// price list
   static SettlementReport: string = '/Billing_SettlementReport';
   static operationReport: string = '/Billing_Opertaions';//Component
   static doctorRevenuePerPatientReport: string = '/Billing_DoctorRevenuePerPatient';//Component
   static paymentRequestForm: string = '/Billing_PaymentRequest';
   static paymentRequestFormAr: string = '/Billing_PaymentRequestAr';
   static incomePerOutPatientSection: string = '/Billing_IncomePerOutPatientSection';
   static cashClaimInvoiceDetailsAr: string = '/Billing_CashClaimInvoiceDetailsAr';
   static cashClaimInvoiceAr: string = '/Billing_CashClaimInvoiceAr';
   static cashClaimInvoiceDetailsEn: string = '/Billing_CashClaimInvoiceDetailsEn';
   static cashClaimInvoiceEn: string = '/Billing_CashClaimInvoiceEn';
   static creditOrderSetInvoiceEn: string = '/Billing_CreditOrderSetInvoiceEn';
   static creditOrderSetInvoiceAr: string = '/Billing_CreditOrderSetInvoiceAr';
   static doctorPayments: string = '/Billing_DoctorPayments';
   static doctorPaymentTransaction: string = 'Billing_DoctorPayment_Transaction'
   static orderSummaryByItem: string = '/Billing_OrderSummaryByItem';
   static UcafReport = '/UCAF_Report';
   static DcafReport = '/DCAF_Report';
   static creditCashReceiptReport = '/Credit_Cash_Receipt_Report';
   static CreditTaxInvoiceReport = '/Credit_Tax_Invoice_Report';
   static creditCashInvoiceReport = '/Credit_Cash_Invoice_Report';
   static TurnaroundTimeReport = '/TURNAROUND_TIME_REPORT';
   /* ================
   * Core Reports
   * ================
   */

   static visitLabel = '/Core_visitSticker'; // emergency station, outpatient station
   static erWristbandLabel = '/ER_Wristband_Label'; // emergency station
   static cardPatientReport = '/Patient_Card_Payer';// component
   static outPatientInformation = '/Out_PatientInformation';
   static sickLeaveReport: string = '/Sick_Leave_Report'; // component
   static ticketReport: string = '/Ticket_Report'; // component


   /* ================
   * Pharmacy Reports
   * ================
   */
   static pharmacyMedicalLabel = '/Pharmacy_Medical_Label'; // despense medication , pharmacy daily work, return request
   static pharmacyMedicalLabelByAction = '/Pharmacy_Medical_Label_By_Action'; // despense medication , pharmacy daily work, return request
   static pharmacyDischargePrescriptionReport = '/Dyn_Phr_Discharge_Prescription_Report'; //DischargePrescriptionReport
   static pharmacyUnitDoseDrugDistribution = '/Pharmacy_UnitDoseDrugDistribution';
   static pharmacyDispensedDrug = '/Pharmacy_DispensedDrug';
   static pharmacyDischargePrescriptionLogReport = '/Dyn_Phr_Discharge_Prescription_Log_Report'; //DischargePrescriptionLogReport



   /* ================
    * Security Reports
    * ================
   */

   static privilegeReport = '/Security_UserPrivilege'; // privilegeReport
}
