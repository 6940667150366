import { SelectItem } from 'primeng/api';
import { AllergySource } from 'app/shared/models/medispan/enums/allergy-source';

export class AllergySourceLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Class', value: AllergySource.Class },
            { label: 'Ingredient', value: AllergySource.Ingredient },
        ];
    }

}
