
export class ErArea {

    Id: number;
    Title: string;
    ForeignTitle: string;
    Status: boolean;
    Code: string;
    CreatedBy: number;
    CreatedDate: Date;
    FacilityId:number;
    constructor() {
    }
}