import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class SpecimenTransactionSearchService extends ServiceBase {

    private actionUrl: string;
    private _filterUnCollectedSpecimenUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = this._configuration.server;
        this._filterUnCollectedSpecimenUrl = this.actionUrl + this._configuration.SpecimenTransaction.filterUnCollectedSpecimen;
    }

    uncollectedSpecimenFilter = (viewModel): Observable<any> => {

        const orderDateString = viewModel?.OrderDate ? viewModel.OrderDate.toLocaleDateString() : '';

        let url: string = '';
        url += `${this._filterUnCollectedSpecimenUrl}?SpecimenNumber=${viewModel?.SpecimenNumber || ''}&&SpecimenType=${viewModel.SpecimenType || ''}` +
            `&&Status=${viewModel?.Status || null}&&OrderNumber=${viewModel?.OrderNumber || ''}` +
            `&&OrderDate=${orderDateString}&&PatientCode=${viewModel?.PatientCode || ''}&&PatientName=${viewModel?.PatientName || ''}` +
            `&&PatientSection=${viewModel?.PatientSection || ''}&&OrderDoctor=${viewModel?.OrderDoctor || ''}` +
            `&&CivilId=${viewModel?.CivilId || ''}`;
        return this._http.get<any>(url);
    }
}
