
import { Component, OnInit, OnDestroy, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { PatientDiagnosisService, AdmissionService } from 'app/shared/services';
import { AdmissionReasonInformation } from 'app/shared/models/admission/admission-reason-information';
import { PatientDiagnosis } from 'app/shared/models/core/patient-diagnosis';

@Component({
    selector: 'patient-diagnosis-information-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./patient-diagnosis-information.scss'],
    templateUrl: './patient-diagnosis-information.html',
})

export class PatientDiagnosisInformationComponent extends BaseComponent implements OnInit, OnDestroy {

    admissionReason: AdmissionReasonInformation = new AdmissionReasonInformation();
    patientDiagnosis: PatientDiagnosis[] = [];

    isNoDiagnosisCase: boolean;

    constructor(private patientDiagnosisService: PatientDiagnosisService
        , private admissionService: AdmissionService) {
        super();
    }

    ngOnInit() {
        this.isNoDiagnosisCase = true;
    }

    loadData(patientId: number, encounterId: number, patientTypeId: number) {
        this.getAdmissionReason(patientId, encounterId, patientTypeId);
        this.getPatientDiagnosis(patientId, encounterId, patientTypeId);
    }

    getAdmissionReason(patientId: number, encounterId: number, patientTypeId: number) {
        if (encounterId > 0) {
            this.admissionService.getAdmissionReasonByAdmissionId(encounterId)
                .subscribe((data: AdmissionReasonInformation) => {
                    this.admissionReason = data;
                });
        } else {
            this.showError('Please check admission data..!');
        }
    }

    getPatientDiagnosis(patientId: number, encounterId: number, patientTypeId: number) {
        if (encounterId === null || encounterId === undefined) {
            this.showError('Please check encounter data..!');
            return;
        }

        if (patientId === null || patientId === undefined) {
            this.showError('Please check patient data..!');
            return;
        }

        if (patientTypeId === null || patientTypeId === undefined) {
            this.showError('Please check encounter type data..!');

            return;
        }

        this.patientDiagnosisService.getAllByPatientEncounter(patientId, encounterId, patientTypeId)
            .subscribe((data: PatientDiagnosis[]) => {
                if (data.length > 0) {
                    this.patientDiagnosis = data;
                    this.isNoDiagnosisCase = false;
                } else {
                    this.isNoDiagnosisCase = true;
                }
            });
    }

    ngOnDestroy() {

    }

}
