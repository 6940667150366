import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'advance-search-claim-settlement-station-cmp',
    styleUrls: ['./advance-search-claim-settlement-station.scss'],
    templateUrl: './advance-search-claim-settlement-station.html'
})

export class AdvanceSearchClaimSettlementStationComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {

    }

    onSubmit() {

    }

    ngOnDestroy() {

    }

}
