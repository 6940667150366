import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { OrderActionLookupModel, OrderActionSearchModel, OrderActionSearchFilter, ServicesLookup, AllServicesLookup } from 'app/shared/models/queryModel/order-action-lookup-model';
import { OrderApiConfiguration } from 'app/shared/services/order-and-billing';
import { ReceiveOrderSearch } from 'app/shared/models/orders-and-billing/receive-order-search.model';
import { MedicationChartsSearch } from 'app/shared/models/queryModel/medication-charts-search-filter';
import { MedicationCharts } from 'app/shared/models/queryModel/medication-charts-result';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderActionAuditSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration, private _orderConfiguration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientOrderSearch.url;
    }

    getOrderActionLookup = (): Observable<OrderActionLookupModel> => {

        const url = `${this.actionUrl}OrderActionSearch/GetOrderAuditLookup`

        return this._http.get<any>(url);
    }

    getOrderActionSearch = (orderActionSearchFilter: OrderActionSearchFilter): Observable<OrderActionSearchModel[]> => {

        const url = `${this.actionUrl}OrderActionSearch${this.modelToSearchParams(orderActionSearchFilter)}`;

        return this._http.get<any>(url);
    }

    exportOrderActionAudit = (orderActionSearchFilter: OrderActionSearchFilter): Observable<any> => {

        const url = `${this.actionUrl}OrderActionSearch/CsvOrderAudit${this.modelToSearchParams(orderActionSearchFilter)}`;

        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    getServicesByCodeLookup = (code: string): Observable<ServicesLookup[]> => {

        const url = `${this.actionUrl}OrderActionSearch/GetServicesByCode?code=` + code;

        return this._http.get<any>(url);
    }

    GetAllServiceInformationByCodeLookup = (code: string): Observable<AllServicesLookup[]> => {

        const url = `${this.actionUrl}OrderActionSearch/GetAllServiceInformationByCode?code=` + code;

        return this._http.get<any>(url);
    }

    getServicesByNameLookup = (name: string): Observable<ServicesLookup[]> => {

        const url = `${this.actionUrl}OrderActionSearch/GetServicesByName?name=` + name;

        return this._http.get<any>(url);
    }


    getReceivedOrderBySearch = (data: ReceiveOrderSearch): Observable<any> => {

        let url = `${this.actionUrl}OrderActionSearch/GeReceivedOrderBySearch?pageIndex=${data.pageIndex}&rowNo=${data.rowNo}`;
        url += `&patientId=${data.patientId ? data.patientId : ''}`;
        url += `&itemId=${data.itemId ? data.itemId : ''}`;
        url += `&sectionId=${data.sectionId ? data.sectionId : ''}`;
        url += `&fromOrderDate=${data.fromOrderDate ? data.fromOrderDate.toISOString() : ''}`;
        url += `&toOrderDate=${data.toOrderDate ? data.toOrderDate.toISOString() : ''}`;
        url += `&orderStatusId=${data.orderStatusId ? data.orderStatusId : ''}`;
        url += `&orderId=${data.orderId ? data.orderId : ''}`;
        url += `&patientInformation=${data.patientInformation ? data.patientInformation : ''}`;
        url += `&doctorId=${data.doctorId ? data.doctorId : ''}`;
        url += `&encounterId=${data.encounterId ? data.encounterId : ''}`;
        url += `&patientTypeId=${data.patientTypeId ? data.patientTypeId : ''}`;
        url += `&itemTypeId=${data.itemTypeId || ''}`;
        url += `&genderId=${data.genderId || ''}`;
        url += `&documentCode=${data.documentCode || ''}`;

        return this._http.get<any>(url);
    }

    getReceivedOrderActionLog = (orderActionId: number): Observable<any> => {

        let url = `${this.actionUrl}OrderActionSearch/ReceivedOrderActionLog?orderActionId=${orderActionId}`;
        return this._http.get<any>(url);
    }

    getPharmacyReceivedOrderActionLog = (orderActionId: number): Observable<any> => {

        let url = `${this.actionUrl}OrderActionSearch/PharmacyReceivedOrderActionLog?orderActionId=${orderActionId}`;
        return this._http.get<any>(url);
    }

    getMedicationChartsBySearch = (data: MedicationChartsSearch): Observable<MedicationCharts[]> => {
        const queryString: string = this.serializeQueryStringLocalDateTime(data);
        const url = `${this.actionUrl}OrderActionSearch/GetMedicationChartsBySearch${queryString}`;

        return this._http.get<any>(url);
    }

    getDispensedMedicationChartsBySearch = (data: MedicationChartsSearch): Observable<MedicationCharts[]> => {
        const queryString: string = this.serializeQueryStringLocalDateTime(data);
        const url = `${this.actionUrl}OrderActionSearch/DispensedMedicationChartsBySearch${queryString}`;

        return this._http.get<any>(url);
    }

    cancelOrderByCancelAdmission = (data: any): Observable<any> => {

        const url = `${this.actionUrl}OrderActionSearch/CancelOrderByCancelAdmission`

        return this._http.put<any>(url, data);
    }

    modelToSearchParams(obj): string {

        let params: string = null;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (params == null) {
                    if (obj[key] instanceof Date) {
                        params = "?" + key + "=" + obj[key].toISOString();
                    } else {
                        params = "?" + key + "=" + obj[key];
                    }
                }
                else {
                    if (obj[key] instanceof Date) {
                        params = params + "&" + key + "=" + obj[key].toISOString();
                    } else {
                        if (obj[key] == null) {
                            params = params + "&" + key + "=";
                        } else {
                            params = params + "&" + key + "=" + obj[key] || '';
                        }
                    }
                }
            }
        }
        return params;
    }

    getRecieveOrdersCountsToday = (): Observable<any> => {

        let url = `${this._configuration.server}OrderActionSearch/RecieveOrdersCountsToday`;
        return this._http.get<any>(url);
    }
}
