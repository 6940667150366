import { SelectItem } from 'primeng/api';
import { BooleanType } from '../enums/boolean-type';

export class BooleanTypeLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Yes', value: BooleanType.Yes },
            { label: 'No', value: BooleanType.No },
        ];
    }
}
