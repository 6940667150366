import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientDischargeSearchFilter } from '../../../models/queryModel';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientOpenAndHaltStatmentSearchService extends ServiceBase {

  private actionUrl: string;
  private _get: string;
  private _action: string;
  private _getOpenAndHaltStatmentAdmission: string;
  constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
    super();
    this.actionUrl = _configuration.server;
    this._get = this.actionUrl + _configuration.patientOpenAndHaltStatmentSearch.url;
  }


  public getAdmmitedAndFinalDischarge(patientCode: string) {
    return this._http.get<any>(this._get + `?patientCode=${patientCode}`);
  }
}