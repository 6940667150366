import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { ExternalOrderStationSearchModel } from
    "app/shared/models/lab/external-order-station-search-model";
import { ExternalLaboratoryService } from 'app/shared/services/lab/externalLaboratory';
import { ExternalLaboratory } from 'app/shared/models/lab/externalLaboratory';
import { SelectItem, Message } from 'primeng/api';
import { BaseComponent } from 'app/shared/infrastructure';

@Component({
    selector: 'advance-search-external-order-station-cmp',
    templateUrl: './advance-search-external-order-station.html'
})

export class AdvanceSearchExternalOrderStationComponent extends BaseComponent implements OnInit {
    externalLabs: SelectItem[];
    ngOnInit() {
        this.getExternalLaboratories();
    }

    @Input()
    set orderId(orderId) {
        this.searchModel.orderNumber = orderId || null;
        this.onSubmit();
    }

    @Output() onSearch = new EventEmitter<ExternalOrderStationSearchModel>();
    maxDateValue: Date;
    minDateValue: Date;

    searchModel: ExternalOrderStationSearchModel;

    constructor(private externalLaboratoryService: ExternalLaboratoryService) {
        super();
        this.searchModel = new ExternalOrderStationSearchModel;
    }

    setMinDate() {
        this.minDateValue = this.searchModel.fromOrderDate;
    }

    setMaxDate() {
        this.maxDateValue = this.searchModel.toOrderDate;
    }

    onSubmit() {
        this.onSearch.emit(this.searchModel);
    }

    getExternalLaboratories() {
        this.externalLaboratoryService.get()
            .subscribe((data: ExternalLaboratory[]) => {
                this.externalLabs = [];
                this.externalLabs.push({ label: 'All Data', value: null });
                for (const lab of data) {
                    this.externalLabs.push({ label: lab.Title, value: lab.Id });
                }
            },
                error => this.showError(error));
    }
}
