import { DoctorType } from '../enums';

export class DoctorTypeLookup {

    static getLookup(): any[] {
        return [
            { label: 'Primary Doctor', value: DoctorType.PrimaryDoctor },
            { label: 'Visit Doctor', value: DoctorType.VisitDoctor },
        ];
    }
}
