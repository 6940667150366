import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class DocumentAlertSetupSearchService {

    constructor(private _http: HttpClient) { }

    get = (): Observable<any> => {
        return this._http.get<any>(`${environment.queryModel}DocumentAlertSetupSearch`);
    }
}