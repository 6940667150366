import { SelectItem } from 'primeng/api';
import { EligibilityResponseStatus } from '../enums';

export class EligibilityResponseStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Eligible', value: EligibilityResponseStatus.Eligible },
            { label: 'Not Active', value: EligibilityResponseStatus.NotActive },
            { label: 'Not Covered', value: EligibilityResponseStatus.NotCovered },
            { label: 'Not Direct Billing', value: EligibilityResponseStatus.NotDirectBilling },
            { label: 'Out Network', value: EligibilityResponseStatus.OutNetwork },
            { label: 'Limit Exhausted', value: EligibilityResponseStatus.LimitExhausted },
            { label: 'Coverage Suspended', value: EligibilityResponseStatus.CoverageSuspended },
            { label: 'Provider Contract Suspended', value: EligibilityResponseStatus.ProviderContractSuspended },
        ];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
