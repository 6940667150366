export enum SpecimenStatus {
    // Ordrerd = Rejected
    Ordrerd = 1,
    Rejected = 1,
    Collected = 2,
    Received = 3,
    Accepted = 4,
    UnderProcess = 5,
    Released = 6,
    Canceled = 7
}
