
import { PharmacyLookup } from './pharmacy-lookup';
import { PharmacyLookupCategory } from './pharmacy-lookup-category';
import { MedicationDefinition } from './medication-definition';

export class MedicationConfiguration {

    public Id: number;
    public LookUpId: number;
    public CategoryId: number;
    public MedicationDefintionId: number;
    public FacilityId: number;
    public Status: boolean;

    public Lookup: PharmacyLookup;
    public LookupCategory: PharmacyLookupCategory;
    public MedicationDefinition: MedicationDefinition;
    constructor() { }
}
