import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { TriageScaleSetup } from '../../../models/core/triage-scale-setup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class TriageScaleSetupService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.triageScaleSetup.url;
    }

    getActive = (): Observable<TriageScaleSetup[]> => {
        return this._http.get<any>(this._url+'/Active');
    }

    getAll = (): Observable<TriageScaleSetup[]> => {
        return this._http.get<any>(this._url+'/All');
    }

    getById = (id: number): Observable<TriageScaleSetup> => {
        return this._http.get<any>(this._url + '?id=' + id);
    }

    insert = (data: TriageScaleSetup): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: TriageScaleSetup): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
