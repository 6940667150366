import { Injectable } from '@angular/core';

import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { DimensionServiceApiConfiguration } from '../dimension-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class DemographicInfoService extends ServiceBase {

    private actionUrl: string;
    private _getByNationalId: string;
    private _url: string;
    constructor(private _http: HttpClient, private _configuration: DimensionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.serverUrl;
        this._getByNationalId = this.actionUrl + _configuration.demographicInfo.patientInfo;
    }

    getDemographicInfoByNationalId = (id): Observable<any> => {
        let url = `${this._getByNationalId}?id=${id}`;    
        return this._http.get<any>(url);
    }
}