import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class UpdateOutpatientSetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.updateOutpatientSetup.url;
    }

    get = (): Observable<any[]> => {
        return this._http.get<any>(this._url);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }
}
 