import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { InfectiousSources } from 'app/shared/models/core/enums/infectious-sources';
import { MainStaffService, NormalResultService, PatientInfectiousService, SensitiveResultService } from 'app/shared/services';

@Component({
  selector: 'infictious-info-cmp',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./infictious-info.scss'],
  templateUrl: './infictious-info.html',
})
export class InfictiousInfoComponent extends BaseComponent {

  infectiousSources = InfectiousSources;
  private _patient: any = {};
  @Input() get patient() {
    return this._patient
  };

  set patient(value) {
    this._patient = value;
    this.getInfictiousInfo();
  }

  patientInfectious: any;
  staff: any;
  item: any;
  constructor(private _patientInfectiousService: PatientInfectiousService,
    private _staffService: MainStaffService,
    private _normalResultService: NormalResultService,
    private _sensitiveResultService: SensitiveResultService) {
    super();
  }

  getInfictiousInfo() {
    if (!this.patient?.PatientId) {
      return;
    }
    this._patientInfectiousService
      .getByPatientEncounterInformation(this.patient.PatientId, this.patient.EncounterId, this.patient.PatientTypeId).subscribe((data) => {
        if (!data?.length) {
          this.reset();
        }
        this.patientInfectious = data[0];
        if (!this.patientInfectious) {
          return;
        }
        this.getStaffById();

        if (this.patientInfectious.SourceId !== InfectiousSources.Doctor) {
          this.getLabItem();
        }
      });
  }

  getStaffById() {
    this._staffService.getStaffById(this.patientInfectious.CreatedBy).subscribe(data => {
      this.staff = data;
    });
  }

  getLabItem() {
    let itemUrl;
    if (this.patientInfectious.SourceId === InfectiousSources.NormalResult) {
      itemUrl = this._normalResultService.getItemByNormalResult(this.patientInfectious.ReferenceId);
    }
    else if (this.patientInfectious.SourceId === InfectiousSources.CultureResult) {
      itemUrl = this._sensitiveResultService.getItemBySensitiveResult(this.patientInfectious.ReferenceId);
    }
    itemUrl.subscribe(data => {
      this.item = data;
    });

  }

  reset() {
    this.patient = null;
    this.patientInfectious = null;
    this.item = null;
    this.staff = null;
  }
}
