import { SelectItem } from 'primeng/api';
import { PendingAreaInfoStatus } from '../enums';

export class PendingAreaInfoStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'New', value: PendingAreaInfoStatus.New },
            { label: 'Approved', value: PendingAreaInfoStatus.Approved },
            { label: 'Canceled', value: PendingAreaInfoStatus.Canceled }];
    }

    static getAll(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'New', value: PendingAreaInfoStatus.New },
            { label: 'Approved', value: PendingAreaInfoStatus.Approved },
            { label: 'Canceled', value: PendingAreaInfoStatus.Canceled }];
    }

    static getTitleById(id): string {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id): SelectItem {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
