import { SelectItem } from 'primeng/api';
import { SmsSetupActionType } from '../enums';

export class SmsSetupActionTypeLookup {

    static get(): SelectItem[] {
        return [
            { label: 'Booking Appointment', value: SmsSetupActionType.BookingAppointment },
            { label: 'Cancel Appointment', value: SmsSetupActionType.CancelAppointment },
            { label: 'Reminder Appointment', value: SmsSetupActionType.ReminderAppointment },
        ];
    }
}
