import { TestDefinition } from './testDefinition';

export class ResultFormulaTest {

    Id: number;
    TestsDefinitionId: number;
    Operand: string;
    RelatedTestId: number;
    RelatedTestDefinition: TestDefinition;
}
