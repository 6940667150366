import { Injectable } from '@angular/core';
//import { SettlementElementDetailsMode } from 'app/shared/models/orders-and-billing/Settlement-Element-Details-model';
//import { SettlementItemDetailsModel } from 'app/shared/models/orders-and-billing/Settlement-Item-Details-model';

@Injectable()
export class MockSettlementDetailService {

    //settlemenInvoices: any[] = [
    //    {
    //        $id: 1,
    //        invoiceNumber: 1,
    //        claimNumber: 1,
    //        subscriptionNumber: 1,
    //        issueDate: new Date(),
    //        amount: 500,
    //        settlementAmount: 315,
    //        patientName: 'Noor Joudeh',
    //        isEditable: false,
    //        Stamp: 0,
    //        DrSubTax: 0
    //    },
    //    {
    //        $id: 2,
    //        invoiceNumber: 2,
    //        claimNumber: 2,
    //        subscriptionNumber: 2,
    //        issueDate: new Date(),
    //        amount: 18.25,
    //        settlementAmount: null,
    //        patientName: 'Noor Noor',
    //        isEditable: false,
    //        Stamp: 0,
    //        DrSubTax: 0
    //    },
    //    {
    //        $id: 3,
    //        invoiceNumber: 3,
    //        claimNumber: 3,
    //        subscriptionNumber: 3,
    //        issueDate: new Date(),
    //        amount: 20,
    //        settlementAmount: null,
    //        patientName: 'Joudeh Noor',
    //        isEditable: false,
    //        Stamp: 0,
    //        DrSubTax: 0
    //    },
    //    {
    //        $id: 4,
    //        invoiceNumber: 4,
    //        claimNumber: 4,
    //        subscriptionNumber: 4,
    //        issueDate: new Date(),
    //        amount: 9.75,
    //        settlementAmount: null,
    //        patientName: 'Joudeh Joudeh',
    //        isEditable: false,
    //        Stamp: 0,
    //        DrSubTax: 0
    //    },
    //];

    //settlementElementDetails: SettlementElementDetailsModel[] = [
    //    {
    //        TransNo: 1,
    //        ElementId: 1,
    //        ElementTitle: 'Dr Fees',
    //        NetAmount: 100,
    //        SettPercent: 15,
    //        SettAmount: 15,
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefInvoiceId: 1,
    //        IsEditable: false,
    //    },
    //    {
    //        TransNo: 2,
    //        ElementId: 2,
    //        ElementTitle: 'Accommodation',
    //        NetAmount: 10,
    //        SettPercent: 15,
    //        SettAmount: 100,
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefInvoiceId: 1,
    //        IsEditable: false,
    //    },
    //    {
    //        TransNo: 3,
    //        ElementId: 3,
    //        ElementTitle: 'Laboratory Tests',
    //        NetAmount: 10,
    //        SettPercent: 15,
    //        SettAmount: 100,
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefInvoiceId: 1,
    //        IsEditable: false,
    //    },
    //    {
    //        TransNo: 4,
    //        ElementId: 4,
    //        ElementTitle: 'Pharmacy',
    //        NetAmount: 10,
    //        SettPercent: 15,
    //        SettAmount: 100,
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefInvoiceId: 1,
    //        IsEditable: false,
    //    },
    //];

    //settlementItemDetails: SettlementItemDetailsModel[] = [
    //    {
    //        TransNo: 5,
    //        ItemId: 1,
    //        ItemCode: 'Item1',
    //        ItemTitle: 'Item1',
    //        NetAmount: 15,
    //        SettAmount: 2.25,
    //        SettType: 1,
    //        Note: 'Note',
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefElementId: 1,
    //        Stamp: 0,
    //        DrSubTax: 0,
    //    },
    //    {
    //        TransNo: 6,
    //        ItemId: 1,
    //        ItemCode: 'Item2',
    //        ItemTitle: 'Item2',
    //        NetAmount: 15,
    //        SettAmount: 2.25,
    //        SettType: 1,
    //        Note: 'Note',
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefElementId: 1,
    //        Stamp: 0,
    //        DrSubTax: 0,
    //    },
    //    {
    //        TransNo: 7,
    //        ItemId: 1,
    //        ItemCode: 'Item3',
    //        ItemTitle: 'Item3',
    //        NetAmount: 40,
    //        SettAmount: 6,
    //        SettType: 1,
    //        Note: 'Note',
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefElementId: 1,
    //        Stamp: 0,
    //        DrSubTax: 0,
    //    },
    //    {
    //        TransNo: 8,
    //        ItemId: 1,
    //        ItemCode: 'Item4',
    //        ItemTitle: 'Item4',
    //        NetAmount: 10,
    //        SettAmount: 1.5,
    //        SettType: 1,
    //        Note: 'Note',
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefElementId: 1,
    //        Stamp: 0,
    //        DrSubTax: 0,
    //    },
    //    {
    //        TransNo: 9,
    //        ItemId: 1,
    //        ItemCode: 'Item5',
    //        ItemTitle: 'Item5',
    //        NetAmount: 20,
    //        SettAmount: 3,
    //        SettType: 0,
    //        Note: 'Note',
    //        EntryBy: 5,
    //        EntryDate: new Date(0),
    //        FacilityId: 6,
    //        RefElementId: 1,
    //        Stamp: 0,
    //        DrSubTax: 0,
    //    },
    //];

    //constructor() {

    //}

    //getSettlemenInvoices(settlementId: number, invoiceNumber: number, subscriptionNumber: string, patientName: string, page: number, row: number) {
    //    return this.settlemenInvoices;
    //}

    //getSettlemenInvoiceElement(invoiceId: number) {
    //    return this.settlementElementDetails;
    //}

    //getSettlemenElementItem(elementId: number) {
    //    return this.settlementItemDetails;
    //}
}
