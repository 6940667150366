import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { SMSSetup } from 'app/shared/models/core/sms-setup';
import { SMSSetupConfiguration } from 'app/shared/models/core/sms-setup-configuration';
@Injectable()
export class SMSSetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _configurationUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.smsSetup.url;
        this._configurationUrl = this.actionUrl + _configuration.smsSetup.configurationUrl;
    }

    getAll = (): Observable<SMSSetup[]> => {
        return this._http.get<any>(`${this._url}`);
    }

    getConfiguration = (): Observable<SMSSetupConfiguration[]> => {
        return this._http.get<any>(`${this._configurationUrl}`);
    }

    post = (data: SMSSetup): Observable<SMSSetup> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: SMSSetup): Observable<SMSSetup> => {
        return this._http.put<any>(`${this._url}`, data);
    }
}
