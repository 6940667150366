export class ExternalOrderStationSearchModel {

    accessionNumber: string;
    patientMrn: string;
    orderNumber: string;
    testCode: string;
    fromOrderDate: Date;
    toOrderDate: Date;
    externalLaboratoryId: number;
    civilId: string;
    constructor() {
        this.externalLaboratoryId = null;
    }
}
