import { Component, EventEmitter, Output, OnInit } from '@angular/core';

// Services
import { LabSectionService } from "app/shared/services/lab/labSection/labSection.service";

// Models
import { Section } from "app/shared/models/lab/section";
import { TatMonitorSearchModel } from "app/shared/models/lab/tat-monitor-search-model";
import { BaseComponent } from 'app/shared/infrastructure';

@Component({
    selector: 'advance-search-tat-monitor-cmp',
    templateUrl: './advance-search-tat-monitor.html'
})

export class AdvanceSearchTatMonitorComponent extends BaseComponent implements OnInit {

    @Output() onSearch = new EventEmitter<TatMonitorSearchModel>();

    labSections: any;

    searchModel: TatMonitorSearchModel;

    maxDateValue: Date;
    minDateValue: Date;

    constructor(private labSectionService: LabSectionService) {
        super();
        this.searchModel = new TatMonitorSearchModel;
    }

    ngOnInit() {
        this.getLabSections();
    }

    setMinDate() {
        this.minDateValue = this.searchModel.fromOrderDate;
    }

    setMaxDate() {
        this.maxDateValue = this.searchModel.toOrderDate;
    }

    getLabSections() {
        this.labSectionService.getAll()
            .subscribe((data: Section[]) => {
                this.labSections = [];
                for (let i = 0; i < data.length; i++) {
                    this.labSections.push({ label: data[i].Title, value: data[i].Id });
                }
            },
                error => this.showError(error));
    }

    onSubmit() {
        this.onSearch.emit(this.searchModel);
    }
}
