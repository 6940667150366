import { MicrobiologyPanel } from './microbiology-panel';
import { Antibiotic } from './antibiotic';
import { SensitiveMicrobiology } from './sensitiveMicrobiology';


export class SensitiveMicrobiologyAnti {
    Id: number;
    SensitiveMicrobiologyId: number;
    MicroBiologyPanelId: number;
    AntibioticId: number;
    FlagId: number;
    MicUnit: number;
    SensitivityRanges: string;
    Remarks: string;

    Antibiotic: Antibiotic;
    MicrobiologyPanel: MicrobiologyPanel;
    SensitiveMicrobiology: SensitiveMicrobiology;

    constructor() {

    }
}