import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientStatement } from '../../../models/orders-and-billing/patient-statement';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientStatmentSearch } from '../../../models/queryModel/patient-statment-search';
import { PatientStatementFilter } from 'app/shared/models/queryModel/patient-statement-filter';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Injectable()
export class PatientStatmentSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientStatmentSearch.url;
    }

    getPatientStatment = (patientStatmentSearch: PatientStatmentSearch, researchPatientOnly: boolean, page: number, rows: number): Observable<PatientStatement[]> => {

        const patientId = patientStatmentSearch.PatientId ? patientStatmentSearch.PatientId : '';
        const patientCode = patientStatmentSearch.PatientCode ? patientStatmentSearch.PatientCode : '';
        const patientName = patientStatmentSearch.PatientName ? patientStatmentSearch.PatientName : '';
        const patientTypeId = patientStatmentSearch.PatientTypeId ? patientStatmentSearch.PatientTypeId : '';
        const dealingTypeId = patientStatmentSearch.DealingTypeId ? patientStatmentSearch.DealingTypeId : '';
        const payerId = patientStatmentSearch.PayerId ? patientStatmentSearch.PayerId : '';
        const providerName = patientStatmentSearch.ProviderName ? patientStatmentSearch.ProviderName : '';
        const encounterStatusId = patientStatmentSearch.EncounterStatusId ? patientStatmentSearch.EncounterStatusId : '';
        const encounterSectionId = patientStatmentSearch.EncounterSectionId ? patientStatmentSearch.EncounterSectionId : '';
        const encounterFromString = patientStatmentSearch.EncounterFromDate ? patientStatmentSearch.EncounterFromDate.toISOString() : '';
        const encounterToString = patientStatmentSearch.EncounterToDate ? patientStatmentSearch.EncounterToDate.toISOString() : '';
        const invoiceStatusId = patientStatmentSearch.InvoiceStatusId ? patientStatmentSearch.InvoiceStatusId : '';

        const statmentStatusId = patientStatmentSearch.StatmentStatusId ? patientStatmentSearch.StatmentStatusId : '';
        const invoiceId = patientStatmentSearch.InvoiceId ? patientStatmentSearch.InvoiceId : '';
        const issuedById = patientStatmentSearch.IssuedById ? patientStatmentSearch.IssuedById : '';

        const balanceNet = patientStatmentSearch.BalanceNet != null ? patientStatmentSearch.BalanceNet : '';
        const doctorId = patientStatmentSearch.DoctorId != null ? patientStatmentSearch.DoctorId : '';
        const isDonor = +patientStatmentSearch.IsDonor || '';

        const url = this.url
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientTypeId=' + patientTypeId
            + '&&PatientName=' + patientName
            + '&&DealingTypeId=' + dealingTypeId
            + '&&PayerId=' + payerId
            + '&&providerName=' + providerName
            + '&&EncounterStatusId=' + encounterStatusId
            + '&&EncounterSectionId=' + encounterSectionId
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatmentStatusId=' + statmentStatusId
            + '&&InvoiceId=' + invoiceId
            + '&&IssuedById=' + issuedById
            + '&&BalanceNet=' + balanceNet
            + '&&Page=' + page
            + '&&Rows=' + rows
            + '&&DoctorId=' + doctorId
            + '&&InvoiceStatusId=' + invoiceStatusId
            + '&&IsDonor=' + isDonor
            + '&&researchPatientOnly=' + researchPatientOnly;
        return this._http.get<any>(url);
    }

    public ExportPatientStatement = (patientStatmentSearch: PatientStatmentSearch, researchPatientOnly: boolean): Observable<any> => {

        const patientId = patientStatmentSearch.PatientId ? patientStatmentSearch.PatientId : '';
        const patientCode = patientStatmentSearch.PatientCode ? patientStatmentSearch.PatientCode : '';
        const patientName = patientStatmentSearch.PatientName ? patientStatmentSearch.PatientName : '';
        const patientTypeId = patientStatmentSearch.PatientTypeId ? patientStatmentSearch.PatientTypeId : '';
        const dealingTypeId = patientStatmentSearch.DealingTypeId ? patientStatmentSearch.DealingTypeId : '';
        const payerId = patientStatmentSearch.PayerId ? patientStatmentSearch.PayerId : '';
        const encounterStatusId = patientStatmentSearch.EncounterStatusId ? patientStatmentSearch.EncounterStatusId : '';
        const encounterSectionId = patientStatmentSearch.EncounterSectionId ? patientStatmentSearch.EncounterSectionId : '';
        const encounterFromString = patientStatmentSearch.EncounterFromDate ? patientStatmentSearch.EncounterFromDate.toLocaleDateString() : '';
        const encounterToString = patientStatmentSearch.EncounterToDate ? patientStatmentSearch.EncounterToDate.toLocaleDateString() : '';

        const statmentStatusId = patientStatmentSearch.StatmentStatusId ? patientStatmentSearch.StatmentStatusId : '';
        const invoiceId = patientStatmentSearch.InvoiceId ? patientStatmentSearch.InvoiceId : '';
        const issuedById = patientStatmentSearch.IssuedById ? patientStatmentSearch.IssuedById : '';

        const balanceNet = patientStatmentSearch.BalanceNet ? patientStatmentSearch.BalanceNet : '';
        const doctorId = patientStatmentSearch.DoctorId ? patientStatmentSearch.DoctorId : '';
        const invoiceStatusId = patientStatmentSearch.InvoiceStatusId ? patientStatmentSearch.InvoiceStatusId : '';

        const url = this.url + '/CsvPatientStatment'
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&PatientTypeId=' + patientTypeId
            + '&&DealingTypeId=' + dealingTypeId
            + '&&PayerId=' + payerId
            + '&&EncounterStatusId=' + encounterStatusId
            + '&&EncounterSectionId=' + encounterSectionId
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatmentStatusId=' + statmentStatusId
            + '&&InvoiceId=' + invoiceId
            + '&&IssuedById=' + issuedById
            + '&&BalanceNet=' + balanceNet
            + '&&DoctorId=' + doctorId
            + '&&InvoiceStatusId=' + invoiceStatusId
            + '&&researchPatientOnly=' + researchPatientOnly;
        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        return new Blob(['\ufeff', (<any>response).body], { type: contentType });
                    }

                    return response;
                }));
    }

    getCreditInvoicesSearch = (pageNo, rowNo, filter: PatientStatementFilter): Observable<PatientStatement[]> => {
        let queryString: string = '';
        queryString += '?invoiceId=';
        queryString += filter.InvoiceId ? filter.InvoiceId : '';

        queryString += '&&patientCode=';
        queryString += filter.PatientCode ? filter.PatientCode : '';

        queryString += '&&patientName=';
        queryString += filter.PatientName ? filter.PatientName : '';

        queryString += '&&patientTypeId=';
        queryString += filter.PatientTypeID ? filter.PatientTypeID : '';

        queryString += '&&payerId=';
        queryString += filter.PayerID ? filter.PayerID : '';

        queryString += '&&coverageContractId=';
        queryString += filter.CoverageContractId ? filter.CoverageContractId : '';

        queryString += '&&coverageClassId=';
        queryString += filter.CoverageClassId ? filter.CoverageClassId : '';

        queryString += '&&fromIssueDate=';
        queryString += filter.FromIssueDate ? filter.FromIssueDate.toDateString() : '';

        queryString += '&&toIssueDate=';
        queryString += filter.ToIssueDate ? filter.ToIssueDate.toDateString() : '';

        queryString += '&&pageNo=';
        queryString += pageNo;

        queryString += '&&rowNo=';
        queryString += rowNo;

        return this._http.get<any>(this.url + '/CreditInvoicesSearch' + queryString);
    }

    public exportCreditInvoices = (patientStatementFilter: PatientStatementFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?invoiceId=';
        queryString += patientStatementFilter.InvoiceId ? patientStatementFilter.InvoiceId : '';;

        queryString += '&&patientCode=';
        queryString += patientStatementFilter.PatientCode ? patientStatementFilter.PatientCode : '';

        queryString += '&&patientName=';
        queryString += patientStatementFilter.PatientName ? patientStatementFilter.PatientName : '';

        queryString += '&&patientTypeId=';
        queryString += patientStatementFilter.PatientTypeID ? patientStatementFilter.PatientTypeID : '';

        queryString += '&&payerId=';
        queryString += patientStatementFilter.PayerID ? patientStatementFilter.PayerID : '';

        queryString += '&&coverageContractId=';
        queryString += patientStatementFilter.CoverageContractId ? patientStatementFilter.CoverageContractId : '';

        queryString += '&&coverageClassId=';
        queryString += patientStatementFilter.CoverageClassId ? patientStatementFilter.CoverageClassId : '';

        queryString += '&&fromIssueDate=';
        queryString += patientStatementFilter.FromIssueDate ? patientStatementFilter.FromIssueDate.toDateString() : '';

        queryString += '&&toIssueDate=';
        queryString += patientStatementFilter.ToIssueDate ? patientStatementFilter.ToIssueDate.toDateString() : '';
        const url = this.url + '/CsvCreditInvoice' + queryString;
        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        return new Blob(['\ufeff', (<any>response).body], { type: contentType });
                    }

                    return response;
                }));
    }

    ClaimAmount = (filter: PatientStatementFilter): Observable<PatientStatement[]> => {
        let queryString: string = '';
        queryString += '?InvoiceId=';
        queryString += filter.InvoiceId ? filter.InvoiceId : '';;

        queryString += '&&PatientCode=';
        queryString += filter.PatientCode ? filter.PatientCode : '';

        queryString += '&&PatientName=';
        queryString += filter.PatientName ? filter.PatientName : '';

        queryString += '&&PatientTypeId=';
        queryString += filter.PatientTypeID ? filter.PatientTypeID : '';;

        queryString += '&&PayerId=';
        queryString += filter.PayerID ? filter.PayerID : '';;

        queryString += '&&CoverageContractId=';
        queryString += filter.CoverageContractId ? filter.CoverageContractId : '';

        queryString += '&&CoverageClassId=';
        queryString += filter.CoverageClassId ? filter.CoverageClassId : '';

        queryString += '&&FromIssueDate=';
        queryString += filter.FromIssueDate ? filter.FromIssueDate.toLocaleDateString() : '';

        queryString += '&&ToIssueDate=';
        queryString += filter.FromIssueDate ? filter.ToIssueDate.toLocaleDateString() : '';

        return this._http.get<any>(this.url + '/ClaimAmount' + queryString
        );
    }

    searchCloseInvoiceManagement = (filter): Observable<PatientStatement[]> => {
        const value = {
            patient: filter.patient || null,
            fromDate: filter.fromDate?.toLocaleDateString() || null,
            toDate: filter.toDate?.toLocaleDateString() || null,
            paidTypeId: filter.paidTypeId || null,
            isDepositInvoice: filter.isDepositInvoice || false,
            payerIds: filter.payerIds?.join(',') || null,
            statementTypeIds: filter.statementTypeIds?.join(',') || null,
            sectionIds: filter.sectionIds?.join(',') || null,
        }

        return this._http.post<any>(`${environment.queryModel}PatientStatmentSearch/SearchCloseInvoiceManagement`, value);
    }

    getPatientStatmentTotals = (patientStatmentSearch: PatientStatmentSearch): Observable<any> => {

        const patientId = patientStatmentSearch.PatientId ? patientStatmentSearch.PatientId : '';
        const patientCode = patientStatmentSearch.PatientCode ? patientStatmentSearch.PatientCode : '';
        const patientName = patientStatmentSearch.PatientName ? patientStatmentSearch.PatientName : '';
        const patientTypeId = patientStatmentSearch.PatientTypeId ? patientStatmentSearch.PatientTypeId : '';
        const dealingTypeId = patientStatmentSearch.DealingTypeId ? patientStatmentSearch.DealingTypeId : '';
        const payerId = patientStatmentSearch.PayerId ? patientStatmentSearch.PayerId : '';
        const providerName = patientStatmentSearch.ProviderName ? patientStatmentSearch.ProviderName : '';
        const encounterStatusId = patientStatmentSearch.EncounterStatusId ? patientStatmentSearch.EncounterStatusId : '';
        const encounterSectionId = patientStatmentSearch.EncounterSectionId ? patientStatmentSearch.EncounterSectionId : '';
        const encounterFromString = patientStatmentSearch.EncounterFromDate ? patientStatmentSearch.EncounterFromDate.toISOString() : '';
        const encounterToString = patientStatmentSearch.EncounterToDate ? patientStatmentSearch.EncounterToDate.toISOString() : '';
        const invoiceStatusId = patientStatmentSearch.InvoiceStatusId ? patientStatmentSearch.InvoiceStatusId : '';
        const statmentStatusId = patientStatmentSearch.StatmentStatusId ? patientStatmentSearch.StatmentStatusId : '';
        const invoiceId = patientStatmentSearch.InvoiceId ? patientStatmentSearch.InvoiceId : '';
        const issuedById = patientStatmentSearch.IssuedById ? patientStatmentSearch.IssuedById : '';
        const balanceNet = patientStatmentSearch.BalanceNet != null ? patientStatmentSearch.BalanceNet : '';
        const doctorId = patientStatmentSearch.DoctorId != null ? patientStatmentSearch.DoctorId : '';
        const isDonor = +patientStatmentSearch.IsDonor || '';

        return this._http.get<any>(this.url
            + '/StatementsTotals?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientTypeId=' + patientTypeId
            + '&&PatientName=' + patientName
            + '&&DealingTypeId=' + dealingTypeId
            + '&&PayerId=' + payerId
            + '&&providerName=' + providerName
            + '&&EncounterStatusId=' + encounterStatusId
            + '&&EncounterSectionId=' + encounterSectionId
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatmentStatusId=' + statmentStatusId
            + '&&InvoiceId=' + invoiceId
            + '&&IssuedById=' + issuedById
            + '&&BalanceNet=' + balanceNet
            + '&&DoctorId=' + doctorId
            + '&&InvoiceStatusId=' + invoiceStatusId
            + '&&IsDonor=' + isDonor
        );
    }

    getDonorStation = (pageIndex: number, rows: number = 10): Observable<any> => {
        const url = `${environment.queryModel}PatientStatmentSearch/GetDonorStation?` +
            `pageIndex=${pageIndex}&&rows=${rows}`;

        return this._http.get<any>(url);
    }
}
