import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class ReceiveEmergencyOrdersService extends ServiceBase {

    private actionUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
    }

    
    getReceivedOrderBySearch = (filter): Observable<any> => {

        let queryString: string = `${this.actionUrl}OrderActionSearch/GetErReceivedOrderBySearch?pageIndex=${filter.pageIndex || 1}&rowNo=${filter.rowNo || 10}`;
        queryString += `&patientId=${filter?.patientId ? filter.patientId : ''}`;
        queryString += `&itemId=${filter?.itemId ? filter.itemId : ''}`;
        queryString += `&sectionId=${filter?.sectionId ? filter.sectionId : ''}`;
        queryString += `&fromOrderDate=${filter?.fromOrderDate ? filter.fromOrderDate.toISOString() : ''}`;
        queryString += `&toOrderDate=${filter?.toOrderDate ? filter.toOrderDate.toISOString() : ''}`;
        queryString += `&orderStatusId=${filter?.orderStatusId ? filter.orderStatusId : ''}`;
        queryString += `&orderId=${filter?.orderId ? filter.orderId : ''}`;
        queryString += `&patientInformation=${filter?.patientInformation ? filter.patientInformation : ''}`;
        queryString += `&doctorId=${filter?.doctorId ? filter.doctorId : ''}`;
        queryString += `&encounterId=${filter?.encounterId ? filter.encounterId : ''}`;
        queryString += `&patientTypeId=${filter?.patientTypeId ? filter.patientTypeId : ''}`;

        return this._http.get<any>(queryString);
    }
}