

export class BlockedMedicalFileSetup {

    Id?: number;
    SectionNumber: number;
    Time: number;


    constructor() {
    }
}