export class ConsultationType {
    Code: string;
    Id: number;
    Title: string;
    ForeignTitle: number;
    FacilityId: string;
    ServiceId?: number;
    Status?: boolean;
    VisitType?: number;
    VisitSource?: number;
}
