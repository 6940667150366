import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { CashierTrans } from '../../models/orders-and-billing/cashier-trans';

@Injectable()
export class CashierTransService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byStaffIdUrl: string;
    private byLoggedUserUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.cashierTrans.url;
        this.byStaffIdUrl = this.actionUrl + _configuration.cashierTrans.byStaffId;
        this.byLoggedUserUrl = this.actionUrl + _configuration.cashierTrans.byLoggedUser;
    }

    byStaffId = (staffId: number): Observable<CashierTrans> => {
        const url = `${this.byStaffIdUrl}?staffId=${staffId}`;
        return this._http.get<any>(url);
    }

    byLoggedUser = (): Observable<CashierTrans> => {
        return this._http.get<any>(this.byLoggedUserUrl);
    }

    post(data: CashierTrans) {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    updated(data: CashierTrans) {
        return this._http.put<any>(this.url, JSON.stringify(data));
    }
}
