import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { HangFireApiConfiguration } from '../hang-fire-api-configuration';

@Injectable()
export class ClaimAttachmentService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;
    private _retry: string;

    constructor(private _http: HttpClient, private _configuration: HangFireApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._retry = this._actionUrl + _configuration.claimAttachment.retry;
    }

    retry = (claimPatientAttachmentId: number): Observable<any> => {
        return this._http.get<any>(this._retry + '?claimPatientAttachmentId=' + claimPatientAttachmentId);
    }
}