import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class SickLeaveService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _bySearch: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.sickLeave.url;
        this._bySearch = this.actionUrl + _configuration.sickLeave.bySearch
    }

    get = (filter): Observable<any> => {
        let queryString: string = `?PatientId=${filter.patientId || ''}`;
        queryString += `&DoctorId=${filter.doctorId || ''}`;
        queryString += `&Mobile=${filter.mobile || ''}`;
        queryString += `&DocumentCode=${filter.documentCode || ''}`;
        queryString += `&FromDate=${filter.fromDate?.toISOString() || ''}`;
        queryString += `&ToDate=${filter.toDate?.toISOString() || ''}`;
        queryString += `&Status=${filter.status}`;

        return this._http.get<any>(this._bySearch + queryString);
    }

}
