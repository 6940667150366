import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ErAreaService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.erArea.url;
        this._byIdUrl = this.actionUrl + _configuration.erArea.byId;

    }

    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(`${this._byIdUrl}?id=${id}`);
    }
    
    add(data: any) {
        return this._http.post<any>(this._url, data);
    }

    update(id: number, data: any) {
        return this._http.put<any>(`${this._url}?Id=${id}`, data);
    }
}
