import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { environment } from 'environments/environment';

@Injectable()
export class BillingSetupService {

    constructor(private http: HttpClient) { }

    getByFacility = (): Observable<any> => {
        return this.http.get<any>(`${environment.order}/BillingSetup/ByFacility`);
    }

    add = (data): Observable<any> => {
        return this.http.post<any>(`${environment.order}/BillingSetup`, data);
    }

    update = (data): Observable<any> => {
        return this.http.put<any>(`${environment.order}/BillingSetup`, data);
    }
}