export class ExternalLaboratory {

    Id: number;
    Title: string;
    ForeignTitle: string;
    ContactPerson: string;
    Doctor: string;
    Telephone: string;
    Address: string;

    constructor() {
    }
}