import { SelectItem } from 'primeng/api';
import { ResultSeverity } from 'app/shared/models/medispan/enums/result-severity';

export class ResultSeverityLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Minor', value: ResultSeverity.Minor },
            { label: 'Moderate', value: ResultSeverity.Moderate },
            { label: 'Major', value: ResultSeverity.Major },
            { label: 'Success', value: ResultSeverity.Success },
            { label: 'MissingInfo', value: ResultSeverity.MissingInfo },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Minor', value: ResultSeverity.Minor },
            { label: 'Moderate', value: ResultSeverity.Moderate },
            { label: 'Major', value: ResultSeverity.Major },
            { label: 'Success', value: ResultSeverity.Success },
            { label: 'MissingInfo', value: ResultSeverity.MissingInfo },
        ];
    }
}
