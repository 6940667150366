import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaSlider } from '.';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
  
@NgModule({
  imports: [
    CommonModule,
    // BrowserAnimationsModule
  ],

  declarations: [
    BaSlider
  ],
  exports: [
    BaSlider
  ]
})
export class BaSliderModule { }