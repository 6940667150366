import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';

import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { ReceiveOrderSetup } from "app/shared/models/orders-and-billing/receive-order-setup";

@Injectable()
export class ReceiveOrderSetupService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _byStaffUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.receiveOrderSetup.url;
        this._byStaffUrl = this._actionUrl + _configuration.receiveOrderSetup.byStaff;
    }

    public getByStaff = (staffId: number): Observable<ReceiveOrderSetup[]> => {
        return this._http.get<any>(this._byStaffUrl + '?staffId=' + staffId)
    }

    public insert = (receiveOrderSetup: ReceiveOrderSetup): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(receiveOrderSetup));
    }

    public update = (receiveOrderSetup: ReceiveOrderSetup): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(receiveOrderSetup));
    }
}
