import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CoverageDiscount } from "app/shared/models/orders-and-billing/coverage-discount";

@Injectable()
export class CoverageDiscountService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _discountsByClassId: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.coverageDiscount.url;
        this._discountsByClassId = this._actionUrl + _configuration.coverageDiscount.discountsByClassId;
    }

    public getDiscountsByClassId = (classId: number): Observable<CoverageDiscount[]> => {
        return this._http.get<any>(this._discountsByClassId + '?classId=' + classId)
    }

    public add = (data): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data)
    }
}
