export class ClaimAttachmentModel {
    patientCode: any;
    claimId: number;
    patientTypeId: number;
    doctorId: number;
    sectionId: number;
    statusId: number;
    payerId: number;
    coverageContractId: number;
    coverageClassId: number;
    fromDate: Date;
    toDate: Date;
    pageIndex: number;
    rowNumber: number;
}