import { SelectItem } from 'primeng/api';
import { PostPendingTransactionStatus } from '../enums/post-pending-transaction-status';

export class PostPendingTransactionStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Faild', value: PostPendingTransactionStatus.faild },
            { label: 'Success', value: PostPendingTransactionStatus.success },
            { label: 'In Progress', value: PostPendingTransactionStatus.inProgress }];
    }
}