import { SelectItem } from 'primeng/api';
import { DocumentationLevel } from 'app/shared/models/medispan/enums/documentation-level';

export class DocumentationLevelLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Established', value: DocumentationLevel.Established },
            { label: 'Probable', value: DocumentationLevel.Probable },
            { label: 'Suspected', value: DocumentationLevel.Suspected },
            { label: 'Possible', value: DocumentationLevel.Possible },
            { label: 'Doubtful Unknown', value: DocumentationLevel.DoubtfulUnknown },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Established', value: DocumentationLevel.Established },
            { label: 'Probable', value: DocumentationLevel.Probable },
            { label: 'Suspected', value: DocumentationLevel.Suspected },
            { label: 'Possible', value: DocumentationLevel.Possible },
            { label: 'Doubtful Unknown', value: DocumentationLevel.DoubtfulUnknown },
        ];
    }

}
