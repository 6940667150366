import { SelectItem } from 'primeng/api';
import { SpecimenStatus } from '../enums/specimenStatus';

export class SpecimenStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Ordrerd', value: SpecimenStatus.Ordrerd },
            { label: 'Collected', value: SpecimenStatus.Collected },
            { label: 'Received', value: SpecimenStatus.Received },
            { label: 'Accepted', value: SpecimenStatus.Accepted },
            { label: 'Under Process', value: SpecimenStatus.UnderProcess },
            { label: 'Released', value: SpecimenStatus.Released },
            { label: 'Canceled', value: SpecimenStatus.Canceled }];
    }
    static getSpecimenStatusLookup(): SelectItem[] {
        return [
            { label: 'Completed', value: SpecimenStatus.UnderProcess },
            { label: 'Released', value: SpecimenStatus.Released },
        ];
    }

}
