import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ContractStationCoverageDiscount } from 'app/shared/models/orders-and-billing/contract-station-coverage-discount';

@Injectable()
export class ContractStationCoverageDiscountService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _discountsByContractId: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.contractStationCoverageDiscount.url;
        this._discountsByContractId = this._actionUrl + _configuration.contractStationCoverageDiscount.discountsByContractId;
    }

    getDiscountsByContractId = (contractId: number): Observable<ContractStationCoverageDiscount[]> => {
        return this._http.get<any>(`${this._discountsByContractId}?contractId=${contractId}`)
    }

    add(data) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
