import { AuthService } from 'app/shared/services';
import { AppModule } from 'app/app.module';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
export class ServiceBase {

    options: any;
    authService: AuthService;
    public dimensionPassword: string;
    public dimensionUsername: string;
    public webSocketRetry: number;
    public sendNotificationURI: string;
    public notificationURI: string;
    public authorityUrl: string;
    constructor() {
        this.authService = AppModule.injector.get(AuthService);
        this.dimensionUsername = environment.dimensionUsername;
        this.dimensionPassword = environment.dimensionPassword;
        this.authorityUrl = environment.authorityService;
        this.webSocketRetry = environment.webSocketRetry;
        this.sendNotificationURI = environment.sendNotificationURI;
        this.notificationURI = environment.notificationURI;
    }

    serializeToQueryString(objectToSerialize: any): string {

        let queryString = '?';
        queryString += Object.keys(objectToSerialize).map((key) => {
            if (objectToSerialize[key] === null) {
                return `${encodeURIComponent(key)}=`;
            }
            if (objectToSerialize[key] instanceof Date) {
                return `${encodeURIComponent(key)}=${encodeURIComponent((objectToSerialize[key] as Date).toISOString())}`;
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(objectToSerialize[key])}`;
            }

        }).join('&');

        if (queryString === '?') {
            queryString = '';
        }
        var unwantedStr = /undefined|NaN/gi;
        queryString = queryString.replace(unwantedStr, "");

        return queryString;
    }

    serializeQueryStringLocalDateTime = (objectToSerialize: any): string => {

        let queryString = '?';
        queryString += Object.keys(objectToSerialize).map((key) => {
            if (objectToSerialize[key] === null) {
                return `${encodeURIComponent(key)}=`;
            }
            if (objectToSerialize[key] instanceof Date) {
                const date = `${objectToSerialize[key].toLocaleDateString()},${objectToSerialize[key].toLocaleTimeString()}`
                return `${encodeURIComponent(key)}=${date}`;
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(objectToSerialize[key])}`;
            }
        }).join('&');

        if (queryString === '?') {
            queryString = '';
        }
        var unwantedStr = /undefined|NaN/gi;
        queryString = queryString.replace(unwantedStr, "");
        return queryString;
    }

    protected handleError(error: Response | Error) {
        if (error instanceof Error) {
            return throwError((error as Error).message);
        }
        const errorResponse = error as Response;
        if (errorResponse.status && errorResponse.status === 401) {
            // Unauthorized
            this.authService = AppModule.injector.get(AuthService);
            this.authService.signinSilent();
            return throwError('Please wait for sign in...');
        }

        try {
            return throwError(errorResponse.statusText);
        } catch (e) {
            return throwError(errorResponse.statusText);
        }
    }
}
