import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';
@Injectable()
export class QMSTicketResultService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    getQMSPendingTickets = (sectionIds: any[]): Observable<any> => {
        const url = `${environment.notification}api/QMSTicket/PendingTickets`;
        return this._http.post<any>(url, sectionIds, { responseType: 'silent' as 'json', withCredentials: false });
    }

    getQMSActiveTickets = (sectionId: number, doctorId): Observable<any> => {
        const url = `${environment.notification}api/QMSTicket/ActiveTickets`;
        return this._http.get<any>(`${url}?sectionId=${sectionId}&doctorId=${doctorId}`, { responseType: 'silent' as 'json', withCredentials: false });
    }
}
