import { Component, OnInit } from '@angular/core';
import { TestDefinition } from './testDefinition';

export class GroupTest {
    
    Id: number;
    TestId: number;
    RelatedTestId: number;
    FacilityId: number;
    Serial: number;
    TestCode: string;
    Description: string;
    OtherDescription: string;
    IsDifferential: boolean;
    TestDefinition: TestDefinition;

    constructor() {
    }
}
