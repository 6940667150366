import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class EligibilityOrderResultService {

    constructor(private _http: HttpClient) { }

    byPatient = (patientId: number, encounterId: number, totalNetAmount: number = 0): Observable<any> => {
        const url = `${environment.queryModel}EligibilityOrderResult/ByPatient?` +
            `PatientId=${patientId}&EncounterId=${encounterId}&TotalNetAmount=${totalNetAmount}`;

        return this._http.get<any>(url);
    }
}