import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable'
import { HttpClient } from '@angular/common/http';

import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { DynamicForm } from 'app/shared/models/enum/dynamic-form';
import { environment } from 'environments/environment';

@Injectable()
export class DynamicFormService extends ServiceBase {


    constructor(private _http: HttpClient, private systemSettings: SystemSettingsService) {
        super();
    }

    CordinateUser(userId: any) {
        return this._http.post<any>(`${this.dynamicFormConstantUrl()}/schema/CordinateUser?userId=` + userId, JSON.stringify({}));
    }

    updatePatientForms(data:any) {
        return this._http.put<any>(`${this.dynamicFormConstantUrl()}/schema/updatePatientForms`, data);
    }
    dynamicFormConstantUrl() {
        return environment.dynamicFormPath;
    }

    dynamicFormConstantViewerUrl() {
        return environment.dynamicFormViewer;
    }

    allForms(): Observable<any> {
        return this._http.get<any>(`${this.dynamicFormConstantUrl()}/schema/uilist`);
    }

    public buildSmartFormsUiUrl(
        form: DynamicForm,
        patientId,
        encounterId,
        patientType,
        userSecurityId,
        patientName,
        doctorName
    ) {
        const formName = this.systemSettings.getDynamicFormByName(form);
        return `${this.dynamicFormConstantViewerUrl()}/form?encounterid=${encounterId}&patientid=${patientId}&patienttype=${patientType}&userid=${userSecurityId}&patientname=${patientName}&doctorname=${doctorName}&form=${formName}&output=embed&disablenav=1`;
    }

}