export class CollectSpecimenViewModel {
    specimenTransactionId: number;
    isSharedWith: boolean;
    specimenNo: number;
    orderId:number;
    availableSpecimenNo: number;
    id: number;
}














