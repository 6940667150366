import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { ReservationModel} from 'app/shared/models/admission/reservation-model';
import { Reservation } from 'app/shared/models/admission/reservation';
import { ReservationInfo } from 'app/shared/models/admission/reservation-info';
import { CancelReservationInfo } from 'app/shared/models/admission/cancel-reservation-info';

@Injectable()
export class ReservationService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _bySearchUrl: string;
    private _byIdUrl: string;
    private _cancelReservationUrl: string;

    constructor(private http: HttpClient, private configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this._url = this.actionUrl + configuration.reservation.url;
        this._bySearchUrl = this.actionUrl + configuration.reservation.bySearch;
        this._byIdUrl = this.actionUrl + configuration.reservation.byId;
        this._cancelReservationUrl = this.actionUrl + configuration.reservation.cancelReservation;
    }

    insert = (reservationModel: ReservationModel): Observable<Reservation> => {
        return this.http.post<any>(this._url, JSON.stringify(reservationModel));
    }

    getBySearch = (pageIndex: number, rowNo: number, date: Date, statusId?: number): Observable<ReservationInfo[]> => {

        const dateValue = date ? date.toISOString() : null;
        const statusValue = statusId ? statusId : null;

        return this.http.get<any>(`${this._bySearchUrl}?date=${dateValue}&statusId=${statusValue}&pageIndex=${pageIndex}&rowNo=${rowNo}`);
    }

    getById = (id: number): Observable<Reservation> => {
        return this.http.get<any>(`${this._byIdUrl}?id=${id}`);
    }

    cancelReservation = (cancelReservationInfo: CancelReservationInfo): Observable<Reservation> => {
        return this.http.post<any>(`${this._cancelReservationUrl}`, JSON.stringify(cancelReservationInfo));
    }

    getAdmissionDoctor = (id): Observable<any> => {
        return this.http.get<any>(`${this._url}?admissionId=${id}`);
    }

    add(data: any) {
        return this.http.post<any>(this._url, JSON.stringify(data));
    }
}
