import { Component, Input } from '@angular/core';
import { SharedService } from 'app/shared/services/shared/shortcut.service';

@Component({
    selector: 'shortcuts-cmp',
    templateUrl: './shortcuts.html',
    styleUrls: ['./shortcuts.scss']
})

export class ShortcutsComponent {

    _fileName: any;
    @Input() set fileName(value: string) {
        this._fileName = value;
        if (this._fileName) {
            this.setResults();
        }
    }

    public results: any[];
    constructor(private _sharedService: SharedService) {
    }

    setResults() {
        this.results = [];
        this._sharedService.getShortcuts(this._fileName)
            .subscribe((data: any[]) => {
                this.results = [];
                this.results = data;
            });
    }
}
