import { SelectItem } from 'primeng/api';
import { EligibilityPurposeType } from '../enums';

export class EligibilityPurposeTypeLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Benefit', value: EligibilityPurposeType.Benefit },
            { label: 'Discovery', value: EligibilityPurposeType.Discovery },
            { label: 'Validation', value: EligibilityPurposeType.Validation }];
    }

    static getWithoutValidation(): SelectItem[] {
        return [
            { label: 'Benefit', value: EligibilityPurposeType.Benefit },
            { label: 'Discovery', value: EligibilityPurposeType.Discovery },];
    }

    static getTitleById(id) {
        return this.get().find(f => f.value === id)?.label || '';
    }
}
