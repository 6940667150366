import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from "../coreApiConfiguration";
import { PrintTemplateSetup } from "app/shared/models/core/print-template-setup";

@Injectable({
    providedIn: 'root'
})

export class PrintTemplateSetupService extends ServiceBase {

    public printTemplateSetup: any = null;
    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.printTemplateSetup.url;
        this.getPrintTemplateSetup();
    }

    getSetup = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    addSetup = (data: PrintTemplateSetup): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    updateSetup = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getPrintTemplateSetup() {
        this.getSetup()
            .subscribe((data) => {
                this.printTemplateSetup = data;
            });
    }

}