import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { OrderActionSearchFilter, SectionsLookup, OrderBillingUnitLookup, OrderActionPayerLookup, OrderActionServiceCategoryLookup, OrderActionStaffLookup, BillElementsLookup, OrderActionLookupModel, ServicesLookup } from 'app/shared/models/queryModel/order-action-lookup-model';
import { OrderActionAuditSearchService } from 'app/shared/services/queryModel/order-action-audit-search/order-action-audit-search.service';
import { PatientService } from 'app/shared/services/core/patient/patient.service';
import { PatientVisitService } from 'app/shared/services/core/patientVisit/patientVisit.service';
import { AdmissionService } from 'app/shared/services/admissionService/admission/admission.service';
import { SelectItem } from 'primeng/api';
import { PatientStatmentStatusLookup } from 'app/shared/models/orders-and-billing/lookup/patient-statment-status-lookup';
import { ServiceStatusLookup } from 'app/shared/models/orders-and-billing/lookup/service-status-lookup';
import { DealingTypeLookup } from 'app/shared/models/orders-and-billing/lookup/dealing-type-lookup';
import { PatientTypesLookup } from 'app/shared/models/orders-and-billing/lookup/patient-types-lookup';
import { PatientEncounterInfo } from 'app/shared/models/queryModel/patient-encounter-info';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';


const orderEditButtons = [
    { label: 'Edit', value: 1 },
    { label: 'Cancel', value: 2 },
];


@Component({
    selector: 'advance-search-audit-order-cmp',
    templateUrl: './advance-search-audit-order.html'
})

export class AdvanceSearchAuditOrderComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    //@Output() onValidationError = new EventEmitter<any>();

    orderActionSearchFilter: OrderActionSearchFilter = new OrderActionSearchFilter();

    visitSectionFilter: any;
    visitTypeFilter: any;
    visitDoctorFilter: any;
    issuedByFilter: any;
    statementStatusFilter: any;
    dealingTypeFilter: any;
    payerFilter: any;
    serviceStatusFilter: any;
    unitFilter: any;
    serviceCategoryFilter: any;
    patients: any;

    visitTypeOptions: SelectItem[] = [];
    serviceStatusOptions: SelectItem[] = [];
    dealingOptions: SelectItem[] = [];
    patientStatementStatus: SelectItem[] = [];
    doctorscache: { [id: string]: OrderActionStaffLookup; } = {};


    doctors: OrderActionStaffLookup[] = new Array<OrderActionStaffLookup>();
    serviceCategories: OrderActionServiceCategoryLookup[] = new Array<OrderActionServiceCategoryLookup>();
    payers: OrderActionPayerLookup[] = new Array<OrderActionPayerLookup>();
    units: OrderBillingUnitLookup[] = new Array<OrderBillingUnitLookup>();
    sections: SectionsLookup[] = new Array<SectionsLookup>();
    encounters: PatientEncounterInfo[];
    encountersList: SelectItem[] = [];
    services: ServicesLookup[];

    searchForm: FormGroup;
    serviceCode: AbstractControl;
    serviceName: AbstractControl;

    constructor(private fb: FormBuilder, private orderActionAuditSearchService: OrderActionAuditSearchService, private patientService: PatientService,
        private patientVisitService: PatientVisitService, private admissionService: AdmissionService) {
        this.initializeLookup();
        this.searchForm = fb.group({
            'ServiceCode': [''],
            'ServiceName': [''],
        });
        this.serviceCode = this.searchForm.controls['ServiceCode'];
        this.serviceName = this.searchForm.controls['ServiceName'];
    }

    SearchAudit() {
        this.orderActionSearchFilter.VisitSectionId = this.visitSectionFilter == undefined ? '' : this.visitSectionFilter.ID;
        this.orderActionSearchFilter.VisitTypeId = this.visitTypeFilter == undefined ? null : this.visitTypeFilter;
        this.orderActionSearchFilter.VisitDoctor = this.visitDoctorFilter == undefined ? null : this.visitDoctorFilter.ID;
        this.orderActionSearchFilter.IssuedBy = this.issuedByFilter == undefined ? null : this.issuedByFilter.ID;
        this.orderActionSearchFilter.StatementStatus = this.statementStatusFilter == undefined ? null : this.statementStatusFilter;
        this.orderActionSearchFilter.DealingType = this.dealingTypeFilter == undefined ? null : this.dealingTypeFilter;
        this.orderActionSearchFilter.PayerId = this.payerFilter == undefined ? null : this.payerFilter.ID;
        this.orderActionSearchFilter.ServiceStatus = this.serviceStatusFilter == undefined ? null : this.serviceStatusFilter;

        this.orderActionSearchFilter.ServiceCategory = this.serviceCategoryFilter == undefined ? null : this.serviceCategoryFilter.CODE;
        if (this.orderActionSearchFilter.DischargeDateFrom > this.orderActionSearchFilter.DischargeDateTo ||
            this.orderActionSearchFilter.AdmissionDateFrom > this.orderActionSearchFilter.AdmissionDateTo ||
            this.orderActionSearchFilter.IssuedDateFrom > this.orderActionSearchFilter.IssuedDateTo) {

        } else {

            if ((this.orderActionSearchFilter.DischargeDateFrom != null && this.orderActionSearchFilter.DischargeDateTo != null) ||
                (this.orderActionSearchFilter.EncounterDateFrom != null && this.orderActionSearchFilter.EncounterDateTo != null) ||
                (this.orderActionSearchFilter.IssuedDateFrom != null && this.orderActionSearchFilter.IssuedDateTo != null) ||
                (this.orderActionSearchFilter.ServiceDateFrom != null && this.orderActionSearchFilter.ServiceDateTo != null) ||
                (this.orderActionSearchFilter.InvoiceId != null) ||
                (this.orderActionSearchFilter.PatientId != null)) {
                let validateDates: boolean = this.validateDateConstraint(this.orderActionSearchFilter.DischargeDateTo, this.orderActionSearchFilter.DischargeDateFrom, 6) &&
                    this.validateDateConstraint(this.orderActionSearchFilter.EncounterDateTo, this.orderActionSearchFilter.EncounterDateFrom, 6) &&
                    this.validateDateConstraint(this.orderActionSearchFilter.IssuedDateTo, this.orderActionSearchFilter.IssuedDateFrom, 6) &&
                    this.validateDateConstraint(this.orderActionSearchFilter.ServiceDateTo, this.orderActionSearchFilter.ServiceDateFrom, 6);

                if (validateDates) {
                    this.onSearch.emit(this.orderActionSearchFilter);
                } else {
                    this.onSearch.emit({ erorr: 'You cant Set Date larger than six months', errorstatus: true });
                }
            } else {
                this.onSearch.emit({ erorr: 'Please Provide at least Mrn,Invoice Id or Any date field', errorstatus: true });
            }
        }
    }

    validateDateConstraint(toDate: Date, fromDate: Date, monthCount: number): boolean {
        if (fromDate != null && toDate != null) {
            if ((toDate.getMonth() + 1) - (fromDate.getMonth() + 1) > monthCount) {

                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }

    resetFields() {
        this.orderActionSearchFilter.VisitSectionId = null;
        this.orderActionSearchFilter.VisitTypeId = null;
        this.orderActionSearchFilter.VisitDoctor = null;
        this.orderActionSearchFilter.IssuedBy = null;
        this.orderActionSearchFilter.StatementStatus = null;
        this.orderActionSearchFilter.DealingType = null;
        this.orderActionSearchFilter.PayerId = null;
        this.orderActionSearchFilter.ServiceStatus = null;
        this.orderActionSearchFilter.Unit = null;
        this.orderActionSearchFilter.ServiceCategory = null;
        this.orderActionSearchFilter.ServiceCode = null;
        this.orderActionSearchFilter.ServiceName = null;
        this.orderActionSearchFilter.OrderId = null;
    }

    initializeLookup() {
        this.orderActionAuditSearchService.getOrderActionLookup().subscribe((searchModel: OrderActionLookupModel) => {
            this.doctors = searchModel.Doctors;
            this.serviceCategories = searchModel.ServiceCategories;
            this.payers = searchModel.Payers;
            this.units = searchModel.UnitsLookup;
            this.sections = searchModel.SectionsLookup;
            this.initializeDoctorsCache();
        });
    }

    initializeDoctorsCache() {
        this.doctors.forEach((doctor) => {
            this.doctorscache[doctor.AP_ID] = doctor;
        });
    }

    ngOnInit() {
        this.visitTypeOptions = PatientTypesLookup.getLookup();
        this.dealingOptions = DealingTypeLookup.getLookup();
        this.serviceStatusOptions = ServiceStatusLookup.getLookup();
        this.patientStatementStatus = PatientStatmentStatusLookup.getLookup();
    }

    filterByPatientMRN(event) {
        this.patientService.getPatientByCode(event)
            .subscribe((data: any) => {
                if (data && data.Id) {
                    this.orderActionSearchFilter.PatientId = data.Id;
                    this.setEncounters();
                }
                this.patients = data;
            });
    }


    setEncounters() {
        this.encounters = [];
        this.encountersList = [];
        this.encountersList.push({ label: 'All', value: null });
        let title: string;
        this.patientVisitService.getAllPatientEncounters(this.orderActionSearchFilter.PatientId)
            .subscribe((data: PatientEncounterInfo[]) => {
                data.forEach(element => {
                    this.encounters.push(element);
                    title = 'Date: ' + element.EncounterDate + '  |  ' + 'Doctor: ' + element.EncounterDoctorFullName + '  |  ' + 'Section: ' + element.EncounterSectionTitle;
                    this.encountersList.push({ label: title, value: element.EncounterId });
                });
            });
        this.admissionService.getAllPatientEncounters(this.orderActionSearchFilter.PatientId)
            .subscribe((data: PatientEncounterInfo[]) => {
                data.forEach(element => {
                    this.encounters.push(element);
                    title = 'Date: ' + element.EncounterDate + '  |  ' + 'Doctor: ' + element.EncounterDoctorFullName + '  |  ' + 'Section: ' + element.EncounterSectionTitle;
                    this.encountersList.push({ label: title, value: element.EncounterId });
                });
            });
    }

    ngOnDestroy() {
    }

    filterServices(event) {
        this.orderActionAuditSearchService.getServicesByCodeLookup(event.query).subscribe((data: ServicesLookup[]) => {
            this.services = data;
        });
    }

    filterServicesByName(event) {
        this.orderActionAuditSearchService.getServicesByNameLookup(event.query).subscribe((data: ServicesLookup[]) => {
            this.services = data;
        });
    }

    setServiceCode(event) {
        this.orderActionSearchFilter.ServiceCode = event.ID;
    }

    setServiceName(event) {
        this.orderActionSearchFilter.ServiceName = event.TITLE;
    }

    clearServiceCode(event) {
        this.serviceCode.reset();
        this.orderActionSearchFilter.ServiceCode = null;
    }

    clearServiceName(event) {
        this.serviceName.reset();
        this.orderActionSearchFilter.ServiceName = null;
    }
}
