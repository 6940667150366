import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from 'app/shared/infrastructure';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorAppointmentService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    cancelAppointment = (id: number, cancelReasonId: number): Observable<any> => {

        return this._http.put<any>(`${environment.httpAggregatorApi}Appointment/Cancel?id=${id}&&cancelReasonId=${cancelReasonId}`, null, { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    activeBillService = (data): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}Appointment/ActiveBillForAppointment`, data, { responseType: 'appointment' as 'json', withCredentials: false, })
    }
}
