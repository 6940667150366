import { SelectItem } from "primeng/api";

export enum DiscountPer {
    Item = 1,
    NetAmount = 2,
}

export class DiscountPerLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Item', value: DiscountPer.Item },
            { label: 'Net Amount', value: DiscountPer.NetAmount }];
    }
}