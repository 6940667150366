import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CashBoxPendingTransferRequestViewModel } from 'app/shared/models/orders-and-billing/cash-box-pending-transfer-request-view-model';
import { SendTransferRequest } from 'app/shared/models/orders-and-billing/send-transfer-request';
import { TransferRequest } from 'app/shared/models/orders-and-billing/transfer-request.model';
import { AnswerTransferRequest } from 'app/shared/models/orders-and-billing/answer-transfer-request.model';

@Injectable()
export class CashBoxTransferService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private pendingRequest: string;
    private requestDetailsUrl: string;
    private sendTransferRequestUrl: string;
    private acceptTransferRequestUrl: string;
    private rejectTransferRequestUrl: string;
    private retrySapDispatchMoneyUrl: string;
    private changeActualAmountUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.cashBoxTranfer.url;
        this.pendingRequest = this.actionUrl + configuration.cashBoxTranfer.pendingRequest;
        this.requestDetailsUrl = this.actionUrl + configuration.cashBoxTranfer.requestDetailsUrl;
        this.sendTransferRequestUrl = this.actionUrl + configuration.cashBoxTranfer.sendTransferRequestUrl;
        this.acceptTransferRequestUrl = this.actionUrl + configuration.cashBoxTranfer.acceptTransferRequestUrl;
        this.rejectTransferRequestUrl = this.actionUrl + configuration.cashBoxTranfer.rejectTransferRequestUrl;
        this.retrySapDispatchMoneyUrl = this.actionUrl + configuration.cashBoxTranfer.retrySapDispatchMoney;
        this.changeActualAmountUrl = this.actionUrl + configuration.cashBoxTranfer.changeActualAmount;
    }

    sendTransferRequest = (sendTransferRequest: SendTransferRequest): Observable<any> => {
        return this.http.post<any>(this.sendTransferRequestUrl, JSON.stringify(sendTransferRequest));
    }

    getPendingRequest = (): Observable<CashBoxPendingTransferRequestViewModel[]> => {
        return this.http.get<any>(this.pendingRequest);
    }

    requestDetails = (fromUserId: number, fromFundId: number, facilityId: number, requestDateIso: string, toUserId: number, toFundId: number, cashBoxTransferNo: number): Observable<TransferRequest> => {
        const url = `${this.requestDetailsUrl}?fromUserId=${fromUserId}&fromFundId=${fromFundId}&facilityId=${facilityId}&requestDate=${requestDateIso}&toUserId=${toUserId}&toFundId=${toFundId}&cashBoxTransferNo=${cashBoxTransferNo}`;
        return this.http.get<any>(url);
    }

    acceptTransferRequest = (answerTransferRequest: AnswerTransferRequest): Observable<any> => {
        return this.http.post<any>(this.acceptTransferRequestUrl, JSON.stringify(answerTransferRequest));
    }

    changeActualAmount = (cashBoxTransferNo, actualAmount): Observable<any> => {
        return this.http.put<any>(`${this.changeActualAmountUrl}?cashBoxTransferNo=${cashBoxTransferNo}&actualAmount=${actualAmount}`, null);
    }

    rejectTransferRequest = (answerTransferRequest: AnswerTransferRequest): Observable<any> => {
        return this.http.post<any>(this.rejectTransferRequestUrl, JSON.stringify(answerTransferRequest));
    }

    retrySapDispatchMoney = (transferNumbers: number[]): Observable<any> => {
        return this.http.post<any>(this.retrySapDispatchMoneyUrl, JSON.stringify(transferNumbers));
    }
}
