import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemRelation } from '../../../models/orders-and-billing/item-relation';
import { GroupItemSearchFilter } from '../../../models/orders-and-billing/group-item-search-filter';

@Injectable()
export class GroupItemService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private suggestedGroupItemUrl: string;
    private groupItemBySearchUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.item.url;
        this.suggestedGroupItemUrl = this.actionUrl + _configuration.groupItem.suggestedGroupItem;
        this.groupItemBySearchUrl = this.actionUrl + _configuration.groupItem.groupItemBySearch;
    }


    public getSuggestedGroupItem = (query: string, groupId: number): Observable<Item[]> => {
        return this._http.get<any>(this.suggestedGroupItemUrl
            + '?query=' + query
            + '&&groupId=' + groupId
        )
    }

    public getGroupItemBySearch = (groupItemSearchFilter: GroupItemSearchFilter): Observable<ItemRelation[]> => {
        groupItemSearchFilter.Code = groupItemSearchFilter.Code ? groupItemSearchFilter.Code : "";
        groupItemSearchFilter.Description = groupItemSearchFilter.Description ? groupItemSearchFilter.Description : "";

        return this._http.get<any>(this.groupItemBySearchUrl
            + '?GroupId=' + groupItemSearchFilter.GroupId
            + '&&Code=' + groupItemSearchFilter.Code
            + '&&Description=' + groupItemSearchFilter.Description
            + '&&IsOrderable=' + groupItemSearchFilter.IsOrderable
            + '&&IsChargable=' + groupItemSearchFilter.IsChargable
            + '&&IsVariablePrice=' + groupItemSearchFilter.IsVariablePrice
        )
    }
}