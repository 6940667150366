import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { MedicationProduct } from 'app/shared/models/pharmacy/medication-product';
import { ItemBasicInfo } from 'app/shared/models/orders-and-billing/item-basic-info';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class MedicationProductService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getFilteredMedicationProductUrl: string;
    private _getWithItemsUrl: string;
    private _action: string;
    private _getByMedicationDefinitionIdUrl: string;
    private _getByMedicationDefinitionIdLookupUrl: string;
    private _getDefaultRout: string;
    private _filterByMedicationDefinitionId: string;
    private _getAlternateBatch: string;
    private _getAlternateByIngredientBatch: string;
    private _getAlternateBalance: string;
    private _getAlternateByIngredientBalance: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.medicationProduct.url;
        this._getFilteredMedicationProductUrl = this.actionUrl + _configuration.medicationProduct.getFilteredMedicationProductUrl;
        this._getWithItemsUrl = this.actionUrl + _configuration.medicationProduct.getWithItems;
        this._getByMedicationDefinitionIdUrl = this.actionUrl + _configuration.medicationProduct.getByMedicationDefinitionIdUrl;
        this._getByMedicationDefinitionIdLookupUrl = this.actionUrl + _configuration.medicationProduct.getFilteredMedicationProductLookupUrl;
        this._filterByMedicationDefinitionId = this.actionUrl + _configuration.medicationProduct.filterByMedicationDefinitionId;
        this._getAlternateBatch = this.actionUrl + _configuration.medicationProduct.getAlternateBatch;
        this._getAlternateByIngredientBatch = this.actionUrl + _configuration.medicationProduct.getAlternateByIngredientBatch;
        this._getAlternateBalance = this.actionUrl + _configuration.medicationProduct.getAlternateWithBalance;
        this._getAlternateByIngredientBalance = this.actionUrl + _configuration.medicationProduct.getAlternateByIngredientBalance;
    }

    getFilteredMedicationProductLookup = (query): Observable<ItemBasicInfo[]> => {
        const url = `${this._getByMedicationDefinitionIdLookupUrl}?query=${query}`;

        return this._http.get<any>(url);
    }

    getWithItems = (query): Observable<MedicationProduct[]> => {
        const url = `${this._getWithItemsUrl}?query=${query}`;

        return this._http.get<any>(url);
    }
 

    gettMedicationDefinitionProduct = (medicationDefinitionId: number): Observable<MedicationProduct[]> => {
        return this._http.get<any>(this._getByMedicationDefinitionIdUrl + '?medicationDefinitionId=' + medicationDefinitionId);
    }

    filterByMedicationDefinitionId = (query: string, id: number): Observable<MedicationProduct[]> => {
        return this._http.get<any>(`${this._filterByMedicationDefinitionId}?query=${query}&medicationDefinitionId=${id}`)
    }

    insert = (medicationProduct: MedicationProduct): Observable<MedicationProduct> => {
        return this._http.post<MedicationProduct>(this._url, medicationProduct)
    }

    update = (medicationProduct: MedicationProduct): Observable<MedicationProduct> => {
        return this._http.put<MedicationProduct>(this._url, medicationProduct)
    }

    getAlternateBatch = (medicationDefinitionId: number, itemId: number, warehouseCode: string) : Observable<MedicationProduct[]> => {
        return this._http.get<any>(`${this._getAlternateBatch}?medicationDefinitionId=${medicationDefinitionId}&itemId=${itemId}&warehouseCode=${warehouseCode}`);
    }

    getAlternateByIngredientBatch = (medicationDefinitionId: number, itemId: number, warehouseCode: string) : Observable<MedicationProduct[]> => {
        return this._http.get<any>(`${this._getAlternateByIngredientBatch}?medicationDefinitionId=${medicationDefinitionId}&itemId=${itemId}&warehouseCode=${warehouseCode}`);
    }

    getAlternateBalance = (medicationDefinitionId: number, itemId: number, warehouseCode: string) : Observable<MedicationProduct[]> => {
        return this._http.get<any>(`${this._getAlternateBalance}?medicationDefinitionId=${medicationDefinitionId}&itemId=${itemId}&warehouseCode=${warehouseCode}`);
    }

    getAlternateByIngredientBalance = (medicationDefinitionId: number, itemId: number, warehouseCode: string) : Observable<MedicationProduct[]> => {
        return this._http.get<any>(`${this._getAlternateByIngredientBalance}?medicationDefinitionId=${medicationDefinitionId}&itemId=${itemId}&warehouseCode=${warehouseCode}`);
    }
}
