import { Component, OnInit } from '@angular/core';

export class Control {

    Id: number;
    Title: string;
    ForeignTitle: string;
    CodeId: number;
    constructor() {
    }
}