import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PayerDiagnosisGroupDetailsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.PayerDiagnosisGroupDetails.url;
    }

    insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete(`${this._url}/${id}`);
    }

    getById = (id): Observable<any[]> => {
        return this._http.get<any>(`${this._url}/${id}`);
    }
}
