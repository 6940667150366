import { SelectItem } from 'primeng/api';
import { ClaimAttachmentStatus } from '../enums';

export class ClaimAttachmentStatusLookup {

    static getSuccessFailed(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Success', value: ClaimAttachmentStatus.Success },
            { label: 'Failed', value: ClaimAttachmentStatus.Failed },
        ];
    }
}
