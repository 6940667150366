export class PriceList {
    GuarantorPolicy: any;
    Id: number;
    FacilityId: number;
    Title: string;
    ForeignTitle:string;
    IsStandard: boolean = false;
    IsUtilizedFromStandard: boolean = true;
    PriceListProfitMarginId?: number;
    Status: boolean = false;
    Code: string;
}