import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { forkJoin, map } from 'rxjs';

@Injectable()
export class HospitalPerformanceService extends ServiceBase {

    private actionUrl: string;
    private _generalPerformance: string;

    // Radiology Revenues
    private _dailyRadiologyRevenue: string;
    private _monthlyRadiologyRevenue: string;
    private _yearlyRadiologyRevenue: string;
    // Laboratory Revenues
    private _dailyLaboratoryRevenue: string;
    private _monthlyLaboratoryRevenue: string;
    private _yearlyLaboratoryRevenue: string;
    // Emergency Revenues
    private _dailyEmergencyRevenue: string;
    private _monthlyEmergencyRevenue: string;
    private _yearlyEmergencyRevenue: string;
    // In Patient Dealing Type
    private _dailyInPatientRevenuesByDealingType: string;
    private _monthlyInPatientRevenuesByDealingType: string;
    private _yearlyInPatientRevenuesByDealingType: string;

    // Out Patient Dealing Type
    private _dailyOutPatientRevenuesByDealingType: string;
    private _monthlyOutPatientRevenuesByDealingType: string;
    private _yearlyOutPatientRevenuesByDealingType: string;

    // Revenues By Section
    private _dailyRevenuesBySection: string;
    private _monthlyRevenuesBySection: string;
    private _yearlyRevenuesBySection: string;


    // Revenues By Element
    private _dailyRevenuesByElement: string;
    private _monthlyRevenuesByElement: string;
    private _yearlyRevenuesByElement: string;

    // EXPORT Revenues By Section
    private _csvDailyRevenuesBySection: string;
    private _csvMonthlyRevenuesBySection: string;
    private _csvYearlyRevenuesBySection: string;

    // EXPORT Revenues By Elements
    private _csvDailyRevenuesByElements: string;
    private _csvMonthlyRevenuesByElements: string;
    private _csvYearlyRevenuesByElements: string;


    //By Dealing Type
    private _dailyRevenuesByDealingType: string;
    private _monthlyRevenuesByDealingType: string;
    private _yearlyRevenuesByDealingType: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._generalPerformance = this.actionUrl + _configuration.hospitalPerformance.generalPerformance;

        // Radiology Revenues
        this._dailyRadiologyRevenue = this.actionUrl + _configuration.hospitalPerformance.dailyRadiologyRevenue;
        this._monthlyRadiologyRevenue = this.actionUrl + _configuration.hospitalPerformance.monthlyRadiologyRevenue;
        this._yearlyRadiologyRevenue = this.actionUrl + _configuration.hospitalPerformance.yearlyRadiologyRevenue;
        // Laboratory Revenues
        this._dailyLaboratoryRevenue = this.actionUrl + _configuration.hospitalPerformance.dailyLaboratoryRevenue;
        this._monthlyLaboratoryRevenue = this.actionUrl + _configuration.hospitalPerformance.monthlyLaboratoryRevenue;
        this._yearlyLaboratoryRevenue = this.actionUrl + _configuration.hospitalPerformance.yearlyLaboratoryRevenue;
        // Laboratory Revenues
        this._dailyEmergencyRevenue = this.actionUrl + _configuration.hospitalPerformance.dailyEmergencyRevenue;
        this._monthlyEmergencyRevenue = this.actionUrl + _configuration.hospitalPerformance.monthlyEmergencyRevenue;
        this._yearlyEmergencyRevenue = this.actionUrl + _configuration.hospitalPerformance.yearlyEmergencyRevenue;
        // In Patient Dealing Type
        this._dailyInPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.dailyInPatientRevenuesByDealingType;
        this._monthlyInPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.monthlyInPatientRevenuesByDealingType;
        this._yearlyInPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.yearlyInPatientRevenuesByDealingType;

        // Out Patient Dealing Type
        this._dailyOutPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.dailyOutPatientRevenuesByDealingType;
        this._monthlyOutPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.monthlyOutPatientRevenuesByDealingType;
        this._yearlyOutPatientRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.yearlyOutPatientRevenuesByDealingType;

        // Revenues By Section
        this._dailyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.dailyRevenuesBySection;
        this._monthlyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.monthlyRevenuesBySection;
        this._yearlyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.yearlyRevenuesBySection;

        // Revenues By Element
        this._dailyRevenuesByElement = this.actionUrl + _configuration.hospitalPerformance.dailyRevenuesByElement;
        this._monthlyRevenuesByElement = this.actionUrl + _configuration.hospitalPerformance.monthlyRevenuesByElement;
        this._yearlyRevenuesByElement = this.actionUrl + _configuration.hospitalPerformance.yearlyRevenuesByElement;

        // EXPORT Revenues By Section
        this._csvDailyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.csvDailyRevenuesBySection;
        this._csvMonthlyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.csvMonthlyRevenuesBySection;
        this._csvYearlyRevenuesBySection = this.actionUrl + _configuration.hospitalPerformance.csvYearlyRevenuesBySection;

        // EXPORT Revenues By Elements
        this._csvDailyRevenuesByElements = this.actionUrl + _configuration.hospitalPerformance.csvDailyRevenuesByElement;
        this._csvMonthlyRevenuesByElements = this.actionUrl + _configuration.hospitalPerformance.csvMonthlyRevenuesByElement;
        this._csvYearlyRevenuesByElements = this.actionUrl + _configuration.hospitalPerformance.csvYearlyRevenuesByElement;

        //By Dealing Type
        this._dailyRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.dailyRevenuesByDealingType;
        this._monthlyRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.monthlyRevenuesByDealingType;
        this._yearlyRevenuesByDealingType = this.actionUrl + _configuration.hospitalPerformance.yearlyRevenuesByDealingType;

    }

    public getGeneralPerformance = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._generalPerformance + queryString);
    }

    // In Patient Dealing Type

    public getInPatientDailyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._dailyInPatientRevenuesByDealingType + queryString);
    }


    getDailyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += `?date=${new Date(selectedDate).toLocaleDateString()}` : new Date().toLocaleDateString();

        const inPatient = this._http.get<any>(this._dailyInPatientRevenuesByDealingType + queryString).pipe(map(res => res));
        const outPatient = this._http.get<any>(this._dailyOutPatientRevenuesByDealingType + queryString).pipe(map(res => res));
        return forkJoin([inPatient, outPatient]);
    }

    public getInPatientMonthlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyInPatientRevenuesByDealingType + queryString);
    }

    public getInPatientYearlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyInPatientRevenuesByDealingType + queryString);
    }

    // Out Patient Dealing Type

    public getOutPatientDailyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._dailyOutPatientRevenuesByDealingType + queryString);
    }

    public getOutPatientMonthlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyOutPatientRevenuesByDealingType + queryString);
    }

    public getOutPatientYearlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyOutPatientRevenuesByDealingType + queryString);
    }

    // Revenues By Section

    public getDailyRevenuesBySection = (sectionId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._dailyRevenuesBySection + queryString);
    }

    public getMonthlyRevenuesBySection = (sectionId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._monthlyRevenuesBySection + queryString);
    }

    public getYearlyRevenuesBySection = (sectionId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._yearlyRevenuesBySection + queryString);
    }

    // Revenues By Element

    public getDailyRevenuesByElement = (elementId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._dailyRevenuesByElement + queryString);
    }

    public getMonthlyRevenuesByElement = (elementId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._monthlyRevenuesByElement + queryString);
    }

    public getYearlyRevenuesByElement = (elementId: number, selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._yearlyRevenuesByElement + queryString);
    }

    public exportDailyRevenuesBySections = (sectionId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._csvDailyRevenuesBySection + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportMonthlyRevenuesBySections = (sectionId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._csvMonthlyRevenuesBySection + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportYearlyRevenuesBySections = (sectionId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&sectionId=' + sectionId;

        return this._http.get<any>(this._csvYearlyRevenuesBySection + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportDailyRevenuesByElements = (elementId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._csvDailyRevenuesByElements + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportMonthlyRevenuesByElements = (elementId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._csvMonthlyRevenuesByElements + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportYearlyRevenuesByElements = (elementId: number, selectedDate?: Date): Observable<any> => {

        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        queryString += '&elementId=' + (elementId || '');

        return this._http.get<any>(this._csvYearlyRevenuesByElements + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }


    // Radiology Revenues

    public getDailyRadiologyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        return this._http.get<any>(this._dailyRadiologyRevenue + queryString);
    }

    public getMonthlyRadiologyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyRadiologyRevenue + queryString);
    }

    public getYearlyRadiologyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyRadiologyRevenue + queryString);
    }

    // Radiology Revenues


    // Laboratory Revenues

    public getDailyLaboratoryRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        return this._http.get<any>(this._dailyLaboratoryRevenue + queryString);
    }

    public getMonthlyLaboratoryRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyLaboratoryRevenue + queryString);
    }

    public getYearlyLaboratoryRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyLaboratoryRevenue + queryString);
    }

    // Laboratory Revenues


    // Emergency Revenues

    public getDailyEmergencyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();
        return this._http.get<any>(this._dailyEmergencyRevenue + queryString);
    }

    public getMonthlyEmergencyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyEmergencyRevenue + queryString);
    }

    public getYearlyEmergencyRevenue = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyEmergencyRevenue + queryString);
    }

    // Emergency Revenues


    // Dealing Type

    getPatientDailyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._dailyRevenuesByDealingType + queryString);
    }

    getPatientMonthlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._monthlyRevenuesByDealingType + queryString);
    }

    getPatientYearlyRevenuesByDealingType = (selectedDate?: Date): Observable<any> => {
        let queryString: string = '';
        selectedDate ? queryString += '?date=' + new Date(selectedDate).toLocaleDateString() : new Date().toLocaleDateString();

        return this._http.get<any>(this._yearlyRevenuesByDealingType + queryString);
    }
}
