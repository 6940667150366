export class SpecimenTransactionViewModel {
    SpecimenNumber?: number;
    PatientCode: string = '';
    PatientName: string = '';
    PatientTelCode: number;
    AccessionNumber: string = '';
    LabSection?: number;
    SpecimenType?: number;
    OrderNumber?: number;
    OrderFrom?: Date;
    OrderTo?: Date;
    Status?: number;
    PatientTypeId: number;
    EncounterId: number;
    CivilId: string;
};
