export class ReleaseDrugCardAdmissionVM {

    AdmissionId: number;
    PatientId: number;
    AdmissionNumber: number;
    MRN: string;
    SectionId: number;
    Location: string;
    Status: number;
    StatusDescription: string;
    AdmissionDoctor?: number;
    PatientFullName: string;
    OtherPatientFullName: string;
    AdmissionDoctorFullName: string;
    OtherAdmissionDoctorFullName: string;
    constructor() { }
}
