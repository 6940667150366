import { KitSetup } from './kitSetup';

export class TestNormalRange {
    Id: number;
    TestId: number;
    KitId: number;
    NationalityId: number;
    GenderId: number;
    AgeFromId: number;
    AgeFromValue: number;
    AgeToId: number;
    AgeToValue: number;
    NormalRangeMin: number;
    NormalRangeMax: number;
    PositiveAbnormalRangeMin: number;
    PositiveAbnormalRangeMax: number;
    NegativeAbnormalRangeMin: number;
    NegativeAbnormalRangeMax: number;
    PositivePanicRangeMin: number;
    PositivePanicRangeMax: number;
    NegativePanicRangeMin: number;
    NegativePanicRangeMax: number;
    PositiveAbsurdRangeMin: number;
    PositiveAbsurdRangeMax: number;
    NegativeAbsurdRangeMin: number;
    NegativeAbsurdRangeMax: number;

    KitSetup: KitSetup = new KitSetup();

    constructor() {
    }
}