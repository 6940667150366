import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { PatientPaymentCodesLookup } from 'app/shared/models/orders-and-billing/lookup/patient-payment-codes-lookup';
import { Staff } from 'app/shared/models/orders-and-billing/staff.model';
import { FilterStatementTransaction } from 'app/shared/models/orders-and-billing/filter-statement-transaction';

import { StatementTransactionService } from 'app/shared/services/order-and-billing';
import { StaffService } from 'app/shared/services/order-and-billing/staff/staff.service';

@Component({
    selector: 'advance-search-statement-transaction-cmp',
    styleUrls: ['./advance-search-statement-transaction.scss'],
    templateUrl: './advance-search-statement-transaction.html',
})

export class AdvanceSearchStatementTransactionComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();
    filterStatementTransaction: FilterStatementTransaction;

    paymentCodes: SelectItem[];
    cashiers: Staff[];

    autoCompleteObject: Staff;

    constructor(private _statementTransactionService: StatementTransactionService,
        private _staffService: StaffService) {
    }

    ngOnInit() {
        this.filterStatementTransaction = new FilterStatementTransaction();
        this.filterStatementTransaction.TransactionById = null;
        this.paymentCodes = PatientPaymentCodesLookup.getStatementTransactionLookup();
    }

    filterCashiers(event) {
        this.resetSelectedCashier();
        this._staffService.getCashiersByQuery(event.query)
            .subscribe((data: Staff[]) => {
                this.cashiers = data;
            });
    }

    setCashier(event: Staff) {
        this.filterStatementTransaction.TransactionById = event.Id;
    }

    resetSelectedCashier() {
        // Reset Desc and Other Desc
        this.autoCompleteObject = null;
        this.filterStatementTransaction.TransactionById = null;
    }

    checkIfValidCashier(event) {
        if (!this.filterStatementTransaction.TransactionById) {
            // User didn't select any service,clear the field
            event.target.value = '';
        }
    }

    clearFieldsOfSelectedCashier(event) {
        this.resetSelectedCashier();
    }

    onSubmit() {
        this._statementTransactionService.searchTransactionStatements(this.filterStatementTransaction)
            .subscribe((data) => {
                this.onSearch.emit({ results: data, filter: this.filterStatementTransaction });
            });
    }
}
