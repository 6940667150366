import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class CalculateAccomodationService extends ServiceBase {

    private actionUrl: string;
    private _post: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._post = this.actionUrl + _configuration.calculateAccomodation.url;
    }


    public calculateAccomodation(patientId, encounterId) {
        const params = { PATIENT_ID: patientId, ENCOUNTER_ID: encounterId };
        return this._http.post<any>(this._post, params);
    }
}