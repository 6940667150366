import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { SapPatientBill } from 'app/shared/models/queryModel/sap-patient-bill';
import { SapAccountReceivableInvoiceSearch, SapAccountReceivableInvoiceSearchBase } from 'app/shared/models/orders-and-billing/sap-account-receivable-invoice-search.model';

@Injectable()
export class PatientBillService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private sapFailedActivePatientsBillsUrl: string;
    private retrySapFailedActivePatientsBillsUrl: string;
    private retryAllSapFailedActivePatientsBillsUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.patientBill.url;
        this.sapFailedActivePatientsBillsUrl =
            this.actionUrl + configuration.patientBill.sapFailedActivePatientsBillsUrl;
        this.retrySapFailedActivePatientsBillsUrl =
            this.actionUrl + configuration.patientBill.retrySapFailedActivePatientsBillsUrl;
        this.retryAllSapFailedActivePatientsBillsUrl =
            this.actionUrl + configuration.patientBill.retryAllSapFailedActivePatientsBillsUrl;
    }

    getSapFailedActivePatientsBills = (sapAccountReceivableInvoiceSearch: SapAccountReceivableInvoiceSearch): Observable<SapPatientBill[]> => {

        let queryString = this.serializeToQueryString(sapAccountReceivableInvoiceSearch);

        return this.http.get<any>(`${this.sapFailedActivePatientsBillsUrl}${queryString}`)
    }

    retrySapFailedActivePatientsBills = (patientsBillsIds: number[]): Observable<any> => {
        return this.http.post<any>(this.retrySapFailedActivePatientsBillsUrl, JSON.stringify(patientsBillsIds));
    }

    retryAllSapFailedActivePatientsBills = (searchBase: SapAccountReceivableInvoiceSearchBase): Observable<any> => {
        return this.http.post<any>(this.retryAllSapFailedActivePatientsBillsUrl, searchBase);
    }
}
