import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { NotificationRole } from 'app/shared/models/core/notificationRole';
import { NotificationRoleType } from 'app/shared/models';

@Injectable()
export class NotificationRoleService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = this._configuration.server;
        this._url = this.actionUrl + this._configuration.notificationRole.url;
    }

    getAll = (): Observable<NotificationRole[]> => {
        return this._http.get<any>(this._url);
    }

    getById = (id: number): Observable<NotificationRole> => {
        return this._http.get<any>(`${this._url}?id=${id}`);
    }

    post = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: any, id: number): Observable<any> => {
        return this._http.put<any>(`${this._url}?id=${id}`, data);
    }

    delete(id: number) {
        return this._http.delete(`${this._url}?id=${id}`);
    }

    getByType(notificationType: NotificationRoleType) {
        return this._http.get(`${this._url}/GetByType?notificationType=${notificationType}`);
    }
}
