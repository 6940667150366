import { PharmacyLookup } from './pharmacy-lookup';
import { Frequency } from './frequency';
import { MedicationProduct } from './medication-product';

export interface IMedicationDefinitionMetaData {
    CanInactiveStatus?: boolean;
    IngredientDescription: string;
    FormDescription: string;
    StrengthUnitDescription: string;
    VolumeUnitDescription: string;
    RouteDescription: string;
    FrequencyDescription: string;
}

export class MedicationDefinition implements IMedicationDefinitionMetaData {

    public Id: number;
    public ItemId: number;
    public ItemCode: string;
    public Name: string;
    public IngredientId: number;
    public FormId: number;
    public Strength: number;
    public StrengthUnitId: number;
    public PieceUnitId?: number;
    public FrequencyId?: number;
    public IsControl: boolean;
    public IsAntibiotic: boolean;
    public IsRefill: boolean;
    public IsFormulary: boolean;
    public IsAlerts: boolean;
    public Note: string;
    public Status: boolean;
    public GroupId: number;
    public GroupCode: string;
    public GroupTitle: string;
    public GroupForeignTitle: string;

    public CanInactiveStatus?: boolean;
    public IngredientDescription: string;
    public FormDescription: string;
    public StrengthUnitDescription: string;
    public VolumeUnitDescription: string;
    public RouteDescription: string;
    public FrequencyDescription: string;

    public IsControlledMedication: boolean;
    public IsHighAlert: boolean;
    public HighAlertReasonType?: number;
    public HighAlertReasonId?: number;
    public HighAlertOtherReason: string;
    public NormalResultType?: number;
    public PeriodType?: number;
    public PeriodValue?: number;
    public IsSolution: boolean;
    public IsAdditive: boolean;

    public Ingredient: PharmacyLookup;
    public Form: PharmacyLookup;
    public StrengthUnit: PharmacyLookup;

    public PieceUnit: PharmacyLookup;
    public Frequency: Frequency;

    public GPI: string;
    public IsNarcotics: boolean;
    public IsInfusion: boolean;

    public IsChronic: boolean;
    public MaxPeriodType?: number;
    public MaxPeriodValue?: number;
    public StrengthText: Text;

    public MedicationProducts: MedicationProduct[];
    constructor() { }
}
