import { Routes, RouterModule } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from 'app/shared/services';


export const routes: Routes = [
    {
        path: 'notAuthorized',
        loadChildren: () => import('app/pages/not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule)

    },
    {
        path: 'pages',
        component: Pages, canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('app/pages/home/home.module').then(m => m.HomeModule) },
            { path: 'dashboard', loadChildren: () => import('app/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'dashboard/:notificationId', loadChildren: () => import('app/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'control', loadChildren: () => import('app/pages/control/control.module').then(m => m.ControlModule) },
            { path: 'administrative', loadChildren: () => import('app/pages/administrative/administrative.module').then(m => m.AdministrativeModule), canActivateChild: [AuthGuard] },
            { path: 'patientAccounting', loadChildren: () => import('app/pages/patientAccounting/patientAccounting.module').then(m => m.PatientAccountingModule) },
            { path: 'clinical', loadChildren: () => import('app/pages/clinical/clinical.module').then(m => m.ClinicalModule) },
            { path: 'order', loadChildren: () => import('app/pages/order/order.module').then(m => m.OrderModule) },
            { path: 'insurance', loadChildren: () => import('app/pages/insurance/insurance.module').then(m => m.InsurnaceModule) },
            { path: 'itemStructure', loadChildren: () => import('app/pages/item-structure/item-structure.module').then(m => m.ItemStructureModule) },
            { path: 'nursing', loadChildren: () => import('app/pages/nursing/nursing.module').then(m => m.NursingModule) },
            { path: 'admissionReports', loadChildren: () => import('app/pages/admission-reports/admission-reports.module').then(m => m.AdmissionReportsModule) },
            { path: 'laboratoryReports', loadChildren: () => import('app/pages/laboratory-reports/laboratory-reports.module').then(m => m.LaboratoryReportsModule) },
            { path: 'claimManagement', loadChildren: () => import('app/pages/claim-management/claim-management.module').then(m => m.ClaimManagementModule) },
            { path: 'hospitalPerformanceDashboard', loadChildren: () => import('app/pages/dashboard-hospital-performance/dashboard-hospital-performance.module').then(m => m.DashboardHospitalPerformanceModule) },
            { path: 'patientOrderManagement', loadChildren: () => import('app/pages/patient-order-management/patient-order-management.module').then(m => m.PatientOrderManagementModule) },
            { path: 'radiologyReports', loadChildren: () => import('app/pages/radiology-reports/radiology-reports.module').then(m => m.RadiologyReportsModule) },
            { path: 'userReports', loadChildren: () => import('app/pages/user-reports/user-reports.module').then(m => m.UserReportsModule) },
            { path: 'pharmacyOrderManagement', loadChildren: () => import('app/pages/pharmacy-order-management/pharmacy-order-management.module').then(m => m.PharmacyOrderManagementModule) },
            { path: 'labOrderManagement', loadChildren: () => import('app/pages/lab-order-management/lab-order-management.module').then(m => m.LabOrderManagementModule) },
            { path: 'doctorSettlement', loadChildren: () => import('app/pages/doctor-settlement/doctor-settlement.module').then(m => m.DoctorSettlementModule) },
            { path: 'outPatientOrderManagement', loadChildren: () => import('app/pages/outpatient-order-management/outpatient-order-management.module').then(m => m.OutPatientOrderManagementModule) },
            { path: 'labMonitor', loadChildren: () => import('app/pages/lab-monitor/lab-monitor.module').then(m => m.LabMonitorModule) },
            { path: 'systemSetup', loadChildren: () => import('app/pages/system-setup/system-setup.module').then(m => m.SystemSetupModule) },
            { path: 'reportViewer', loadChildren: () => import('app/pages/report-viewer/report-viewer.module').then(m => m.ReportViewerModule) },
            { path: 'notification', loadChildren: () => import('app/pages/notification/notification.module').then(m => m.NotificationModule) },
            { path: 'supplyChain', loadChildren: () => import('app/pages/supply-chain/supply-chain.module').then(m => m.SupplyChainModule) },
            { path: 'homeCare', loadChildren: () => import('app/pages/home-care/home-care.module').then(m => m.HomeCareModule) }
        ],
    },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
