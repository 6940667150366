import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientStatement } from '../../../models/orders-and-billing/patient-statement';
import { PatientStatementFilter } from 'app/shared/models/queryModel/patient-statement-filter';
import { AddInvoiceSearchFilter } from 'app/shared/models/queryModel/add-invoice-search-filter';
import { StatementRemarks } from '../../../models/orders-and-billing/remarks';
import { LabReportStatmentInfo } from 'app/shared/models/orders-and-billing/lab-report-statment-info';
import { EncounterInfo } from 'app/shared/models/orders-and-billing//encounter-info';
import { PatientStatementDto } from 'app/shared/models/orders-and-billing/patient-statement-dto';
import { environment } from 'environments/environment';

@Injectable()
export class PatientStatmentService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getByFillter: string;
    private _reOpen: string;
    private _financeRelease: string;
    private _issueBill: string;
    private _inPatientPatientTotalAmount: string;
    private _haltStatement: string;
    private _openHaltStatement: string;
    private _validateStatement: string;
    private _validateForChangeEncounter: string;
    private _isOrderAttachedToOpenStatements: string;
    private _canDischargePatient: string;
    private _canCancelPatientAdmission: string;
    private _isOrderActionAttachedToOpenStatements: string;
    private _blockStatement: string;
    private _unBlockStatement: string;
    private _creditInvoiceSearch: string;
    private _creditInvoicesSearchForAddInvoice: string;
    private _claimUrl: string;
    private _claimByPatientUrl: string;
    private _getPatientStatementStatus: string;
    private _getPrintResualtEligibility: string;
    private _canTransfePatientUrl: string;
    private _remark: string;
    private _getDealingType: string;
    private _getCloseDate: string;
    private _setCloseDate: string;
    private _isStatementOpenOrHalted: string;
    private _encounterInfoUrl: string;
    private _cashStatementInvoiceNetUrl: string;
    private _patientStatement: string;
    private _openHaltOutpatientStatement: string;
    private _haltOutpatientStatement: string;
    private _validateStatementsForApproval: string;
    private _haltOutPatientStatementById: string;
    private _validateStatementsPendingArea: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientStatement.url;
        this._getByFillter = this.actionUrl + _configuration.patientStatement.getByFillter;
        this._reOpen = this.actionUrl + _configuration.patientStatement.reOpen;
        this._financeRelease = this.actionUrl + _configuration.patientStatement.financeRelease;
        this._issueBill = this.actionUrl + _configuration.patientStatement.issueBill;
        this._inPatientPatientTotalAmount = this.actionUrl + _configuration.patientStatement.inPatientPatientTotalAmount;
        this._haltStatement = this.actionUrl + _configuration.patientStatement.haltStatement;
        this._openHaltStatement = this.actionUrl + _configuration.patientStatement.openHaltStatement;
        this._validateStatement = this.actionUrl + _configuration.patientStatement.validateStatement;
        this._isOrderAttachedToOpenStatements = this.actionUrl + _configuration.patientStatement.isOrderAttachedToOpenStatements;
        this._canDischargePatient = this.actionUrl + _configuration.patientStatement.canDischargePatient;
        this._canCancelPatientAdmission = this.actionUrl + _configuration.patientStatement.canCancelPatientAdmission;
        this._isOrderActionAttachedToOpenStatements = this.actionUrl + _configuration.patientStatement.isOrderActionAttachedToOpenStatements;
        this._blockStatement = this.actionUrl + _configuration.patientStatement.blockStatement;
        this._unBlockStatement = this.actionUrl + _configuration.patientStatement.unBlockStatement;
        this._creditInvoiceSearch = this.actionUrl + _configuration.patientStatement.creditInvoiceSearch;
        this._creditInvoicesSearchForAddInvoice = this.actionUrl + _configuration.patientStatement.creditInvoicesSearchForAddInvoice;
        this._claimUrl = this.actionUrl + _configuration.claim.url;
        this._claimByPatientUrl = this.actionUrl + _configuration.claim.claimByPatientUrl;
        this._canTransfePatientUrl = this.actionUrl + _configuration.patientStatement.canTransfePatient;
        this._remark = this.actionUrl + _configuration.patientStatement.remark;
        this._getDealingType = this.actionUrl + _configuration.patientStatement.getDealingType;
        this._getCloseDate = this.actionUrl + _configuration.patientStatement.getCloseDate;
        this._setCloseDate = this.actionUrl + _configuration.patientStatement.setCloseDate;
        this._isStatementOpenOrHalted = this.actionUrl + _configuration.patientStatement.isStatementOpenOrHalted;
        this._encounterInfoUrl = this.actionUrl + _configuration.patientStatement.encounterInfo;
        this._validateForChangeEncounter = this.actionUrl + _configuration.patientStatement.validateForChangeEncounter;
        this._cashStatementInvoiceNetUrl = this.actionUrl + _configuration.patientStatement.cashStatementInvoiceNet;
        this._patientStatement = this.actionUrl + _configuration.patientStatement.creditStatmentInvoice;
        this._openHaltOutpatientStatement = this.actionUrl + _configuration.patientStatement.openHaltOutpatientStatement;
        this._haltOutpatientStatement = this.actionUrl + _configuration.patientStatement.haltOutpatientStatement;
        this._validateStatementsForApproval = this.actionUrl + _configuration.patientStatement.validateStatementsForApproval;
        this._haltOutPatientStatementById = this.actionUrl + _configuration.patientStatement.haltOutPatientStatementById;
        this._validateStatementsPendingArea = this.actionUrl + _configuration.patientStatement.validateStatementsPendingArea;
    }

    getByFillter = (patientId, encounterId, patientType): Observable<PatientStatement[]> => {
        return this._http.get<PatientStatement[]>(this._getByFillter + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    getCloseDate = (patientId, encounterId, patientType): Observable<Date> => {
        return this._http.get<Date>(this._getCloseDate + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    reOpen = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._reOpen + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    issueBill = (data): Observable<string[]> => {
        return this._http.put<any>(this._issueBill, data);
    }

    financeRelease = (patientId, encounterId, patientType, closeDate: Date): Observable<string[]> => {
        return this._http.get<any>(this._financeRelease + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType + '&&closeDate=' + closeDate.toISOString());
    }

    haltStatement = (patientId, encounterId, patientType, haltReason, haltDate): Observable<string[]> => {
        return this._http.get<any>(this._haltStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType + '&&haltReason=' + haltReason + '&&haltDate=' + haltDate.toLocaleDateString());
    }

    inPatientPatientTotalAmount = (encounterId): Observable<string[]> => {
        return this._http.get<any>(this._inPatientPatientTotalAmount + '?encounterId=' + encounterId);
    }

    openHaltStatement = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._openHaltStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType
        );
    }

    validateStatement = (patientId, encounterId, patientType, orderId, orderSourceId): Observable<string[]> => {
        return this._http.get<any>(this._validateStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType + '&&orderId=' + orderId + '&&orderSourceId=' + orderSourceId
        );
    }

    validateForChangeEncounter = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._validateForChangeEncounter + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType
        );
    }

    isOrderAttachedToOpenStatements = (orderId: number, itemId: number): Observable<boolean> => {
        const url = `${this._isOrderAttachedToOpenStatements}?orderId=${orderId}&&itemId=${itemId}`;
        return this._http.get<any>(url);
    }

    checkCanDischargePatient = (patientId: number, encounterId: number): Observable<boolean> => {
        const url = `${this._canDischargePatient}?patientId=${patientId}&&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }

    getTotalIssuedPatient() {
        return this._http.get<any>(this.url + "/TotalIssuedPatients");
    }

    checkCanCancelPatientAdmission = (patientId: number, encounterId: number): Observable<boolean> => {
        const url = `${this._canCancelPatientAdmission}?patientId=${patientId}&&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }

    getCreditInvoicesSearch = (pageNo, rowNo, filter: PatientStatementFilter): Observable<PatientStatement[]> => {
        let queryString: string = '';
        queryString += '?invoiceId=';
        queryString += filter.InvoiceId;

        queryString += '&&patientCode=';
        queryString += filter.PatientCode;

        queryString += '&&patientName=';
        queryString += filter.PatientName;

        queryString += '&&patientTypeId=';
        queryString += filter.PatientTypeID;

        queryString += '&&payerId=';
        queryString += filter.PayerID;

        queryString += '&&fromIssueDate=';
        queryString += filter.FromIssueDate.toLocaleDateString();

        queryString += '&&toIssueDate=';
        queryString += filter.ToIssueDate.toLocaleDateString();

        queryString += '&&pageNo=';
        queryString += pageNo;

        queryString += '&&rowNo=';
        queryString += rowNo;

        return this._http.get<any>(this._creditInvoiceSearch + queryString);
    }

    blockStatement = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._blockStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType)
    }

    unBlockStatement = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._unBlockStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    generateClaim = (filter: PatientStatementFilter): Observable<any> => {

        return this._http.post<any>(this._claimUrl, filter);
    }

    generateClaimByPatient = (filter: PatientStatementFilter): Observable<number[]> => {

        return this._http.post<number[]>(this._claimByPatientUrl, filter);
    }

    getInvoicesByPayer = (filter: AddInvoiceSearchFilter): Observable<any[]> => {
        let queryString: string = '';

        queryString += '?payerId=';
        queryString += filter.PayerID;

        queryString += '&&coverageContractId=';
        queryString += filter.CoverageContractId;

        queryString += '&&coverageClassId=';
        queryString += filter.CoverageClassId;

        queryString += '&&invoiceId=';
        queryString += filter.InvoiceId;

        queryString += '&&patientCode=';
        queryString += filter.PatientCode;

        queryString += '&&patientName=';
        queryString += filter.PatientName;

        queryString += '&&patientOtherName=';
        queryString += filter.PatientOtherName;

        queryString += '&&encounterDate=';
        queryString += filter.EncounterDate ? filter.EncounterDate.toLocaleDateString() : null;

        queryString += '&&pageNo=';
        queryString += filter.PageNo;

        queryString += '&&rowNo=';
        queryString += filter.RowNo;

        return this._http.get<any>(this._creditInvoicesSearchForAddInvoice + queryString)
    }

    getStatus = (id: number): Observable<number> => {
        return this._http.get<any>(`${this.url}/Status?id=${id}`)
    }

    getStatusByEncounterInfo = (patientId, encounterId, patientType): Observable<number> => {
        return this._http.get<any>(`${this.url}/StatusByEncounterInfo?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`)
    }

    getInfoByEncounterInfo = (patientId, encounterId, patientType): Observable<any> => {
        return this._http.get<any>(`${this.url}/StatmentInfoByEncounterInfo?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`
        );
    }

    getStatusByOrder = (orderNumber: number): Observable<number> => {
        return this._http.get<any>(`${this.url}/StatusByOrder?orderId=${orderNumber}`);
    }

    getLatestStatus = (patientcode: string): Observable<number> => {
        return this._http.get<any>(`${this.url}/LatestStatus?patientcode=${patientcode}`);
    }

    checkCanTransfePatient = (patientId: number, encounterId: number): Observable<boolean> => {
        const url = `${this._canTransfePatientUrl}?patientId=${patientId}&&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }

    setRemark = (patientStatementId: number, remarks: string): Observable<any> => {
        let statementRemarks: StatementRemarks = new StatementRemarks();
        statementRemarks.PatientStatementId = patientStatementId;
        statementRemarks.Remarks = remarks;

        return this._http.patch<any>(this._remark, statementRemarks);

    }

    getDealingType = (patientId, encounterId, patientType): Observable<string> => {
        return this._http.get<any>(this._getDealingType + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType
        );
    }

    setCloseDate = (encounterId, patientId, patientType, closeDate): Observable<any> => {
        return this._http.put<any>(this._setCloseDate + '?encounterId=' + encounterId + '&&patientId=' + patientId + '&&patientType=' + patientType, JSON.stringify(closeDate));
    }

    getIsStatementOpenOrHalted = (patientId: number, encounterId: number, patientType: number): Observable<boolean> => {
        return this._http.get<any>(`${this._isStatementOpenOrHalted}?encounterId=${encounterId}&patientId=${patientId}&patientType=${patientType}`);
    }

    getLabReportStatmentInfo = (orderNumber?: number, patientcode?: string): Observable<LabReportStatmentInfo> => {
        return this._http.get<any>(`${this.url}/LabReportStatmentInfo?orderId=${orderNumber}&patientcode=${patientcode}`);
    }

    getLabReportStatmentInfoByEncounter = (patientId: number, encounterId: number, patientTypeId: number): Observable<LabReportStatmentInfo> => {
        return this._http.get<any>(`${this.url}/LabReportStatmentInfoByEncounter?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`);
    }

    getEncounterInfo = (patientCode: string, patientTypeId?: number): Observable<EncounterInfo[]> => {
        patientCode = patientCode ? patientCode : "";
        patientTypeId = patientTypeId ? patientTypeId : null;
        return this._http.get<any>(`${this._encounterInfoUrl}?patientCode=${patientCode}&patientTypeId=${patientTypeId}`);
    }

    getIsStatementIssued = (patientId: number, encounterId: number, patientType: number): Observable<boolean> => {
        return this._http.get<any>(`${this.url}/IsStatementIssued?encounterId=${encounterId}&patientId=${patientId}&patientType=${patientType}`);
    }

    getByOrder = (patientId: number, encounterId: number, patientTypeId: number, orderId: number, orderSourceId: number): Observable<PatientStatement[]> => {
        return this._http.get<PatientStatement[]>(`${this.url}/ByOrder?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}&orderId=${orderId}&orderSourceId=${orderSourceId}`);
    }

    cancelVisitStatement = (statementId): Observable<string[]> => {
        return this._http.put<any>(`${this.url}/CancelVisitStatement?statementId=${statementId}`, null);
    }

    getPatientOpenHaltedEncounter = (patientId: number, payerId: number, currentEncounterId: number): Observable<PatientStatementDto[]> => {
        return this._http.get<PatientStatementDto[]>(`${this.url}/PatientOpenHaltedEncounter?patientId=${patientId}&payerId=${payerId}&currentEncounterId=${currentEncounterId}`);
    }

    getCashStatementInvoiceNet = (patientId: number, encounterId: number, orderId: number): Observable<any> => {
        return this._http.get<any>(`${this._cashStatementInvoiceNetUrl}?patientId=${patientId}&encounterId=${encounterId}&orderId=${orderId}`);
    }

    getPatientStatement = (patientId: number, encounterId: number, patientType: number): Observable<any> => {
        return this._http.get<any>(`${this._patientStatement}?patientId=${patientId}&encounterId=${encounterId}&patientType=${patientType}`);
    }

    openHaltOutpatientStatement = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(`${this._openHaltOutpatientStatement}?patientId=${patientId}&encounterId=${encounterId}&patientType=${patientType}`);
    }

    haltOutpatientStatement = (patientId, encounterId, patientType, haltReason): Observable<string[]> => {
        return this._http.get<any>(this._haltOutpatientStatement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType + '&&haltReason=' + haltReason);
    }

    validateStatementsForApprovl = (patientId, encounterId, patientType): Observable<string[]> => {
        return this._http.get<any>(this._validateStatementsForApproval + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    haltOutPatientStatementById = (statementId: number, haltReason, haltDate): Observable<string[]> => {
        return this._http.patch<any>(`${this._haltOutPatientStatementById}?statementId=${statementId}&&haltReason=${haltReason}&&haltDate=${haltDate.toLocaleDateString()}`, {});
    }

    PatientDonorInvoice = (patientId, encounterId, patientType, statementId, patientDonors): Observable<any> => {
        return this._http.put<any>(`${this.url}/PatientDonorInvoice?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}&&statementId=${statementId}`, patientDonors);
    }

    getPatientEncounterStatement = (patientId, encounterId, patientType): Observable<any> => {
        return this._http.get<any>(`${this.url}/PatientEncounterStatement?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`);
    }

    getPayerInfo = (patientId, encounterId, patientType): Observable<any> => {
        return this._http.get<any>(`${environment.order}PatientStatement/PayerInfo?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`);
    }

    validateStatementsPendingArea = (patientId, encounterId, patientType, id): Observable<string[]> => {
        return this._http.get<any>(this._validateStatementsPendingArea + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType + '&&orderActionId=' + id);
    }

    getDischargeDate(patientId: number, encounterId: number, patientTypeId: number): Observable<any> {
        return this._http.get<any>(`${environment.order}PatientStatement/GetDischargeDate?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`);
    }

    moveDonorInvoices = (data: any[]): Observable<any> => {
        return this._http.put<any>(`${this.url}/MoveDonorInvoices`, data);
    }
}
