import { Lookup } from './lookup';

export class LookupCategory {
    Id: number;
    Title: string;
    ForeignTitle: string;
    Lookups: Lookup[] = [];

    constructor() {
    }
}