
export class CPOESetup {

    Id: number = 0;
    PainScoreAge: number ;
    
    constructor() {
        this.Id = 0;
        this.PainScoreAge=0 ;
        
    }
}
