import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { AssignmentRole } from 'app/shared/models/orders-and-billing/assignment-role';

@Injectable()
export class AssignmentRoleService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getBySearchUrl: string;
    private _searchByItem: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.assignmentRole.url;
        this.getBySearchUrl = this.actionUrl + configuration.assignmentRole.bySearch;
    }

    add = (data: AssignmentRole): Observable<any> => {
        return this.http.post<any>(this.url, JSON.stringify(data));
    }

    update = (data: AssignmentRole): Observable<any> => {
        return this.http.put<any>(this.url, JSON.stringify(data));
    }

    getBySearch = (assignmentId: number, typeId: number, itemId: number, actionId: number, isActive: boolean, pageIndex: number, rowNo: number): Observable<any> => {
        let assignmentIdValue = assignmentId ? assignmentId : null;
        let typeIdValue = typeId ? typeId : null;
        let itemIdValue = itemId ? itemId : null;
        let actionIdValue = actionId ? actionId : null;
        let isActiveValue = isActive ? isActive : (isActive == false ? isActive : null);

        return this.http.get<any>(this.getBySearchUrl
            + '?assignmentId=' + assignmentIdValue
            + '&&typeId=' + typeIdValue
            + '&&itemId=' + itemIdValue
            + '&&actionId=' + actionIdValue
            + '&&isActive=' + isActiveValue
            + '&&pageIndex=' + pageIndex
            + '&&rowNo=' + rowNo
        );
    }
}
