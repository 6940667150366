import { MedicationDefinition } from './medication-definition';
import { Item } from 'app/shared/models/orders-and-billing/item';
import { Unit } from 'app/shared/models/orders-and-billing/unit';
import { PharmacyLookup } from './pharmacy-lookup';
import { SelectItem } from 'primeng/api';

export interface IMedicationProductMetaData {
    IsEditable: boolean;
    NewMedicationProductId: number;

    properties: MedicationProductProperties;
}

export class MedicationProductProperties {
    freq: number = null;
    unit: number = null;
    qty: number;
    defaultUnit: number = null;
    mainDefaultUnit: number = null;
    batchNo: string = null;
}


export class MedicationProduct implements IMedicationProductMetaData {

    Id: number;
    MedicationDefinitionId: number;
    MedicationDefinitionItemId: number;
    ProductId: number;
    ProductCode: string;
    ProductTitle: string;
    ProductForeignTitle: string;
    Volume: number;
    VolumeUnitId: number;
    Note: string;
    Status: boolean;
    RouteId?: number;
    IsEditable: boolean;
    NewMedicationProductId: number;
    IsScheduled: boolean;

    InPatientUnitId?: number;
    InPatientUnitDescription: string;

    OutPatientUnitId?: number;
    OutPatientUnitDescription: string;

    ErPatientUnitId?: number;
    ErPatientUnitDescription: string;

    Item: Item = new Item();

    VolumeUnit: PharmacyLookup;
    MedicationDefinition: MedicationDefinition;
    Route: PharmacyLookup;

    InPatientUnits: SelectItem[];
    OutPatientUnits: SelectItem[];
    ErPatientUnits: SelectItem[];
    IsPRN?: boolean;

    properties: MedicationProductProperties = new MedicationProductProperties();

    constructor(init?: Partial<MedicationProduct>) {
        if (init) {
            Object.assign(this, init);
            if (this.MedicationDefinition && this.MedicationDefinition.FrequencyId) {
                this.properties.freq = this.MedicationDefinition.FrequencyId;
            }        
        }
        return this;
    }
}
