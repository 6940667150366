export class TriageScaleSetup {

    Id: number;
    Title: string;
    ForeignTitle: string;
    IsThreatening: boolean;
    IsActive: boolean;

    constructor() {
    }
}