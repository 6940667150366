import { SelectItem } from 'primeng/api';
import { ItemTypes } from '../enums';

export class ItemTypesLookup {
    static get(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Parent', value: ItemTypes.Parent },
            { label: 'Group', value: ItemTypes.Group },
            { label: 'Order Set', value: ItemTypes.OrderSet }];
    }

    static getWithService(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Parent', value: ItemTypes.Parent },
            { label: 'Package', value: ItemTypes.Package },
            { label: 'Group', value: ItemTypes.Group },
            { label: 'Order Set', value: ItemTypes.OrderSet },
            { label: 'Service', value: ItemTypes.Service }];
    }
}
