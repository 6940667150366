import { MicrobiologyPanelAntibiotic } from './microbiology-panel-antibiotic';

export class MicrobiologyPanel {

    Id: number;
    Title: string;
    ForeignTitle: string;
    MicrobiologyPanelAntibiotics: MicrobiologyPanelAntibiotic[] = [];

    constructor() {
    }
}