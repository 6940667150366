import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CashBoxTransferHistory } from '../../../models/queryModel/cash-box-transfer-history';
import { FailedCashBoxTransfer } from '../../../models/queryModel/failed-cash-box-transfer';

@Injectable()
export class SearchCashBoxTransferService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private historyUrl: string;
    private failedTransfersUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.cashBoxTransfer.url;
        this.historyUrl = this.actionUrl + _configuration.cashBoxTransfer.history;
        this.failedTransfersUrl = this.actionUrl + _configuration.cashBoxTransfer.failedTransfers;
    }

    getCashBoxTransferHistory = (page: number, rows: number, transactionDate?: Date, fromCashierId?: number, toCashierId?: number, fromFundId?: number, toFundId?: number, status?: number): Observable<CashBoxTransferHistory[]> => {
        const fCashierId = fromCashierId ? fromCashierId : '';
        const tCashierId = toCashierId ? toCashierId : '';
        const fFundId = fromFundId ? fromFundId : '';
        const tFundId = toFundId ? toFundId : '';
        const cstatus = status ? status : '';
        const isoTransactionDate = transactionDate ? transactionDate.toDateString() : '';

        const url = `${this.historyUrl}?transactionDate=${isoTransactionDate}&&fromCashierId=${fCashierId}&&toCashierId=${tCashierId}&&fromFundId=${fFundId}&&toFundId=${tFundId}&&status=${cstatus}&&page=${page}&&rows=${rows}`;

        return this._http.get<any>(url);
    }

    getFailedCashBoxTransfer = (page: number, rows: number, transactionDateFrom?: Date, transactionDateTo?: Date, fromCashierId?: number, toCashierId?: number, fromFundId?: number, toFundId?: number, sapErrorMsg?: string): Observable<FailedCashBoxTransfer[]> => {
        const fCashierId = fromCashierId ? fromCashierId : '';
        const tCashierId = toCashierId ? toCashierId : '';
        const fFundId = fromFundId ? fromFundId : '';
        const tFundId = toFundId ? toFundId : '';
        const isoTransactionDateFrom = transactionDateFrom ? transactionDateFrom.toISOString() : '';
        const isoTransactionDateTo = transactionDateTo ? transactionDateTo.toISOString() : '';
        sapErrorMsg = sapErrorMsg ? sapErrorMsg : '';

        const url = `${this.failedTransfersUrl}?transactionDateFrom=${isoTransactionDateFrom}&&transactionDateTo=${isoTransactionDateTo}&&fromCashierId=${fCashierId}&&toCashierId=${tCashierId}&&fromFundId=${fFundId}&&toFundId=${tFundId}&&sapErrorMsg=${sapErrorMsg}&&page=${page}&&rows=${rows}`;

        return this._http.get<any>(url);
    }
}
