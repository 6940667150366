import { Injectable, EventEmitter } from '@angular/core';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScannerService extends ServiceBase {

    private socket: WebSocket;
    driver: EventEmitter<any> = new EventEmitter();

    constructor(private _http: HttpClient) {
        super();
        this.initWebSocket();
    }

    setPort(portNumber: any) {
        if (localStorage.getItem('ScannerPort') === null) {
            localStorage.setItem('ScannerPort', portNumber);
            this.initWebSocket();
        }
    }

    getPort() {
        return localStorage.getItem('ScannerPort');
    }

    initWebSocket() {
        if (localStorage.getItem('ScannerPort') !== null) {
            this.socket = new WebSocket('ws://localhost:' + localStorage.getItem('ScannerPort') + '/');
            this.initEventListeners();
        }
    }

    initEventListeners() {
        this.socket.onmessage = event => {
            const message: any = JSON.parse(event.data);
            const mode = JSON.parse(event.data).Mode;

            if (mode === undefined && event.data) {
                this.proccessImage(event);
            }
        };
        this.socket.onopen = event => {
        };
        this.socket.onclose = event => {
        };
        this.socket.onerror = event => {
        };
    }

    proccessImage(evt) {
        const record = JSON.parse(evt.data);
        const byteArray = record.Data;
        const imageWidth = record.Width, imageHeight = record.Height;

        const canvas = document.createElement('canvas');
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        const ctx = canvas.getContext('2d');

        const imageData = ctx.getImageData(0, 0, imageWidth, imageHeight);
        const dataLen = imageData.data.length;

        for (let i = 0; i < dataLen; i++) {
            imageData.data[i] = byteArray[i];
        }
        ctx.putImageData(imageData, 0, 0);

        this.driver.emit({ 'width': imageWidth, 'data': imageHeight, 'src': canvas.toDataURL() });

        canvas.remove();

    }

    scan() {
        if (this.socket.readyState != this.socket.CONNECTING) {
            this.socket.send('');
            return true;
        }
        else {
            return false;
        }
    }
}

