import { Pipe, PipeTransform } from '@angular/core';
import { layoutPaths } from '../../../shared';

@Pipe({ name: 'baSystemSvg' })
export class BaSystemSvgPipe implements PipeTransform {

  transform(input: string, ext = 'svg'): string {
    return layoutPaths.images.system + input + '.' + ext;
  }
}
