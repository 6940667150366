import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class SignalRConfiguration {

    constructor() {
        let apiServer = environment.lab;
        this.labApiServer = apiServer.replace('/api/', '');
    }

    labApiServer: string
}
