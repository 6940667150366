import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PharmacyDailyWorkSearch } from 'app/shared/models/queryModel/pharmacy-daily-work-search';
import { PharmacyDailyWork } from 'app/shared/models/queryModel/pharmacy-daily-work';
import { map } from 'rxjs';

@Injectable()
export class PharmacyOrderSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private exportDailyWork: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.pharmacyOrderSearch.url;
        this.exportDailyWork = this.actionUrl + _configuration.pharmacyOrderSearch.exportDailyWork;
    }

    public getPharmacyDailyWork = (pharmacyDailyWorkSearch: PharmacyDailyWorkSearch, page: number, rows: number, drugTitleSortType?: number, drugCodeSortType?: number): Observable<PharmacyDailyWork[]> => {
        const patientId = pharmacyDailyWorkSearch.PatientId ? pharmacyDailyWorkSearch.PatientId : '';
        const patientCode = pharmacyDailyWorkSearch.PatientCode ? pharmacyDailyWorkSearch.PatientCode : '';
        const drugId = pharmacyDailyWorkSearch.DrugId ? pharmacyDailyWorkSearch.DrugId : '';
        var fromOrderDateAsString = pharmacyDailyWorkSearch.FromOrderDate ? pharmacyDailyWorkSearch.FromOrderDate.toISOString() : '';
        var toOrderDateAsString = pharmacyDailyWorkSearch.ToOrderDate ? pharmacyDailyWorkSearch.ToOrderDate.toISOString() : '';
        const orderActionStatusId = pharmacyDailyWorkSearch.OrderActionStatusId ? pharmacyDailyWorkSearch.OrderActionStatusId : '';
        const pharmacyId = pharmacyDailyWorkSearch.PharmacyId ? pharmacyDailyWorkSearch.PharmacyId : '';
        const orderById = pharmacyDailyWorkSearch.OrderById ? pharmacyDailyWorkSearch.OrderById : '';
        const orderSectionId = pharmacyDailyWorkSearch.OrderSectionId ? pharmacyDailyWorkSearch.OrderSectionId : '';
        const unitId = pharmacyDailyWorkSearch.UnitId ? pharmacyDailyWorkSearch.UnitId : '';
        const quantity = pharmacyDailyWorkSearch.Quantity ? pharmacyDailyWorkSearch.Quantity : '';
        const returnedQuantity = pharmacyDailyWorkSearch.ReturnedQuantity ? pharmacyDailyWorkSearch.ReturnedQuantity : '';
        const returnedBy = pharmacyDailyWorkSearch.ReturnedBy ? pharmacyDailyWorkSearch.ReturnedBy : '';
        const orderId = pharmacyDailyWorkSearch.OrderId ? pharmacyDailyWorkSearch.OrderId : (pharmacyDailyWorkSearch.OrderId == 0 ? 0 : '');
        var dispensedDate = pharmacyDailyWorkSearch.DispensedDate ? pharmacyDailyWorkSearch.DispensedDate.toLocaleDateString() : '';
        const dispensedById = pharmacyDailyWorkSearch.DispensedById ? pharmacyDailyWorkSearch.DispensedById : '';
        var fromDispensedDate = pharmacyDailyWorkSearch.FromDispensedDate ? pharmacyDailyWorkSearch.FromDispensedDate.toISOString() : '';
        var toDispensedDate = pharmacyDailyWorkSearch.ToDispensedDate ? pharmacyDailyWorkSearch.ToDispensedDate.toISOString() : '';
        const isPoms = pharmacyDailyWorkSearch.IsPoms ? pharmacyDailyWorkSearch.IsPoms : '';
        
        return this._http.get<any>(this.url
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&DrugId=' + drugId
            + '&&FromOrderDate=' + fromOrderDateAsString
            + '&&ToOrderDate=' + toOrderDateAsString
            + '&&OrderActionStatusId=' + orderActionStatusId
            + '&&PharmacyId=' + pharmacyId
            + '&&OrderById=' + orderById
            + '&&OrderSectionId=' + orderSectionId
            + '&&UnitId=' + unitId
            + '&&Quantity=' + quantity
            + '&&ReturnedQuantity=' + returnedQuantity
            + '&&ReturnedBy=' + returnedBy
            + '&&OrderId=' + orderId
            + '&&DispensedDate=' + dispensedDate
            + '&&DispensedById=' + dispensedById
            + '&&FromDispensedDate=' + fromDispensedDate
            + '&&ToDispensedDate=' + toDispensedDate
            + '&&Page=' + page
            + '&&Rows=' + rows
            + '&&DrugTitleSortType=' + (drugTitleSortType || '')
            + '&&DrugCodeSortType=' + (drugCodeSortType || '')
            + '&&IsPoms=' + isPoms
        );
    }

    exportPharmacyDailyWork = (pharmacyDailyWorkSearch: PharmacyDailyWorkSearch, page: number, rows: number, drugTitleSortType?: number, drugCodeSortType?: number): Observable<any> => {

        const patientId = pharmacyDailyWorkSearch.PatientId ? pharmacyDailyWorkSearch.PatientId : '';
        const patientCode = pharmacyDailyWorkSearch.PatientCode ? pharmacyDailyWorkSearch.PatientCode : '';
        const drugId = pharmacyDailyWorkSearch.DrugId ? pharmacyDailyWorkSearch.DrugId : '';
        var fromOrderDateAsString = pharmacyDailyWorkSearch.FromOrderDate ? pharmacyDailyWorkSearch.FromOrderDate.toISOString() : '';
        var toOrderDateAsString = pharmacyDailyWorkSearch.ToOrderDate ? pharmacyDailyWorkSearch.ToOrderDate.toISOString() : '';
        const orderActionStatusId = pharmacyDailyWorkSearch.OrderActionStatusId ? pharmacyDailyWorkSearch.OrderActionStatusId : '';
        const pharmacyId = pharmacyDailyWorkSearch.PharmacyId ? pharmacyDailyWorkSearch.PharmacyId : '';
        const orderById = pharmacyDailyWorkSearch.OrderById ? pharmacyDailyWorkSearch.OrderById : '';
        const orderSectionId = pharmacyDailyWorkSearch.OrderSectionId ? pharmacyDailyWorkSearch.OrderSectionId : '';
        const unitId = pharmacyDailyWorkSearch.UnitId ? pharmacyDailyWorkSearch.UnitId : '';
        const quantity = pharmacyDailyWorkSearch.Quantity ? pharmacyDailyWorkSearch.Quantity : '';
        const returnedQuantity = pharmacyDailyWorkSearch.ReturnedQuantity ? pharmacyDailyWorkSearch.ReturnedQuantity : '';
        const returnedBy = pharmacyDailyWorkSearch.ReturnedBy ? pharmacyDailyWorkSearch.ReturnedBy : '';
        const orderId = pharmacyDailyWorkSearch.OrderId ? pharmacyDailyWorkSearch.OrderId : (pharmacyDailyWorkSearch.OrderId == 0 ? 0 : '');

        const _exportDailyWork = this.exportDailyWork
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&DrugId=' + drugId
            + '&&FromOrderDate=' + fromOrderDateAsString
            + '&&ToOrderDate=' + toOrderDateAsString
            + '&&OrderActionStatusId=' + orderActionStatusId
            + '&&PharmacyId=' + pharmacyId
            + '&&OrderById=' + orderById
            + '&&OrderSectionId=' + orderSectionId
            + '&&UnitId=' + unitId
            + '&&Quantity=' + quantity
            + '&&ReturnedQuantity=' + returnedQuantity
            + '&&ReturnedBy=' + returnedBy
            + '&&OrderId=' + orderId
            + '&&Page=' + page
            + '&&Rows=' + (rows + 50)
            + '&&DrugTitleSortType=' + (drugTitleSortType || '')
            + '&&DrugCodeSortType=' + (drugCodeSortType || '')

        return this._http.get<any>(_exportDailyWork,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        return new Blob(['\ufeff', (<any>response).body], { type: contentType });
                    }

                    return response;
                }));
    }
}
