import { Component, Input, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalState } from "app/global.state";

@Component({
    selector: 'ba-menu-item',
    templateUrl: './baMenuItem.html',
    styleUrls: ['./baMenuItem.scss']
})
export class BaMenuItem implements AfterViewChecked {

    constructor(
        private _router: Router,
        private _state: GlobalState,
        private _changeDetectorRef: ChangeDetectorRef) {
    }

    @Input() menuItem: any;
    @Input() child: boolean = false;

    @Output() itemHover = new EventEmitter<any>();
    @Output() toggleSubMenu = new EventEmitter<any>();

    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    public onHoverItem($event): void {
        this.itemHover.emit($event);
    }

    public onToggleSubMenu($event, item): boolean {
        $event.item = item;
        this.toggleSubMenu.emit($event);
        return false;
    }
    public setHomePage(event, item) {

        jQuery('.selected-home-item').removeClass('selected-home-item');
        jQuery('.selected-home-reference').removeClass('selected-home-reference');
        if (item.route.data.children) {

            if (event.target.localName === 'span') {
                event.target.classList.add('selected-home-reference');
                jQuery('.selected-home-reference').parent().addClass('selected-home-item');
            } else {
                event.target.classList.add('selected-home-item');
            }

            localStorage.setItem('HomeItems', JSON.stringify(item));
            this._router.navigateByUrl('/pages/home/' + item.route.data.id);
        } else {
            this._router.navigateByUrl(item.route.url);
            this.toggleMenu();
        }

    }

    toggleMenu() {
        this._state.notifyDataChanged('menu.isCollapsed', true);
        return false;
    }
}
