import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Message } from 'primeng/api';
//Services
import { AdmissionService } from 'app/shared/services/admissionService/admission/admission.service';
import { PatientVisitService } from 'app/shared/services/core/patientVisit/patientVisit.service';
import { AdmissionStationSearchService } from 'app/shared/services/queryModel/admission-station-search/admission-station-search.service'
//Models
import { VisitAdmissionsSearchResult } from 'app/shared/models/queryModel/visitAdmissionsSearchResult';
import { AdmissionVisitType } from 'app/shared/models/queryModel/enums/admission-visit-type';
import { BaseComponent } from 'app/shared/infrastructure';
@Component({
    selector: 'visit-admissions-cmp',
    styleUrls: ['./visit-admissions.scss'],
    templateUrl: './visit-admissions.html'
})


export class VisitAdmissionsComponent extends BaseComponent implements OnInit {
    visitsAndAdmissions: any;
    @Output() onSetResult = new EventEmitter<any>();
    _id: number;

    @Input() public set id(value: number) {
        this._id = value;
        if (value) {
            this.getAdmittedPatients(value);
            this.getOpenVisitByPatientId(value);
        }
    }

    ngOnInit() {
        this.visitsAndAdmissions = [];
    }

    constructor(private _admissionService: AdmissionService
        , private _patientVisitService: PatientVisitService
        , private _admissionStationSearchService: AdmissionStationSearchService) {
        super();

    }

    getAdmittedPatients(patientId: number) {
        this._admissionStationSearchService.getAdmittedPatient(patientId)
            .subscribe((data: VisitAdmissionsSearchResult) => {
                if (data != null) {
                    data.AdmissionDate = new Date(data.AdmissionDate);
                    data.Type = AdmissionVisitType.Admission;
                    this.visitsAndAdmissions.push(data);
                }

            },
                error => {
                    this.showError(error);
                    this.getOpenVisitByPatientId(patientId);
                });
    }

    getOpenVisitByPatientId(patientId: number) {
        this._patientVisitService.getOpenVisitByPatientId(patientId)
            .subscribe((data: VisitAdmissionsSearchResult[]) => {
                if (data.length > 0) {
                    data.forEach((a) => {
                        a.Type = AdmissionVisitType.PatientVisit;
                        a.VisitDate = new Date(a.VisitDate);
                        this.visitsAndAdmissions.push(a);
                    });
                }
                this.viewResult();
            },
                error => this.showError(error));
    }
    getSelectedPatient(item) {
        this.onSetResult.emit(item);
    }

    viewResult() {
        jQuery('.dispense').toggleClass('result-collapse');
        this.visitsAndAdmissions.sort((a, b) => {
            if (a.VisitDate && b.VisitDate) {
              return b.VisitDate.getTime() - a.VisitDate.getTime();
            }
            if (a.AdmissionDate && b.AdmissionDate) {
              return b.AdmissionDate.getTime() - a.AdmissionDate.getTime();
            }
            return 0;
        });
    }
}
