import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { VisitLookup } from '../../../models/core/visit-lookup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { VisitAdmissionsSearchResult } from '../../../models/queryModel/visitAdmissionsSearchResult';
import { PatientVisitFilter } from 'app/shared/models/core/patient-visit-filter';
import { map } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class PatientVisitService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _walkInVisit: string;
    private _erVisit: string;
    private _openVisitByPatientIdUrl: string;
    private _patientVisitByDoctorId: string;
    private _patientVisitByNurseId: string;
    private _listAllErVisitsOfPatient: string;
    private _visitStatusById: string;
    private _patientVisitBySearch: string;
    private _reOpenPatientVisit: string;
    private _getVisitDate: string;
    private _dischargePatientUrl: string;
    private _notifyPyxisOnPatientUpdateUrl: string;
    private _openVisitUrl: string;
    private _patientVisitsTodayUrl: string;
    private _getFollowUpCountUrl: string;
    private _walkinVisitCountUrl: string;
    private _notifyPacsOnPatientUpdateUrl: string;
    private _updateVisitDoctorAndSection: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientVisit.url;
        this._walkInVisit = this.actionUrl + _configuration.patientVisit.walkInVisit;
        this._erVisit = this.actionUrl + _configuration.patientVisit.erVisit;
        this._openVisitByPatientIdUrl = this.actionUrl + _configuration.patientVisit.getOpenVisitByPatientId;
        this._patientVisitByDoctorId = this.actionUrl + _configuration.patientVisit.patientVisitByDoctorId;
        this._patientVisitByNurseId = this.actionUrl + _configuration.patientVisit.patientVisitByNurseId;
        this._listAllErVisitsOfPatient = this.actionUrl + _configuration.patientVisit.listAllErVisitsOfPatient;
        this._visitStatusById = this.actionUrl + _configuration.patientVisit.visitStatusById;
        this._patientVisitBySearch = this.actionUrl + _configuration.patientVisit.patientVisitBySearch;
        this._reOpenPatientVisit = this.actionUrl + _configuration.patientVisit.reOpenPatientVisit;
        this._getVisitDate = this.actionUrl + _configuration.patientVisit.getVisitDate;
        this._dischargePatientUrl = this.actionUrl + _configuration.patientVisit.dischargePatient;
        this._notifyPyxisOnPatientUpdateUrl = this.actionUrl + _configuration.patientVisit.notifyPyxisOnPatientUpdate;
        this._openVisitUrl = this.actionUrl + _configuration.patientVisit.openVisit;
        this._patientVisitsTodayUrl = this.actionUrl + _configuration.patientVisit.patientVisitsToday;
        this._getFollowUpCountUrl = this.actionUrl + _configuration.patientVisit.getFollowUpCount;
        this._walkinVisitCountUrl = this.actionUrl + _configuration.patientVisit.walkInVisitCount;
        this._notifyPacsOnPatientUpdateUrl = this.actionUrl + _configuration.patientVisit.notifyPacsOnPatientUpdate;
        this._updateVisitDoctorAndSection = this.actionUrl + _configuration.patientVisit.updateVisitDoctorAndSection;
    }

    getVisitStatusById = (visitId): Observable<any> => {
        return this._http.get<any>(this._visitStatusById + '?visitId=' + visitId);
    }

    getById = (id: number): Observable<any> => {
        return this._http.get<any>(this._url + "/GetById?Id=" + id);
    }

    getVisitDate = (id: number): Observable<any> => {
        return this._http.get<any>(this._getVisitDate + "?Id=" + id);
    }

    listAllErVisitsOfPatient = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._listAllErVisitsOfPatient + '?patientId=' + patientId);
    }

    patientVisitInfo = (id: number, checkStatus = true): Observable<any> => {
        const url = `${this._url}/PatientVisitInfo?id=${id}`;
        return this._http.get<any>(url);
    }

    getOpenVisitByPatientId = (patientId, id = null): Observable<any> => {
        return this._http.get<any>(`${this._openVisitByPatientIdUrl}?patientId=${patientId}&&id=${id}`);
    }

    getOpenedVisits = (patientId): Observable<VisitLookup[]> => {
        return this._http.get<any>(this._url + "/OpenedVisitId?patientId=" + patientId)
            .pipe(
                map((response: Response) => {

                    let objects = response;
                    let visits: VisitLookup[] = [];
                    for (let index in objects) {
                        visits.push(new VisitLookup(objects[index]));
                    }
                    return visits || [];
                })
            );
    }

    getLastVisitDate = (sectionId: number, doctorId: number, patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '?sectionId=' + sectionId + '&&doctorId=' + doctorId + '&&patientId=' + patientId);
    }

    addWalkInVisit = (patientId: number, data: any): Observable<any> => {
        return this._http.post<any>(this._walkInVisit + '?walkInPatientId=' + patientId, data);
    }

    addErVisit = (patientId: number, data: any): Observable<any> => {
        return this._http.post<any>(this._erVisit + '?erPatientId=' + patientId, data);
    }

    getPatientVisitByDoctorId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._patientVisitByDoctorId + '?isActiveEncounter=' + isActiveEncounter);
    }

    public getPatientVisitByNurseId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._patientVisitByNurseId + '?isActiveEncounter=' + isActiveEncounter);
    }

    updateVisitDetails = (visitAdmissionsSearchResult: VisitAdmissionsSearchResult): Observable<any> => {
        return this._http.put<any>(this._url + '?cashPriceListId=' + visitAdmissionsSearchResult.CashPriceListId, JSON.stringify(visitAdmissionsSearchResult));
    }


    getOpenPatientVisitById = (visitId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/OpenPatientVisitById?visitId=' + visitId);
    }

    public getPatientVisitBySearch = (patientVisitFilter: PatientVisitFilter, page: number, rows: number): Observable<any> => {
        const patientId = patientVisitFilter.PatientId ? patientVisitFilter.PatientId : null;
        const patientCode = patientVisitFilter.PatientCode ? patientVisitFilter.PatientCode : '';
        const patientName = patientVisitFilter.PatientName ? patientVisitFilter.PatientName : '';
        const sectionId = patientVisitFilter.SectionId ? patientVisitFilter.SectionId : null;
        var visitDate = patientVisitFilter.VisitDate ? patientVisitFilter.VisitDate.toDateString() : null;
        const dealingTypeId = patientVisitFilter.DealingTypeId ? patientVisitFilter.DealingTypeId : null;
        const statusId = patientVisitFilter.StatusId ? patientVisitFilter.StatusId : null;
        const consultationTypeId = patientVisitFilter.ConsultationTypeId ? patientVisitFilter.ConsultationTypeId : null;
        const sourceId = patientVisitFilter.SourceId ? patientVisitFilter.SourceId : null;
        const doctorId = patientVisitFilter.DoctorId ? patientVisitFilter.DoctorId : null;
        const consultationTypeIds = patientVisitFilter.ConsultationTypeIds ? patientVisitFilter.ConsultationTypeIds : '';

        return this._http.get<any>(this._patientVisitBySearch
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&SectionId=' + sectionId
            + '&&VisitDate=' + visitDate
            + '&&DealingTypeId=' + dealingTypeId
            + '&&StatusId=' + statusId
            + '&&ConsultationTypeId=' + consultationTypeId
            + '&&DoctorId=' + doctorId
            + '&&SourceId=' + sourceId
            + '&&ConsultationTypeIds=' + consultationTypeIds
            + '&&PageIndex=' + page
            + '&&PageSize=' + rows
        );
    }

    reOpenPatientVisit = (visitId): Observable<any> => {
        return this._http.put<any>(`${this._reOpenPatientVisit}?visitId=${visitId}`
            , null);
    }

    getAllPatientEncounters = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AllPatientEncounters?patientId=' + patientId);
    }

    getVistsBySession = (patientId: any, sessionId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/VisitsBySessionId?patientId=' + patientId + '&&sessionId=' + sessionId);
    }

    dischargePatient(visitId: number) {
        return this._http.post<any>(this._dischargePatientUrl, visitId);
    }

    notifyPyxisOnPatientUpdate = (patientId: number) => {
        return this._http.post<any>(this._notifyPyxisOnPatientUpdateUrl, patientId);
    }

    getOpenVisit = (patientId): Observable<any> => {
        return this._http.get<any>(this._openVisitUrl + '?patientId=' + patientId);
    }

    getVisitDoctorId = (encounterId: number): Observable<number> => {
        return this._http.get<any>(this._url + '/VisitDoctorId?encounterId=' + encounterId);
    }

    getPatientVisitsToday = (sectionIds: any[] = null) => {
        return this._http.post<any>(this._patientVisitsTodayUrl, sectionIds, { responseType: 'noCredential' as 'json' });
    }

    getFollowUpCount = (sectionId: number, doctorId: number, patientId: number): Observable<any> => {
        return this._http.get<any>(this._getFollowUpCountUrl + '?sectionId=' + sectionId + '&&doctorId=' + doctorId + '&&patientId=' + patientId);
    }

    getWalkInVisitCount = (sectionId: number, doctorId: number): Observable<number> => {
        return this._http.get<number>(this._walkinVisitCountUrl + '?sectionId=' + sectionId + '&&doctorId=' + doctorId);
    }

    notifyPacsOnPatientUpdate = (patientId: number) => {
        return this._http.post<any>(this._notifyPacsOnPatientUpdateUrl, patientId);
    }

    updateVisitDoctorAndSection = (encounterId: number, doctorId: number): Observable<any> => {
        return this._http.put<any>(`${this._updateVisitDoctorAndSection}?encounterId=${encounterId}`, doctorId);
    }

    getPatientHistory = (patientId): Observable<any> => {
        return this._http.get<any>(`${environment.core}PatientVisit/PatientHistory?patientId=${patientId}`);
    }

    appointmentHasVisit = (appointmentId, patientId): Observable<number> => {
        return this._http.get<number>(`${environment.core}PatientVisit/AppointmentHasVisit?appointmentId=${appointmentId}&&patientId=${patientId}`);
    }
}