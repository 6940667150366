import { NormalResultHistory } from './normal-result-history';

export class SpecimenTransactionNormalResultHistory {

    ItemId:number;
    ItemTitle:string;
    ItemForigenTitle:string;
    Unit:string;
    NormalRange: string;

    FirstColumnHistoryValue: string;
    SecondColumnHistoryValue: string;
    ThirdColumnHistoryValue: string;
    ForthColumnHistoryValue: string;
    FifthColumnHistoryValue: string;

    NormalResultHistories: NormalResultHistory[];

    constructor() { }
}