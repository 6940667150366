import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from 'app/shared/infrastructure';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorPendingAreaManagementService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    release = (data): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}PendingAreaManagement/Release`, JSON.stringify(data))
    }
}
