import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class OncologyServiceApiConfiguration {

    constructor() {
        this.server = environment.oncology;
    }

    server: string;

    case = {
        url: 'Case',
        getEncounterInformationByPatientId: 'Case/GetEncounterInformationByPatientId',
    };
}
