import { SelectItem } from 'primeng/api';
import { ApprovalPeriodType } from '../enums';

export class ApprovalPeriodTypesLookup{

    static getLookup(): SelectItem[] {
        return [
            { label: 'Hour', value: ApprovalPeriodType.Hour },
            { label: 'Day', value: ApprovalPeriodType.Day },
        ];
    }
}
