import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { PatientStatementFilter } from 'app/shared/models/queryModel/patient-statement-filter';
import { PatientTypesLookup } from 'app/shared/models/orders-and-billing/lookup/patient-types-lookup';
import { Payer } from 'app/shared/models/orders-and-billing/payer';
import { PayerService, CoverageContractService, CoverageClassService } from 'app/shared/services/order-and-billing';
import { PatientStatement } from 'app/shared/models/orders-and-billing/patient-statement';
import { PatientStatmentSearchService } from 'app/shared/services/queryModel/patient-statment-search/patient-statment-search.service';

@Component({
    selector: 'advance-search-claim-management-cmp',
    styleUrls: ['./advance-search-claim-management.scss'],
    templateUrl: './advance-search-claim-management.html'
})

export class AdvanceSearchClaimManagementComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();

    patientTypes: any[];
    insuranceProviders: any[];
    patientStatementFilter: PatientStatementFilter = new PatientStatementFilter();
    payers: SelectItem[];
    coverageContracts: SelectItem[];
    coverageClasses: SelectItem[];
    searchForm = new FormGroup({
        invoiceId: new FormControl(null, Validators.compose([])),
        patientName: new FormControl(null, Validators.compose([])),
        patientCode: new FormControl(null, Validators.compose([])),
        patientType: new FormControl(null, Validators.compose([Validators.required])),
        fromDate: new FormControl(null, Validators.compose([Validators.required])),
        toDate: new FormControl(null, Validators.compose([Validators.required])),
        payer: new FormControl(null, Validators.compose([Validators.required])),
        coverageContract: new FormControl(null, Validators.compose([])),
        coverageClass: new FormControl(null, Validators.compose([])),
    });

    get invoiceId(): any { return this.searchForm.get('invoiceId'); };
    get patientName(): any { return this.searchForm.get('patientName'); };
    get patientCode(): any { return this.searchForm.get('patientCode'); };
    get patientType(): any { return this.searchForm.get('patientType'); };
    get fromDate(): any { return this.searchForm.get('fromDate'); };
    get toDate(): any { return this.searchForm.get('toDate'); };
    get payer(): any { return this.searchForm.get('payer'); };
    get coverageContract(): any { return this.searchForm.get('coverageContract'); };
    get coverageClass(): any { return this.searchForm.get('coverageClass'); };

    constructor(
        private _payerService: PayerService,
        public _formBuilder: FormBuilder,
        private _patientStatmentSearchService: PatientStatmentSearchService,
        private _coverageContractService: CoverageContractService,
        private _coverageClassService: CoverageClassService) {

        this.patientTypes = [];
        this.patientTypes = PatientTypesLookup.getLookupWithBoth();
        this.getPayer();
        this.payer.valueChanges.subscribe(payerId => { if(payerId > 0) { this.getCoverageContractByPayer(payerId); } });
        this.coverageContract.valueChanges.subscribe(contractId => { if(contractId > 0) { this.getCoverageClassByContract(contractId); } });
    }

    ngOnInit() {

    }

    getPayer() {
        this._payerService.getPayersHasParent()
            .subscribe((data: Payer[]) => {
                this.payers = [];
                this.payers = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    getCoverageContractByPayer(payerId: number) {
        this._coverageContractService.getInfoByPayer(payerId)
            .subscribe((data: any[]) => {
                this.coverageContracts = [];
                this.coverageContracts = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    getCoverageClassByContract(contractId: number) {
        this._coverageClassService.getInfoByContract(contractId)
            .subscribe((data: any[]) => {
                this.coverageClasses = [];
                this.coverageClasses = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    onSubmit() {
        if (!this.searchForm.valid) {
            return;
        }

        this.patientStatementFilter.InvoiceId = this.invoiceId.value;
        this.patientStatementFilter.PatientCode = this.patientCode.value;
        this.patientStatementFilter.PatientName = this.patientName.value;
        this.patientStatementFilter.PatientTypeID = this.patientType.value;
        this.patientStatementFilter.PayerID = this.payer.value;
        this.patientStatementFilter.FromIssueDate = this.fromDate.value;
        this.patientStatementFilter.CoverageContractId = this.coverageContract.value;
        this.patientStatementFilter.CoverageClassId = this.coverageClass.value;
        this.patientStatementFilter.ToIssueDate = this.toDate.value;

        const page: number = 1, rows: number = 10;
        let payerDescription = this.payers.find(item => item.value == this.patientStatementFilter.PayerID).label;
        let patientTypeDescription = this.patientTypes.find(item => item.value == this.patientStatementFilter.PatientTypeID).label;
        this._patientStatmentSearchService.getCreditInvoicesSearch(page, rows, this.patientStatementFilter).subscribe((data: PatientStatement[]) => {
            this.onSearch.emit({ claims: data, filter: this.patientStatementFilter, payerDescription: payerDescription, patientTypeDescription: patientTypeDescription });
        })
    }

    ngOnDestroy() {

    }
}
