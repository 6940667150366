
export class VisitStationInfo {
    Id: number;
    PatientId: number;
    PatientCode: string;
    PatientFullName: string;
    PatientBirthDate?: Date;
    PatientGenderId?: number;
    PatientTelCode: string;
    PatientIsBlocked?: boolean;
    PatientBlockReason: string;
    PatientFirstName: string;
    PatientFatherName : string;
    PatientMidName: string;
    PatientLastName: string;
    OrganizationSectionTitle: string;
    VisitDealingType: number;
    VisitDate: Date;
    VisitDoctorStaffFullName: string;
    VisitStatus: number;
    VisitConsultationTypeId: number;
    VisitSessionNo?: number;

    PayerTitle: string;

    constructor() {
    }
}