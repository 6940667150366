import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class AppointmentServiceApiConfiguration {

    constructor() {
        this.server = environment.appointment;
    }

    server: string;

    appointment = {
        url: 'Appointment',
        visitSearch: 'Appointment/Visit/Search',
        cancelAppointment: 'Appointment/Cancel',
        getPatientDataById: 'Appointment/PatientDataById',
        getVisitDataById: 'Appointment/VisitDataById',
        arriveAppoinment: 'Appointment/Arrive',
    };

    resource = {
        maxAllowedVisitCount: 'Resource/MaxAllowedVisitCount',
    };
}
