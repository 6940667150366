import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OperationApiConfiguration } from '../operation-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class RoomBookedService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    constructor(private _http: HttpClient, private _configuration: OperationApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + this._configuration.operation.url;
    }

    isAllowCreateVisit = (doctorId: number, visitDate: any): Observable<number> => {
        return this._http.get<number>(`${this._url}/IsAllowCreateVisit?doctorId=${doctorId}&&visitDate=${visitDate.toGMTString()}`);
    }
}
