export enum InvoiceReportType {
    Billing_CreditInvoiceEn = 1,
    Billing_CreditInvoiceDetailsEn = 2,
    Billing_StatementOfAccounts = 3,
    Billing_CreditOrderSetInvoiceEn = 4,
    Billing_CreditInvoiceAr = 5,
    Billing_CreditInvoiceDetailsAr = 6,
    Billing_CreditInvoiceDetailsArSummary = 7,
    Billing_CreditOrderSetInvoiceAr = 8,
    Billing_CashInvoiceAr = 9,
    Billing_CashInvoiceDetailsAr = 10,
    Billing_CashInvoiceDetailsArSummary = 11,
    Billing_CashInvoiceEn = 12,
    Billing_CashInvoiceDetailsEn = 13,
}
