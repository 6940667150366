import { SelectItem } from 'primeng/api';
import { PblAction } from '../enums';

export class PblActionLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Alert', value: PblAction.Alert },
            { label: 'Raise', value: PblAction.Raise },
        ];
    }

    static get(): SelectItem[] {
        return [
            { label: 'Select Action ...', value: null },
            { label: 'Alert', value: PblAction.Alert },
            { label: 'Raise', value: PblAction.Raise },
        ];
    }
}
