import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { SystemCurrency } from 'app/shared/models/orders-and-billing/system-currency';

@Injectable()
export class LinkServicesSectionsService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getAll: string;
    private _addLinkServicesSections: string;
    private _updateLinkServicesSections: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.allowedServicesSection.url;
        this._addLinkServicesSections = this.actionUrl + configuration.allowedServicesSection.add;
        this._updateLinkServicesSections = this.actionUrl + configuration.allowedServicesSection.update;
    }

    getAll = (): Observable<SystemCurrency[]> => {
        return this.http.get<any>(this.url);
    }

    add = (data: any): Observable<any> => {
        return this.http.post<any>(this._addLinkServicesSections, data);
    }

    update = (data: any): Observable<any> => {
        return this.http.put<any>(`${this._updateLinkServicesSections}?id=${data.id}`, data);
    }
}
