import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientTransferSearchFilter } from '../../../models/queryModel';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientTransferSearchService extends ServiceBase {

    private actionUrl: string;
    private _get: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.patientTransferSearch.url;
    }

    public getSearchResult = (data: PatientTransferSearchFilter): Observable<any> => {

        let queryString: string = '';
        queryString += '?patientCode=';
        if (data.patientCode)
            queryString += data.patientCode;
        queryString += '&patientName=';
        if (data.patientName)
            queryString += data.patientName;

        queryString += '&admissionDoctor=';
        if (data.admissionDoctor)
            queryString += data.admissionDoctor;

        queryString += '&section=';
        if (data.section)
            queryString += data.section;

        queryString += '&room=';
        if (data.room)
            queryString += data.room;

        return this._http.get<any>(this._get + queryString);
    }
}