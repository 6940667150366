import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Diagnose } from 'app/shared/models/core/diagnosis';

@Injectable()
export class DiagnoseService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byTitleUrl: string;
    private _bySearchUrl: string;
    private _bySearchWithLeafAndParent: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.diagnose.url;
        this._byTitleUrl = this.actionUrl + _configuration.diagnose.byTitle;
        this._bySearchUrl = this.actionUrl + _configuration.diagnose.bySearch;
        this._bySearchWithLeafAndParent = this.actionUrl + _configuration.diagnose.bySearchWithLeafAndParent;
    }

    public getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getByTitle = (title): Observable<Diagnose[]> => {
        return this._http.get<any>(`${this._byTitleUrl}?title=${title}`);
    }

    getBySearch = (query: string = null): Observable<Diagnose[]> => {
        return this._http.get<any>(`${this._bySearchUrl}?query=${query ? query : ''}`);
    }

    BySearchWithLeafAndParent = (query: string = null): Observable<Diagnose[]> => {
        return this._http.get<any>(`${this._bySearchWithLeafAndParent}?query=${query ? query : ''}`);
    }

    public insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    public update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    public delete = (id: number, isActive: number): Observable<any> => {
        return this._http.delete(this._url);
    }
}