import { SelectItem } from 'primeng/api';
import { DealingType } from 'app/shared/models/admission/enums/dealing-types';

export class DealingTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Credit', value: DealingType.Credit },
            { label: 'Cash', value: DealingType.Cash }];
    }

    static getWithOutAllLookup(): SelectItem[] {
        return [
            { label: 'Credit', value: DealingType.Credit },
            { label: 'Cash', value: DealingType.Cash }];
    }

    static getCashLookup(): SelectItem[] {
        return [
            { label: 'Cash', value: DealingType.Cash }];
    }

    static getCreditLookup(): SelectItem[] {
        return [
            { label: 'Credit', value: DealingType.Credit }];
    }
}
