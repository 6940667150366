import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';

import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { PendingAreaFilter } from 'app/shared/models/queryModel/pending-area-filter';
import { ActivePendingArea } from 'app/shared/models/queryModel/active-pending-area';
import { PendingAreaInfoStatus } from 'app/shared/models/orders-and-billing';

@Injectable()
export class PendingAreaService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private activePendingAreaBySearchUrl: string;
    private haveActivePendingAreaForCreditPatientsUrl: string;
    private haveActivePendingAreaForCashPatientsUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorFeesStation.url;
        this.activePendingAreaBySearchUrl = this.actionUrl + _configuration.pendingArea.activePendingAreaBySearch;
        this.haveActivePendingAreaForCreditPatientsUrl = this.actionUrl + _configuration.pendingArea.haveActivePendingAreaForCreditPatients;
        this.haveActivePendingAreaForCashPatientsUrl = this.actionUrl + _configuration.pendingArea.haveActivePendingAreaForCashPatients;
    }

    getActivePendingAreaBySearch = (pendingAreaFilter: PendingAreaFilter, page: number, rows: number): Observable<ActivePendingArea[]> => {
        const orderId = pendingAreaFilter.OrderId ? pendingAreaFilter.OrderId : '';
        const patientCode = pendingAreaFilter.PatientCode ? pendingAreaFilter.PatientCode : '';
        var orderDateString = pendingAreaFilter.OrderDate ? pendingAreaFilter.OrderDate.toLocaleDateString() : '';
        const patientName = pendingAreaFilter.PatientName ? pendingAreaFilter.PatientName : '';
        const payerTitle = pendingAreaFilter.PayerTitle ? pendingAreaFilter.PayerTitle : '';
        const orderedByName = pendingAreaFilter.OrderedByName ? pendingAreaFilter.OrderedByName : '';
        const location = pendingAreaFilter.Location ? pendingAreaFilter.Location : '';
        const status = pendingAreaFilter.Status == true ? PendingAreaInfoStatus.New : PendingAreaInfoStatus.Approved;

        return this._http.get<any>(this.activePendingAreaBySearchUrl
            + '?OrderId=' + orderId
            + '&PatientCode=' + patientCode
            + '&OrderDate=' + orderDateString
            + '&PatientName=' + patientName
            + '&PayerTitle=' + payerTitle
            + '&OrderedByName=' + orderedByName
            + '&Location=' + location
            + '&Page=' + page
            + '&Rows=' + rows
            + '&Status=' + status
        );
    }

    haveActivePendingAreaForCreditPatients = (): Observable<boolean> => {
        return this._http.get<any>(this.haveActivePendingAreaForCreditPatientsUrl);
    }

    haveActivePendingAreaForCashPatients = (): Observable<boolean> => {
        return this._http.get<any>(this.haveActivePendingAreaForCashPatientsUrl);
    }

}

