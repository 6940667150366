export class MobileAccountAvailabilityFilter {

  PatientId  : number;
    PatientCode: string;
    NationalityId: number;
    DocumentType?: number;
    DocumentNumber: string;
    NationalNumber: string
    constructor() {
    }
}