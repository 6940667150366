import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemBasicInfo } from '../../../models/orders-and-billing/item-basic-info';
import { ItemSearchFilter } from '../../../models/orders-and-billing/item-search-filter';
import { Control } from 'app/pages/control/control';
import { PagedResults } from 'app/shared/interfaces/paged-results.interface';
import { OrderApiConfiguration } from '../order-api-configuration';
import { LabApiConfiguration } from '../../lab';

@Injectable()
export class ItemService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _labActionUrl: string;
    private _labUrl: string;
    private bySearchUrl: string;
    private bySearchPagedUrl: string;
    private _isMultiDentalParent: string;
    private _isItemHasDentalParent: string;
    private _activeParentsAndLeaf: string;
    _getServiceByCategoryURL: string;
    currentItemSet: Item[];


    constructor(private _http: HttpClient, _configuration: OrderApiConfiguration, labApiConfiguration: LabApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.item.url;
        this.bySearchUrl = this.actionUrl + _configuration.item.bySearch;
        this.bySearchPagedUrl = this.actionUrl + _configuration.item.bySearchPaged;

        this._labActionUrl = labApiConfiguration.server;
        this._labUrl = this._labActionUrl + labApiConfiguration.item.url;
        this._getServiceByCategoryURL = this.actionUrl + _configuration.item.getServiceByCategory;
        this._isMultiDentalParent = this.actionUrl + _configuration.item.isMultiDentalParent;
        this._isItemHasDentalParent = this.actionUrl + _configuration.item.isItemHasDentalParent;
        this._activeParentsAndLeaf = this.actionUrl + _configuration.item.activeParentsAndLeaf;
    }

    private _buildSearchQueryString(itemSearchFilter: ItemSearchFilter): string {
        const code = (itemSearchFilter.Code === '') ? '' : `&&Code=${itemSearchFilter.Code}`;
        const desc = (itemSearchFilter.Description === '') ? '' : `&&Description=${itemSearchFilter.Description}`;
        const categoriesIds = (itemSearchFilter.CategoriesIds) ? `&&CategoriesIds=${itemSearchFilter.CategoriesIds}` : '';

        return `Id=${itemSearchFilter.Id}${code}&&MainItemId=${itemSearchFilter.MainItemId}` +
            `&&TypeId=${itemSearchFilter.TypeId}${desc}&&IsVariablePrice=${itemSearchFilter.IsVariablePrice}` +
            `&&IsOrderable=${itemSearchFilter.IsOrderable}` +
            `&&CategoryId=${itemSearchFilter.CategoryId}` +
            `&&IsChargable=${itemSearchFilter.IsChargable}` +
            `&&IsActive=${itemSearchFilter.IsActive}` +
            `&&PageSize=${itemSearchFilter.PageSize}` +
            `&&PageIndex=${itemSearchFilter.PageIndex}${categoriesIds}`;
    }

    getById = (id: number): Observable<Item> => {
        return this._http.get<any>(`${this._url}?itemId=${id}`);
    }

    getBySearch = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {
        return this._http.get<any>(`${this.bySearchUrl}?${this._buildSearchQueryString(itemSearchFilter)}`
        );
    }

    getBySearchPaged = (itemSearchFilter: ItemSearchFilter): Observable<PagedResults<Item>> => {
        return this._http.get<any>(`${this.bySearchPagedUrl}?${this._buildSearchQueryString(itemSearchFilter)}`
        );
    }

    getLeaf = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(`${this._url}/leaf?${this._buildSearchQueryString(itemSearchFilter)}`
        ).pipe(
            map((response: Response) => {
                const objects = response;
                const items: Item[] = [];
                for (const index in objects) {
                    items.push(new Item(objects[index]));
                }
                this.currentItemSet = items;
                items.forEach(i => i.UnitId = i.DefaultUnitId);
                return items || [];
            })
        )

    }
    getLeafAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(this._url + "/BasicInfo/leaf"
            + '?' + this._buildSearchQueryString(itemSearchFilter));
    }

    getAllTypesAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(this._url + "/BasicInfo/AllTypes"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getParentOrServiceOrPackageLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(this._url + "/BasicInfo/ParentOrServiceOrPackage"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getParentsAndLeaf = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(this._url + "/ParentsAndLeaf"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }
    getParentsAndLeafAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(this._url + "/BasicInfo/ParentsAndLeaf"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getParents = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(this._url + "/Parents"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }
    getParentsAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(this._url + "/BasicInfo/Parents"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getParentsTypeAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(this._url + "/BasicInfo/ParentsType"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getPackages = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(`${this._url}/Packages?${this._buildSearchQueryString(itemSearchFilter)}`
        );
    }
    getPackagesAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(`${this._url}/BasicInfo/Packages?${this._buildSearchQueryString(itemSearchFilter)}`
        );
    }

    getPackagesAndLeaf = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(`${this._url}/PackageOrLeaf?${this._buildSearchQueryString(itemSearchFilter)}`
        ).pipe(
            map((response: Response) => {
                const objects = response;
                const items: Item[] = [];
                for (const index in objects) {
                    items.push(new Item(objects[index]));
                }
                this.currentItemSet = items;
                items.forEach(i => i.UnitId = i.DefaultUnitId);
                return items || [];
            })
        )
    }


    getCompanionService = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {

        return this._http.get<any>(`${this._url}/CompanionService?${this._buildSearchQueryString(itemSearchFilter)}`
        ).pipe(
            map((response: Response) => {
                const objects = response;
                const items: Item[] = [];
                for (const index in objects) {
                    items.push(new Item(objects[index]));
                }
                this.currentItemSet = items;
                items.forEach(i => i.UnitId = i.DefaultUnitId);
                return items || [];
            })
        )
    }

    getPACSCodes = (): Observable<Control[]> => {
        return this._http.get<any>(`${this._url}/PACSCodes`);
    }

    getPackagesAndLeafbyAllowedCategories = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {
        return this._http.get<any>(`${this._url}/PackageOrLeafByCategory?${this._buildSearchQueryString(itemSearchFilter)}`
        ).pipe(
            map((response: Response) => {
                const objects = response;
                const items: Item[] = [];
                for (const index in objects) {
                    items.push(new Item(objects[index]));
                }
                this.currentItemSet = items;
                items.forEach(i => i.UnitId = i.DefaultUnitId);
                return items || [];
            })
        )
    }

    insert = (data: Item): Observable<any> => {
        // First add item to OrderAndBilling, then add item to LAB
        let item: Item;
        return this._http.post<any>(`${this._url}/item`, data)
            .pipe(
                map(response => {
                    item = response as Item;
                }),
                concatMap(() =>
                    this._http.post<any>(this._labUrl, item))
            )
    }

    update = (data: Item): Observable<any> => {
        // First update item to OrderAndBilling, then update item to LAB
        return this._http.put<any>(`${this._url}/item`, data)
            .pipe(
                concatMap(() => this._http.put<any>(this._labUrl, data))
            );

    }

    getChargeableServiceAndPackageAndParent = (query: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/ChargeableServiceAndPackageAndParent?query=${query}`
        );
    }

    getRadiologyServiceItem = (query: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/RadiologyServiceItem?query=${query}`
        );
    }

    getFavoriteItemByCategory = (categoryIds: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/BasicInfo/FavoriteItemByCategory?categoryIds=${categoryIds}`
        );
    }

    getParentAndOrderSetLeafItemsAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {
        return this._http.get<any>(this._url + "/ParentAndOrderSetLeafItems"
            + '?' + this._buildSearchQueryString(itemSearchFilter)
        );
    }

    getAccommodationServiceItem = (query: string, itemId: number = null): Observable<ItemBasicInfo[]> => {

        return this._http.get<any>(`${this._url}/AccommodationServiceItem?query=${query}&&itemId=${itemId}`
        );
    }

    getServiceItems = (query: string, categoryIds: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/BasicInfo/ServiceItems?query=${query}&&categoryIds=${categoryIds}`
        );
    }

    getVariablePriceServiceItems = (query: string, itemId: number = null): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/BasicInfo/VariablePriceServiceItems?query=${query}&&itemId=${itemId}`
        );
    }

    getPatientPackages = (patientId: number): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/BasicInfo/PatientPackages?patientId=${patientId}`
        );
    }

    getActiveServiceItems = (query: string, itemId: number = null, code: string = null): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/BasicInfo/ActiveServiceItems?query=${query}&&itemId=${itemId}&&code=${code ?? ''}`
        );
    }

    getServiceByCategory = (): Observable<any> => {
        return this._http.get<any>(this._getServiceByCategoryURL);
    }

    getLeafByCategoryAsLookup = (itemSearchFilter: ItemSearchFilter): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(this._url + "/BasicInfo/LeafByCategory"
            + '?' + this._buildSearchQueryString(itemSearchFilter));
    }

    getLeafByCategory = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {
        return this._http.get<any>(this._url + "/LeafByCategory"
            + '?' + this._buildSearchQueryString(itemSearchFilter));
    }

    isMultiDentalParent = (itemId: number = null): Observable<any> => {
        return this._http.get<any>(`${this._isMultiDentalParent}?itemId=${itemId}`);
    }

    isItemHasDentalParent = (itemId: number = null): Observable<any> => {
        return this._http.get<any>(`${this._isItemHasDentalParent}?itemId=${itemId}`);
    }

    getActiveParentAndLeaf = (query: string): Observable<any> => {
        return this._http.get<any>(`${this._activeParentsAndLeaf}?query=${query}`
        );
    }

    searchByTypes = (query: string, typeIds: any[]): Observable<any> => {
        return this._http.get<any>(`${this._url}/GetByTypes?query=${query}&&typeIds=${typeIds}`
        );
    }

    updateItem = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getItemsWithoutMedications = (query: string, typeIds: any[]): Observable<any> => {
        return this._http.get<any>(`${this._url}/GetItemsWithoutMedications?query=${query}&&typeIds=${typeIds}`
        );
    }

    getPreAdmissionItems = (itemSearchFilter: ItemSearchFilter): Observable<Item[]> => {
        return this._http.get<any>(`${this._url}/PreAdmissionItems?${this._buildSearchQueryString(itemSearchFilter)}`);
    }

    getServiceAndParentItems = (query: string, categoryIds: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(`${this._url}/ServiceAndParentItems?query=${query}&&categoryIds=${categoryIds}`);
    }
}
