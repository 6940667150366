import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PatientDischargeSearchService } from "../../../services/queryModel/patientDischargeSearch";
import { LookUpService } from "../../../services/core/lookup";
import { PatientDischargeSearchFilter } from "../../../models/queryModel";
@Component({
    selector: 'advance-search-discharge-cmp',
    styleUrls: ['./advanceSearchDischarge.scss'],
    templateUrl: './advanceSearchDischarge.html'
})

export class AdvanceSearchDischargeComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();
    patientDischargeSearchFilter: PatientDischargeSearchFilter = new PatientDischargeSearchFilter();
    sections: any;
    admissionDoctors: any;
    constructor(private _patientDischargeSearchService: PatientDischargeSearchService, private _lookUpService: LookUpService) {

    }
    ngOnInit() {
        this.getSections();
        this.getAdmissionDoctor();
    }

    getSections() {
        this._lookUpService.getAllMedicalCategorySection()
            .subscribe((data: any) => {
                this.sections = [];
                for (let i = 0; i < data.length; i++) {
                    this.sections.push({ label: data[i].Title, value: data[i].Id });
                }
            });
    }

    getAdmissionDoctor() {
        this._lookUpService.getDoctorStaff()
            .subscribe((data: any) => {
                this.admissionDoctors = [];
                for (let i = 0; i < data.length; i++) {
                    this.admissionDoctors.push({ label: data[i].Title, value: data[i].Id });
                }
            });

    }

    onSubmit() {
        this._patientDischargeSearchService.getSearchResult(this.patientDischargeSearchFilter)
            .subscribe((data) => {
                this.onSearch.emit({ patientDischargeSearchResult: data });

            });

    }
}
