import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { SpecimenTransactionViewModel } from '../../../models/lab/specimenTransactionViewModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
    selector: 'advance-search-specimen-transactiontext-result-cmp',
    styleUrls: ['./advanceSearchSpecimenTransactionTextResult.scss'],
    templateUrl: './advanceSearchSpecimenTransactionTextResult.html'
})

export class AdvanceSearchSpecimenTransactionTextResult extends BaseComponent implements OnInit, OnDestroy {

    @Output() onSearch = new EventEmitter<any>();
    specimenTransactionViewModel: SpecimenTransactionViewModel = new SpecimenTransactionViewModel();

    _refreshInformation: SpecimenTransactionViewModel;
    @Input() set refreshInformation(value: SpecimenTransactionViewModel) {
        this._refreshInformation = value;
        if (this._refreshInformation) {
            this.specimenTransactionViewModel = new SpecimenTransactionViewModel();
            this.specimenTransactionViewModel = value;
        }
    }

    public form: FormGroup;

    ngOnInit() {
    }

    constructor(fb: FormBuilder) {
        super();
        this.form = fb.group({
            'PatientCode': ['', Validators.compose([])],
            'OrderNumber': ['', Validators.compose([])],
            'AccessionNumber': ['', Validators.compose([])],
            'OrderFrom': ['', Validators.compose([])],
            'OrderTo': ['', Validators.compose([])],
        });
    }

    onSubmit() {
        if (!this.specimenTransactionViewModel.OrderNumber && !this.specimenTransactionViewModel.AccessionNumber) {
            this.showError('Order number or Accession number shold be provided..!');
            return;
        } else {
            if (!this.specimenTransactionViewModel.OrderNumber && (this.specimenTransactionViewModel.AccessionNumber != null && this.specimenTransactionViewModel.AccessionNumber.trim().length == 0)) {
                this.showError('Order number or Accession number shold be provided..!');
                return;
            }
        }

        if (this.specimenTransactionViewModel.OrderFrom != undefined)
            this.specimenTransactionViewModel.OrderFrom = new Date(this.specimenTransactionViewModel.OrderFrom);
        if (this.specimenTransactionViewModel.OrderTo != undefined)
            this.specimenTransactionViewModel.OrderTo = new Date(this.specimenTransactionViewModel.OrderTo);

        this.onSearch.emit(this.specimenTransactionViewModel);
    }

    ngOnDestroy() {
    }
}
