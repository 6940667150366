import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { GlobalState } from '../../../global.state';
import { TranslateService } from '@ngx-translate/core';
import { Icons } from 'app/pages/icons';
import { isMobile } from 'app/shared';

@Component({
    selector: 'ba-page-top',
    templateUrl: './baPageTop.html',
    styleUrls: ['./baPageTop.scss']
})
export class BaPageTop implements OnInit, AfterViewChecked {

    public isScrolled: boolean = false;
    public isMenuCollapsed: boolean = false;
    public selectedLanguages: any[] = [];
    public mobilePicture: string = 'assets/img/app/system/Certacure-internal.png';
    public selectedLanguage: string = '';
    constructor(private _state: GlobalState,
        public translate: TranslateService,
        private _DomSanitizer: DomSanitizer,
        private _changeDetectorRef: ChangeDetectorRef) {

        translate.addLangs(["en", "ar"]);
        let lang = JSON.parse(localStorage.getItem('lang'));
        if (lang) {
            this.setSelectedLanguage('ar');
            translate.setDefaultLang('ar');
            jQuery('body').addClass('rtl');
            this.selectedLanguage = 'العربية';
        } else {
            this.setSelectedLanguage('en');
            translate.setDefaultLang('en');
        }

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }

    ngOnInit() {
        console.log(isMobile);
    }

    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    setLanguage(item) {
        if (item === 'ar') {
            if (!jQuery('body').hasClass('rtl')) {
                jQuery('body').addClass('rtl');
                localStorage.setItem('lang', JSON.stringify(item));
            }
        } else {
            jQuery('body').removeClass('rtl');
            localStorage.removeItem('lang');
        }
        this.setSelectedLanguage(item);
        this.translate.use(item);
    }

    setSelectedLanguage(language) {
        switch (language) {
            case 'en':
                this.selectedLanguage = 'English';
                break;
            case 'ar':
                this.selectedLanguage = 'العربية';
                break;
        }
    }


    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }

    setIcon($event) {
        const icons = Icons;
        const founded = icons.find(i => i.label === $event);
        return founded.value;
    }

    validateMobile() {
        if (isMobile()) {
            return true;
        }
        return false;
    }
}
