import {
    OrderApiConfiguration,
    OrderService,
    OrderDetailService,
    ApprovalInsurance,
    TransActionService,
    VoucherService,
    PatientStatmentService,
    FundsService,
    CashierTransService,
    CashierFundsService,
    PayerService,
    CoverageContractService,
    CoverageClassService,
    ElementConfigurationService,
    StatementTransactionService,
    BillServicesService,
    ApprovalInsuranceService,
    ItemService,
    ItemRelationService,
    PackageItemService,
    GroupItemService,
    ParentItemService,
    CoverageServiceService,
    CoverageDiscountService,
    PatientEncounterInsuranceService,
    BillSerialsService,
    OrderSetItemService,
    PackageSessionService,
    PatientPackageSessionService,
    PriceListService,
    PriceListItemService,
    PricingCategoryService,
    PriceCopyListService,
    FavoriteListService,
    PatientPayerService,
    PendingOrder,
    CashBoxTransferService,
    PatientBillService,
    UnitConversionService,
    StaffService,
    BillElementService,
    ClaimService,
    ClaimDetailsService,
    ClaimSettlementService,
    PayerReceiptService,
    BankService,
    CashierDiscountService,
    SettllementInvoiceService,
    SettllementInvoiceDetailsService,
    ItemCategoryService,
    RevenueSectionDefinitionService,
    AssignmentService,
    AssignmentRoleService,
    ContractStationCoverageDiscountService,
    ContractStationCoverageServiceService,
    ItemInstructionService,
    VisaService,
    FundService,
    PackagePlanService,
    DoctorSettlementService,
    DoctorSettlementDetailService,
    PriceService,
    UnitService,
    StaffCategoryService,
    PriceListProfitMarginService,
    PriceListProfitMarginDetailService,
    DoctorBalanceService,
    SystemCurrencyService,
    PayerContactPersonService,
    ExcludedSectionsService,
    ExcludedDiagnosisService,
    PayerTemplateService,
    TemplateMasterCodeService,
    PayerDiscountService,
    PayerDiagnosisGroupService,
    PayerDiagnosisGroupDetailsService,
    CoverageApprovalSectionService,
    CoverageApprovalDiagnosiService,
    SettlementDeductionSetupService,
    CurrencyLogService,
    ReceiveOrderSetupService,
    PayerSessionService,
    ApprovalAndStaffNoteService,
    PendingAreaInfoNoteService,
    PendingAreaInfoService,
    PayerServiceService,
    PayerExcludedSectionService,
    PayerExcludedDiagnosisService,
    ParentPayerSessionService,
    RadiologyTemplateService,
    RadiologyReportTemplateService,
    ChargingSetupService,
    ChargingOrderHelperService,
    ConsultationRequestService,
    ConsultationPendingAreaInfoNoteService,
    PatientBalanceService,
    EclaimSetupService,
    CoverageApprovalService,
    CashDiscountSetupService,
    BillingSetupService,
    TransactionAttachmentService,
    FDASetupService,
    PriceListItemPerStaffService,
    ItemPriceApproveService,
    PriceListItemPerSectionService,
    UnitGroupService,
    PrintersetupService,
    PrinterOrderService,
    ResearchBillService,
    LinkServicesSectionsService,
    InvoiceIssueLogService,
    PrintInvoiceReportLogService,
    MohContractSetupService,
    OrderClaimPatientAttachmentService,
    EligibiltyTransactionService,
} from './services/order-and-billing';


export const NGA_ORDER_SERVICES = [
    OrderService,
    OrderApiConfiguration,
    OrderDetailService,
    ApprovalInsurance,
    TransActionService,
    VoucherService,
    PatientStatmentService,
    PayerService,
    ApprovalInsuranceService,
    FundsService,
    CashierTransService,
    CashierFundsService,
    PayerService,
    ElementConfigurationService,
    CoverageContractService,
    CoverageClassService,
    StatementTransactionService,
    BillServicesService,
    ItemService,
    ItemRelationService,
    PackageItemService,
    GroupItemService,
    ParentItemService,
    CoverageServiceService,
    CoverageDiscountService,
    PatientEncounterInsuranceService,
    CoverageDiscountService,
    BillSerialsService,
    PackageSessionService,
    PatientPackageSessionService,
    OrderSetItemService,
    PatientPayerService,
    PendingOrder,
    CashBoxTransferService,
    StaffService,
    ClaimService,
    ClaimDetailsService,
    PatientBillService,
    UnitConversionService,
    BillElementService, PriceListService,
    PriceListItemService,
    PricingCategoryService,
    PriceCopyListService,
    FavoriteListService,
    ClaimSettlementService,
    PayerReceiptService,
    BankService,
    CashierDiscountService,
    SettllementInvoiceService,
    SettllementInvoiceDetailsService,
    ItemCategoryService,
    RevenueSectionDefinitionService,
    AssignmentService,
    AssignmentRoleService,
    ContractStationCoverageDiscountService,
    ContractStationCoverageServiceService,
    ItemInstructionService,
    VisaService,
    FundService,
    PackagePlanService,
    DoctorSettlementService,
    DoctorSettlementDetailService,
    PriceService,
    UnitService,
    StaffCategoryService,
    PriceListProfitMarginService,
    PriceListProfitMarginDetailService,
    DoctorBalanceService,
    SystemCurrencyService,
    ExcludedSectionsService,
    PayerContactPersonService,
    ExcludedDiagnosisService,
    PayerTemplateService,
    TemplateMasterCodeService,
    PayerDiscountService,
    PayerDiagnosisGroupService,
    PayerDiagnosisGroupDetailsService,
    CoverageApprovalSectionService,
    CoverageApprovalDiagnosiService,
    SettlementDeductionSetupService,
    CurrencyLogService,
    ReceiveOrderSetupService,
    PayerSessionService,
    ApprovalAndStaffNoteService,
    PendingAreaInfoNoteService,
    PendingAreaInfoService,
    PayerServiceService,
    PayerExcludedSectionService,
    PayerExcludedDiagnosisService,
    ParentPayerSessionService,
    RadiologyTemplateService,
    RadiologyReportTemplateService,
    ChargingSetupService,
    ChargingOrderHelperService,
    ConsultationRequestService,
    ConsultationPendingAreaInfoNoteService,
    PatientBalanceService,
    EclaimSetupService,
    CoverageApprovalService,
    CashDiscountSetupService,
    BillingSetupService,
    TransactionAttachmentService,
    CashDiscountSetupService,
    FDASetupService,
    PriceListItemPerStaffService,
    PriceListItemPerSectionService,
    ItemPriceApproveService,
    MohContractSetupService,
    UnitGroupService,
    PrintersetupService,
    PrinterOrderService,
    ResearchBillService,
    LinkServicesSectionsService,
    InvoiceIssueLogService,
    PrintInvoiceReportLogService,
    OrderClaimPatientAttachmentService,
    EligibiltyTransactionService
];
