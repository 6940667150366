import { SelectItem } from 'primeng/api';
import { MaritalStatus } from '../enums';

export class MaritalStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Single', value: MaritalStatus.Single },
            { label: 'Married', value: MaritalStatus.Married },
            { label: 'Widower', value: MaritalStatus.Widower },
            { label: 'Divorced', value: MaritalStatus.Divorced },
        ];
    }
}