import { SelectItem } from 'primeng/api';
import { StatementStatusType } from '../enums';

export class StatementStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Issued', value: StatementStatusType.Issued },
            { label: 'Halted', value: StatementStatusType.Halted },
        ];
    }
}
