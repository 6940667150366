import { PatientStatement } from './patient-statement';
import { Item } from './item';
import { Unit } from './unit';
import { ElementConfigration } from './element-configuration';
import { Payer } from './payer';
import { Staff } from './staff.model';

export interface IBillService {
    DiscountPercentage: number;
    DiscountValue: number;
    OldNetAmount: number;
    OldDiscountAmount: number;
    IsValid: boolean;
    IsAverageCostValid: boolean;
    IsAverageCostRaise: boolean;
}
export class BillService implements IBillService {

    Id: number = 0;
    ActionId: number = 0;
    PatientId: number = 0;
    PayerId: number = 0;
    ElementId: number = 0;
    SectionId: number = 0;
    EncounterId: number = 0;
    StatementId: number = 0;
    Quantity: number = 0;
    Price: number = 0;
    TotalPrice: number = 0;
    NetAmount: number = 0;
    DiscountAmount: number = 0;
    ActionDate: Date = new Date();
    ItemId: number = 0;
    NeedApproval: boolean = false;
    ApprovalNo: number = 0;
    IsExcluded: boolean = false;
    PatientPackageId: number = 0;
    PkgRefId: number = 0;
    PkgIncluded: boolean = false;
    PackageDisc: number = 0;
    StaffId: number = 0;
    Status: number = 0;
    IsAccomFees: boolean = false;
    Log: string = '';

    DiscountPercentage: number = 0;
    DiscountValue: number = 0;
    OldNetAmount: number = 0;
    OldDiscountAmount: number = 0;
    IsValid: boolean = true;
    location?: string;
    PatientStatement: PatientStatement = new PatientStatement();
    Item: Item = new Item();
    BillElement: ElementConfigration = new ElementConfigration();
    IsAverageCostValid: boolean = true;
    IsAverageCostRaise: boolean = false;

    Unit: Unit = new Unit();
    Payer: Payer = new Payer();
    OrderDoctor: Staff = new Staff();
    VATAmount: number = 0;
    OrderId: number = 0;
    ActionSourceId: number = 0;
    CreatedBy: number = 0;
    IsResearch: boolean = false;
    StatementDiscountDetails: any[] = [];

    constructor(init?: Partial<BillService>) {
        if (init) {
            for (const p in this) {
                this[p.toString()] = (p in init ? init : this)[p.toString()];
            }
            this.OldNetAmount = this.NetAmount;
            this.OldDiscountAmount = this.DiscountAmount;
        }
        return this;
    }
}