import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from 'app/shared';

@Injectable()
export class ConfirmModalService {

    appConfirmModal: ConfirmModalComponent;

    confirm(options: any): Promise<boolean> {
        return this.appConfirmModal.confirm(options);
    }
}