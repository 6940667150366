import { NumberFound } from "libphonenumber-js";

export class ErAreaBed {

    Id: number;
    Code: string;
    AreaId: NumberFound;
    CreatedBy: number;
    CreatedDate: Date;
    FacilityId:number;
    constructor() {
    }
}