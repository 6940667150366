import {
    NotificationsComponent,
    FavoriteComponent,
    ShortcutsComponent,
    NotificationAndFavoriteComponent,
    ImageViewerComponent,
    InfictiousInfoComponent,
    NotificationDynamicComponent,
    Test1Component,
    PatientVisitDiagnosisComponent,
    ConfirmPasswordComponent,
    ConfirmModalComponent,
} from './components';

import {
    BaAmChart,
    BaBackTop,
    BaCard,
    BaCheckbox,
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaMsgCenter,
    BaMultiCheckbox,
    BaPageTop,
    baMainMenu,
    baMainMenuItem,
    BaSidebar,
} from './controls';


export const NGA_SYSTEM_COMPONENTS = [
    BaAmChart,
    BaBackTop,
    BaCard,
    BaCheckbox,
    BaContentTop,
    BaMenuItem,
    BaMenu,
    BaMsgCenter,
    BaMultiCheckbox,
    BaPageTop,
    baMainMenu,
    baMainMenuItem,
    BaSidebar,
    NotificationsComponent,
    FavoriteComponent,
    ShortcutsComponent,
    NotificationAndFavoriteComponent,
    ImageViewerComponent,
    InfictiousInfoComponent,
    NotificationDynamicComponent,
    Test1Component,
    PatientVisitDiagnosisComponent,
    ConfirmPasswordComponent,
    ConfirmModalComponent,
];
