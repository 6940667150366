import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { VisaType } from "app/shared/models/orders-and-billing/visa-type";
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';

@Injectable()
export class VisaService extends ServiceBase {
    private actionUrl: string;
    private _url: string;
    private _allUrl: string;
    private _allActiveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.visaType.url;
        this._allUrl = this.actionUrl + _configuration.visaType.all;
        this._allActiveUrl = this.actionUrl + _configuration.visaType.allActive;
    }

    add(data: VisaType) {
        return this._http.post<any>(this._url, data);
    }

    update(data: VisaType) {
        return this._http.put<any>(this._url, data);
    }

    getAll() {
        return this._http.get<any>(this._allUrl);
    }

    getAllActive = (): Observable<VisaType[]> => {
        return this._http.get<any>(this._allActiveUrl)
    }
}