import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class ConsultationRequestSearchService extends ServiceBase {

    private actionUrl: string;
    private byFilter: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.byFilter = this.actionUrl + _configuration.consultationRequesSearch.byFilter;
    }

    getConsultationRequestBySearch = (filter: any): Observable<any[]> => {
        const patientName = filter?.patientName || '';
        const sectionId = filter?.sectionId || '';
        const fromDoctorId = filter?.fromDoctorId || '';
        const toDoctorId = filter?.toDoctorId || '';
        const requestType = filter?.requestType || '';

        return this._http.get<any>(this.byFilter
            + '?Patient_Name=' + patientName
            + '&Section_Id=' + sectionId
            + '&From_Doctor_Id=' + fromDoctorId
            + '&To_Doctor_Id=' + toDoctorId
            + '&Request_Type=' + requestType
        );
    }
}

