import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class CoverageApprovalSectionService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.coverageApprovalSection.url;
    }

    public getByApprovalId = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`)
    }

    public add = (data): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data))
    }

    public update = (data, id): Observable<any> => {
        return this._http.put<any>(`${this._url}/${id}`, data)
    }

    public delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${this._url}/${id}`)
    }

}
