import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { MedicationHistory } from 'app/shared/models';

@Injectable()
export class PharmacyMedicationHistorySearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.pharmacyMedicationHistorySearch.url;
    }

    patientMedicationHistorySearch = (patientId: number, fromDate?: Date, toDate?: Date, itemId?: number, statusId?: number, genericItemId?: number): Observable<MedicationHistory[]> => {

        const localFromDate = fromDate !== null && fromDate !== undefined ? fromDate.toISOString() : '';
        const localToDate = toDate !== null && toDate !== undefined ? toDate.toISOString() : '';
        const localItemId = itemId !== null && itemId !== itemId ? itemId : '';
        const localStatusId = statusId !== null && statusId !== statusId ? statusId : '';
        const localGenericItemId = genericItemId !== null && genericItemId !== genericItemId ? genericItemId : '';

        const url = `${this.url}?patientId=${patientId}&fromDate=${localFromDate}&toDate=${localToDate}&itemId=${localItemId}&statusId=${localStatusId}&genericItemId=${localGenericItemId}`;
        return this._http.get<any>(url);
    }
}
