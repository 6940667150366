import { AbstractControl, ValidatorFn } from '@angular/forms';

export class HijriGregorianDateValidator {
    public static validate = (): ValidatorFn | null => {
        return (control: AbstractControl) => {
            if (!control) {
                return;
            }
            if (!control.value?.date) {
                return {
                    dateRequired: true
                };
            }
        };
    }
}