import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardDoctorPerformanceService extends ServiceBase {

    private actionUrl: string;

    // Patient Counter
    private _getDailyDoctorsPerfomanceCounter: string;
    private _getMonthlyDoctorsPerfomanceCounter: string;
    private _getYearlyDoctorsPerfomanceCounter: string;
    // Patient Counter

    // Doctor Revenue
    private _getDailyDoctorsPerfomance: string;
    private _getMonthlyDoctorsPerfomance: string;
    private _getYearlyDoctorsPerfomance: string;
    // Doctor Revenue

    // Doctor Revenue
    private _csvDailyDoctorsPerfomance: string;
    private _csvMonthlyDoctorsPerfomance: string;
    private _csvYearlyDoctorsPerfomance: string;
    // Doctor Revenue

    //Doctor Performance
    private _getDailyPerfomanceBySection: string;
    private _getmonthlyPerfomanceBySection: string;
    private _getyearlyPerfomanceBySection: string;
    private _csvDailyPerfomanceBySection: string;
    private _csvMonthlyPerfomanceBySection: string;
    private _csvYearlyPerfomanceBySection: string;
    // Doctor Performance

    // Selected Doctor Revenue
    private _getSelectedDoctorRevenue: string;
    // Selected Doctor Revenue

    // Selected Doctor Number of Admissions
    private _getSelectedDoctorNumberofAdmissions: string;
    // Selected Doctor Number of Admissions

    // DoctorsOrderedByRevenue
    private _doctorsOrderedByRevenue: string;
    // DoctorsOrderedByRevenue

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        // Patient Counter
        this._getDailyDoctorsPerfomanceCounter = this.actionUrl + _configuration.dashboardDoctorPerformance.dailyDoctorsPerformanceCounter;
        this._getMonthlyDoctorsPerfomanceCounter = this.actionUrl + _configuration.dashboardDoctorPerformance.monthlyDoctorsPerformanceCounter;
        this._getYearlyDoctorsPerfomanceCounter = this.actionUrl + _configuration.dashboardDoctorPerformance.yearlyDoctorsPerformanceCounter;
        // Patient Counter

        // Doctor Revenue
        this._getDailyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.dailyDoctorsPerformance;
        this._getMonthlyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.monthlyDoctorsPerformance;
        this._getYearlyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.yearlyDoctorsPerformance;
        this._csvDailyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.csvDailyDoctorsPerformance;
        this._csvMonthlyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.csvMonthlyDoctorsPerformance;
        this._csvYearlyDoctorsPerfomance = this.actionUrl + _configuration.dashboardDoctorPerformance.csvYearlyDoctorsPerformance;
        // Doctor Revenue

        // Doctor Performance
        this._getDailyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.dailyPerformanceBySection;
        this._getmonthlyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.monthlyPerformanceBySection;
        this._getyearlyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.yearlyPerformanceBySection;
        this._csvDailyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.csvDailyPerformanceBySection;
        this._csvMonthlyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.csvMonthlyPerformanceBySection;
        this._csvYearlyPerfomanceBySection = this.actionUrl + _configuration.dashboardDoctorPerformance.csvYearlyPerformanceBySection;
        // Doctor Performance

        // Selected Doctor Revenue
        this._getSelectedDoctorRevenue = this.actionUrl + _configuration.dashboardDoctorPerformance.selectedDoctorRevenue;
        // Selected Doctor Revenue

        // Selected Doctor Number of Admissions
        this._getSelectedDoctorNumberofAdmissions = this.actionUrl + _configuration.dashboardDoctorPerformance.selectedDoctorNumberofAdmissions;
        // Selected Doctor Number of Admissions

        // Selected Doctor Revenue
        this._doctorsOrderedByRevenue = this.actionUrl + _configuration.dashboardDoctorPerformance.doctorsOrderedByRevenue;
        // Selected Doctor Revenue
    }

    // Get Patients Counter
    public getDailyDoctorsPerfomanceCounter = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getDailyDoctorsPerfomanceCounter, _doctorPerformanceFilter);
    }

    public getMonthlyDoctorsPerfomanceCounter = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getMonthlyDoctorsPerfomanceCounter, _doctorPerformanceFilter);
    }

    public getYearlyDoctorsPerfomanceCounter = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getYearlyDoctorsPerfomanceCounter, _doctorPerformanceFilter);
    }
    // Get Patients Counter

    // Get Doctors Revenue
    public getDailyDoctorsPerfomance = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getDailyDoctorsPerfomance, _doctorPerformanceFilter);
    }

    public getMonthlyDoctorsPerfomance = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getMonthlyDoctorsPerfomance, _doctorPerformanceFilter);
    }

    public getYearlyDoctorsPerfomance = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }
        return this._http.post<any>(this._getYearlyDoctorsPerfomance, _doctorPerformanceFilter);
    }

    public exportDailyDoctorPerformance = (doctors: string, date: Date): Observable<any> => {
        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }

        return this._http.post<any>(this._csvDailyDoctorsPerfomance, _doctorPerformanceFilter,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportMonthlyDoctorPerformance = (doctors: string, date: Date): Observable<any> => {

        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }

        return this._http.post<any>(this._csvMonthlyDoctorsPerfomance, _doctorPerformanceFilter,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportYearlyDoctorPerformance = (doctors: string, date: Date): Observable<any> => {

        const _doctorPerformanceFilter = {
            doctors: doctors,
            date: this.setIsoDate(date)
        }


        return this._http.post<any>(this._csvYearlyDoctorsPerfomance, _doctorPerformanceFilter,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
    // Get Doctors Revenue

    // Get Doctors Performance
    public getDailyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._getDailyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`
        return this._http.get<any>(uri);
    }

    public getmonthlyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._getmonthlyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`
        return this._http.get<any>(uri);
    }

    public getyearlyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._getyearlyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`
        return this._http.get<any>(uri);
    }

    public exportDailyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._csvDailyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`

        return this._http.get<any>(uri,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportMonthlyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._csvMonthlyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`

        return this._http.get<any>(uri,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    public exportYearlyPerfomanceBySection = (doctorId: number, sectionId: number, date: Date): Observable<any> => {
        const uri: string = `${this._csvYearlyPerfomanceBySection}?DoctorId=${doctorId}&SectionId=${sectionId}&Date=${this.setIsoDate(date)}`

        return this._http.get<any>(uri,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
    // Get Doctors Performance

    // Selected Doctor Revenue
    public getSelectedDoctorRevenue = (fromDate: Date, toDate: Date, doctorId: number): Observable<any> => {
        const uri: string = `${this._getSelectedDoctorRevenue}?DoctorId=${doctorId}&FromDate=${this.setIsoDate(fromDate)}&ToDate=${this.setIsoDate(toDate)}`
        return this._http.get<any>(uri);
    }
    // Selected Doctor Revenue

    // Selected Doctor Number of Admissions
    public getSelectedDoctorNumberofAdmissions = (fromDate: Date, toDate: Date, doctorId: number): Observable<any> => {
        const uri: string = `${this._getSelectedDoctorNumberofAdmissions}?DoctorId=${doctorId}&FromDate=${this.setIsoDate(fromDate)}&ToDate=${this.setIsoDate(toDate)}`
        return this._http.get<any>(uri);
    }
    // Selected Doctor Number of Admissions
    setIsoDate(date: Date) {
        return date.toISOString();
    }

    // Doctors Ordered By Revenue
    public getDoctorsOrderedByRevenue = (doctorId: number = null, pageIndex: number = 0, pageSize: number = 10): Observable<any> => {
        const uri: string = `${this._doctorsOrderedByRevenue}?doctorId=${doctorId || ''}&pageIndex=${pageIndex}&pageSize=${pageSize}`
        return this._http.get<any>(uri);
    }
    // Doctors Ordered By Revenue
}
