import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { NotificationService } from 'app/shared/services/notificationService/notification.service';
import { SignalrService } from 'app/shared/services';
import { Notification } from 'app/shared/models/hubs/enums//notification';
import { ReceiverNotification } from 'app/shared/models/hubs/receiver-notification';
import { ReceiveNotificationService } from 'app/shared/services/his-notification';
import { BaseComponent } from 'app/shared/infrastructure';
import { firstValueFrom, interval, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NotificationActionType, ReceiverModule } from 'app/shared/models/enum';

@Component({
    selector: 'notifications-cmp',
    styleUrls: ['./notifications.scss'],
    templateUrl: './notifications.html',
    encapsulation: ViewEncapsulation.None,
})


export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {

    // we need this var on afterViewChecked method to check if service recieved or not
    isReceivedNotification: boolean;
    notifications: ReceiverNotification[] = [];
    unReadNotificationCount: number;
    securityServer: string;
    static onSelectNotification = new Subject();
   
    public constructor(private _router: Router,
        private _notificationService: NotificationService,
        private _signalrService: SignalrService,
        private _receiveNotificationService: ReceiveNotificationService,
        private _changeDetectorRef: ChangeDetectorRef) {
        super();
        this.securityServer = environment.authorityService;
    }

    public ngOnInit() {
        firstValueFrom(this._signalrService.onFinishConnect).then(() => {
            this.connectSignalR();
        });


        this._notificationService.unreadCount
            .subscribe(value => {
                this.unReadNotificationCount = value;
            });

        if (this.enableNotification()) {
            interval(2000)
                .pipe(takeWhile(() => !this.isReceivedNotification))
                .subscribe(() => {
                    this.getLatestNotifications();
                });
        }

        this._changeDetectorRef.detectChanges();
    }

    connectSignalR() {
        this._signalrService.on(Notification.Name, Notification.ReceiveNotification)
            .subscribe((value: ReceiverNotification) => {
                if (value) {
                    this.notifications.unshift(value);
                    if (this.notifications.length > 20) {
                        this.notifications.splice(this.notifications.length - 1, 1)
                    }
                    this.calculateUnReadCount();
                }
            });
    }

    getLatestNotifications() {
        const userData = JSON.parse(sessionStorage.getItem(`oidc.user:${this.securityServer}:js`))?.profile;
        if (!userData) {
            return;
        }
        this.notifications = [];
        const roles = Array.isArray(userData.role) ? userData.role?.join(',') : userData.role;
        this._receiveNotificationService.getLatestNotifications(userData.staff_id, roles)
            .subscribe((notifications: ReceiverNotification[]) => {
                if (notifications && notifications.length > 0) {
                    this.notifications = notifications;
                }
                this.calculateUnReadCount();
                this.isReceivedNotification = true;
            }, error => {
                this.isReceivedNotification = true;
            });
    }

    openNote(note: ReceiverNotification) {
        this._receiveNotificationService.markAsRead(note.id).subscribe(value => {
            if (value) {
                let payLoad = null;;

                try {
                    payLoad = note.payLoad && typeof note.payLoad == "string" ? JSON.parse(note.payLoad) : note.payLoad;
                } catch (error) {
                    payLoad = null;
                }
                note.payLoad = payLoad ?? null;
                if (payLoad?.IsPopUp) {
                    const obj = {
                        commentTitle: "Approval Notification",
                        note: note
                    };
                    NotificationsComponent.onSelectNotification.next(obj);
                }

                note.markAsRead = true;
                if (note?.notificationAction?.actionType === NotificationActionType.Popup && note.receiverModule === ReceiverModule.His) {
                    this._notificationService.onOpenNotification.next(note);
                }
                else if (note.url) {
                    let urlData = this.externalUrl(note.receiverModule);
                    if (urlData?.externalUrl) {
                        const url = (note?.notificationAction?.actionType === NotificationActionType.Popup) ? `${urlData.externalUrl}/${urlData.defaultUrl}${note.id}` : urlData?.externalUrl + note.url;
                        this.openLinkInNewTab(url);
                    }
                    else {
                        this._router.navigateByUrl(note.url);
                    }
                }
                this.calculateUnReadCount();
                jQuery('.dropdown-menu').removeClass('show');
            }
        });
    }

    calculateUnReadCount() {
        const unreadNotification = this.notifications?.filter(f => !f.markAsRead);
        this._notificationService.unreadCount.next(unreadNotification?.length || 0);
    }

    public ngOnDestroy() {
    }
}
