export class MedicationHistory {
    OrderId: number;
    ItemId: number;
    ItemCode: string;
    StartDate: Date;
    StopDate: Date;
    ItemTitle: string;
    GenericId: number;
    GenericName: string;
    MedicationProductId: number;
    DoseValue: number;
    Duration: number;
    DoseUnitId: number;
    DoseUnitTitle: string;
    RouteId: number;
    RouteTitle: string;
    FrequencyId: number;
    FrequencyTitle: string;
    DurationTypeId: number;
    DurationType: string;
    PrescriptionUnitId: number;
    PrescriptionUnit: string;
    PrescriptionQuantity: number;
    DispeneseQuantity: number;
    ScheduledQuantity: number;
    ItemStatusTitle: string;
    IsDischargePrescription: boolean;
    Comment: string;
    ItemStatusId: number;
    OrderStatusId: number;
    IsPrn: boolean;
    PrescriptionPriorityId: number;
}
