import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PayerExcludedDiagnosis } from 'app/shared/models/orders-and-billing/payer-excluded-diagnosis';

@Injectable()
export class PayerExcludedDiagnosisService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private allPayerExcludedDiagnosisUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.payerExcludedDiagnosis.url;
        this.allPayerExcludedDiagnosisUrl = this.actionUrl + _configuration.payerExcludedDiagnosis.allPayerExcludedDiagnosis;
    }

    getAllPayerExcludedDiagnosis = (payerId: number): Observable<PayerExcludedDiagnosis[]> => {
        return this._http.get<any>(`${this.allPayerExcludedDiagnosisUrl}?payerId=${payerId}`);
    }

    insert = (data: PayerExcludedDiagnosis): Observable<PayerExcludedDiagnosis> => {
        return this._http.post<any>(this.url, data);
    }

    update = (data: PayerExcludedDiagnosis): Observable<PayerExcludedDiagnosis> => {
        return this._http.put<any>(this.url, data);
    }
}