import { Item } from './item';

export class PayerService {
    Id:number;
    ServiceId:number;
    IPCoverage?:number;
    IPCoverageLimit?:number;
    IPCashLimit?:number;
    IPExceedLimitAction?:number;
    IPParticipantPercentage?:number;
    IPSponsorPercentage?:number;
    IPCoveragePer?: number;
    IPCashPer?: number;
    OPCoverage?:number;
    OPCoverageLimit?:number;
    OPCashLimit?:number;
    OPExceedLimitAction?:number;
    OPParticipantPercentage?:number;
    OPSponsorPercentage?:number;
    OPCoveragePer?: number;
    OPCashPer?: number;
    ERCoverage?:number;
    ERCoverageLimit?:number;
    ERCashLimit?:number;
    ERExceedLimitAction?:number;
    ERParticipantPercentage?:number;
    ERSponsorPercentage?:number;
    ERCoveragePer?: number;
    ERCashPer?: number;
    CoverageType:number;
    PayerId?:number;
    Status: boolean;
    InPatientStatus: boolean;
    OutPatientStatus: boolean;
    EmergencyPatientStatus: boolean;
    InPatientServiceChargeLimit?:number;
    OutPatientServiceChargeLimit?:number;
    EmergencyPatientServiceChargeLimit?:number;
    InPatientApplyLimitOn:number;
    OutPatientApplyLimitOn:number;
    EmergencyPatientApplyLimitOn:number;
    InPatientMinCashAmount?: number;
    OutPatientMinCashAmount?: number;
    EmergencyMinCashAmount?: number;
    PatientType: string;
    Priority?: number;
    
    Item: Item = new Item();
}
