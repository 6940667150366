import { SelectItem } from 'primeng/api';
import { AdmissionRequiredField } from '../enums';

export class AdmissionRequiredFieldLookup {

    static getAdmissionRequiredFieldLookup(): SelectItem[] {
        return [
            { label: 'Admission Doctor', value: AdmissionRequiredField.AdmissionDoctor },
            { label: 'Admission Reason', value: AdmissionRequiredField.AdmissionReason },
            { label: 'Transfer Method', value: AdmissionRequiredField.TransferMethod },
            { label: 'Consulting Doctor', value: AdmissionRequiredField.ConsultingDoctor },
            { label: 'Length of Stay', value: AdmissionRequiredField.LengthOfStay },
            { label: 'Remark', value: AdmissionRequiredField.Remark },
            { label: 'Treatment Plan', value: AdmissionRequiredField.TreatmentPlane },
            { label: 'Section', value: AdmissionRequiredField.Section },
            { label: 'Diet', value: AdmissionRequiredField.Diet },
            { label: 'Class', value: AdmissionRequiredField.Class },
        ];
    }
}
