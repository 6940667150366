import { Injectable } from '@angular/core';

import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { DimensionServiceApiConfiguration } from '../dimension-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';

@Injectable()
export class ApprovalClaimsService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private claimApprovalUrl: string;

    constructor(private _http: HttpClient, private _configuration: DimensionServiceApiConfiguration) {
        super();
        this.actionUrl = this._configuration.serverUrl;
        this.url = this.actionUrl + this._configuration.approvalClaim.url;
        this.claimApprovalUrl = this.actionUrl + this._configuration.approvalClaim.claimApproval;
    }

    claimApproval = (id: number): Observable<any> => {
        return this._http.post<any>(this.claimApprovalUrl, JSON.stringify(id));
    }

    bySearch = (filter: any, pageIndex, rowNumber = 10): Observable<any> => {
        const url = `${this.url}/BySearch?ApprovalNo=${filter.approvalNumber || null}&&` +
            `PayerId=${filter.payerId || null}&&PatientId=${filter.patientId || null}&&` +
            `IsDownloaded=${filter.isDownload}&&denialCode=${filter?.denialCode || ''}&&` +
            `RowNumber=${rowNumber}&&PageIndex=${pageIndex}&&` +
            `FromApprovalDate=${filter.fromDate.toISOString()}&&ToApprovalDate=${filter.toDate.toISOString()}&&` +
            `AuthorizationStatusId=${filter.authorizationStatus}`;
        return this._http.get<any>(url);
    }

    authorizationNew = (): Observable<any> => {
        return this._http.get<any>(`${this.url}/AuthorizationNew`);
    }

    setAuthorizationDownloaded = (ids: any[]): Observable<any> => {
        return this._http.post<any>(`${this.url}/SetAuthorizationDownloaded`, ids);
    }

    authorizationSearch = (data): Observable<any> => {
        return this._http.post<any>(`${this.url}/AuthorizationSearch`, data);
    }

    entityIdByApprovalId = (id: number): Observable<any> => {
        return this._http.get<any>(`${this.url}/EntityIdByApprovalId?approvalId=${id}`);
    }

    authorizationView = (data: any): Observable<any> => {
        return this._http.post<any>(`${this.url}/AuthorizationView`, data);
    }

    resubmission = (id: number, data: any): Observable<any> => {
        return this._http.post<any>(`${this.url}/Resubmission?approvalId=${id}`, data);
    }

    detailsBySearch = (id: number): Observable<any> => {
        return this._http.get<any>(`${this.url}/DetailsBySearch?authorizationId=${id}`);
    }
}
