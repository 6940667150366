export class medicationErVisit {
    Id: number;
    DoctorId: number;
    SectionId: number;
    VisitDate: Date;
    ConsultationTypeId: number;
    AppointmentDate: Date;
    VisitNo: number;
    FacilityId: number;
    PatientId: number;
    ErAreaId: number;
    IsPoliceCase: number;
    IsErCase: number;
    PoliceCaseNote: string;
    IsAccident: number;
    constructor() {
    }


}