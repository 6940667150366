import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class SpecimenRejectionReasonService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.specimenRejectionReason.url;
    }


    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    delete(id: number) {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }
}
