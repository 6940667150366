import { Directive, Input, ElementRef } from '@angular/core';

import 'jquery-slimscroll';
import 'jquery';

import * as jQuery from 'jquery';

@Directive({
  selector: '[baSlimScroll]'
})
export class BaSlimScroll {

  @Input() public baSlimScrollOptions: Object;

  constructor(private _elementRef: ElementRef) {
  }

  ngOnChanges(changes) {
    this._scroll();
  }

  private _scroll() {
    this._destroy();
    this._init();
  }

  private _init() {
    (<any>jQuery(this._elementRef.nativeElement)).slimScroll(this.baSlimScrollOptions);
  }

  private _destroy() {
    (<any>jQuery(this._elementRef.nativeElement)).slimScroll({ destroy: true });
  }
}
