export class ItemSearchFilter {
    Id?: number = null;
    MainItemId?: number = null;
    TypeId?: number = null;
    Code: string = "";
    Description: string = "";
    ForeignTitle: string = "";
    IsOrderable?: boolean = null;
    IsVariablePrice?: boolean = null;
    IsChargable?: boolean = null;
    IsActive?: boolean = null;
    CategoryId?: number = null;
    PageIndex?: number = null;
    PageSize?: number = null;
    CategoriesIds?:number[];
    constructor() {
    }

}