import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { QMSModifyType } from 'app/shared/models/core/enums';
import { environment } from 'environments/environment';
import { BaseComponent } from 'app/shared/infrastructure';
import { ReportConstants } from 'app/reports.constants';

@Injectable()
export class QMSTicketService extends BaseComponent {

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
    }

    update = (encounterId: number, type: QMSModifyType): Observable<any> => {
        return this._http.put<any>(`${environment.core}QMSTicket?encounterId=${encounterId}&type=${type}`, null);
    }

    callPatient = (sectionId: number, doctorId: Number): Observable<any> => {
        const url = `${environment.core}QMSTicket/CallPatient`
        return this._http.put<any>(`${url}?sectionId=${sectionId}&doctorId=${doctorId}`, null);
    }

    printQMSTicket(encounterId: number) {
        const lang = JSON.parse(localStorage.getItem('lang')) || 'en';
        window.open(`${this.reportConstantUrl()}?Path=${ReportConstants.ticketReport}&ENCOUNTER_ID=${encounterId}&lang=${lang}`, '_blank');
    }
}