import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { CoreApiConfiguration } from '../core';
import { AdmissionServiceApiConfiguration } from '../admissionService';
import { OrderApiConfiguration } from '../order-and-billing';
import { LabApiConfiguration } from '../lab';
import { forkJoin, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

@Injectable()
export class SharedService {

    constructor(private _http: HttpClient
        , private _coreApiConfiguration: CoreApiConfiguration
        , private _admissionServiceApiConfiguration: AdmissionServiceApiConfiguration
        , private _orderApiConfiguration: OrderApiConfiguration
        , private _labApiConfiguration: LabApiConfiguration) {
    }
    private _extension: string = '.json';
    private _path: string = 'assets/data/';
    private _coreUrl: string = `${this._coreApiConfiguration.server}`;
    private _admissionUrl: string = `${this._admissionServiceApiConfiguration.server}`;
    private _orderUrl: string = `${this._orderApiConfiguration.server}`;
    private _labUrl: string = `${this._labApiConfiguration.server}`;

    getShortcuts = (fileName: string): Observable<any> => {
        let fullPath = [this._path, fileName, this._extension].join("");
        return this._http.get<any>(fullPath)

    }

    publishPatientHistoryToMobile = (patientId: number, patientCode: string): Observable<any> => {
        //Publish patient visits
        let patientVisitUrl = this._http.post<any>(`${this._coreUrl}${this._coreApiConfiguration.patientVisit.publishVisitHistory}?patientId=${patientId}` , null , { responseType: 'silent' as 'json'}).pipe(first());
        //Publish patient admissions
        let admissionUrl = this._http.post<any>(`${this._admissionUrl}${this._admissionServiceApiConfiguration.admission.publishAdmissionHistory}?patientId=${patientId}`, null ,{ responseType: 'silent' as 'json'}).pipe(first());
        //Publish radiology orders
        let orderUrl = this._http.post<any>(`${this._orderUrl}${this._orderApiConfiguration.order.publishRadilogyHistory}?patientId=${patientId}`, null ,{ responseType: 'silent' as 'json'}).pipe(first());
        //Publish lab normal results
        let labNormalResultUrl = this._http.post<any>(`${this._labUrl}${this._labApiConfiguration.normalResult.publishNormalResultHistory}?patientId=${patientId}&patientCode=${patientCode}`, null , { responseType: 'silent' as 'json'}).pipe(first());
        //Publish lab culture results
        let labCulturetUrl = this._http.post<any>(`${this._labUrl}${this._labApiConfiguration.sensitiveResult.publishCultureTestHistory}?patientId=${patientId}&patientCode=${patientCode}`, null , { responseType: 'silent' as 'json'}).pipe(first());
        //Publish Medication Order results
        let medicationOrderUrl = this._http.post<any>(`${this._orderUrl}${this._orderApiConfiguration.order.publishMedicationHistory}?patientId=${patientId}`, null).pipe(first());

        return of(forkJoin([patientVisitUrl, admissionUrl]) , forkJoin([orderUrl , labNormalResultUrl ,labCulturetUrl, medicationOrderUrl]))
        .pipe(
            concatMap( value =>  value)
        );
    }
}