import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { PatientAllergy } from '../../../models/core/patient-allergy';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PatientAllergyService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byPatientIdUrl: string;
    private _activeByPatientIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientAllergy.url;
        this._byPatientIdUrl = this.actionUrl + _configuration.patientAllergy.byPatientId;
        this._activeByPatientIdUrl = this.actionUrl + _configuration.patientAllergy.activeByPatientId;
    }

    public getByPatientId = (patientId: number): Observable<PatientAllergy[]> => {
        return this._http.get<any>(this._byPatientIdUrl + '?patientId=' + patientId);
    }

    public getActiveByPatientId = (patientId: number): Observable<PatientAllergy[]> => {
        return this._http.get<any>(this._activeByPatientIdUrl + '?patientId=' + patientId);
    }

    public insert = (data: PatientAllergy): Observable<PatientAllergy> => {
        return this._http.post<any>(this._url, data);
    }

    public update = (data: PatientAllergy): Observable<PatientAllergy> => {
        return this._http.put<any>(this._url, data);
    }
}