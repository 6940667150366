import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { OncologyServiceApiConfiguration } from '../oncologyServiceApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class OncologyService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getEncounterInformationByPatientId: string;

    constructor(private _http: HttpClient, private _configuration: OncologyServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + this._configuration.case.url;
        this._getEncounterInformationByPatientId = this.actionUrl + _configuration.case.getEncounterInformationByPatientId;
    }

    getEncounterInformationByPatientId = (id: number): Observable<any> => {

        return this._http.get<any>(`${this._getEncounterInformationByPatientId}?Id=${id}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }
}
