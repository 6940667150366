import { SelectItem } from 'primeng/api';
import { OccursTimeType } from '../enums';

export class OccursTimeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Second', value: OccursTimeType.Second },
            { label: 'Minutes', value: OccursTimeType.Minutes },
            { label: 'Hour', value: OccursTimeType.Hour },
        ];
    }
}
