import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ApiConfiguration } from 'app/shared/services/his-notification/apiConfiguration/app.constant.service';
@Injectable()
export class ReceiveNotificationService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _latestNotificationUrl: string;

    constructor(private _http: HttpClient, private _configuration: ApiConfiguration) {
        super();
        this.actionUrl = this._configuration.server;
        this._url = this.actionUrl + this._configuration.receiveNotification.url;
        this._latestNotificationUrl = this.actionUrl + this._configuration.receiveNotification.getLatestNotification;
    }

    getById = (id: string): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`, { responseType: 'silent' as 'json', withCredentials: false })
    }

    getLatestNotifications = (staffId: string, roles: string): Observable<any> => {
        return this._http.get<any>(`${this._latestNotificationUrl}?hisUserId=${staffId}&roles=${roles}`, { responseType: 'silent' as 'json', withCredentials: false })
    }

    markAsRead = (id: string): Observable<any> => {
        return this._http.patch<any>(`${this._url}/${id}`, null, { responseType: 'silent' as 'json', withCredentials: false })
    }
}
