import { SelectItem } from 'primeng/api';
import { InteractionSeverity } from 'app/shared/models/medispan/enums/interaction-severity';

export class InteractionSeverityLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Minor', value: InteractionSeverity.Minor },
            { label: 'Moderate', value: InteractionSeverity.Moderate },
            { label: 'Major', value: InteractionSeverity.Major },
        ];
    }

    static getSeverity(): SelectItem[] {
        return [
            { label: 'Minor', value: InteractionSeverity.Minor },
            { label: 'Moderate', value: InteractionSeverity.Moderate },
            { label: 'Major', value: InteractionSeverity.Major },
        ];
    }
}
