export enum VitalSignRowValueType {
    BloodPressure = 1,
    Temperature = 2,
    HeartRate = 3,
    Respiration = 4,
    Height = 5,
    Weight = 6,
    BMI = 7,
    BSA = 8,
    HeadCircumference = 9,
    SPO2 = 10,
    PainScore = 11
}