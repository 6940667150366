export class PatientNurseTask {

    id: number = null;
    title: string;
    frequency: number = 1;
    durationType: number = 1;
    startDate: Date = new Date();
    endDate: Date = new Date();

    constructor() {
    }
}