import { Injectable } from '@angular/core';

import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { MedispanServiceApiConfiguration } from '../medispan-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';

import { Route } from 'app/shared/models/medispan/route';

@Injectable()
export class RouteService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getAllUrl: string;

    constructor(private _http: HttpClient, private _configuration: MedispanServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.route.url;
        this.getAllUrl = this.actionUrl + _configuration.route.getAll;
    }

    getAll = (): Observable<Route[]> => {
        return this._http.get<any>(this.getAllUrl)
    }

}
