import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'advance-search-pre-admission-cmp',
    styleUrls: ['./advanceSearchPreAdmission.scss'],
    templateUrl: './advanceSearchPreAdmission.html'
})

export class AdvanceSearchPreAdmissionComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    constructor() {
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }
}
