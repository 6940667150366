import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Gender } from 'app/shared/models/core/enums/gender';
import { PBLSetup } from 'app/shared/models/core/pbl-setup';
import { DemographicInfoService } from 'app/shared/services';
import { PatientDemographicInfoComponent } from '../patient-demographic-info';


@Component({
    selector: 'national-code-input',
    templateUrl: './national-code-input.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NationalCodeInputComponent),
            multi: true
        }
    ]
})

export class NationalCodeInputComponent implements ControlValueAccessor {
    @ViewChild('PatientDemographicInfo') PatientDemographicInfo: PatientDemographicInfoComponent;
    @Output() onDataRecieved = new EventEmitter<any>();
    @Input() pblSetup: PBLSetup;

    private _disabled: boolean;
    @Input() get disabled() {
        return this._disabled;
    };

    set disabled(value) {
        if(value){
            this.form?.disable();
        } else{
            this.form?.enable();
        }
    }
    info: any;
    form: FormControl;
    constructor(private demographicInfoService: DemographicInfoService) {
        this.form = new FormControl();
    }

    onkeyUp() {
        if (this.form?.value?.length === 10 && this.pblSetup.CheckDemographicInfo) {
            this.getDemographicInfoById(this.form?.value);
        }
    }

    get value(): any {
        return this.form?.value || null;
    }

    onChange(_val?: any) {
        this.form.valueChanges.subscribe(_value => {
            this.onChange(this.value)
        });
    }

    onTouch(_val?: any) {

    }

    writeValue(value: any) {
        this.form.setValue(value);
        this.onChange(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    setDemographicPatientInfo(patientInfo: any) {
        var otherLastNameSplits = patientInfo.Name.split(",")
        var temp = otherLastNameSplits[1];
        var otherNameSplitedBySpace = temp.split(" ");

        
        "ALA' FATHI MOHAMMAD,ABUKHALIL"
        var nameSplitedBySpace = patientInfo.Name_En.split(" ");
        var tempEn = nameSplitedBySpace[2];
        var lastNameSplits = tempEn.split(",")
        
        this.info = {
            otherFirstName: otherNameSplitedBySpace[0],
            otherFatherName: otherNameSplitedBySpace[1],
            otherMidName: otherNameSplitedBySpace[2],
            otherLastName: otherLastNameSplits[0],

            firstName: nameSplitedBySpace[0],
            fatherName: nameSplitedBySpace[1],
            midName: lastNameSplits[0],
            lastName: lastNameSplits[1],
            
            genderId: patientInfo.Gender === 'MALE' ? Gender.Male : Gender.Female,
            genderName: patientInfo.Gender,
            birthDate: new Date(patientInfo.DateOfBirth),
            mobile: patientInfo.Mobile,
            nationalId: patientInfo.NationalId,

        }

    }
    getDemographicInfoById = (value) => {
        this.demographicInfoService.getDemographicInfoByNationalId(value).subscribe((data) => {
            this.PatientDemographicInfo.set(data);
        });
    }

    onToggleApply(event) {
        this.setDemographicPatientInfo(event)
        this.onDataRecieved.emit(this.info);
    }
}
