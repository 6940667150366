import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';

import { ImageViewer } from 'app/shared/models/image-viewer';

@Component({
    selector: 'image-viewer-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./image-viewer.scss'],
    templateUrl: './image-viewer.html'
})

export class ImageViewerComponent implements OnInit, OnDestroy {
    @Output() onClose = new EventEmitter<any>();
    @Input() set images(value: ImageViewer[]) {
        if (value) {
            this._images = value;
            this.setPage(1);
        }
    }


    selectedImage: any;
    private _images: ImageViewer[] = [];
    selectedImagesForPage: ImageViewer[] = [];
    currentImageOrder: number = 0;
    constructor() {

    }

    ngOnInit() {

    }

    closeViewer() {
        this.selectedImage = null;
        this.onClose.emit(true);
    }

    zoomValue: number = 1;

    removeZooming() {
        this.zoomValue = 1;
        const style = "transform: scale(" + this.zoomValue + ");"
        jQuery('#image-view').attr('style', style);
    }
    zooming(zoomType: string) {

        if ((this.zoomValue == 1.3 && zoomType == 'in') || (this.zoomValue == 0.1 && zoomType == 'out')) {
            return;
        }
        switch (zoomType) {
            case 'in':
                this.zoomValue = this.zoomValue + 0.1;
                break;
            case 'out':
                this.zoomValue = this.zoomValue - 0.1;
                break
        }

        this.zoomValue = parseFloat(this.zoomValue.toFixed(1));
        const style = "transform: scale(" + this.zoomValue + ");"
        jQuery('#image-view').attr('style', style);

    }

    setImage(event) {
        jQuery(`.thumbnail-view`).removeClass('active');
        this.selectedImage = event.image;
        jQuery(`#thumbnail-view-${event.id}`).addClass('active');
        this.currentImageOrder = event.order;
    }

    currentPage = 1;
    recordsPerPage = 4;

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.setPage(this.currentPage);
        }
    }

    nextPage() {
        if (this.currentPage < this.numPages()) {
            this.currentPage++;
            this.setPage(this.currentPage);
        }
    }

    goByPage(pageNumber) {
        this.currentPage = pageNumber;
        this.setPage(pageNumber);

    }

    numPages() {
        if (!this._images) {
            return 1;
        }
        return Math.ceil(this._images.length / this.recordsPerPage);
    }

    setPage(page): any {

        if (!this._images) {
            return;
        }
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        this.selectedImagesForPage = [];
        for (let i = (page - 1) * this.recordsPerPage; i < (page * this.recordsPerPage) && i < this._images.length; i++) {
            if (this._images[i]) {
                this.selectedImagesForPage.push(this._images[i])
            }
        }
        if (this.selectedImagesForPage.length > 0) {
            this.currentImageOrder = this.selectedImagesForPage[0].order;
            this.setImage(this.selectedImagesForPage[0])
        }

        this.validateButtonPages();
    }

    validateButtonPages() {

        let goFirst = jQuery('#go-first');
        let goPrev = jQuery('#go-prev');
        let goNext = jQuery('#go-next');
        let goLast = jQuery('#go-last');

        switch (this.currentPage) {
            case this.currentPage = 1:
                goFirst.addClass('disabled-button');
                goPrev.addClass('disabled-button');
                goNext.removeClass('disabled-button');
                goLast.removeClass('disabled-button');

                break;
            case this.currentPage = this.numPages():
                goFirst.removeClass('disabled-button');
                goPrev.removeClass('disabled-button');
                goNext.addClass('disabled-button');
                goLast.addClass('disabled-button');

                break;
            default:
                goFirst.removeClass('disabled-button');
                goPrev.removeClass('disabled-button');
                goNext.removeClass('disabled-button');
                goLast.removeClass('disabled-button');

                break;
        }

        jQuery('.pages').removeClass('active-page');
        switch (this.currentPage) {
            case this.currentPage = 1:
                jQuery('#first-page').addClass('active-page');
                break;
            case this.currentPage = 2:
                jQuery('#second-page').addClass('active-page');
                break;
            case this.currentPage = 3:
                jQuery('#third-page').addClass('active-page');
                break;
            case this.currentPage = 4:
                jQuery('#fourth-page').addClass('active-page');
                break;
            case this.currentPage = 5:
                jQuery('#fifth-page').addClass('active-page');
                break;
        }
    }

    setNextImage() {
        if (this.currentImageOrder < this.selectedImagesForPage[this.selectedImagesForPage.length - 1].order) {
            this.currentImageOrder++;
            let item = this.selectedImagesForPage.find(i => i.order === this.currentImageOrder)
            if (item) {
                this.setImage(item);
            }
        }
    }

    setPrevImage() {
        if (this.currentImageOrder > this.selectedImagesForPage[0].order) {
            this.currentImageOrder--;
            let item = this.selectedImagesForPage.find(i => i.order === this.currentImageOrder)
            if (item) {
                this.selectedImage = item.image;
                this.setImage(item);
            }
        }
    }

    ngOnDestroy() {
    }
}
