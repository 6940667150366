import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { ControlApiConfiguration } from '../apiConfiguration';
import { UserViewModel } from '../../../models/control/user-view-model';
import { UserRoleViewModel } from '../../../models/control/user-role-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class UserRoleService extends ServiceBase {

    private actionUrl: string;
    private _userByRole: string;
    private _update: string;
    private _add: string;
    private _delete: string;
    results: any;
    private getRolesByUserUrl: string;

    constructor(private _http: HttpClient, private _configuration: ControlApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._userByRole = this.actionUrl + _configuration.userRole.userByRole;
        this._add = this.actionUrl + _configuration.userRole.add;
        // this._update = this.actionUrl + _configuration.userRole.update;
        this._delete = this.actionUrl + _configuration.userRole.delete;
        this.getRolesByUserUrl = this.actionUrl + _configuration.userRole.getRolesByUserUrl;
    }

    getUserByRole = (roleId): Observable<UserViewModel[]> => {
        return this._http.get<any>(this._userByRole + '?roleId=' + roleId, { withCredentials: true });
    }

    // update(roleId: string, userId: string, facilityId: number, userRole: UserRoleViewModel) {
    //    return this._http.put<any>(this._update + '?roleId=' + roleId + '&&userId=' + userId + '&&facilityId=' + facilityId, JSON.stringify(userRole));
    // }

    getRolesByUser = (userId: string): Observable<UserRoleViewModel[]> => {
        let url = `${this.getRolesByUserUrl}?userId=${userId}`;
        return this._http.get<any>(url, { withCredentials: true });
    }

    add(userRole: UserRoleViewModel) {
        return this._http.post<any>(this._add, JSON.stringify(userRole), { withCredentials: true });
    }

    delete(userRole: UserRoleViewModel) {
        return this._http.post<any>(this._delete, JSON.stringify(userRole), { withCredentials: true });
    }
}
