import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'patient-demographic-info',
    templateUrl: './patient-demographic-info.html',
})

export class PatientDemographicInfoComponent {
    display = false;
    patientInfo: any;
    @Output() onApply = new EventEmitter<any>();

    set(event) {
        this.display = true;
        this.patientInfo = event;
    }

    onToggleApply() {
        this.display = false;
        this.onApply.emit(this.patientInfo);
    };
}
