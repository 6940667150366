import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'test1-cmp',
    templateUrl: './test1.html',
})

export class Test1Component extends BaseComponent implements AfterViewInit {

    @ViewChild('testModal') public testModal: ModalDirective;

    private _payload: any;
    @Input() get payload() {
        return this._payload;
    }
    set payload(value) {
        this._payload = value ? JSON.parse(value) : null;
        this.toggleModal();
    }

    ngAfterViewInit() {
        this.toggleModal();
    }

    toggleModal() {
        if (this.payload && this.testModal && !this.testModal.isShown) {
            this.testModal.show();
        }
    }
}
