import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';
import { forkJoin, map } from 'rxjs';

@Injectable()
export class TimelineHistoryService extends ServiceBase {

    private actionUrl: string;
    private _historyInformation: string;
    private _visitPatientHistory: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._historyInformation = this.actionUrl + _configuration.timelineHistory.historyInformation;
        this._visitPatientHistory = this.actionUrl + _configuration.timelineHistory.visitPatientHistory;
    }

    get = (patientId: number): Observable<any> => {

        const historyInformation = `${this._historyInformation}?patientId=${patientId}`;
        const visitPatientHistory = `${this._visitPatientHistory}?patientId=${patientId}`;

        let url1 = this._http.get<any>(historyInformation).pipe(map(res => res));
        let url2 = this._http.get<any>(visitPatientHistory).pipe(map(res => res));

        return forkJoin([url1, url2]);
    }
}
