import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';


@Injectable()
export class SapControlSearchService extends ServiceBase {

    private actionUrl: string;
    private get: string;


    constructor(private http: HttpClient, private configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.get = this.actionUrl + configuration.sapControlSearch.url;

    }


}
