import { PatientTypes } from 'app/shared/models/core/enums/patient-types';

export class PhysicianDesktopPatientFilter {

    patientType: PatientTypes;
    patientCode: string = '';
    patientLocation: string = '';
    patientFullName: string = '';
    encounterDate?: Date;
    isActive?: boolean = true;

    // out-patient
    patientSource?: number;
    // out-patient

    pageIndex: number = 1;


    constructor() { }
}
