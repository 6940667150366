import { SelectItem } from 'primeng/api';
import { RefundType } from '../enums';

export class RefundTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Cash Refund', value: RefundType.CashRefund },
            { label: 'Added To Deposit', value: RefundType.AddedToDeposit }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
