export class PatientReleaseResult {
    id: string;
    patientName: string;
    patientId: number;
    encounterId: number;
    patientTypeId: number;
    OrderDate: string;
    releaseDate: Date;
    createdDate: Date; 
}
