export class DoctorPatient {

    Id: number;
    PatientId: number;
    PatientCode: string;
    FullName: string;
    OtherFullName: string;
    Age: string;
    GenderId?: number;
    Location: string;
    PatientTypeId: number;
    PatientSourceId: number;
    EncounterDate: Date;
    DoctorName: string;

    constructor() { }
}
