import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { CoreApiConfiguration } from '../coreApiConfiguration';

@Injectable()

export class MedicalFileSetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.medicationFileSetup.url;
    }

    get = (): Observable<any[]> => {
        return this._http.get<any[]>(this._url);
    }

    add = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${this._url}?id=${id}`);
    }
}
