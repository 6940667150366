import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientReservationSearchFilter } from '../../../models/queryModel';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientReservationSearchService extends ServiceBase {

    private actionUrl: string;
    private _get: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.patientReservationSearch.url;
    }

    public getSearchResult = (data: PatientReservationSearchFilter): Observable<any> => {

        let queryString: string = '';
        queryString += '?patientCode=';
        if (data.patientCode)
            queryString += data.patientCode;

        queryString += '&patientName=';
        if (data.patientName)
            queryString += data.patientName;

        queryString += '&nationalID=';
        if (data.nationalID)
            queryString += data.nationalID;

        queryString += '&phoneNumber=';
        if (data.phoneNumber)
            queryString += data.phoneNumber;

        queryString += '&reservationNumber=';
        if (data.reservationNumber)
            queryString += data.reservationNumber;

        queryString += '&requiredDate=';
        if (data.requiredDate)
            queryString += data.requiredDate.toLocaleDateString();

        return this._http.get<any>(this._get + queryString);
    }
}