import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from './hijri-gregorian-datepicker/date-formatter.service';
import { CustomNgbDateParserFormatter } from './hijri-gregorian-datepicker/CustomNgbDateParserFormatter';
import { ProvideParentFormDirective } from './hijri-gregorian-datepicker/provide-parent-form.directive';
import { HijriDatepickerComponent, HijriGregorianDatepickerComponent } from './hijri-gregorian-datepicker';
import { GregorianDatepickerComponent } from './hijri-gregorian-datepicker/gregorian-datepicker/gregorian-datepicker.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
    ],
    declarations: [
        HijriGregorianDatepickerComponent,
        HijriDatepickerComponent,
        ProvideParentFormDirective,
        GregorianDatepickerComponent,
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
        DateFormatterService
    ],
    exports: [
        HijriGregorianDatepickerComponent,
        GregorianDatepickerComponent,
    ]
})

export class HijriGregorianDatepickerModule {
}