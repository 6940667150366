import { SelectItem } from 'primeng/api';
import { PatientTypePreRequisite } from '../enums';

export class PatientTypePreRequisiteLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'In Patient', value: PatientTypePreRequisite.inPatient },
            { label: 'Out Patient', value: PatientTypePreRequisite.outPatient },
            { label: 'Emergency', value: PatientTypePreRequisite.ER },
        ];
    }
}
