import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class PayerBalanceService extends ServiceBase {

    private actionUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
    }

    getByPayer = (payerId: number): Observable<any> => {
        return this._http.get<any>(`${environment.queryModel}PayerBalance/ByPayer?payerId=${payerId}`);
    }

    getByApproval = (approvalNo: number): Observable<any> => {
        return this._http.get<any>(`${environment.queryModel}PayerBalance/ByApproval?approvalNo=${approvalNo}`);
    }

}