export enum AdmissionRequiredField {
    AdmissionDoctor = 1,
    AdmissionReason = 2,
    TransferMethod = 3,
    ConsultingDoctor = 4,
    LengthOfStay = 5,
    Remark = 6,
    TreatmentPlane = 7,
    Section = 8,
    Diet = 9,
    Class = 10
}
