import { Component, EventEmitter, Output, Input } from '@angular/core';
import { PatientService } from '../../services/core/patient/patient.service';
import { PatientStatusLookup } from '../../models/core/lookup/patient-status-lookup';
import { PatientStatus } from '../../models/core/enums/patient-status';

@Component({
    selector: 'admission-result-cmp',
    templateUrl: './admission-result.html',
})

export class AdmissionResultComponent {
    @Output() onSetResult = new EventEmitter<any>();
    @Input() result;
    
    patientStatus = new PatientStatusLookup();
    constructor() {
      
    }
    
    getPatientName(patient) {
        return [patient.FirstName, patient.FatherName, patient.LastName].join(' ');
    }
    
    getStatus(status: number) { 
        if (!status) {
            return '';
        }

        const statusLookup = this.patientStatus.getlook().find(e => e.value == status);

        if (statusLookup) {
            return statusLookup.label;
           
        }

        return '';
    }
    

    
    onSelect(event) {
        const item = event;
        this.onSetResult.emit({ item });
    }
}
