export class Transfer {

    FacilityId: number;
    PatientId: number;
    AdmissionId: number;
    HasCompanion: boolean;
    SectionId: number;
    RoomId: number;
    BedId: number;
    IsAdditionalBed: number;
    StartDate: Date;
    EndDate: Date;
    RequestedClassId: number;
    ActualClassId: number;
    Remarks: string;
    TransferReasonId: number;
    ApprovalRemarks: string;
    ApprovedBy: number;
    ApprovalDate: Date;
    Location?:string;
    Bed?:string;
    ActualClass?:string;
    Room?:string;
    RequestedClass?:string;
    SourceSectionId?: number;
    constructor() { }
}
