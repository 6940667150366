import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ItemRelation } from '../../../models/orders-and-billing/item-relation';
import { MedicationProduct } from 'app/shared/models/pharmacy/medication-product';

@Injectable()
export class ItemRelationService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private itemCountByMainItemId: string;
    private medicationProductItemRelation: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.itemRelation.url;
        this.itemCountByMainItemId = this.actionUrl + _configuration.itemRelation.itemCountByMainItemId;
        this.medicationProductItemRelation = this.actionUrl + _configuration.itemRelation.medicationProductItemRelation;
    }

    insert = (itemRelation: ItemRelation) => {
        return this._http.post<any>(this.url, itemRelation);
    }

    update = (itemRelation: ItemRelation) => {
        return this._http.put<any>(this.url, itemRelation);
    }

    delete(id: number) {
        return this._http.delete(`${this.url}/${id}`);
    }
    getRelatedItemCountByMainItemId(mainItemId: number) {
        return this._http.get<any>(`${this.itemCountByMainItemId}?mainItemId=${mainItemId}`);
    }

    insertMedicationProductItemRelation(medicationProduct: MedicationProduct) {
        return this._http.post<any>(this.medicationProductItemRelation, medicationProduct);
    }
}
