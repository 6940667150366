import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PriceListService } from 'app/shared/services/order-and-billing/priceList/priceList.service';

import { ItemBasicInfo } from 'app/shared/models/orders-and-billing/item-basic-info';
import { ItemService } from 'app/shared/services/order-and-billing/item/item.service';
import { ItemSearchFilter } from 'app/shared/models/orders-and-billing/item-search-filter';

import { StaffCategoryService } from 'app/shared/services/order-and-billing/staff-category.service';
import { StaffCategory } from 'app/shared/models/orders-and-billing/staff-category.model';

@Component({
    selector: 'advance-search-price-list-item-cmp',
    styleUrls: ['./advanceSearchPriceListItem.scss'],
    templateUrl: './advanceSearchPriceListItem.html',
})

export class AdvanceSearchPriceListItemComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();

    priceLists: any = [];
    priceListId: number;
    categoriesIds: any = [];

    selectedItem: ItemBasicInfo;
    filteredItems: ItemBasicInfo[];


    constructor(private priceListService: PriceListService,
        private itemService: ItemService,
        private _StaffCategoryService: StaffCategoryService
    ) {
    }

    ngOnInit(): void {
        this.getAllActivePriceList();
        this.getStaffCategories();
    }

    getAllActivePriceList() {
        this.priceListService.getAllActivePriceList()
            .subscribe((data: any) => {
                this.priceLists = [];
                for (const priceList of data) {
                    this.priceLists.push({ label: priceList.Title, value: priceList.Id });
                }
            });
    }

    filterItems(event) {
        const itemSearchFilter: ItemSearchFilter = new ItemSearchFilter();
        itemSearchFilter.Description = event.query;
        itemSearchFilter.CategoriesIds = this.categoriesIds;
        itemSearchFilter.PageSize = 5;
        this.itemService.getParentOrServiceOrPackageLookup(itemSearchFilter)
            .subscribe((data: ItemBasicInfo[]) => {
                if (data) {
                    this.filteredItems = data;
                } else {
                    this.filteredItems = [];
                }
            });
    }

    onSubmit() {
        const selectedItemId = this.selectedItem ? this.selectedItem.Id : null;
        this.onSearch.emit({ priceListId: this.priceListId, itemId: selectedItemId });
    }

    getStaffCategories() {
        this._StaffCategoryService.getStaffCategories()
            .subscribe((data: StaffCategory[]) => {
                data.forEach(element => {
                    this.categoriesIds.push(element.CategoryId)
                });
            })
    }
}