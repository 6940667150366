import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class FDASetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.FDASetup.url;
    }

    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${this._url}?id=${id}`);
    }
}
