import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateType } from './date-type';
import { DateFormatterService } from './date-formatter.service';

import * as momentjs from 'moment';
const moment = momentjs;


import * as moment_ from 'moment-hijri';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
const momentHijri = moment_;


@Component({
  selector: 'hijri-gregorian-datepicker',
  styleUrls: ['./hijri-gregorian-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './hijri-gregorian-datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: HijriGregorianDatepickerComponent,
      multi: true
    }
  ],
})
export class HijriGregorianDatepickerComponent implements ControlValueAccessor {

  selectedDateType: DateType = DateType.Gregorian;
  selectedDate: NgbDateStruct;
  @Input() showLabel = true;

  private _enableDateType = false;
  @Input() get enableDateType() {
    return this._enableDateType;
  }
  set enableDateType(value) {
    this._enableDateType = value;
    if (value) {
      this.selectedDateType = DateType.Hijri;
    }
    else {
      this.selectedDateType = DateType.Gregorian;
    }
  }

  @Input() readonly = false;
  @Input() disabled = false;

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private _min: any;
  @Input() get min() {
    return this._min;
  };

  set min(value) {
    if (value) {
      this._min = this.dateFormatterService.fromModel(value);
    }
  }

  private _max: any;
  @Input() get max() {
    return this._max;
  };

  set max(value) {
    if (value) {
      this._max = this.dateFormatterService.fromModel(value);
    }
  }

  @Input() placeholder: string;
  _value: FormControl = new FormControl();

  get DateType() {
    return DateType;
  }

  constructor(private dateFormatterService: DateFormatterService) {
    this.onChange();
  }

  getSelectedDate(): string {
    let formattedDate = this.dateFormatterService.ToString(this.selectedDate);

    if (this.selectedDateType == DateType.Hijri) {
      return momentHijri(formattedDate, 'iD/iM/iYYYY').locale('en').format();
    }

    if (this.selectedDateType == DateType.Gregorian) {
      return moment(formattedDate, 'D/M/YYYY').locale('en').format();
    }
  }

  dateSelected(event) {
    if (this.selectedDateType === DateType.Gregorian) {
      this.selectedDate = event;
    } else {
      this.selectedDate = this.dateFormatterService.ToGregorian(event);

    }
    this._value.setValue(this.getResult());
  }

  getResult() {
    let date;
    if (this.selectedDate instanceof Date) {
      date = this.selectedDate;
      this.selectedDate = this.dateFormatterService.fromModel(this.selectedDate);
    } else {
      date = this.dateFormatterService.toModel(this.selectedDate);
    }
    return {
      date: date,
      dateType: this.selectedDateType,
    };
  }

  get value(): any {
    return this.getResult();
  }

  onChangeType(event) {
    this.selectedDateType = event;
    this._value.setValue(this.getResult());
  }

  onChange(val?: any) {
    this._value.valueChanges.subscribe(() => {
      this.selectedDateType = this.enableDateType && this._value.value?.dateType ? this._value.value?.dateType : this.selectedDateType || DateType.Gregorian
      this.selectedDate = this._value.value?.date;
      this.onChange(this._value.value)
    });
  }

  onTouch(val?: any) {
  }

  writeValue(value: any) {
    this._value.setValue(value, { emitEvent: false });
    this.onChange(this._value.value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
