import { Item } from './item';

export class CoverageService {
     Id: number;
     ClassId: number;
     ServiceId: number;
     IPCoverageLimit?: number;
     IPCashLimit?: number;
     IPExceedLimitAction?: number;
     IPParticipantPercentage?: number;
     InPatientServiceChargeLimit?: number;
     InPatientApplyLimitOn: number;
     IPCoveragePer?: number;
     IPCashPer?: number;
     InPatientMinCashAmount?: number;

     OPCoverageLimit?: number;
     OPCashLimit?: number;
     OPExceedLimitAction?: number;
     OPParticipantPercentage?: number;
     OutPatientServiceChargeLimit?: number;
     OutPatientApplyLimitOn: number;
     OPCoveragePer?: number;
     OPCashPer?: number;
     OutPatientMinCashAmount?: number;

     ERCoverageLimit?: number;
     ERCashLimit?: number;
     ERExceedLimitAction?: number;
     ERParticipantPercentage?: number;
     EmergencyPatientServiceChargeLimit?: number;
     EmergencyPatientApplyLimitOn: number;
     ERCoveragePer?: number;
     ERCashPer?: number;
     EmergencyMinCashAmount?: number;

     Status: boolean;
     InPatientStatus: boolean;
     OutPatientStatus: boolean;
     EmergencyPatientStatus: boolean;
     CoverageType: number;
     PatientType: string;
     Item: Item = new Item();
     Priority?: number;
}
