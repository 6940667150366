import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PatientDietService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientDiet.url;
    }

    get = (patientId: number): Observable<any> => {

        const url = `${this._url}?patientId=${patientId}`;
        return this._http.get<any>(url);
    }

    update = (patientId, data): Observable<any> => {
        const url = `${this._url}?patientId=${patientId}`;
        return this._http.put<any>(url, data);
    };

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    remove = (id): Observable<any> => {
        return this._http.delete(`${this._url}?id=${id}`);
    }
}