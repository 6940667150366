import { Component } from '@angular/core';

@Component({
    selector: 'advance-search-triage-cmp',
    styleUrls: ['./advanceSearchTriage.scss'],
    templateUrl: './advanceSearchTriage.html'
})

export class AdvanceSearchTriageComponent {
    constructor() {
    }
}
