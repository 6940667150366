import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Control } from 'app/pages/control';

@Injectable()
export class PatientProblemService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getAll: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientProblem.url;
        this._getAll = this.actionUrl + _configuration.patientProblem.getAll;


    }

    getPatientProblems = (): Observable<Control[]> => {
        return this._http.get<any[]>(`${this._getAll}`);
    }

    public insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    public update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}