import {
    AggregatorAppointmentService,
    AggregatorPatientService,
    AggregatorTransferAdmissionService,
    AggregatorPendingAreaManagementService,
    AggregatorEligabilityService,
    AggregatorPatientStatementService,
    AggregatorOrderService,
    AggregatorPharmacyOrderService,
    AggregatorOrderDetailService,
    AggregatorPharmacyOrderDetailService,
    DischargeAndDiscontinuteActiveMedicationService,
    OrderIssueStockLogService,
    ReturnRequestLogService
} from "./services";

export const NGA_HTTP_AGGREGATOR_SERVICES = [
    AggregatorAppointmentService,
    AggregatorPatientService,
    AggregatorTransferAdmissionService,
    AggregatorPendingAreaManagementService,
    AggregatorEligabilityService,
    AggregatorPatientStatementService,
    AggregatorOrderService,
    AggregatorPharmacyOrderService,
    AggregatorOrderDetailService,
    AggregatorPharmacyOrderDetailService,
    DischargeAndDiscontinuteActiveMedicationService,
    OrderIssueStockLogService,
    ReturnRequestLogService
];
