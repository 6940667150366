import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseComponent } from 'app/shared/infrastructure';
import { LookUpService } from 'app/shared/services';
import { DigitsValidator } from 'app/shared/validators';


@Component({
    selector: 'moh-contract-setup',
    templateUrl: './moh-contract-setup.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MOHContractSetupComponent),
            multi: true
        }
    ]
})

export class MOHContractSetupComponent extends BaseComponent implements ControlValueAccessor {

    sections: any[] = [];;

    private _allowAddSection = false;
    @Input() get allowAddSection() {
        return this._allowAddSection;
    }
    set allowAddSection(value) {
        this._allowAddSection = value;
        if (value) {
            this.getSections()
        }
    }

    form = new FormGroup({
        id: new FormControl(null),
        cashAmount: new FormControl(null, [Validators.min(1), DigitsValidator.validate]),
        minCashAmount: new FormControl(null, Validators.min(0)),
        maximumPatientShare: new FormControl(null, Validators.min(0)),
        sectionIds: new FormControl(null),
        patientType: new FormControl(null),
    })

    get id(): AbstractControl { return this.form.get('id'); }
    get cashAmount(): AbstractControl { return this.form.get('cashAmount'); }
    get minCashAmount(): AbstractControl { return this.form.get('minCashAmount'); }
    get maximumPatientShare(): AbstractControl { return this.form.get('maximumPatientShare'); }
    get sectionIds(): AbstractControl { return this.form.get('sectionIds'); }
    get patientType(): AbstractControl { return this.form.get('patientType'); }

    get value(): any {
        return this.form?.value || null;
    }

    constructor(private _lookUpService: LookUpService) {
        super();
    }

    getSections() {
        this.sections = [];
        this._lookUpService.getOutPatientClinicsSection().subscribe({
            next: (response: any) => {
                const data = this.camelizeKeys(response);
                this.sections = data.map((m) => ({ value: m.id, label: m.title })) || [];
            },
            error: (e) => this.showError(e)
        })
    }

    onChange(_val?: any) {
        this.form.valueChanges.subscribe(_value => {
            this.onChange(this.value)
        });
    }

    onTouch(val?: any) {
    }

    writeValue(value: any) {
        this.form.setValue(value);
        this.onChange(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

}
