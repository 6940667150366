import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Islamic } from '../Islamic';
import * as moment_ from 'moment-hijri';
import { DateFormatterService } from '../date-formatter.service';
const momentHijri = moment_;

@Component({
  selector: 'hijri-date-picker',
  templateUrl: './hijri-datepicker.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: Islamic }
  ],
})
export class HijriDatepickerComponent {

  @ViewChild('hijriPicker') datePicker: NgbInputDatepicker;

  @Output() hijriDateChange: EventEmitter<any> = new EventEmitter();

  _date: any;
  @Input() get date() {
    return this._date;
  };

  set date(value) {
    if (value) {
      if (value instanceof Date) {
        const date = this.dateFormatterService.fromModel(value)
        this._date = this.dateFormatterService.ToHijri(date);
      } else {
        this._date = value;
      }
    }
    else{
      this._date = null;
    }
  }

  private _max: any;
  @Input() get max() {
    return this._max;
  };

  set max(value) {
    if (value) {
      if (value instanceof Date) {
        const max = this.dateFormatterService.fromModel(value)
        this._max = this.dateFormatterService.ToHijri(max);
      } else {
        this._max = this.dateFormatterService.ToHijri(value);
      }
    }
  }
  
  private _min: any;
  @Input() get min() {
    return this._min;
  };

  set min(value) {
    if (value) {
      if (value instanceof Date) {
        const min = this.dateFormatterService.fromModel(value)
        this._min = this.dateFormatterService.ToHijri(min);
      } else {
        this._min = new NgbDate(1360, 1, 1);
      }
    }
  }

  @Input() readonly = false;
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() name: string;

  @Input() placeholder: string;

  constructor(private dateFormatterService: DateFormatterService) { }

  changeDate(event) {
    this.date = event;
    this.hijriDateChange.emit(event);
  }

  onBlur() {
    if (!this.date) {
      this.hijriDateChange.emit(null);
    }
  }

  clear() {
    this.date = null;
    this.datePicker.close();
    this.hijriDateChange.emit(null);
  }

  get value(): any {
    return this.date ? new Date(this.date.year, this.date.month - 1, this.date.day) : '';
  }
}