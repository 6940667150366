import { SelectItem } from 'primeng/api';
import { LabeledAvoidanceLevel } from 'app/shared/models/medispan/enums/labeled-avoidance-level';

export class LabeledAvoidanceLevelLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Contraindicated', value: LabeledAvoidanceLevel.Contraindicated },
            { label: 'Avoid', value: LabeledAvoidanceLevel.Avoid },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Contraindicated', value: LabeledAvoidanceLevel.Contraindicated },
            { label: 'Avoid', value: LabeledAvoidanceLevel.Avoid },
        ];
    }

}
