import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { AppointmentServiceApiConfiguration } from '../appointmentServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';
import { AppointmentVisitViewModel } from 'app/shared/models/appointment/appointment-visit-view-model';
import { PagedResult } from 'app/shared/models/appointment/paged-result';
import { AppointmentVisitSearchFilter } from 'app/shared/models/appointment/appointment-search-filter';
import { Patient } from 'app/shared/models';
import { environment } from 'environments/environment';

@Injectable()
export class AppointmentService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _opdSearchUrl: string;
    private _cancelAppointmentUrl: string;
    private _getPatientDataByIdUrl: string;
    private _getVisitDataByIdUrl: string;
    private _maxAllowedVisitCount: string;
    private _arriveAppointmentUrl: string;

    constructor(private _http: HttpClient, private _configuration: AppointmentServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + this._configuration.appointment.url;
        this._opdSearchUrl = this.actionUrl + _configuration.appointment.visitSearch;
        this._cancelAppointmentUrl = this.actionUrl + _configuration.appointment.cancelAppointment;
        this._arriveAppointmentUrl = this.actionUrl + _configuration.appointment.arriveAppoinment;
        this._getPatientDataByIdUrl = this.actionUrl + _configuration.appointment.getPatientDataById;
        this._getVisitDataByIdUrl = this.actionUrl + _configuration.appointment.getVisitDataById;
        this._maxAllowedVisitCount = this.actionUrl + _configuration.resource.maxAllowedVisitCount; //'https://localhost:5001/api/' for test local
    }

    getOpdAppointment = (filter: AppointmentVisitSearchFilter): Observable<any> => {

        let queryString: string = '';
        queryString += '?AppointmentDate=';
        queryString += filter.AppointmentDate ? filter.AppointmentDate.toLocaleDateString() : '';

        queryString += '&PageIndex=';
        queryString += filter.PageIndex;

        queryString += '&PageSize=';
        queryString += filter.PageSize;

        queryString += '&PatientCode=';
        if (filter.PatientCode) {
            queryString += filter.PatientCode;
        }

        queryString += '&TeleCode=';
        if (filter.TeleCode) {
            queryString += filter.TeleCode;
        }

        queryString += '&DoctorId=';
        if (filter.DoctorId) {
            queryString += filter.DoctorId;
        }

        queryString += '&NationalCode=';
        if (filter.NationalCode) {
            queryString += filter.NationalCode;
        }

        queryString += '&PatientName=';
        if (filter.PatientName) {
            queryString += filter.PatientName;
        }

        queryString += '&SectionId=';
        if (filter.SectionId) {
            queryString += filter.SectionId;
        }

        queryString += '&StatusId=';
        if (filter.StatusId) {
            queryString += filter.StatusId;
        }

        return this._http.get<any>(`${this._opdSearchUrl}/${queryString}`,
            { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    cancelAppointment = (appointmentId: number, cancelReasonId: number): Observable<PagedResult<AppointmentVisitViewModel>> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += appointmentId;

        queryString += '&cancelReasonId=';
        queryString += cancelReasonId;

        return this._http.put<any>(`${this._cancelAppointmentUrl}/${queryString}`, null, { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    getPatientDataById = (id: number): Observable<any> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += id;

        return this._http.get<any>(`${this._getPatientDataByIdUrl}/${queryString}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }

    getVisitDataById = (id: number): Observable<any> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += id;

        return this._http.get<any>(`${this._getVisitDataByIdUrl}/${queryString}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }

    linkGeneratedMrnToPatient = (patient: Patient, appointmentId: number): Observable<any> => {

        let queryString: string = '';
        queryString += '?appointmentId=';
        queryString += appointmentId;

        return this._http.put<any>(`${this._url}/LinkGeneratedMrnToPatient${queryString}`, patient, { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    getMaxAllowedVisitCount = (sectionId: number, doctorId: number): Observable<number> => {
        let queryString: string = '';
        queryString += '?sectionId=' + sectionId;
        queryString += '&&doctorId=' + doctorId;

        return this._http.get<number>(`${this._maxAllowedVisitCount}/${queryString}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }

    arriveApoointment = (appId: number): Observable<number> => {
        const url = `${this._arriveAppointmentUrl}?id=${appId}`;
        return this._http.put<number>(url, null);
    }


    getAppointmentById = (id: number): Observable<any> => {

        return this._http.get<any>(`${environment.appointment}Appointment/appointmentById?id=${id}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }

    getByPatient = (patientId: number): Observable<any> => {
        return this._http.get<any>(`${environment.appointment}Appointment/ByPatient?patientId=${patientId}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }
}
