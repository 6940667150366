import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CoverageContract } from 'app/shared/models/orders-and-billing/coverage-contract';
import { environment } from 'environments/environment';

@Injectable()
export class CoverageContractService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _getCoverageContractsByPayerId: string;
    private _getPayerCoverageContractEndDate: string;
    private _copyContract: string;
    private _getInfoByPayer;
    private _getBySearch;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.coverageContract.url;
        this._getCoverageContractsByPayerId = this._actionUrl + _configuration.coverageContract.getCoverageContractsByPayerId;
        this._getPayerCoverageContractEndDate = this._actionUrl + _configuration.coverageContract.getPayerCoverageContractEndDateAndStartDate;
        this._copyContract = this._actionUrl + _configuration.coverageContract.copyContract;
        this._getInfoByPayer = this._actionUrl + _configuration.coverageContract.getInfoByPayer;
        this._getBySearch = this._actionUrl + _configuration.coverageContract.getBySearch;
    }

    public getAllContracts = (): Observable<CoverageContract[]> => {
        return this._http.get<any>(this._url);
    }

    public getPayerCoverageContractEndDateAndStartDate = (payerId: number): Observable<CoverageContract[]> => {
        return this._http.get<any>(this._getPayerCoverageContractEndDate + "?payerId=" + payerId)
    }

    public getContractsByPayerId = (payerId: number): Observable<CoverageContract[]> => {
        return this._http.get<any>(this._getCoverageContractsByPayerId + '?payerId=' + payerId)
    }

    public insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    public copyContract = (data) => {
        return this._http.post<any>(this._copyContract, JSON.stringify(data));
    }

    public getInfoByPayer = (payerId: number): Observable<any[]> => {
        return this._http.get<any>(this._getInfoByPayer + '?payerId=' + payerId)
    }

    public getBySearch = (query: string, payerId: number): Observable<any[]> => {
        return this._http.get<any>(this._getBySearch + '?query=' + query + '&payerId=' + payerId)
    }

    public addAttachment = (data): Observable<any[]> => {
        return this._http.post<any>(`${this._url}/AddAttachment`, data);
    }

    public removeAttachment = (id): Observable<any[]> => {
        return this._http.delete<any>(`${this._url}/RemoveAttachment?id=${id}`);
    }

    public allActive = (): Observable<any[]> => {
        return this._http.get<any>(`${this._url}/AllActive`);
    }

    public getByPolicyNumber = (policyNumber: string): Observable<any> => {
        return this._http.get<any>(`${environment.order}/CoverageContract/ByPolicyNumber?policyNumber=${policyNumber}`)
    }
}
