
export class TextReportResultSearchFilter {

    patientCode: string;
    orderNo: string;
    accessionNo: string;
    orderFrom: Date;
    orderTo: Date;

    constructor() { }
}
