import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';

@Injectable()
export class BedStatusService extends ServiceBase {

    private actionUrl: string;
    private toOccupied: string;
    private toCanceled: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.toOccupied = this.actionUrl + _configuration.bedStatus.toOccupied;
        this.toCanceled = this.actionUrl + _configuration.bedStatus.toCanceled;
    }

    public ChangeBedStatusToCanceled(bedId: number, data: any) {
        return this._http.put<any>(this.toCanceled + bedId, data);

    }

    public ChangeBedStatusToOccupied(bedId: number, data: any) {
        return this._http.put<any>(this.toOccupied + bedId, data);

    }
}