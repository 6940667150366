import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class CoverageApprovalService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    close = (id: string): Observable<any> => {
        return this._http.post<any>(`${environment.order}/CoverageAproval/Close`, JSON.stringify(id))
    }

    getPreApprovalByPatientId = (id): Observable<any> => {
        return this._http.get<any>(`${environment.order}/CoverageAproval/PreApprovalByPatientId?id=${id}`)
    }

    getPatientApprovals = (patientId): Observable<any> => {
        return this._http.get<any>(`${environment.order}/CoverageAproval/PatientApprovals?patientId=${patientId}`)
    }
}
