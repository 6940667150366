import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { MedicationDefinition } from 'app/shared/models/pharmacy/medication-definition';
import { ItemBasicInfo } from 'app/shared/models/orders-and-billing/item-basic-info';
import { ServiceBase } from "app/shared/infrastructure";
import { environment } from 'environments/environment';

@Injectable()
export class MedicationDefinitionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getMedicationDefinitionGroupUrl: string;
    private _getByFilterUrl: string;
    private _getByMedicationDefinitionIdUrl: string;
    private _getByMedicationDefinitionByItemCodeUrl: string;
    private _getByGenericUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.medicationDefinition.url;
        this._getMedicationDefinitionGroupUrl = this.actionUrl + _configuration.medicationDefinition.getMedicationDefinitionGroup;
        this._getByFilterUrl = this.actionUrl + _configuration.medicationDefinition.getByFilter;
        this._getByMedicationDefinitionIdUrl = this.actionUrl + _configuration.medicationDefinition.getByMedicationDefinitionId;
        this._getByMedicationDefinitionByItemCodeUrl = this.actionUrl + _configuration.medicationDefinition.getByMedicationDefinitionItemCode;
        this._getByGenericUrl = this.actionUrl + _configuration.medicationDefinition.getByGeneric;

    }

    get = (page: number, pageSize: number, isActive?: boolean, groupId?: number, genericId?: number, brandId?: number): Observable<any> => {
        return this._http.get<any>(this._getByFilterUrl + "?page=" + page + "&rows=" + pageSize
            + "&groupId=" + groupId + "&genericId=" + genericId
            + "&isActive=" + isActive + "&brandId=" + brandId);
    }

    public gettMedicationDefinitionGroup = (query: string): Observable<ItemBasicInfo[]> => {
        return this._http.get<any>(this._getMedicationDefinitionGroupUrl + '?query=' + query);
    }

    insert = (medicationDefinition: MedicationDefinition): Observable<any> => {
        return this._http.post<any>(this._url, medicationDefinition);
    }

    update = (medicationDefinition: MedicationDefinition): Observable<any> => {
        return this._http.put<any>(this._url, medicationDefinition);
    }

    getById = (medicationDefinitionId: number): Observable<any> => {
        return this._http.get<any>(this._getByMedicationDefinitionIdUrl + "?medicationDefinitionId=" + medicationDefinitionId);
    }

    GetByItemCode = (itemCode: string): Observable<any> => {
        return this._http.get<any>(this._getByMedicationDefinitionByItemCodeUrl + "?itemCode=" + itemCode);

    }

    GetByGeneric = (query: string): Observable<any> => {
        return this._http.get<any>(this._getByGenericUrl + "?query=" + query);
    }

    activeGenericsByFilter = (query: string): Observable<any> => {
        const url = `${environment.pharmacy}MedicationDefinition/ActiveGenericsByFilter?query=${query}`;
        return this._http.get<any>(url);
    }
}
