import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';
@Injectable()
export class DiscontinueTransferMedicationSetupService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    get = (): Observable<any> => {
        return this._http.get<any>(`${environment.core}/TransferSectionSetup`);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(`${environment.core}/TransferSectionSetup?id=${id}`);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.core}/TransferSectionSetup`, data);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(`${environment.core}/TransferSectionSetup`, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${environment.core}/TransferSectionSetup`);
    }
}
