import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from '../date-formatter.service';

@Component({
  selector: 'gregorian-date-picker',
  templateUrl: './gregorian-datepicker.component.html',
})
export class GregorianDatepickerComponent {

  @ViewChild('datePicker') datePicker: any;
  @Input() readonly = false;
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() min: NgbDateStruct;
  @Input() max: NgbDateStruct;
  @Input() placeholder: string;
  @Output() selectedDateChange: EventEmitter<any> = new EventEmitter();

  _gregorianDate: any;
  @Input() get date() {
    return this._gregorianDate;
  };

  set date(value) {
    if (value) {
      const date = this.dateFormatterService.fromModel(value);
      this._gregorianDate = date;
    }
    else{
      this._gregorianDate = null;
    }
  }

  constructor(private dateFormatterService: DateFormatterService) { }

  changeDate(event) {
    this.selectedDateChange.emit(event);
  }

  onBlur() {
    if (!this.date) {
      this.selectedDateChange.emit(null);
    }
  }

  clear() {
    this.date = null;
    this.datePicker.close();
    this.selectedDateChange.emit(null);
  }

  get value(): any {
    return this.date ? new Date(this.date.year, this.date.month - 1, this.date.day) : '';
  }
}