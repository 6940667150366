import { SelectItem } from 'primeng/api';
import { AllergySeverity } from 'app/shared/models/medispan/enums/allergy-severity';

export class AllergySeverityLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Minor', value: AllergySeverity.Minor },
            { label: 'Moderate', value: AllergySeverity.Moderate },
            { label: 'Major', value: AllergySeverity.Major },
        ];
    }

    static getSeverity(): SelectItem[] {
        return [
            { label: 'Minor', value: AllergySeverity.Minor },
            { label: 'Moderate', value: AllergySeverity.Moderate },
            { label: 'Major', value: AllergySeverity.Major },
        ];
    }
}
