import { PatientStatus } from '../enums';
export class PatientStatusLookup {
    getlook(): any[] {
        let patientstatus: any[] = [];
        patientstatus.push({ label: 'none', value: PatientStatus.none });
        patientstatus.push({ label: 'active', value: PatientStatus.active });
        patientstatus.push({ label: 'inActive', value: PatientStatus.inActive });
        patientstatus.push({ label: 'merged', value: PatientStatus.merged });
        return patientstatus;
    };

    static getAll() {
        return [
            { label: 'All', value: PatientStatus.none },
            ({ label: 'Active', value: PatientStatus.active }),
            { label: 'In-Active', value: PatientStatus.inActive },
            { label: 'Merged', value: PatientStatus.merged },
        ]
    }
}
