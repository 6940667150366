import { MenuItemPermission } from '../shared/models/menu/menu-item-permission';
/**
 * path: path specified in pages menu ,see {@link ./pages.menu.ts}/>
 * permissionName: name of the permission specified in Permissions Table
 */
export const pagesMenuPermissions: MenuItemPermission[] = [

    // ********Patient Administration System********
    { path: 'patientRegistration', permissionName: 'ViewPatientRegistration' },
    { path: 'newAdmission', permissionName: 'ViewNewAdmission' },
    { path: 'preAdmission', permissionName: 'ViewPreAdmission' },
    { path: 'companion', permissionName: 'ViewCompanion' },
    { path: 'companionDetails', permissionName: 'ViewCompanionDetails' },
    { path: 'hospitalSection', permissionName: 'ViewHospitalSections' },
    { path: 'transfer', permissionName: 'ViewTransfer' },
    { path: 'discharge', permissionName: 'ViewDischarge' },
    { path: 'cancelAdmission', permissionName: 'ViewCancelAdmission' },
    { path: 'admissionStation', permissionName: 'ViewAdmissionStation' },
    { path: 'resourceManagement', permissionName: 'ViewResource' },
    { path: 'appointmentTracking', permissionName: 'ViewAppointmentTracking' },
    { path: 'transferAppointment', permissionName: 'ViewTransferAppointment' },
    { path: 'bookAppointment', permissionName: 'ViewBookAppointment' },
    { path: 'cancelAppointment', permissionName: 'ViewCancelAppointment' },
    { path: 'recurringAppointment', permissionName: 'ViewRecurringAppointment' },
    { path: 'reportAppointmentList', permissionName: 'ViewReportAppointmentList' },
    { path: 'resourcePeriod', permissionName: 'ViewResourcePeriod' },
    { path: 'appointmentManagement', permissionName: 'ViewAppointments' },
    { path: 'emergencyStation', permissionName: 'ViewEmergencyStation' },
    { path: 'emergencyVisit', permissionName: 'ViewEmergencyVisit' },
    { path: 'outPatientTransaction', permissionName: 'ViewTransactions' },
    { path: 'fileRequest', permissionName: 'ViewFileRequest' },
    { path: 'requestFile', permissionName: 'ViewRequestFile' },
    { path: 'patientFileMerge', permissionName: 'ViewPatientFileMerge' },
    { path: 'sickLeaveStation', permissionName: 'ViewSickLeaveStation' },
    { path: 'doctorRelease', permissionName: 'ViewDoctorRelease' },
    { path: 'activitiesReport', permissionName: 'ViewActivitiesReport' },
    { path: 'monthlyAdmissionSectionReport', permissionName: 'ViewMonthlyAdmissionSectionReport' },
    { path: 'cashAndCreditAdmissionReport', permissionName: 'ViewCashAndCreditAdmissionReport' },
    { path: 'monthlyAdmissionsStatisticsReport', permissionName: 'ViewMonthlyAdmissionsStatisticsReport' },
    { path: 'medicalDiagnosisSearchEngine', permissionName: 'ViewMedicalDiagnosisSearchEngine' },
    { path: 'tatMonitor', permissionName: 'ViewTatMonitor' },
    { path: 'dischargedPatientsPerDoctors', permissionName: 'ViewDischargedPatientsPerDoctorsReport' },
    { path: 'hospitalRooms', permissionName: 'ViewHospitalRooms' },
    { path: 'admissionReport', permissionName: 'ViewAdmissionReport' },
    { path: 'jordanianNonJordanianReport', permissionName: 'ViewJordanianNonJordanianReport' },
    { path: 'maleAndFemaleAdmissionReport', permissionName: 'ViewMaleAndFemaleAdmissionReport' },
    { path: 'monthlyOccupancyRateBySection', permissionName: 'ViewMonthlyOccupancyRateBySection' },
    { path: 'patientDaysBySectionMonthly', permissionName: 'ViewPatientDaysBySectionMonthly' },
    { path: 'emergencyVisitsReport', permissionName: 'ViewEmergencyVisitsReport' },
    { path: 'emergencyDailyCasesReport', permissionName: 'ViewEmergencyDailyCasesReport' },
    { path: 'outPatientClinicsReport', permissionName: 'ViewOutPatientClinicsReport' },
    { path: 'hospitalVisitReport', permissionName: 'ViewHospitalVisitReport' },
    { path: 'admissionReportSetup', permissionName: 'ViewAdmissionReportSetup' },
    { path: 'consultingDoctor', permissionName: 'ViewConsultingDoctors' },
    { path: 'outPatientMonitor', permissionName: 'ViewOutPatientMonitor' },
    { path: 'emergencyArea', permissionName: 'ViewEmergencyArea' },
    { path: 'transferConsultationRequest', permissionName: 'ViewTransferConsultationRequest' },
    { path: 'receiveEROrders', permissionName: 'ViewReceiveEROrders' },
    { path: 'patientMasterIndexPage', permissionName: 'ViewPatientMasterIndexPage' },
    { path: 'mealDefinitions', permissionName: 'ViewMealDefinitions' },
    { path: 'medicalServicesAndRestrictions', permissionName: 'ViewMedicalServicesAndRestrictions' },
    { path: 'scheduledMealServices', permissionName: 'ViewScheduledMealServices' },
    { path: 'scheduleMeal', permissionName: 'ViewScheduleMeal' },
    { path: 'sectionMealSchedulePlan', permissionName: 'ViewSectionMealSchedulePlan' },
    { path: 'scheduledPatientStation', permissionName: 'ViewScheduledPatientStation' },
    { path: 'queueMonitoring', permissionName: 'ViewQueueMonitoring' },
    { path: 'educationalAttachment', permissionName: 'ViewEducationalAttachment' },
    { path: 'nextPatient', permissionName: 'ViewNextPatient' },

    // ********Patient Billing Service********
    { path: 'approvalFollowUp', permissionName: 'ViewApprovalFollowUp' },
    { path: 'coverageLetter', permissionName: 'ViewCoverageLetter' },
    { path: 'accommodationFees', permissionName: 'ViewAccommodationFees' },
    { path: 'businessCenter', permissionName: 'ViewBusinessCenter' },
    { path: 'encounterDetails', permissionName: 'ViewEncounterDetails' },
    { path: 'accommodation', permissionName: 'ViewAccommodation' },
    { path: 'packageSession', permissionName: 'ViewPackageSession' },
    { path: 'patientSession', permissionName: 'ViewPatientSession' },
    // { path: 'billSerials', permissionName: 'ViewBillSerials' },
    { path: 'priceList', permissionName: 'ViewPricingList' },
    { path: 'itemPrice', permissionName: 'ViewItemPricing' },
    { path: 'copyPriceList', permissionName: 'ViewCopyPriceList' },
    { path: 'servicePriceCategory', permissionName: 'ViewServicePriceCategory' },
    { path: 'transactions', permissionName: 'ViewBillingCenterTransactions' },
    { path: 'todayCashBox', permissionName: 'ViewTodayCashBox' },
    { path: 'mainFund', permissionName: 'ViewMainFund' },
    { path: 'trackingMainFund', permissionName: 'ViewTrackingMainFund' },
    { path: 'disclosureIssuedInvoice', permissionName: 'ViewIssuedInvoice' },
    { path: 'disclosureUnissuedInvoice', permissionName: 'ViewUnissuedInvoice' },
    { path: 'openAndHaltedBillsReport', permissionName: 'ViewOpenAndHaltedBillsReport' },
    { path: 'residentsPatientsbyPayersReport', permissionName: 'ViewResidentsPatientsbyPayersReport' },
    { path: 'hospitalSalesReport', permissionName: 'ViewHospitalSalesReport' },
    { path: 'doctorsRevenueByElement', permissionName: 'ViewDoctorsRevenueByElement' },
    { path: 'patientStatementDetails', permissionName: 'ViewPatientStatementDetails' },
    { path: 'trackingMainFundReport', permissionName: 'ViewTrackingMainFundReport' },
    { path: 'ResidentsPatientsRevenue', permissionName: 'ViewResidentsPatientsRevenue' },
    { path: 'patientDeposit', permissionName: 'ViewPatientDeposit' },
    { path: 'depositTransactionManagement', permissionName: 'ViewDepositTransactionManagement' },
    { path: 'closeInvoiceManagement', permissionName: 'ViewCloseInvoiceManagement' },
    { path: 'donorStation', permissionName: 'ViewDonorStation' },

    { path: 'profitMarginConfiguration', permissionName: 'ViewProfitMarginConfiguration' },
    { path: 'statementTransactions', permissionName: 'ViewStatementTransactions' },
    { path: 'incomePerSectionAndElementReport', permissionName: 'ViewIncomePerSectionAndElementReport' },
    { path: 'outPatientPharmacySaleReport', permissionName: 'ViewOutPatientPharmacySaleReport' },
    { path: 'insuranceLiabilityReport', permissionName: 'ViewinsuranceLiabilityReport' },
    { path: 'incomeHospitalReport', permissionName: 'ViewIncomeHospitalReport' },
    { path: 'incomePerOutPatientSectionReport', permissionName: 'ViewIncomePerOutPatientSectionReport' },
    { path: 'copyPayerDetails', permissionName: 'ViewCopyPayerDetails' },
    { path: 'doctorRevenuePerPatientReport', permissionName: 'ViewDoctorRevenuePerPatientReport' },
    { path: 'priceListItemReport', permissionName: 'ViewPriceListItemReport' },
    { path: 'orderSummaryItemReport', permissionName: 'ViewOrderSummaryItemReport' },
    { path: 'revenueReport', permissionName: 'ViewRevenueReport' },
    { path: 'approveRequests', permissionName: 'ViewApproveRequests' },
    { path: 'pricingAuditManagement', permissionName: 'ViewPricingAuditManagement' },
    // ********Insurance********
    { path: 'contractStation', permissionName: 'ViewContractStation' },
    { path: 'contracts', permissionName: 'ViewContracts' },
    { path: 'policy', permissionName: 'ViewPolicy' },

    // ********Claim Management********
    { path: 'claimManagementStation', permissionName: 'ViewClaimManagementStation' },
    { path: 'claimManagementTracking', permissionName: 'ViewClaimManagementTracking' },
    { path: 'claimSettlementsStation', permissionName: 'ViewclaimSettlementsStation' },
    { path: 'remittanceTransaction', permissionName: 'ViewRemittanceTransaction' },
    { path: 'receipt', permissionName: 'ViewReceipt' },
    { path: 'claimAttachment', permissionName: 'ViewClaimAttachment' },

    // ********Clinical Information System********

    // OT
    { path: 'equipmentDefinition', permissionName: 'ViewEquipmentDefinition' },
    { path: 'roomDefinition', permissionName: 'ViewRoomDefinition' },
    { path: 'procedureDefinition', permissionName: 'ViewProcedureDefinition' },
    // { path: 'operationTracking', permissionName: 'ViewOperationTracking' },
    { path: 'operationSlate', permissionName: 'ViewOperationSlate' },
    { path: 'booking', permissionName: 'ViewBooking' },
    { path: 'operationInbox', permissionName: 'ViewOperationInbox' },
    // { path: 'operationDashboard', permissionName: 'ViewOperationDashboard' },

    // Nursing Management
    { path: 'nursingStation', permissionName: 'ViewNursingStation' },
    { path: 'nursingStationConfiguration', permissionName: 'ViewNursingStationConfiguration' },
    { path: 'nursingPatientDiagnosis', permissionName: 'ViewPatientDiagnosis' },
    { path: 'triage', permissionName: 'ViewTriage' },
    { path: 'nursingSupervisor', permissionName: 'ViewNursingSupervisor' },
    { path: 'nursingTimeline', permissionName: 'ViewNursingTimeline' },

    // Pharmacy Management
    { path: 'drugsIndexAndDefinition', permissionName: 'ViewDrugsIndexAndDefinition' },
    { path: 'frequency', permissionName: 'ViewFrequency' },
    { path: 'dispenseMedication', permissionName: 'ViewDispenseMedication' },
    { path: 'receiveDoctorsPrescription', permissionName: 'ViewReceiveDoctorsPrescription' },
    { path: 'returnRequest', permissionName: 'ViewReturnRequest' },
    { path: 'adminUser', permissionName: 'ViewSections' },
    { path: 'doctorPrescription', permissionName: 'ViewDoctorPrescription' },
    { path: 'approveReturnRequest', permissionName: 'ViewApproveReturnRequest' },
    { path: 'closeReopenDrugCard', permissionName: 'ViewPharmacyRelease' },
    { path: 'medicationProduct', permissionName: 'ViewMedicationProduct' },
    { path: 'genericConfiguration', permissionName: 'ViewGenericConfiguration' },
    { path: 'medicationAdministration', permissionName: 'ViewMedicationAdministration' },
    { path: 'pharmacyDailyWorkIndex', permissionName: 'ViewPharmacyDailyWorkIndex' },
    { path: 'pharmacyOrderManagement', permissionName: 'ViewPharmacyOrderManagement' },
    { path: 'unitDoseDrugDistribution', permissionName: 'ViewUnitDoseDrugDistribution' },
    { path: 'pharmacyPendingAreaManagement', permissionName: 'ViewPharmacyPendingAreaManagement' },

    // Laboratory Management
    { path: 'mainResultsList', permissionName: 'ViewResultsList' },
    { path: 'resultListDetails', permissionName: 'ViewResultListDetails' },
    { path: 'lookups', permissionName: 'ViewLookups' },
    { path: 'sections', permissionName: 'ViewLabSections' },
    { path: 'specimenRejectionReason', permissionName: 'ViewSpecimenRejectionReason' },
    { path: 'externalLaboratory', permissionName: 'ViewExternalLaboratory' },
    { path: 'antibiotic', permissionName: 'ViewAntibiotics' },
    { path: 'groupTests', permissionName: 'ViewGroupTestProfile' },
    { path: 'groupTestsDetails', permissionName: 'ViewGroupTestsDetails' },
    { path: 'testsDefinition', permissionName: 'ViewTestsDefinition' },
    { path: 'testNormalRange', permissionName: 'ViewTestNormalRange' },
    { path: 'kitSetup', permissionName: 'ViewKitSetup' },
    { path: 'microbiologyPanel', permissionName: 'ViewMicrobiologyPanel' },
    { path: 'microbiologyPanelAntibiotic', permissionName: 'ViewMicrobiologyPanelAntibiotic' },
    { path: 'commentTemplate', permissionName: 'ViewCommentTemplate' },
    { path: 'resultFormulaTests', permissionName: 'ViewResultFormulaTests' },
    { path: 'resultFormulaTestsDetails', permissionName: 'ViewResultFormulaTestsDetails' },
    { path: 'testInstruction', permissionName: 'ViewTestInstruction' },
    { path: 'testInstructionDetail', permissionName: 'ViewTestInstructionDetails' },
    { path: 'textResultsTemplates', permissionName: 'ViewTextResultsTemplates' },
    { path: 'collectSpecimen', permissionName: 'ViewCollectSpecimen' },
    { path: 'sensitivityResultEntryScreen', permissionName: 'ViewCultureResult' },
    { path: 'numericListTextResultEntryScreen', permissionName: 'ViewNormalResult' },
    { path: 'labTechnicianWorkbench', permissionName: 'ViewWorkbench' },
    { path: 'labMonitor', permissionName: 'ViewLabMonitor' },
    //{ path: 'tatMonitor', permissionName: 'ViewTATMonitor' },
    { path: 'externalOrderStation', permissionName: 'ViewExternalOrder' },
    { path: 'labOrderSpecimenTestSearch', permissionName: 'ViewSearchEngine' },
    { path: 'textReportResultEntry', permissionName: 'ViewReportResult' },
    { path: 'previousResult', permissionName: 'ViewPreviousResult' },
    { path: 'laboratoryTestsReport', permissionName: 'ViewLaboratoryTestsReport' },
    { path: 'laboratoryMonthlyStatisticalReport', permissionName: 'ViewLaboratoryMonthlyStatisticalReport' },
    { path: 'testMapping', permissionName: 'ViewTestMapping' },
    { path: 'microbiologyStatisticsReport', permissionName: 'ViewMicrobiologyStatisticsReport' },
    { path: 'sectionRevenueBySectionReport', permissionName: 'ViewSectionRevenueBySectionReport' },
    { path: 'microOrganismResultsReport', permissionName: 'ViewMicroOrganismResultsReport' },
    { path: 'sectionRevenueByDoctorReport', permissionName: 'ViewSectionRevenueByDoctorReport' },
    { path: 'labOrderManagement', permissionName: 'ViewLabOrderManagement' },
    { path: 'patientLaboratoryVisitReport', permissionName: 'ViewLaboratoryPatientVisitReport' },
    { path: 'specimenReport', permissionName: 'ViewSpecimenReport' },
    { path: 'reportTemplate', permissionName: 'ViewReportTemplate' },

    // CPOE
    { path: 'cancelOrder', permissionName: 'ViewCancelOrder' },
    { path: 'userFavorite', permissionName: 'ViewUserFavorite' },
    { path: 'postPendingTransaction', permissionName: 'ViewPostPendingTransaction' },
    { path: 'auditOrder', permissionName: 'ViewAuditOrder' },
    { path: 'patientOrderManagement', permissionName: 'ViewPatientOrder' },
    { path: 'receiveOrder', permissionName: 'ViewReceiveOrder' },
    { path: 'pendingArea', permissionName: 'ViewPendingArea' },
    { path: 'consultationStation', permissionName: 'ViewConsultationStation' },
    { path: 'pendingAreaManagement', permissionName: 'ViewPendingAreaManagement' },

    //Home Care 
    { path: 'homeCare', permissionName: 'ViewHomeCare' },

    // Physician Desktop
    { path: 'physicianDesktop', permissionName: 'ViewPhysicianDesktop' },
    { path: 'doctorDesktop', permissionName: 'ViewDoctorDesktop' },
    { path: 'patientStation', permissionName: 'ViewPatientStation' },

    // ********Certacure Control******** 

    { path: 'userManagement', permissionName: 'ViewUserManagement' },
    { path: 'diagnosis', permissionName: 'ViewDiagnosis' },
    { path: 'allergy', permissionName: 'ViewAllergy' },
    { path: 'facility', permissionName: 'ViewFacility' },
    { path: 'medicalCategories', permissionName: 'ViewMedicalCategories' },
    { path: 'systemCodes', permissionName: 'ViewSystemCodes' },
    { path: 'systemCodesDetails', permissionName: 'ViewSystemCodesDetails' },
    { path: 'clinicalNotes', permissionName: 'ViewClinicalNotes' },
    { path: 'admissionReason', permissionName: 'ViewAdmissionReason' },
    { path: 'admissionClass', permissionName: 'ViewClasses' },
    { path: 'admissionRoom', permissionName: 'ViewRooms' },
    { path: 'admissionBed', permissionName: 'ViewBeds' },
    { path: 'consultationTypes', permissionName: 'ViewConsultationTypes' },
    { path: 'hospitalClinicAndDoctor', permissionName: 'ViewClinicAndDoctor' },
    { path: 'items', permissionName: 'ViewItems' },
    { path: 'parentItems', permissionName: 'ViewParentItems' },
    { path: 'packageItems', permissionName: 'ViewPackageItems' },
    { path: 'groupItems', permissionName: 'ViewGroupItems' },
    { path: 'OrderSetItems', permissionName: 'ViewOrderSetItems' },
    { path: 'sapControl', permissionName: 'ViewSapControl' },
    { path: 'sapARControl', permissionName: 'ViewSapARControl' },
    { path: 'userSections', permissionName: 'ViewUserSections' },
    { path: 'staffCategoriesItem', permissionName: 'ViewStaffCategoriesItem' },
    { path: 'staffDefinition', permissionName: 'ViewStaffDefinition' },
    { path: 'organizationSection', permissionName: 'ViewOrganizationSection' },
    { path: 'revenueSectionDefinition', permissionName: 'ViewRevenueSectionDefinition' },
    { path: 'Bank', permissionName: 'ViewBank' },
    { path: 'hospitalPerformanceDashboard', permissionName: 'ViewHospitalPerformanceDashboard' },
    { path: 'newDoctorPerformanceDashboard', permissionName: 'ViewNewDoctorPerformanceDashboard' },
    { path: 'sapAccountReceivableInvoice', permissionName: 'ViewSapARControl' },
    { path: 'lisControl', permissionName: 'ViewLISControl' },
    { path: 'privilegesReport', permissionName: 'ViewPrivilegeReport' },
    { path: 'assignment', permissionName: 'ViewAssignment' },
    { path: 'carePlanShortcut', permissionName: 'ViewCarePlanShortcut' },
    { path: 'claimManagementMapping', permissionName: 'ViewClaimManagementMapping' },
    { path: 'medispanSetting', permissionName: 'ViewMedispanSetting' },
    { path: 'itemInstruction', permissionName: 'ViewItemInstruction' },
    { path: 'doctorsSpeciality', permissionName: 'ViewDoctorsSpeciality' },
    { path: 'sapUnPostedTransactions', permissionName: 'ViewSapUnPostedTransactions' },
    { path: 'settlementDeductionSetup', permissionName: 'ViewSettlementDeductionSetup' },
    { path: 'notificationRoles', permissionName: 'ViewNotificationRoles' },
    { path: 'smsSetup', permissionName: 'ViewSMSSetup' },
    { path: 'receiveOrderSetup', permissionName: 'ViewReceiveOrderSetup' },
    { path: 'payerTemplate', permissionName: 'ViewPayerTemplate' },
    { path: 'medicalFileSetup', permissionName: 'ViewMedicalFileSetup' },
    { path: 'patientProblem', permissionName: 'ViewPatientProblem' },
    { path: 'doctorExperienceSetup', permissionName: 'ViewDoctorExperienceSetup' },

    // Radiology Setup
    { path: 'radiologyItemReport', permissionName: 'ViewRadiologyItemReport' },

    // ********Patient Billing Service********
    { path: 'doctorFeesStation', permissionName: 'ViewDoctorFeesStation' },
    { path: 'paymentTracking', permissionName: 'ViewPaymentTracking' },
    { path: 'scheduleAndAppointmentReport', permissionName: 'ViewScheduleAndAppointmentReport' },
    { path: 'appointmentListReport', permissionName: 'ViewAppointmentListReport' },


    { path: 'emergencyReports', permissionName: 'ViewEmergencyReports' },
    { path: 'outPatientReports', permissionName: 'ViewOutPatientReports' },

    { path: 'pharmacyReports', permissionName: 'ViewPharmacyReports' },
    { path: 'laboratoryReports', permissionName: 'ViewLaboratoryReports' },
    { path: 'radiologyReports', permissionName: 'ViewRadiologyReport' },
    { path: 'userReport', permissionName: 'ViewUserReport' },
    { path: 'smartconfig', permissionName: 'ViewSmartconfig' },
    { path: 'doctorsSpecialty', permissionName: 'ViewDoctorsSpecialty' },

    { path: 'billingReport', permissionName: 'ViewBillingReport' },
    { path: 'payerBalance', permissionName: 'ViewPatientBalance' },
    { path: 'passwordPolicy', permissionName: 'ViewPasswordPolicy' },
    { path: 'diagnosisGroups', permissionName: 'ViewDiagnosisGroups' },
    { path: 'operationReport', permissionName: 'ViewOperationReport' },
    { path: 'outPatientOrderManagement', permissionName: 'ViewOutPatientOrderManagement' },
    { path: 'doctorsCapacityManagement', permissionName: 'ViewDoctorsCapacityManagement' },

    // Certacure Setup

    { path: 'pblSetup', permissionName: 'ViewPBLSetup' },

    { path: 'cpoeSetup', permissionName: 'ViewCPOESetup' },

    { path: 'clinicalDocumentaionSetup', permissionName: 'ViewClinicalDocumentaionSetup' },
    { path: 'mrnSetup', permissionName: 'ViewMRNSetup' },
    { path: 'mobileSetup', permissionName: 'ViewMobileSetup' },
    { path: 'medicalFileSetup', permissionName: 'ViewmedicalFileSetup' },
    { path: 'printTemplateSetup', permissionName: 'ViewPrintTemplateSetup' },
    { path: 'admissionSetup', permissionName: 'ViewAdmissionSetup' },
    { path: 'specialityWidgetSetup', permissionName: 'ViewSpecialityWidgetSetup' },
    { path: 'eclaimSetup', permissionName: 'ViewEclaimSetup' },
    { path: 'appointmentSetup', permissionName: 'ViewAppointmentSetup' },
    { path: 'vitalSignSetup', permissionName: 'ViewVitalSignSetup' },

    // Billing Setup
    { path: 'cashierDiscount', permissionName: 'ViewCashierDiscount' },
    { path: 'configurationDetails', permissionName: 'ViewConfigurationDetails' },
    { path: 'cashierDefinition', permissionName: 'ViewCashierDefinition' },
    { path: 'elementConfiguration', permissionName: 'ViewElementConfiguration' },
    { path: 'visaType', permissionName: 'ViewVisaType' },
    { path: 'fund', permissionName: 'ViewFund' },
    { path: 'chargingSetup', permissionName: 'ViewChargingSetup' },
    { path: 'autoCashDiscountSetup', permissionName: 'ViewAutoCashDiscountSetup' },
    { path: 'billingSetupPage', permissionName: 'ViewBillingSetupPage' },
    { path: 'autoUpdatePatientVisitAndStatementSetup', permissionName: 'ViewAutoUpdatePatientVisitAndStatementSetup' },
    { path: 'documentAlertSetup', permissionName: 'ViewDocumentAlertSetup' },

    { path: 'doctorPerformanceDashboard', permissionName: 'ViewDoctorPerformanceDashboard' },
    { path: 'doctorsRevenueByElement', permissionName: 'ViewDoctorsRevenueByElementReport' },
    { path: 'patientStatementDetails', permissionName: 'ViewPatientStatementDetailReport' },
    { path: 'trackingMainFundReport', permissionName: 'ViewTrackingMainFundReport' },
    { path: 'doctorCommission', permissionName: 'ViewDoctorCommission' },
    { path: 'printerSetup', permissionName: 'ViewPrinterSetup' },
    { path: 'systemCurrencySetup', permissionName: 'ViewSystemCurrencySetup' },
    { path: 'linkServicesSectionsSetup', permissionName: 'ViewlinkServicesSectionsSetup' },

    // Oncology
    { path: 'oncologyLookupDefinition', permissionName: 'ViewOncologyLookupDefinition' },
    { path: 'oncologyProtocolDefinition', permissionName: 'ViewOncologyProtocolDefinition' },

    // Radiology Setup
    { path: 'radiologyTemplateSetup', permissionName: 'ViewRadiologyTemplateSetup' },
    { path: 'radiologySetup', permissionName: 'ViewRadiologySetup' },

    //Emergency Setup
    { path: 'triageScale', permissionName: 'ViewTriageScaleSetup' },

    //Pharmacy Setup
    { path: 'pharmacySetup', permissionName: 'ViewPharmacySetup' },
    { path: 'checkEligibility', permissionName: 'ViewCheckEligibility' },

    //Authorization Transaction
    { path: 'authorizationTransaction', permissionName: 'ViewAuthorizationTransaction' },

    { path: 'fdaSetup', permissionName: 'ViewFDASetup' },
    { path: 'discontinueTransferMedicationSetup', permissionName: 'ViewDiscontinueTransferMedicationSetup' },
    { path: 'operationSetup', permissionName: 'ViewOperationSetup' },
    { path: 'checkListDefinition', permissionName: 'ViewCheckListDefinition' },
    { path: 'instruments', permissionName: 'ViewInstruments' },
    { path: 'operationBeds', permissionName: 'ViewOperationBeds' },

    //Supply Chain
    { path: 'orderIssueStock', permissionName: 'ViewOrderIssueStock' },
    { path: 'orderReturnRequest', permissionName: 'ViewOrderReturnRequest' },
];
