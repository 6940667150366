import { Item } from 'app/shared/models/orders-and-billing/item';
import { Staff } from 'app/shared/models/orders-and-billing/staff.model';
import { Unit } from './unit';

export class PreAdmissionOrder {
    Id: number;
    ReservitionId: number;
    ItemId: number;
    ItemCode: string;
    ItemTitle: string;
    ItemCategoryId?: number;
    Quantity: number;
    ItemUnit?: number;
    DoctorId?: number;
    Status: number;
    DoctorNote: string;
    OrderIndex: number;

    Item: Item;
    Doctor: Staff; 
    Unit: Unit;
}
