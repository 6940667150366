import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Modals
import { MediSpanViewModel } from 'app/shared/models/medispan/medispan-view-model';
import { MediSpanDto } from 'app/shared/models/medispan/medispan-dto';
import { OverrideDrug } from 'app/shared/models/medispan/override-drug';
import { ResultType } from 'app/shared/models/medispan/enums/result-type';

// Services
import { MediSpanService } from 'app/shared/services';
import { BaseComponent } from 'app/shared/infrastructure';
import { ResultSeverity } from 'app/shared/models/medispan/enums/result-severity';
import { Subscription } from 'rxjs';

@Component({
    selector: 'medispan-result-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./medispan-result.scss'],
    templateUrl: './medispan-result.html',
})

export class MedispanResultComponent extends BaseComponent implements OnInit {

    mediSpanViewModel: MediSpanViewModel = new MediSpanViewModel();
    resultSeverity: typeof ResultSeverity = ResultSeverity;
    drugs: any[] = [];
    notes: any[] = [];
    note: string;
    finalStatusId: number;
    display: boolean = false;
    selectedDrugTitle: string;
    isLinkAvailable: boolean = false;
    moreDetailsHtml: SafeHtml;
    busy: Subscription;

    mediSpanDto: MediSpanDto = new MediSpanDto();
    overrideDrug: OverrideDrug = new OverrideDrug();
    @Output() OutMediSpanDto = new EventEmitter<any>();

    @Input() IsMaximaizable: boolean = true;

    resultType: typeof ResultType = ResultType;

    constructor(private _sanitizer: DomSanitizer, private _medspainService: MediSpanService) {
        super();
    }

    ngOnInit() {
    }

    showDialog(drug, drugTitle) {
        if(drug.finalStatus !== ResultSeverity.Success){
            const items: any[] = drug.severityResult;
            const haveMissingInfoMessage = drug.haveMissingInfoMessage;
            const missingInfoMessage = drug.missingInfoMessage;
            this.isLinkAvailable = false;
            if (items) {
                this.note = null;
                this.finalStatusId = null;
                this.notes = items;
                this.display = true;
                this.selectedDrugTitle = drugTitle;
            } else if (haveMissingInfoMessage) {
                this.notes = null;
                this.note = missingInfoMessage;
                this.display = true;
                this.finalStatusId = drug.finalStatus;
                this.selectedDrugTitle = drugTitle;
            }
        }
    }

    viewLinkDetails(item) {
        if(item.innerHtml){
            this.isLinkAvailable = true;
            this.moreDetailsHtml = this._sanitizer.bypassSecurityTrustHtml(item.innerHtml);
        }
    }

    getMedispanResults(mediSpanViewModel) {
        this.drugs = [];
        this._medspainService.getMediSpanResultUrl(mediSpanViewModel)
            .subscribe((data: any) => {

                if (data && data.Drugs) {
                    this.mediSpanDto = new MediSpanDto();
                    this.mediSpanDto.Drugs = data.Drugs;
                    this.mediSpanDto.OverrideDrugs = [];
                    this.mapResult(data.Drugs);
                    this.OutMediSpanDto.emit(this.mediSpanDto);
                }
            },
                error => this.showError(error));
    }

    mapResult(data) {
        data.forEach(element => {
            this.overrideDrug = new OverrideDrug();
            this.overrideDrug.DrugId = element.DrugId;
            this.overrideDrug.MedicationSource = element.MedicationSource;
            this.overrideDrug.IsOverride = false;
            this.overrideDrug.DrugTitle = element.DrugTitle;

            const resultByType = [];
            if (element.DrugToDrugInteractionResult) {
                const drugToDrugInteractionResult = this.addDrugByType(element.DrugToDrugInteractionResult, element.FoundGPI, ResultType.DrugToDrug);
                resultByType.push(drugToDrugInteractionResult);
            }

            if (element.DrugToFoodInteractionResult) {
                const drugToFoodInteractionResult = this.addDrugByType(element.DrugToFoodInteractionResult, element.FoundGPI, ResultType.DrugToFood);
                resultByType.push(drugToFoodInteractionResult);
            }

            if (element.DrugToAlcoholInteractionResult) {
                const drugToAlcoholInteractionResult = this.addDrugByType(element.DrugToAlcoholInteractionResult, element.FoundGPI, ResultType.DrugToAlcohol);
                resultByType.push(drugToAlcoholInteractionResult);
            }

            if (element.AllergyResult) {
                const allergyResult = this.addDrugByType(element.AllergyResult, element.FoundGPI, ResultType.Allergy);
                resultByType.push(allergyResult);
            }

            if (element.PregnancyResult) {
                const pregnancyResult = this.addDrugByType(element.PregnancyResult, element.FoundGPI, ResultType.Pregnancy);
                resultByType.push(pregnancyResult);
            }

            if (element.LactationResult) {
                const lactationResult = this.addDrugByType(element.LactationResult, element.FoundGPI, ResultType.Lactation);
                resultByType.push(lactationResult);
            }

            if (element.AgeResult) {
                const ageResult = this.addDrugByType(element.AgeResult, element.FoundGPI, ResultType.Age);
                resultByType.push(ageResult);
            }

            if (element.GenderResult) {
                const genderResult = this.addDrugByType(element.GenderResult, element.FoundGPI, ResultType.Gender);
                resultByType.push(genderResult);
            }

            if (resultByType.length > 0) {
                this.drugs.push({
                    id: element.DrugId,
                    title: element.DrugTitle,
                    resultsByType: resultByType,
                });
            }
        });
    }

    addDrugByType(element, isGPIFound, resultType) {
        const drug: any = {};
        drug.resultType = resultType;
        drug.finalStatus = isGPIFound ? element.SeverityId : ResultSeverity.MissingInfo;
        if(resultType === ResultType.DrugToDrug || resultType === ResultType.DrugToFood || resultType === ResultType.DrugToAlcohol || resultType === ResultType.Allergy){
            if (element && element.ResultDetails && element.ResultDetails.length > 0) {
                drug.severityResult = [];
                element.ResultDetails.forEach(item => {
                    drug.numberOfNotes = element.ResultDetails.length;
                    drug.severityResult.push({
                        status: item.SeverityId,
                        notes: item.Message,
                        innerHtml: item.ProfessionalHtml,
                    });
                });
            }
        }
        else{
            drug.severityResult = [];
            drug.severityResult.push({
                status: element.SeverityId,
                notes: element.Message,
                innerHtml: element.ProfessionalHtml,
            });
        }
        drug.haveMissingInfoMessage = element.HaveMissingInfoMessage;
        drug.missingInfoMessage = element.MissingInfoMessage;

        if (drug.finalStatus === ResultSeverity.Major) {
            this.mediSpanDto.OverrideDrugs.push({
                DrugId: this.overrideDrug.DrugId,
                MedicationSource: this.overrideDrug.MedicationSource,
                IsOverride: this.overrideDrug.IsOverride,
                ResultType: resultType,
                Comment: '',
                DrugTitle: this.overrideDrug.DrugTitle,
            });
        }
        return drug;
    }
}