import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { map } from 'rxjs';

@Injectable()

export class DoctorCommissionService extends ServiceBase {

    private actionUrl: string;
    private _get: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.doctorCommission.url;
    }

    get = (date, doctorId): Observable<any> => {
        const url = `${this._get}?date=${date}&doctorId=${doctorId || ''}`;

        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }
                    return response;
                }));
    }
}