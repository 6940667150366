import { PatientRegisterType } from '../enums';

export class PatientRegisterTypeLookup {

    static getLookup(): any[] {
        return [
            { label: 'Patient', value: PatientRegisterType.Patient },
            { label: 'Early Detection', value: PatientRegisterType.EarlyDetection },
            { label: 'Unknown Patient', value: PatientRegisterType.UnknownPatient },
            { label: 'Hospital Staff', value: PatientRegisterType.HospitalStaff },
            { label: 'Head Of Family', value: PatientRegisterType.HeadOfFamily }
        ];
    }
}
