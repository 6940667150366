import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { Staff } from 'app/shared/models/orders-and-billing/staff.model';
import { CoreApiConfiguration } from '../../core';

@Injectable()
export class StaffService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getCashiersUrl: string;
    private getStaffByQuery: string;
    private getCashiersByQueryUrl: string;
    private getByMedicalCategoryUrl:string;
    private getActiveDoctorStaffUrl: string;
    private getActiveStaffUrl: string;
    private getOrderedDoctor:string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration,private coreConfig:CoreApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.staff.url;
        this.getCashiersUrl = this.actionUrl + configuration.staff.getCashiersUrl;
        this.getStaffByQuery = this.actionUrl + configuration.staff.getByQueryUrl;
        this.getCashiersByQueryUrl = this.actionUrl + configuration.staff.getCashiersByQueryUrl;
        this.getByMedicalCategoryUrl = coreConfig.server + coreConfig.staff.staffByMedicalCategory;
        this.getActiveDoctorStaffUrl = coreConfig.server + coreConfig.staff.getActiveDoctorStaffUrl;
        this.getActiveStaffUrl = this.actionUrl + configuration.staff.getActiveStaff;
        this.getOrderedDoctor =coreConfig.server + configuration.staff.getOrderedDoctor;
    }

    getCashiers = (): Observable<Staff[]> => {
        return this.http.get<any>(this.getCashiersUrl);
    }

    getCashiersByQuery = (query: string): Observable<any> => {
        let url = `${this.getCashiersByQueryUrl}?query=${query}`;

        return this.http.get<any>(url);
    }

    getByQuery = (query: string): Observable<Staff[]> => {
        return this.http.get<any>(this.getStaffByQuery + '?query=' + query);

    }

    getById = (id: number): Observable<Staff> => {
        return this.http.get<any>(`${this.url}/ByStaffId?staffId=${id}`);
    }

    GetByMedicalCategory = (medicalCategoryCode: string): Observable<Staff> => {
        return this.http.get<any>(`${this.getByMedicalCategoryUrl}?medicalCategoryCode=${medicalCategoryCode}`);
    }

    getActiveDoctorStaff = (): Observable<Staff[]> => {
        return this.http.get<any>(this.getActiveDoctorStaffUrl);
    }

    getActiveStaff = (): Observable<Staff[]> => {
        return this.http.get<any>(this.getActiveStaffUrl);
    }

    getOrderedDoctors =():Observable<any[]> =>{
        return this.http.get<any>(this.getOrderedDoctor);
    }
}
