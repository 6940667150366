import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';


export class PhoneValidator {

  //public static validate = (country: string): ValidatorFn => {
  //  return (control: AbstractControl): { [key: string]: boolean } => {
  //    let v: string = control.value;
  //    return isValidNumber({phone: v, country}) ? null : {phone: true};
  //  };
  //}
}
