import { SelectItem } from 'primeng/api';
import { AuthorizationStatusType } from '../enums';

export class AuthorizationStatusTypeLookup {

    static get(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'New', value: AuthorizationStatusType.New },
            { label: 'Pending', value: AuthorizationStatusType.Pending },
            { label: 'Received', value: AuthorizationStatusType.Received },
            { label: 'Close', value: AuthorizationStatusType.Close },
        ];
    }
}
