import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'general-medical-shortcut-cmp',
    templateUrl: './general-medical-shortcut.html',
    styleUrls: ['general-medical-shortcut.scss']
})

export class GeneralMedicalShortcutComponent implements OnInit {
    @Input('patientId') patientId: number;

    patientRegistrationLink: any;

    constructor() {

    }
    ngOnInit() {

    }
}
