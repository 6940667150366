import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PayerReceipt } from 'app/shared/models/orders-and-billing/payer-receipt';
import { ReceiptFilter } from 'app/shared/models/orders-and-billing/receipt-filter';
import { ReceiptStatus } from 'app/shared/models/orders-and-billing/enums/receipt-status';

@Injectable()
export class PayerReceiptService extends ServiceBase {

    private actionUrl: string;
    private _payerReceiptByPayerSearch: string;
    private _getAll: string;
    private _post: string;
    private _chanegStatus: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._payerReceiptByPayerSearch = `${this.actionUrl}${_configuration.payerReceipt.payerReceiptByPayerSearch}`;
        this._getAll = `${this.actionUrl}${_configuration.payerReceipt.getAll}`;
        this._post = `${this.actionUrl}${_configuration.payerReceipt.post}`;
        this._chanegStatus = `${this.actionUrl}${_configuration.payerReceipt.changeStatus}`;
    }

    getPayerReceiptByPayerSearch = (payerId: number, page: number = 0): Observable<any> => {

        const queryString = `?payerId=${payerId}&page=${page}`;
        const url = `${this._payerReceiptByPayerSearch}${queryString}`;
        return this._http.get<any>(url);
    }

    getAll = (data: ReceiptFilter): Observable<any> => {
        const queryString = `?page=${data.page}&pageSize=${data.pageSize}&payerId=${data.payerId ? data.payerId : null}&receiptId=${data.receiptId ? data.receiptId : null}&status=${data.status ? data.status : null}&entryDate=${data.entryDate ? data.entryDate.toDateString() : null}`;
        const url = `${this._getAll}${queryString}`;
        return this._http.get<any>(url);
    }

    post = (item: PayerReceipt): Observable<any> => {
        return this._http.post<any>(this._post, item);
    }

    changeStatus = (payerReceiptId: number, oldStatus: ReceiptStatus, newStatus: ReceiptStatus): Observable<any> => {

        const item = {
            'PayerReceiptId': payerReceiptId,
            'OldStatus': oldStatus,
            'NewStatus': newStatus,
        };
        return this._http.post<any>(this._chanegStatus, item);
    }
}
