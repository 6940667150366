import { SelectItem } from 'primeng/api';
import { ClaimStatus } from '../enums/claim-status';

export class ClaimStatusLookUp {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Pending', value: ClaimStatus.Pending },
            { label: 'Sent', value: ClaimStatus.Sent },
            { label: 'Canceled', value: ClaimStatus.Canceled },
            { label: 'Received', value: ClaimStatus.Received }];
    }

    static getLookupForChangeStatus(): SelectItem[] {
        return [
            { label: 'Pending', value: ClaimStatus.Pending },
            { label: 'Sent', value: ClaimStatus.Sent },
            { label: 'Cancel', value: ClaimStatus.Canceled },
            { label: 'Received', value: ClaimStatus.Received }];
    }

    static getLookupWithoutCancelForChangeStatus(): SelectItem[] {
        return [
            { label: 'Pending', value: ClaimStatus.Pending },
            { label: 'Sent', value: ClaimStatus.Sent },
            { label: 'Received', value: ClaimStatus.Received }];
    }
}
