import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class MohContractSetupService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
    }
    
    public getByContractId = (contractId): Observable<any> => {
        return this._http.get<any>(`${this._actionUrl}/MOHContractSetup/GetByContractId?contractId=${contractId}`);
    }

    public getByClassId = (classId): Observable<any> => {
        return this._http.get<any>(`${this._actionUrl}/MOHContractSetup/GetByClassId?classId=${classId}`);
    }

    public addMohContractSetup = (data, contractId?, classId?): Observable<any> => {
        return this._http.post<any>(`${this._actionUrl}/MOHContractSetup/AddMohContractSetup?mohSetups=${data}&&contractId=${contractId}&&classId=${classId}`, data);
    }
}
