import { ApprovalService } from 'app/shared/models/orders-and-billing/approval-service';
import { ApprovalStatus } from './enums/approval-statuses';
import { Staff } from './staff.model';

export class Coverage {

    Id: number;
    ApprovalRequestId: number;
    ApprovalLetterNo: number;
    Remarks: string;
    PatientId: number;
    EncounterId: number;
    PayerId: number;
    ApprovalStartDate: Date;
    ApprovalEndDate: Date;
    CoverageLimit: number;
    CoveragePercentage: number;
    DischargeCoveragePercentage: number;
    AccClassId: number;
    Status: number = ApprovalStatus.Pending;;
    PatientType: number;
    PatientName: string;
    Mrn: number;
    ApprovalDate: Date;
    CoverageApprovalServices: ApprovalService[];
    CoverageApprovalSections: any[] = [];
    CoverageApprovalDiagnoses: any[] = [];
    InsuranceProvider: string;
    VisitDate: Date;
    Reason: string;
    ApprovalLastEndDate: Date;
    TransactionType: number;
    ModalTitle: string;
    TransferEncounterId: number;
    FacilityId: number;
    ClassId: number;
    ContractId: number;
    IsLongApproval: boolean;
    StaffCreatedBy: Staff = new Staff();
    CoverageClass: any;
    CoverageContract: any;
    PreApproval: boolean;
    Payer: any = {};
}
