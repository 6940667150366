import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class ApiConfiguration {
    server: string;

    constructor() {
        this.server = `${environment.notification}api`;
    }

    receiveNotification =
        {
            url: '/Notification',
            getLatestNotification: '/Notification/GetLatestNotification',
        };

    patientReleaseResult =
        {
            url: '/PatientReleaseResult',
            patientReleaseToday: '/PatientReleaseResult/GetPatientReleaseToday',
        };

    outPatientVisit =
        {
            url: '/OutPatientVisit',
            outPatientVisitToday: '/OutPatientVisit/OutPatientVisitToday',
            outPatientVisitHistory: '/OutPatientVisit/OutPatientVisitHistory',
        };
}
