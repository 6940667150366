import { SelectItem } from 'primeng/api';
import { PatientStatmentStatus } from 'app/shared/models/orders-and-billing/enums/patient-statment-status';

export class PatientStatmentStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: PatientStatmentStatus.Open },
            { label: 'Close', value: PatientStatmentStatus.Close },
            { label: 'Issue', value: PatientStatmentStatus.Issue },
            { label: 'Cancel', value: PatientStatmentStatus.Cancel },
            { label: 'Halt', value: PatientStatmentStatus.Halt },
        ];
    }

    static getLookupWithoutCancel(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: PatientStatmentStatus.Open },
            { label: 'Close', value: PatientStatmentStatus.Close },
            { label: 'Issue', value: PatientStatmentStatus.Issue },
            { label: 'Halt', value: PatientStatmentStatus.Halt },
            { label: 'Blocked', value: PatientStatmentStatus.Blocked },
        ];
    }

}
