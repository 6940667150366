import { VisitSource } from '../enums';
import { SelectItem } from 'primeng/api';

export class VisitSourceLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Er', value: VisitSource.Er.toString() },
            { label: 'Opd', value: VisitSource.Opd.toString() },
        ];
    }
}
