import { SelectItem } from 'primeng/api';
import { InvoiceReportType } from '../enums';

export class InvoiceReportTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Billing Credit Invoice En', value: InvoiceReportType.Billing_CreditInvoiceEn },
            { label: 'Billing Credit Invoice Details En', value: InvoiceReportType.Billing_CreditInvoiceDetailsEn },
            { label: 'Billing Statement Of Accounts', value: InvoiceReportType.Billing_StatementOfAccounts },
            { label: 'Billing Credit OrderSet Invoice En', value: InvoiceReportType.Billing_CreditOrderSetInvoiceEn },
            { label: 'Billing Credit Invoice Ar', value: InvoiceReportType.Billing_CreditInvoiceAr },
            { label: 'Billing Credit Invoice Details Ar', value: InvoiceReportType.Billing_CreditInvoiceDetailsAr },
            { label: 'Billing Credit Invoice Details Ar Summary', value: InvoiceReportType.Billing_CreditInvoiceDetailsArSummary },
            { label: 'Billing Credit OrderSet Invoice Ar', value: InvoiceReportType.Billing_CreditOrderSetInvoiceAr },
            { label: 'Billing Cash Invoice Ar', value: InvoiceReportType.Billing_CashInvoiceAr },
            { label: 'Billing Cash Invoice Details Ar', value: InvoiceReportType.Billing_CashInvoiceDetailsAr },
            { label: 'Billing Cash Invoice Details Ar Summary', value: InvoiceReportType.Billing_CashInvoiceDetailsArSummary },
            { label: 'Billing Cash Invoice En', value: InvoiceReportType.Billing_CashInvoiceEn },
            { label: 'Billing Cash Invoice Details En', value: InvoiceReportType.Billing_CashInvoiceDetailsEn },
        ];
    }
}
