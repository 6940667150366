export class SapAccountReceivableInvoiceSearchBase {
    mrn: string = null;
    billNo: number = null;
    patientName: string= null;
    patientType: number= null;
    payerId: number= null;
    dealingType: number= null;
    errorMessage: string= null;
    fromDate: Date= null;
    toDate: Date= null;


    public constructor(fields? : SapAccountReceivableInvoiceSearch) {
        if (fields) Object.assign(this, fields);
    }
}

export class SapAccountReceivableInvoiceSearch extends SapAccountReceivableInvoiceSearchBase {
    pageIndex: number = 0;
    pageSize: number = 10;

    public constructor(fields?: SapAccountReceivableInvoiceSearch) {
        super(fields);
        if (fields) {
            this.pageIndex = fields.pageIndex;
            this.pageSize = fields.pageSize;
        }
    }
}

