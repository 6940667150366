export class OrderActionLookupModel {
    Payers: OrderActionPayerLookup[];
    Staffs: OrderActionStaffLookup[];
    ServiceCategories: OrderActionServiceCategoryLookup[];
    Doctors: OrderActionStaffLookup[];
    BillElements: BillElementsLookup[];
    UnitsLookup: OrderBillingUnitLookup[];
    SectionsLookup: SectionsLookup[];
}

export class OrderActionPayerLookup {
    ID: number;
    TITLE: string;
}

export class OrderActionStaffLookup {
    ID: number;
    CODE: number;
    FIRST_NAME: string;
    LAST_NAME: string;
    IS_DOCTOR: number;
    FULL_NAME: string;
    AP_ID: string;
}

export class OrderActionServiceCategoryLookup {
    ID: number;
    CODE: number;
    TITLE: string;
    FOREIGN_TITLE: string;
    IS_ACTIVE: number;
}

export class OrderActionSearchModel {
    OrderActionId: number;
    OrderActionStatus: number;
    ServiceCategoryId: number;
    ServiceNote?: string;
    PatientID: number;
    OrderId: number;
    OrderDoctorId: number;
    BillServiceId: number;
    BillServiceQTY: number;
    BillServiceStatus: number;
    Description: string;
    PatientStatementId: number;
    PatientFullName: string;
    IssuedDate: Date;
    PayerId: number;
    PatientType: number;
    AdmissionId: number;
    DoctorId: number;
    VisitId: number;
    VisitDate: Date;
    Mrn: number;
    ApId: string;
    DealingTypeAdmission: number;
    DealingTypeVisit: number;
    PayerName: string;
    ServiceName: string;
    UnitCode: string;
    UnitId: number;
    ServiceStatus: string;
    OrderDate: Date;
    ElementId: number;
    ItemId: number;
    DischargeDate: Date;
    OrderBy: string;
    IssuedByName: string;
    DischargeByName: string;
    OrderByName: string;
    AdmissionDoctorId: number;
    AdmissionDoctorName: string;
    VisitDoctorId: number;
    VisitDoctorName: string;
    RowsCount: number;
    CancelledByName: string;
    DealingType: string;
    DoctorVisit: number;
    ServiceCode: string;
    PatientStatementStatus: number;
    IsExecludedItem: boolean;
    ModifiedBy: string;
    NetAmount: string;
    ReceiveStatus: number;
    CancelReason: string;
}

export class OrderActionSearchFilter {
    InvoiceId: number;
    EncounterId: number;
    PatientId: number;
    VisitSectionId: number;
    AdmissionDateFrom: Date;
    AdmissionDateTo: Date;
    DischargeDateFrom: Date;
    DischargeDateTo: Date;
    IssuedDateFrom: Date;
    IssuedDateTo: Date;
    IssuedBy: number;
    VisitDoctor: number;
    StatementStatus: number;
    DealingType: number;
    PayerId: number;
    VisitTypeId: number;
    ServiceCode: string;
    Unit: number;
    ServiceStatus: number;
    ServiceCategory: number;
    ServiceName: string;
    EncounterDateFrom: Date;
    EncounterDateTo: Date;
    ServiceDateFrom: Date;
    ServiceDateTo: Date;
    PageIndex: number;
    RowNumber: number;
    OrderId: number;
}

export class BillElementsLookup {
    CODE: number;
    DESCRIPTION: string;
    OTHER_DESCRIPTION: string;
}

export class OrderBillingUnitLookup {
    ID: number;
    CODE: string;
    DESCRIPTION: string;
}

export class SectionsLookup {
    ID: number;
    CODE: string;
    TITLE: string;
}

export class ServicesLookup {
    ID: number;
    CODE: string;
    TITLE: string;
}

export class AllServicesLookup {
    ID: number;
    CODE: string;
    TITLE: string;
    CATEGORY_ID: number;
    FOREIGN_TITLE: string;
}
