import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class MedispanServiceApiConfiguration {

    server: string;

    constructor() {
        this.server = environment.mediSpanUrl;
    }

    route = {
        url: 'Route',
        getAll: 'Route/All',
    };

    mediSpan = {
        url: 'MediSpan',
        mediSpanResult: 'MediSpan/MediSpanResult',
        mediSpanLog: 'MediSpan/MediSpanLog',
        mediSpanLogResult: 'MediSpan/MediSpanLogResult'
    };

    setting = {
        url: 'Setting',
        byGroupId: 'Setting/ByGroupId',
    };

    allergySeverity = {
        url: 'AllergySeverity',
        getAll: 'AllergySeverity/All',
    };
}
