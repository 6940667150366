import { SelectItem } from 'primeng/api';
import { OrderType } from '../enums';

export class OrderTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Physician Order', value: OrderType.physicianOrder },
            { label: 'Medication Profile', value: OrderType.medicationProfile },
        ];
    }
}
