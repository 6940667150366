import {
    PharmacyApiConfiguration,
    HospitalPharmacyService,
    PharmacyLookupCategoryService,
    PharmacyLookupService,
    PharmacyUserService,
    FrequencyService,
    MedicationConfigurationService,
    MedicationDefinitionService,
    DosesTimeService,
    MedicationProductService,
    PharmacyOrderService,
    PharmacyOrderActionService,
    PharmacyReturnRequestService,
    PharmacyFavoriteListService,
    DoctorPrescriptionService,
    HospitalPharmacySectionService,
    HighAlertService,
    MedicalLabelLogService,
    MedicalPrescriptionLogService,
    DoctorNoteService,
    DiscontinueTransferMedicationSetupService,
    BrandDoseService,
} from './services/pharmacy';


export const NGA_PHARMACY_SERVICES = [
    PharmacyApiConfiguration,
    HospitalPharmacyService,
    PharmacyLookupCategoryService,
    PharmacyLookupService,
    PharmacyUserService,
    FrequencyService,
    MedicationDefinitionService,
    DosesTimeService,
    MedicationConfigurationService,
    MedicationProductService,
    PharmacyOrderService,
    PharmacyOrderActionService,
    PharmacyReturnRequestService,
    PharmacyFavoriteListService,
    DoctorPrescriptionService,
    HospitalPharmacySectionService,
    HighAlertService,
    MedicalLabelLogService,
    MedicalPrescriptionLogService,
    DoctorNoteService,
    DiscontinueTransferMedicationSetupService,
    BrandDoseService,
];
