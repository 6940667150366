import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';

@Injectable()
export class AdmissionReportSetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.admissionReportSetup.url;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    getByPageId = (pageId): Observable<any> => {
        return this._http.get<any>(`${this._url}?pageId=${pageId}`);
    }
}
