import { SelectItem } from 'primeng/api';
import { PrintingDrugLabelByType } from '../enums';
export class PrintingDrugLabelByTypesLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Both', value: PrintingDrugLabelByType.Both },
            { label: 'By Item ', value: PrintingDrugLabelByType.ByItem },
            { label: 'By Order', value: PrintingDrugLabelByType.ByOrder },

        ];
    }
}
