import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PayerTreeNode } from "app/shared/models/orders-and-billing/payer-tree-node";
import { Payer, PayerBase } from "app/shared/models/orders-and-billing/payer";
import { SapPayer } from 'app/shared/models/orders-and-billing/sap-payer';

@Injectable()
export class PayerService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _byQuery: string;
    private _getChildPayers: string;
    private _activePayersSearch: string;
    private _getPayersContainContracts: string;
    private retrySapFailedPayersRegistrationsUrl: string;
    private getSapFailedPayersRegistrationsUrl: string;
    private _getPayersBySearchChild: string;
    private _getPayersHasParent: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.payer.url;
        this._byQuery = this._actionUrl + _configuration.payer.byQuery;
        this._getChildPayers = this._actionUrl + _configuration.payer.getChildPayers;
        this._getPayersBySearchChild = this._actionUrl + _configuration.payer.getPayersBySearchChild;


        this._activePayersSearch = this._actionUrl + _configuration.payer.activePayersSearch;
        this._getPayersContainContracts = this._actionUrl + _configuration.payer.getPayersContainContracts;
        this.getSapFailedPayersRegistrationsUrl =
        this._actionUrl + _configuration.payer.getSapFailedPayersRegistrationsUrl;
        this.retrySapFailedPayersRegistrationsUrl =
        this._actionUrl + _configuration.payer.retrySapFailedPayersRegistrationsUrl;
        this._getPayersHasParent = this._actionUrl + _configuration.payer.getPayersHasParent;
    }

    public getPayersTree = (): Observable<PayerTreeNode[]> => {
        return this._http.get<any>(this._url);
    }

    public getChildPayers = (parentId: number, title: string = null): Observable<PayerTreeNode[]> => {
        const url = `${this._getChildPayers}?parentId=${parentId || ''}&title=${title || ''}`;
        return this._http.get<any>(url);
    }

    public getPayerById = (id: number): Observable<Payer[]> => {
        return this._http.get<any>(this._url + '?id=' + id)
    }

    public getActivePayersSearch = (description: string, pageIndex?: number, pageSize?: number): Observable<Payer[]> => {
        return this._http.get<any>(this._activePayersSearch + '?description=' + description + '&pageIndex=' + pageIndex + '&pageSize=' + pageSize);
    }

    public getByQuery = (query: string): Observable<PayerBase[]> => {
        return this._http.get<any>(this._byQuery + '?query=' + query);
    }

    public getPayersContainContracts = (): Observable<Payer[]> => {
        return this._http.get<Payer[]>(this._getPayersContainContracts)
    }

    public insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    public payerBasicInfo = (title: string): Observable<any> => {
        return this._http.get<any>(this._url + "/PayerBasicInfo?title=" + title);
    }

    public getSapFailedPayersRegistrations = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapPayer[]> => {
        const url = `${this.getSapFailedPayersRegistrationsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedPayersRegistrations = (payersIds: number[]): Observable<any> => {
        return this._http.post<any>(this.retrySapFailedPayersRegistrationsUrl, JSON.stringify(payersIds));
    }

    public payerContainContract = (title: string): Observable<Payer[]> => {
        return this._http.get<any>(this._url + "/PayersContainContractsByQuery?payerName=" + title);
    }

    public payerReceiptsFromERP = (payerId: number): Observable<any> => {
        return this._http.get<any>(this._url + "/PayerReceipts?payerId=" + payerId);
    }

    public getPayersBySearchChild = (title: string = null): Observable<PayerTreeNode[]> => {
        const url = `${this._getPayersBySearchChild}?title=${title || ''}`;
        return this._http.get<any>(url);
    }

    public getPayersHasParent = (): Observable<Payer[]> => {
        return this._http.get<Payer[]>(this._getPayersHasParent)
    }

    public getActivePayers = (): Observable<any> => {
        return this._http.get<any>(this._url + "/ActivePayers");
    }
}
