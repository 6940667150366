import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class FacilityService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.facility.url;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }
    
    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
    getFacilityById = (facilityId: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/GetFacilityById?facilityId=${facilityId}`);
    }
}