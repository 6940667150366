import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class EducationalAttachmentService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    get = (): Observable<any> => {
        return this._http.get<any>(`${environment.core}EducationalAttachment`);
    }

    add = (data): Observable<any> => {
        return this._http.post<any>(`${environment.core}EducationalAttachment`, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(`${environment.core}EducationalAttachment`, data);
    }

    delete = (id): Observable<any> => {
        return this._http.delete<any>(`${environment.core}EducationalAttachment?id=${id}`)
    }

    getByIndex = (pageIndex): Observable<any> => {
        return this._http.get<any>(`${environment.core}EducationalAttachment?pageIndex=${pageIndex}`);
    }
}
