export class PhysicianDesktopPatientInfo {

    PatientId: number;
    PatientCode: string;
    PatientFullName: string;
    BirthDate: Date;
    Gender: number;
    Location: string;
    EncounterDate: Date;
    EncounterId: number;
    IsInfictious: boolean;
    HasAnyReleasedLabResult: boolean;
}
