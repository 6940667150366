import { SelectItem } from 'primeng/api';
import { ItemPriceApprovStatus } from '../enums';

export class ItemPriceApprovStatusLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Pending', value: ItemPriceApprovStatus.Pending },
            { label: 'Approve', value: ItemPriceApprovStatus.Approve },
            { label: 'Reject', value: ItemPriceApprovStatus.Reject },];
    }
}
