import { Result } from 'app/shared/models/medispan/result';
import { ResultDetail } from 'app/shared/models/medispan/result-detail';

export class LogDrugDto {
    DrugId: number;
    MedicationSource: number;
    DrugTitle: string;
    LogDate: Date;
    DrugToDrugInteractionResult: Result;
    DrugToFoodInteractionResult: Result;
    DrugToAlcoholInteractionResult: Result;
    AllergyResult: Result;
    PregnancyResult: ResultDetail;
    LactationResult: ResultDetail;
    AgeResult: ResultDetail;
    GenderResult: ResultDetail;
}