import { MicrobiologyPanel } from './microbiology-panel';
import { SensitiveMicrobiologyAnti } from './sensitiveMicrobiologyAnti';
import { SensitiveResult } from './sensitiveResult';

export class SensitiveMicrobiology {
    Id: number;
    SensitiveResultId: number;
    MicrobiologyPanelId: number;
    IsComplete: boolean;
    CompleteDate: Date;
    CompleteBy: number;
    IsRelease: boolean;
    ReleaseDate: Date;
    ReleaseBy: number;
    GrouthRate: number;
    ColonyCount: number;
    Remarks: string;
    SpecimenType?: number;
    IsInfictious: boolean;
    PatientName: string = '';
    MicrobiologyPanel: MicrobiologyPanel;
    SensitiveMicrobiologyAntis: SensitiveMicrobiologyAnti[];
    SensitiveResult: SensitiveResult;

    constructor() {

    }
}
