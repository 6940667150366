import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { NormalResultService } from 'app/shared/services';
import { Table } from 'primeng/table';
@Component({
    selector: 'lab-result-by-encounters-cmp',
    templateUrl: './lab-result-by-encounters.html'
})
export class LabResultByEncountersComponent extends BaseComponent {
    items: any[] = [];

    columns = [
        { field: 'TestName', header: 'Test Name' },
        { field: 'Result', header: 'Results' },
        { field: 'ReleaseDateFormat', header: 'Release Date' },
        { field: 'StaffName', header: 'Release By' },
    ];

    constructor(private normalResultService: NormalResultService, private _datePipe: DatePipe) {
        super();
    }

    get(patientId: number, encounterId: number, encounterIds: number[] = null, patientTypes: number[] = null) {
        this.items = [];
        if (encounterIds && patientTypes) {
            this.getLabResultsByEncounters(patientId, encounterIds, patientTypes);
        } else {
            this.getLabResults(patientId, encounterId);
        }
    }

    getLabResults(patientId, encounterId) {
        this.items = [];
        this.normalResultService.getLabResults(patientId, encounterId)
            .subscribe((unitDoseData: any[]) => {
                if (!unitDoseData) { return }
                this.items = unitDoseData;
                this.items.map(m => {
                    m.ReleaseDateFormat = this._datePipe.transform(m.ReleaseDate, 'dd/MM/yyyy');
                })
            }, error => this.showError(error));
    }

    getLabResultsByEncounters(patientId, encounterIds, patientTypes) {
        this.items = [];
        const body = {
            PatientId: patientId, EncounterIds: encounterIds, PatientTypes: patientTypes
        };
        this.normalResultService.getLabResultsByEncounters(body)
            .subscribe((data: any[]) => {
                if (!data) { return }
                this.items = data;
                this.items.map(m => {
                    m.ReleaseDateFormat = this._datePipe.transform(m.ReleaseDate, 'dd/MM/yyyy');
                })
            }, error => this.showError(error));
    }

    onFilterDate(event, field, dt: Table) {
        const selectedDate = this._datePipe.transform(event, 'dd/MM/yyyy');
        dt.filter(selectedDate, field, 'equals')
    }
}