import { Injectable } from '@angular/core';
import { ConfirmPasswordComponent } from 'app/shared';

@Injectable()
export class ConfirmPasswordService {

    appConfirmPassword: ConfirmPasswordComponent;

    confirm(): Promise<boolean> {
        return this.appConfirmPassword.confirm();
    }
}