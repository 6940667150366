import { SelectItem } from 'primeng/api';
import { MedicalSource } from '../enums';
export class MedicationSourceLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Select Medical Source', value: null },
            { label: 'Diagnosis', value: MedicalSource.Diagnosis },
            { label: 'Medication', value: MedicalSource.Medication },
            { label: 'Clinical Documentation', value: MedicalSource.ClinicalDocumentation},
            { label: 'Chief Complaint', value: MedicalSource.ChiefComplaint },
            {label: 'Vital Sign',value: MedicalSource.VitalSign}
        ];
    }
}
