import { SelectItem } from 'primeng/api';
import { ManagementLevel } from 'app/shared/models/medispan/enums/management-level';

export class ManagementLevelLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Potential Interaction Risk', value: ManagementLevel.PotentialInteractionRisk },
            { label: 'Professional Review Suggested', value: ManagementLevel.ProfessionalReviewSuggested },
            { label: 'Professional Intervention Required', value: ManagementLevel.ProfessionalInterventionRequired },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Potential Interaction Risk', value: ManagementLevel.PotentialInteractionRisk },
            { label: 'Professional Review Suggested', value: ManagementLevel.ProfessionalReviewSuggested },
            { label: 'Professional Intervention Required', value: ManagementLevel.ProfessionalInterventionRequired },
        ];
    }

}
