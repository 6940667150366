import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { ControlApiConfiguration } from '../apiConfiguration';
import { RoleViewModel } from '../../../models/control/role-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class RoleService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byQuery: string;
    private createRoleUrl: string;
    private deleteRoleUrl: string;
    results: any;

    constructor(private http: HttpClient, private configuration: ControlApiConfiguration, private router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.role.url;
        this.byQuery = this.actionUrl + configuration.role.byQuery;
        this.createRoleUrl = this.actionUrl + configuration.role.createRoleUrl;
        this.deleteRoleUrl = this.actionUrl + configuration.role.deleteRoleUrl;
        // this._register = this.actionUrl + configuration.user.register;
        // this._update = this.actionUrl + configuration.user.update;
    }

    getAll = (): Observable<RoleViewModel[]> => {
        return this.http.get<any>(this.url, { withCredentials: true });
    }

    getByQuery = (query): Observable<RoleViewModel[]> => {
        return this.http.get<any>(`${this.byQuery}?query=${query}`, { withCredentials: true });
    }

    createRole(roleName: string) {
        return this.http.post<any>(`${this.createRoleUrl}?roleName=${roleName}`, null, { withCredentials: true });
    }

    deleteRole(id: string) {
        return this.http.delete(`${this.deleteRoleUrl}?id=${id}`, { withCredentials: true });
    }
}
