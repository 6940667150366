import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { PatientComponentBase } from 'app/shared/infrastructure/patientComponentBase.component';

import { PatientVisitSearch } from "app/shared/models/queryModel/patient-visit-search";
import { Control } from 'app/shared/models/core/control';
import { Patient } from 'app/shared/models/core/patient.model';
import { PatientSources } from 'app/shared/models/core/enums/patient-sources';
import { MedicalCategory } from 'app/shared/models/core/enums/medical-category';
import { PatientVisitSearchService } from 'app/shared/services/queryModel/patient-visit-search';
import { LookUpService } from 'app/shared/services/core/lookup';

@Component({
    selector: 'advance-search-out-patient-station-cmp',
    styleUrls: ['./advanceSearchOutPatientStation.scss'],
    templateUrl: './advanceSearchOutPatientStation.html'
})

export class AdvanceSearchOutPatientStationComponent extends PatientComponentBase implements OnInit {
    @Output() onSearch = new EventEmitter<any>();

    patientVisitSearch: PatientVisitSearch = new PatientVisitSearch();

    clinics: SelectItem[];
    doctors: SelectItem[];

    maxDate: Date = new Date();
    haveVisitSearch: boolean = false;
    isEmptySearch: boolean = true;

    patients: Patient[] = [];
    autoCompleteDoctorObj: any;

    constructor(private patientVisitSearchService: PatientVisitSearchService, private lookUpService: LookUpService) {
        super();
    }

    ngOnInit() {
        this.patientVisitSearch = new PatientVisitSearch();
        this.getClinics();
    }

    onSubmit() {
        this.patientVisitSearch.SourceId = PatientSources.outPatientDepartment;
        this.patientVisitSearchService.getPatientVisit(this.patientVisitSearch)
            .subscribe((data: Patient[]) => {
                if (data) {
                    this.patients = data;
                }
                if (this.patientVisitSearch.PatientCode || this.patientVisitSearch.PatientName || this.patientVisitSearch.TelCode || this.patientVisitSearch.NationalCode || this.patientVisitSearch.VisitDate || this.patientVisitSearch.DoctorId || this.patientVisitSearch.SectionId) {
                    this.isEmptySearch = false;
                    if (this.patientVisitSearch.VisitDate || this.patientVisitSearch.DoctorId || this.patientVisitSearch.SectionId) {
                        this.haveVisitSearch = true;
                    }
                }
                this.onSearch.emit({ patientVisitSearchResult: this.patients, patientVisitSearch: this.patientVisitSearch, haveVisitSearch: this.haveVisitSearch, isEmptySearch: this.isEmptySearch });
            });
    }

    getClinics() {
        this.lookUpService.getOutPatientClinicsSectionByLoggedInNurse(MedicalCategory.OutPatientClinic.toString()).subscribe((data: Control[]) => {
            this.clinics = data.map(function (clinic: Control) {
                return { label: clinic.Title, value: clinic.Id };
            });
            this.clinics.unshift({ label: "All", value: null });
        }, error => this.showError(error));
    }

    disablePatientName() {
        return (this.patientVisitSearch.PatientCode != null && this.patientVisitSearch.PatientCode != "");
    }

    disablePatientCode() {
        return (this.patientVisitSearch.PatientName != null && this.patientVisitSearch.PatientName != "");
    }
    
    filterDoctorStaff(event) {
        const query = event.query;
        this.lookUpService.getFilteredDoctorStaff(query)
            .subscribe((data: Control[]) => {
                this.doctors = data.map(m => ({ label: m.Title, value: m.Id }));
            },
                error => this.showError(error));
    }

    setDoctorStaff(event) {
        this.patientVisitSearch.DoctorId = event.value;
    }

    checkIfValidDoctor(event) {
        if (!this.autoCompleteDoctorObj.value) {
            event.target.value = '';
            this.patientVisitSearch.DoctorId = null;
        }
    }
}
