import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

import { DoctorSettlement } from 'app/shared/models/orders-and-billing/doctor-settlement';

@Injectable()
export class DoctorSettlementService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private bySearchUrl: string;
    private cancelUrl: string;
    private approveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorSettlement.url;
        this.bySearchUrl = this.actionUrl + _configuration.doctorSettlement.bySearch;
        this.cancelUrl = this.actionUrl + _configuration.doctorSettlement.cancel;
        this.approveUrl = this.actionUrl + _configuration.doctorSettlement.approve;
    }

    getBySearch = (pageIndex: number, rowNo: number, doctorId?: number, fundId?: number, cashierId?: number, doctorSettlementStatus?: number, transactionDate?: Date): Observable<DoctorSettlement[]> => {

        const doctorIdValue = doctorId ? doctorId : null;
        const FundIdValue = fundId ? fundId : null;
        const cashierIdValue = cashierId ? cashierId : null;
        const doctorSettlementStatusValue = doctorSettlementStatus ? doctorSettlementStatus : null;
        var transactionDateValue = transactionDate ? transactionDate.toISOString() : null;

        return this._http.get<any>(this.bySearchUrl
            + '?doctorId=' + doctorIdValue
            + '&&fundId=' + FundIdValue
            + '&&cashierId=' + cashierIdValue
            + '&&doctorSettlementStatus=' + doctorSettlementStatusValue
            + '&&transactionDate=' + transactionDateValue
            + '&&pageIndex=' + pageIndex
            + '&&rowNo=' + rowNo
        )
    }

    add = (doctorSettlement: DoctorSettlement): Observable<any> => {
        return this._http.post<any>(this.url, doctorSettlement);
    }

    cancel = (doctorSettlementId: number): Observable<any> => {
        const url = `${this.cancelUrl}?doctorSettlementId=${doctorSettlementId}`;
        return this._http.put<any>(url, null)
    }

    approve = (doctorSettlementId: number): Observable<any> => {
        const url = `${this.approveUrl}?doctorSettlementId=${doctorSettlementId}`;
        return this._http.put<any>(url, null)
    }
}
