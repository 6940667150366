import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { OrganizationSectionFilter } from 'app/shared/models/core/organization-section-filter';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()

export class OrganizationSectionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _allUrl: string;
    private _inPatientSections: string;
    private _allActiveSections: string;
    private _getByPatientType: string;
    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.OganizationSection.url;
        this._allUrl = this.actionUrl + _configuration.OganizationSection.all;
        this._inPatientSections = this.actionUrl + _configuration.OganizationSection.inPatientSections;
        this._allActiveSections = this.actionUrl + _configuration.OganizationSection.allActive;
        this._getByPatientType = this.actionUrl + _configuration.OganizationSection.getByPatientType;
    }

    get = (): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(this._allUrl);
    }

    getInPatientSections = (): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(this._inPatientSections);
    }

    insert = (data: OrganizationSectionFilter): Observable<OrganizationSectionFilter> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: OrganizationSectionFilter): Observable<OrganizationSectionFilter> => {
        return this._http.put<any>(this._url, data);
    }

    getAllActive() {
        return this._http.get<any>(this._allActiveSections);
    }

    getByPatientType = (patientTypeId): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(`${this._getByPatientType}?patientTypeId=${patientTypeId}`)
    }

    filterOrganizationSections = (query): Observable<any[]> => {
        return this._http.get<any>(`${this._url}/FilteredOrganizationSections?query=${query}`)
    }
    
    getOutPatientSections = (): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(`${environment.core}OrganizationSection/OutPatientSections`);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(`${this._url}?id=${id}`)
    }
}
