import { SelectItem } from 'primeng/api';
import { PatientStationType } from '../enums/patient-station-type';
export class PatientStationTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Table', value: PatientStationType.Table },
            { label: 'Card', value: PatientStationType.Card },            
        ];
    }
}
