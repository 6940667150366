import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class RadiologyService extends ServiceBase {

    private actionUrl: string;
    private _radiology: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._radiology = this.actionUrl + _configuration.radiology.getRadiologyInfo;
    }

    getRadiologyInfo = (orderActionId = null, itemNumber = null): Observable<any> => {
        let queryString = '';
        queryString += `?orderActionId=${orderActionId ? orderActionId : ''}`;
        queryString += `&itemNo=${itemNumber ? itemNumber : ''}`;

        return this._http.get<any>(this._radiology + queryString);
    }
}