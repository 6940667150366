import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';


export class CreditCardValidator {

  public static validate:ValidatorFn = (control: AbstractControl): {[key: string]: boolean} => {
    
    let v: string = control.value;
    
    let sanitized = v.replace(/[^0-9]+/g, '');
    
    // problem with chrome
    
    if (!(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(sanitized))) {
      return {creditCard: true};
    }
    
    let sum = 0;
    let digit;
    let tmpNum;
    let shouldDouble;
    for (let i = sanitized.length - 1; i >= 0; i--) {
      digit = sanitized.substring(i, (i + 1));
      tmpNum = parseInt(digit, 10);
      if (shouldDouble) {
        tmpNum *= 2;
        if (tmpNum >= 10) {
          sum += ((tmpNum % 10) + 1);
        } else {
          sum += tmpNum;
        }
      } else {
        sum += tmpNum;
      }
      shouldDouble = !shouldDouble;
    }
    
    if (Boolean((sum % 10) === 0 ? sanitized : false)) {
      return null;
    }
    
    return {creditCard: true};
  };
}