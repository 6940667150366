import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CarePlanShortcut } from 'app/shared/models/core/care-plan-shortcut';
@Injectable()
export class CarePlanShortcutService extends ServiceBase {

    private actionUrl: string;
    private _search: string;
    private _getAllActive: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._search = this.actionUrl + _configuration.carePlanShortcut.search;
        this._getAllActive = this.actionUrl + _configuration.carePlanShortcut.getAllActive;
        this._url = this.actionUrl + _configuration.carePlanShortcut.url;
    }


    public search = (carePlanShortcut): Observable<CarePlanShortcut[]> => {
        let query = `${this._search}?PageIndex=${carePlanShortcut.pageNumber}&PageSize=${carePlanShortcut.rows}`;
        const title = carePlanShortcut.Title ? carePlanShortcut.Title : '';
        const patientNeeds = carePlanShortcut.PatientNeeds ? carePlanShortcut.PatientNeeds : '';
        const planningGoal = carePlanShortcut.PlanningGoal ? carePlanShortcut.PlanningGoal : '';
        const interventions = carePlanShortcut.Interventions ? carePlanShortcut.Interventions : '';
        const implementations = carePlanShortcut.Implementations ? carePlanShortcut.Implementations : '';
        const evaluation = carePlanShortcut.Evaluation ? carePlanShortcut.Evaluation : '';
        const isActive = carePlanShortcut.IsActive != null ? carePlanShortcut.IsActive : '';

        query += `&Title=${title}&PatientNeeds=${patientNeeds}&PlanningGoal=${planningGoal}&Interventions=${interventions}&Implementations=${implementations}&Evaluation=${evaluation}&IsActive=${isActive}`;
        return this._http.get<any>(query);
    }

    public toggleActive = (id: number, isToggle: boolean): Observable<any> => {
        return this._http.patch<any>(`${this._url}/${id}/${isToggle}`, null);
    }

    public insert = (data: CarePlanShortcut): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    public update = (data: CarePlanShortcut): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

}