import { SelectItem } from 'primeng/api';
import { EducationalAttachmentStatus } from '../enums';

export class EducationalAttachmentLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Active', value: EducationalAttachmentStatus.Active},
            { label: 'In Active', value: EducationalAttachmentStatus.InActive},
        ];
    }
}
