import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { PharmacyUser } from 'app/shared/models/pharmacy/pharmacy-user';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable'

@Injectable()
export class PharmacyUserService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _action: string;
    private getPharmacyUsersUrl: string;
    private getPharmacyUserByPharmacyUrl: string;
    private getDefaultPharmacyUrl: string;
    private getPharmacyStaffIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.pharmacyUser.url;
        this.getPharmacyUsersUrl = this.actionUrl + _configuration.pharmacyUser.getPharmacyUsersUrl;
        this.getPharmacyUserByPharmacyUrl = this.actionUrl + _configuration.pharmacyUser.getPharmacyUserByPharmacyUrl;
        this.getDefaultPharmacyUrl = this.actionUrl + _configuration.pharmacyUser.getDefaultPharmacyUrl;
        this.getPharmacyStaffIdUrl = this.actionUrl + _configuration.pharmacyUser.getPharmacyStaffIdUrl;
    }

    getAll = (): Observable<PharmacyUser[]> => {
        return this._http.get<any>(this.getPharmacyUsersUrl);
    }

    getByPharmacyId = (pharmacyId): Observable<PharmacyUser[]> => {
        return this._http.get<any>(this.getPharmacyUserByPharmacyUrl + '?pharmacyId=' + pharmacyId);
    }

    getDefaultPharmacy = (): Observable<PharmacyUser> => {
        const url = `${this.getDefaultPharmacyUrl}`;

        return this._http.get<any>(url);
    }


    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    delete = (id): Observable<any> => {
        return this._http.delete(this._url + '?id=' + id);
    }

    getPharmacyStaffId = (pharmacyId?: number): Observable<any> => {
        return this._http.get<any>(this.getPharmacyStaffIdUrl + '?pharmacyId=' + pharmacyId);
    }
}
