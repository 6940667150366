import { SelectItem } from 'primeng/api';
import { ReceiveOrderStatus } from '../enums/receive-order-status';

export class ReceiveOrderStatusLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'Order', value: ReceiveOrderStatus.Order },
            { label: 'Received', value: ReceiveOrderStatus.Received },
            { label: 'Completed', value: ReceiveOrderStatus.Completed },
        ];
    }

    static getAllLookupWithAll(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Order', value: ReceiveOrderStatus.Order },
            { label: 'Received', value: ReceiveOrderStatus.Received },
            { label: 'Completed', value: ReceiveOrderStatus.Completed },
        ];
    }

    static getInpatientLookupForMedicationCategory(): SelectItem[] {
        return [
            { label: 'Order', value: ReceiveOrderStatus.Order },
            { label: 'Received', value: ReceiveOrderStatus.Received },
        ];
    }
}
