import { SelectItem } from 'primeng/api';
import { TimeType } from '../enums';
export class TimeTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Hours', value: TimeType.Hour },
            { label: 'Minutes', value: TimeType.Minute },
        ];
    }

    static getLookupWithDay(): SelectItem[] {
        return [
            { label: 'Hours', value: TimeType.Hour },
            { label: 'Minutes', value: TimeType.Minute },
            { label: 'Day', value: TimeType.Day },
        ];
    }

    static getTitleById(id){
        return this.getLookup().find(f => f.value === id)?.label || '';
    }
}
