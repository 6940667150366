import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PriceListItemPerStaffService extends ServiceBase {

    private actionUrl: string;

    constructor(private http: HttpClient, configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
    }

    get = (id: any, status: any = null, pageIndex: number = 0, rows: number = 10): Observable<any> => {
        return this.http.get<any>(`${this.actionUrl}PriceListItemPerStaff?id=${id}&pageIndex=${pageIndex}&rows=${rows}&status=${status}`);
    }

    add(data: any) {
        return this.http.post<any>(`${this.actionUrl}PriceListItemPerStaff`, JSON.stringify(data));
    }

    update(data: any) {
        return this.http.put<any>(`${this.actionUrl}PriceListItemPerStaff`, JSON.stringify(data));
    }
}
