import { SelectItem } from "primeng/api";
import { LISLabOrderSendModes } from "../enums";

export class LISLabOrderSendModesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Accepted', value: LISLabOrderSendModes.Accepted },
            { label: 'Ordered', value: LISLabOrderSendModes.Ordered },
        ];
    }
}