import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { StaffCategory } from '../../models/orders-and-billing/staff-category.model';
@Injectable()
export class StaffCategoryService extends ServiceBase {

    private actionUrl: string;
    private postUrl: string;
    private deleteUrl: string;
    private getByStaff: string;
    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.postUrl = `${this.actionUrl}/StaffCategory/NewStaffCategory`;
        this.deleteUrl = `${this.actionUrl}/StaffCategory/ExecludeCategory`;
        this.getByStaff = `${this.actionUrl}/StaffCategory/ByStaff`;
    }

    addStaffCategory = (data: StaffCategory): Observable<any> => {
        return this._http.post<any>(this.postUrl, data);
    }

    deleteStaffCategory = (staffCategoryId: number): Observable<any> => {
        return this._http.delete(`${this.deleteUrl}?staffCategoryId=${staffCategoryId}`);
    }

    getStaffCategories = (): Observable<StaffCategory[]> => {
        return this._http.get<any>(this.getByStaff);
    }


}

