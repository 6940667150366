import { CloseInvoiceTransactionStatus } from '../enums';

export class CloseInvoiceTransactionStatusLookup {

    static get(): any[] {
        return [
            { label: 'All', value: null },
            { label: 'New', value: CloseInvoiceTransactionStatus.New },
            { label: 'Under Process', value: CloseInvoiceTransactionStatus.UnderProcess },
            { label: 'Close', value: CloseInvoiceTransactionStatus.Close }];
    }
}
