import { SelectItem } from 'primeng/api';
import { PlagSeverity } from 'app/shared/models/medispan/enums/plag-severity';

export class PlagSeverityLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Contraindicated', value: PlagSeverity.Contraindicated },
            { label: 'Not Recommended', value: PlagSeverity.NotRecommended },
            { label: 'Extreme Caution', value: PlagSeverity.ExtremeCaution },
            { label: 'Use Cautiously', value: PlagSeverity.UseCautiously },
            { label: 'Informational', value: PlagSeverity.Informational },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Contraindicated', value: PlagSeverity.Contraindicated },
            { label: 'Not Recommended', value: PlagSeverity.NotRecommended },
            { label: 'Extreme Caution', value: PlagSeverity.ExtremeCaution },
            { label: 'Use Cautiously', value: PlagSeverity.UseCautiously },
            { label: 'Informational', value: PlagSeverity.Informational },
        ];
    }
}
