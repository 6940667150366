import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable';

import { PharmacyApiConfiguration } from '../apiConfiguration';
import { MedicalPrescriptionLog } from 'app/shared/models/pharmacy/medical-prescription-log';

@Injectable()
export class MedicalPrescriptionLogService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private http: HttpClient, private configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.medicalPrescriptionLog.url;
    }

    StoreOrderActionIdsForPrintPrescription(medicalPrescriptionLog: MedicalPrescriptionLog): Observable<number> {
        return this.http.post<any>(this.url, medicalPrescriptionLog);
    }
}