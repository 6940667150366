import { SelectItem } from 'primeng/api';
import { AgeTypes } from '../enums';
export class AgeTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Day', value: AgeTypes.Day },
            { label: 'week', value: AgeTypes.Week },
            { label: 'Month', value: AgeTypes.Month },
            { label: 'Year', value: AgeTypes.Year }
        ];
    }
}
