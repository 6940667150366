import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { TpaApprovalErrorInfo } from 'app/shared/models/queryModel/tpa-approval-error-info';
import { TpaApprovalNotMappedItem } from 'app/shared/models/queryModel/tpa-approval-not-mapped-item';
@Injectable()
export class ApprovalService extends ServiceBase {

    private actionUrl: string;
    private tpaApprovalErrorInfoUrl;
    private tpaApprovalNotMappedItemUrl;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.tpaApprovalErrorInfoUrl = this.actionUrl + _configuration.approval.tpaApprovalErrorInfo;
        this.tpaApprovalNotMappedItemUrl = this.actionUrl + _configuration.approval.tpaApprovalNotMappedItem
    }

    getTpaApprovalErrorInfo = (approvalId: any): Observable<TpaApprovalErrorInfo> => {
        const url = `${this.tpaApprovalErrorInfoUrl}?approvalId=${approvalId || ''}`;

        return this._http.get<any>(url);
    }

    getTpaApprovalNotMappedItem = (approvalId: any): Observable<TpaApprovalNotMappedItem[]> => {
        const url = `${this.tpaApprovalNotMappedItemUrl}?approvalId=${approvalId || ''}`;
        return this._http.get<any>(url);
    }

}