import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PatientTransferSearchService } from "../../../services/queryModel/patientTransferSearch";
import { LookUpService } from "../../../services/core/lookup";
import { RoomService } from "../../../services/admissionService/room";
import { PatientTransferSearchFilter } from "../../../models/queryModel";
import { MedicalCategory } from 'app/shared/models/admission/enums/medical-category';
@Component({
    selector: 'advance-search-transfer-cmp',
    styleUrls: ['./advanceSearchTransfer.scss'],
    templateUrl: './advanceSearchTransfer.html'
})

export class AdvanceSearchTransferComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();
    patientTransferSearchFilter: PatientTransferSearchFilter = new PatientTransferSearchFilter();
    sections: any;
    admissionDoctors: any;
    rooms;
    constructor(private _patientTransferSearchService: PatientTransferSearchService, private _lookUpService: LookUpService, private _roomService: RoomService) {

    }
    ngOnInit() {
        this.getSections();
        this.getAdmissionDoctor();
        this.getRooms();
    }

    getSections() {
        this._lookUpService.getSectionByMedicalCategory(MedicalCategory.InPatienFloor)
            .subscribe((data: any[]) => {
                this.sections = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        this.sections.push({ label: data[i].Title, value: data[i].Id });
                    }
                }

            });
    }

    getAdmissionDoctor() {
        this._lookUpService.getDoctorStaff()
            .subscribe((data: any) => {
                this.admissionDoctors = [];
                for (let i = 0; i < data.length; i++) {
                    this.admissionDoctors.push({ label: data[i].Title, value: data[i].Id });
                }
            });

    }

    getRooms() {
        this._roomService.getAll()
            .subscribe((data: any) => {
                this.rooms = [];
                for (let i = 0; i < data.length; i++) {
                    this.rooms.push({ label: data[i].Code, value: data[i].Id });
                }
            });

    }

    onSubmit() {
        this._patientTransferSearchService.getSearchResult(this.patientTransferSearchFilter)
            .subscribe((data) => {
                this.onSearch.emit({ patientTransferSearchResult: data });
            });
    }
}
