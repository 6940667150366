import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { BillSerial } from '../../../models/orders-and-billing/bill-serial';
import { BillElement } from 'app/shared/models/orders-and-billing/bill-element';

@Injectable()
export class BillElementService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getAllElement: string;
    private _allActiveElementUrl: string;
    private _serviceHaveBillElement: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.billElement.url;
        this._getAllElement = this.actionUrl + _configuration.billElement.getAllElement;
        this._allActiveElementUrl = this.actionUrl + _configuration.billElement.allActiveElement;
        this._serviceHaveBillElement = this.actionUrl + _configuration.billElement.serviceHaveBillElement;
    }

    get = (): Observable<BillElement[]> => {
        return this._http.get<any>(this.url);
    }

    getAllElement() {
        return this._http.get<any>(this._getAllElement);
    }

    getAllActiveElement = (): Observable<BillElement[]> => {
        return this._http.get<any>(this._allActiveElementUrl);
    }

    serviceHaveBillElement = (itemId: number, sectionId: number): Observable<boolean> => {
        return this._http.get<any>(this._serviceHaveBillElement+ `?itemId=${itemId}&&sectionId=${sectionId}`);
    }
}
