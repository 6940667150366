import { SelectItem } from 'primeng/api';
import { LandingPageType } from '../enums';

export class LandingPageTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Metro', value: LandingPageType.Metro },
            { label: 'Widget', value: LandingPageType.Widget },
        ];
    }
}
