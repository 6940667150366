import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PriceListItemService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private http: HttpClient, configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.priceListItem.url;
        this.postUrl = this.actionUrl + configuration.priceListItem.url;
        this.putUrl = this.actionUrl + configuration.priceListItem.url;
        this.deleteUrl = this.actionUrl + configuration.priceListItem.url;
    }

    get = (priceListId: any, itemId: any, page: any, pageSize: any, categoriesId: any): Observable<any> => {
        let queryString = `?page=${page}&rows=${pageSize}&categoriesId=${categoriesId}`;
        if (priceListId) {
            queryString += `&plId=${priceListId}`;
        }
        if (itemId !== undefined && itemId !== null) {
            queryString += `&itemId=${itemId}`;
        }
        return this.http.get<any>(this.getUrl + queryString);
    }

    post(data: any) {
        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }

    put(data: any) {
        return this.http.put<any>(this.putUrl, JSON.stringify(data));
    }

    delete(id: number) {
        return this.http.delete(`${this.deleteUrl}/${id}`);
    }
}
