import { SelectItem } from 'primeng/api';
import { PayerContractDocumentTypes } from '../enums';

export class PayerContractDocumentTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'PRC', value: PayerContractDocumentTypes.PRC },
            { label: 'PPN', value: PayerContractDocumentTypes.PPN },
            { label: 'NI', value: PayerContractDocumentTypes.NI },
            { label: 'VP', value: PayerContractDocumentTypes.VP },
            { label: 'MR', value: PayerContractDocumentTypes.MR },
        ];
    }
}
