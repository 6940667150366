import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { CommentTemplate } from '../../../models/lab/commentTemplate';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class CommentTemplateService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getBySectionId: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.commentTemplate.url;
        this._getBySectionId = this.actionUrl + _configuration.commentTemplate.getBySectionId;
    }

    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getById = (id): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.get<any>(url);
    }

    getBySectionId = (id): Observable<any> => {
        const url = `${this._getBySectionId}?sectionId=${id}`;
        return this._http.get<any>(url);
    }

    add = (data: CommentTemplate): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    put = (id: number, data: CommentTemplate): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.put<any>(url, JSON.stringify(data))
    }

    delete = (id: number): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }
}