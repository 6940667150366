import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemInstruction } from 'app/pages/control/components/systemParameter/components/item-instruction/item-instruction';

@Injectable()
export class ItemInstructionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    currentItemSet: Item[];

    constructor(private _http: HttpClient, _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.itemInstruction.url;
    }

    get = (pageNo, rowNo, code_Id?: string, title?: string, foreignTitle?: string, itemInstruction?: string, categoryTypeId?: number): Observable<any> => {
        code_Id = code_Id ? code_Id : '';
        title = title ? title : '';
        foreignTitle = foreignTitle ? foreignTitle : '';
        itemInstruction = itemInstruction ? itemInstruction : '';
        categoryTypeId = categoryTypeId ? categoryTypeId : 0;
        const url = `${this._url}?code_Id=${code_Id}&title=${title}` +
            `&foreignTitle=${foreignTitle}&itemInstruction=${itemInstruction}&categoryTypeId=${categoryTypeId}&pageNo=${pageNo}&rowNo=${rowNo}`;
        return this._http.get<any>(url);
    }

    update(data: ItemInstruction) {
        return this._http.put<any>(this._url, data);
    }

    Add(data: ItemInstruction) {
        return this._http.post<any>(this._url, data);
    }

    getById = (id: number): Observable<ItemInstruction> => {
        return this._http.get<any>(this._url + `/ById?id=${id}`);
    }
    getRadiologyTemplate(itemCode:number){
        return this._http.get<any>(this._url + `/ByItemCode?code=${itemCode}`);
    }
}
