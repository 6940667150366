import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, Self } from '@angular/core';
import { FadeInOut } from 'app/shared/animation';

@Component({
  selector: 'ba-slider',
  templateUrl: './baSlider.html',
  animations: [FadeInOut]
})

export class BaSlider implements AfterViewInit {

  @Input() images: any[] = [];
  selectedImages: any[];
  count = 3;
  currentIndex = 0;
  interval;

  ngAfterViewInit(): void {
    this.handleImage();
    this.imageInterval();
  }

  imageInterval() {
    this.interval = setInterval(() => {
      this.handleImage();
    }, 20000);
  }

  handleImage() {
    this.selectedImages = [];
    for (let i = 0; i < this.count; i++) {
      let index = (this.currentIndex + i) % this.images.length;
      this.selectedImages.push(this.images[index]);
    }
    this.currentIndex = (this.currentIndex + this.count) % this.images.length;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }
}
