import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ItemSearch } from 'app/shared/models/pharmacy/item-search';
import { POMSMedication } from 'app/shared/models/pharmacy/poms-medication';

@Injectable()
export class DoctorPrescriptionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    private searchItems: string;
    private favoriteItems: string;
    private pomsMedicationsUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.doctorPrescription.url;
        this.searchItems = this.actionUrl + _configuration.doctorPrescription.SearchItems;
        this.pomsMedicationsUrl = this.actionUrl + _configuration.doctorPrescription.pomsMedications;
    }

    getItems = (itemSearchQuery: string): Observable<ItemSearch[]> => {

        return this._http.get<any>(`${this.searchItems}?query=${itemSearchQuery}`);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    getPOMSMedications = (patientId: number, encounterId: number, patientTypeId: number): Observable<POMSMedication[]> => {
        return this._http.get<any>(`${this.pomsMedicationsUrl}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`
        );
    }
}
