import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {

    @ViewChild('modal') modal: ModalDirective;
    onConfirm: Subject<boolean> = new Subject();
    options: any;

    onToggleConfirm(value) {
        this.onConfirm.next(value);
        this.modal.hide();
    }

    confirm(options): Promise<boolean> {
        this.options = options;
        this.modal.show();
        return firstValueFrom(this.onConfirm);
    }
}
