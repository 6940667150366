import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { environment } from 'environments/environment';

@Injectable()
export class ExperienceSetupService {

    constructor(private _http: HttpClient) {
    }

    get = (): Observable<any[]> => {
        return this._http.get<any>(`${environment.core}/ExperienceSetup`);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.core}/ExperienceSetup`, data);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(`${environment.core}/ExperienceSetup`, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${environment.core}/ExperienceSetup?id=${id}`);
    }

}
