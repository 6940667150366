import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class NurseApiConfiguration {
    server: string;

    constructor() {
        this.server = environment.nurse;
    }

    assignTask = {
        url: 'assignTask',
        calculateTimePerDay: 'assignTask/calculateTimePerDay',
        insertTaskById: 'assignTask/InsertTaskById',
    };

    assignTaskDetails = {
        url: 'assignTaskDetails',
    };
}
