import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ApprovalAndStaffNote } from 'app/shared/models/orders-and-billing/approval-and-staff-note';

@Injectable()
export class ApprovalAndStaffNoteService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.approvalAndStaffNote.url;
        this.postUrl = this.actionUrl + _configuration.approvalAndStaffNote.url;
    }

    post = (data: ApprovalAndStaffNote): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    getByFilter = (senderId, receiverId, approvalNo): Observable<ApprovalAndStaffNote[]> => {
        return this._http.get<ApprovalAndStaffNote[]>(`${this.getUrl}?senderId=${senderId}&receiverId=${receiverId}&approvalNo=${approvalNo}`);
    }
}
