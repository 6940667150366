import { Injectable } from '@angular/core';

import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { DimensionServiceApiConfiguration } from '../dimension-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';

@Injectable()
export class SubmissionTransactionsService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private submissionByClaimUrl: string;
    private submissionByStatmentUrl: string;
    private cancelCalimInvoiceUrl: string;

    constructor(private _http: HttpClient, private _configuration: DimensionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.serverUrl;
        this.url = this.actionUrl + _configuration.submissionClaim.url;
        this.submissionByClaimUrl = this.actionUrl + _configuration.submissionClaim.submissionByClaim;
        this.submissionByStatmentUrl = this.actionUrl + _configuration.submissionClaim.submissionByStatment;
        this.cancelCalimInvoiceUrl = this.actionUrl + _configuration.submissionClaim.cancelCalimInvoice;
    }
    

    submissionByClaim = (claimId: number): Observable<any> => {
        return this._http.post<any>(this.submissionByClaimUrl, JSON.stringify(claimId));
    }

    submissionByStatment = (statementId: number): Observable<any> => {
        return this._http.post<any>(this.submissionByStatmentUrl, JSON.stringify(statementId));
    }

    cancelCalimInvoice = (statementId: number): Observable<any> => {
        return this._http.post<any>(this.cancelCalimInvoiceUrl, JSON.stringify(statementId));
    }

}
