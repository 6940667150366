import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class ApprovalInsurance extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.insurance.url;
        this.postUrl = this.actionUrl + _configuration.insurance.url;
        this.putUrl = this.actionUrl + _configuration.insurance.url;
        this.deleteUrl = this.actionUrl + _configuration.insurance.url;
    }

    get = (): Observable<any> => {
        return null;
    }
    // handel Coverage-letter 
    newApproval = (data: any): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    getApprovalById = (id): Observable<any> => {
        return this._http.get<any>(this.getUrl + '/?id=' + id);
    }

    /// handel Approval follow up functionality

    getAllApprovals = (): Observable<any> => {
        return this._http.get<any>(this.getUrl);
    }

    approvalsbyFilter = (pageNo, rowNo, status?: number, patientCode?: string, startDate?: Date, endDate?: Date, approvalPatientType?: number, encounterId?: any, payerId: number = null, sectionIds = []): Observable<any> => {
        const start = startDate ? startDate.toISOString() : '';
        const end = endDate ? endDate.toISOString() : '';
        patientCode = patientCode ? patientCode : '';
        const strStatus = status ? status : '';
        const url = `${this.getUrl}/ApprovalsByFilter?status=${strStatus}&patientCode=${patientCode}` +
            `&startDate=${start}&endDate=${end}&pageNo=${pageNo}&rowNo=${rowNo}&approvalPatientType=${approvalPatientType}&&` + `
            encounterId=${encounterId}&payerId=${payerId || ''}&sectionIds=${sectionIds || []}`;
        return this._http.get<any>(url);
    }

    // handel change status from pending to approved
    ApprovedApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/ApprovedApprovalById?id=' + id, null);
    }

    // handel reject approve 
    RejectApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/RejectApprovalById?id=' + id, null);
    }

    // handel re open 
    // handel reject approve 
    ReOpenApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/ReOpenApprovalById?id=' + id, null);
    }

    // handel update approve 
    UpdateApproval = (approval): Observable<any> => {
        return this._http.put<any>(this.putUrl, approval);
    }

    ExtendApproval = (approval): Observable<any> => {
        return this._http.put<any>(`${this.putUrl}/Extend`, approval);
    }

    TransferApproval = (approval): Observable<any> => {
        return this._http.put<any>(`${this.putUrl}/Transfer`, approval);
    }

    getApprovalLog = (id: number): Observable<any> => {
        return this._http.get<any>(`${this.getUrl}/ApprovalLogById?id=${id}`);
    }
    cancelApproval = (id: number): Observable<any> => {
        return this._http.put<any>(`${this.getUrl}/CancelApproval?id=${id}`, null);
    }

    addAttachment = (approval): Observable<any> => {
        return this._http.post<any>(`${this.postUrl}/AddAttachment`, approval);
    }

    getAttachments = (approvalId): Observable<any> => {
        return this._http.get<any>(`${this.getUrl}/GetAttachments?id=${approvalId}`);
    }

    linkPreApproval = (value): Observable<any> => {
        return this._http.put<any>(`${environment.order}CoverageAproval/LinkPreApproval`, value);
    }

}
