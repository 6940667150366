
export class ConsultationTypes {
    Id: number;
    FacilityId: number;
    Code: number;
    Title: string;
    ForeignTitle: string;
    Status: boolean;
    ServiceId: number;
    VisitType: number;
    VisitSource: number;
    constructor() {
    }
}