import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class PharmacyApiConfiguration {

    server: string;

    constructor() {
        this.server = environment.pharmacy;
    }

    dosesTime = {
        url: 'DosesTime',
        getAllUrl: 'DosesTime/All',
        getByFrequencyIdUrl: 'DosesTime/ByFrequencyId',
    };

    highAlertLog = {
        url: 'HighAlertLog',
        insertBulk: 'HighAlertLog/InsertBulk',
    };

    frequency = {
        url: 'Frequency',
        getAllByActiveFilterUrl: 'Frequency/AllByActiveFilter',
        getByIdUrl: 'Frequency/ById',
    };

    hospitalPharmacy = {
        url: 'HospitalPharmacy',
        getByIdUrl: 'HospitalPharmacy/ById',
        getActivePharmaciesUrl: 'HospitalPharmacy/Active',
        activePharmaciesByPharmacyType: 'HospitalPharmacy/ActivePharmaciesByPharmacyType',
        getActiveAuthPharmaciesUrl: 'HospitalPharmacy/ActiveAuth',
        getDefaultActiveAuthPharmacies: 'HospitalPharmacy/DefaultActiveAuth',
    };

    hospitalPharmacySection = {
        url: 'HospitalPharmacySection',
    };
    lookupCategory = {
        url: 'LookupCategory',
    };

    lookup = {
        url: 'Lookup',
        getByCategoryId: 'Lookup/getByCategoryId',
        getById: 'Lookup/getById',
        lookUpByCategoryIdAndQuery: 'Lookup/LookUpByCategoryIdAndQuery',
        getLookupUrl: 'Lookup/Filter',
    };

    medicationConfiguration = {
        url: 'MedicationConfiguration',
        getByGenericAndCategory: 'MedicationConfiguration/ByGenericAndCategory',
        getById: 'MedicationConfiguration/ById',
    };

    favoriteList = {
        url: 'FavoriteList',
        addList: 'FavoriteList/AddList',
        favoriteItems: 'FavoriteList/UserFavorites',
    };

    doctorPrescription = {
        url: 'DoctorPrescription',
        SearchItems: 'DoctorPrescription/SearchItems',
        pomsMedications: 'DoctorPrescription/POMSMedications'
    };

    doctorNote = {
        url: 'PharmacyAndDoctorNote',
    };

    medicationDefinition = {
        url: 'MedicationDefinition',
        getMedicationDefinitionGroup: 'MedicationDefinition/MedicationDefinitionGroup',
        getByFilter: 'MedicationDefinition/ByFilter',
        getByMedicationDefinitionId: 'MedicationDefinition/ByMedicationDefinitionId',
        getByMedicationDefinitionItemCode: 'MedicationDefinition/GetByItemCode',
        getByGeneric: 'MedicationDefinition/FilterWithItems/ByGeneric',
    };

    medicationProduct = {
        url: 'MedicationProduct',
        getByMedicationDefinitionIdUrl: 'MedicationProduct/ByMedicationDefinitionId',
        getFilteredMedicationProductUrl: 'MedicationProduct/Filter',
        getWithItems: 'MedicationProduct/FilterWithItems',
        getFilteredMedicationProductLookupUrl: 'MedicationProduct/Filter/Lookup',
        filterByMedicationDefinitionId: 'MedicationProduct/Filter/ByMedicationDefinitionId',
        getAlternateBatch: 'MedicationProduct/Alternate/Batch',
        getAlternateByIngredientBatch: 'MedicationProduct/AlternateByIngredient/Batch',
        getAlternateWithBalance: 'MedicationProduct/Alternate/Balance',
        getAlternateByIngredientBalance: 'MedicationProduct/AlternateByIngredient/Balance',
    };

    pharmacyOrderAction = {
        url: 'OrderAction',
        byPatientIdAndStatus: 'OrderAction/ByPatientIdAndStatus',
        orderStatus: 'OrderAction/OrderStatus',
        orderIndex: 'OrderAction/OrderIndex',
        orderByEncounter: 'OrderAction/OrderByEncounter',
        byOrderId: 'OrderAction/OrderByDoctorPrescription',
        dispenseScheduledDrugUrl: 'OrderAction/DispenseScheduledDrug',
        medicationChartInfo: 'OrderAction/MedicationChartInfo',
        orderActionByMedicationCharts: 'OrderAction/MedicationChart',
        activeMedication: 'OrderAction/ActiveMedication',
        isMedicineSafeToOrder: 'OrderAction/IsMedicineSafeToOrder',
        changeOrderReceiveStatus: 'OrderAction/ChangeOrderReceiveStatus',
        receivedOrderLog: 'OrderAction/ReceivedOrderLog',
        sendPyxisMessages: 'OrderAction/SendPyxisMessages',
        dispenseBatchScheduledDrugsUrl: 'OrderAction/DispenseBatchScheduledDrugs',
        getDrugIntervalInformation: 'OrderAction/DrugIntervalInformation',
    };

    pharmacyOrder = {
        url: 'Order',
        all: 'Order/All',
        byId: 'Order/ById',
        doctorsPrescription: 'Order/DoctorsPrescription',
        drugDiscontinue: 'Order/DrugDiscontinue',
        receivePrescription: 'Order/ReceivePrescription'
    };

    pharmacyUser = {
        url: 'PharmacyUser',
        getPharmacyUsersUrl: 'PharmacyUser/PharmacyUsers',
        getPharmacyUserByPharmacyUrl: 'PharmacyUser/PharmacyUserByPharmacy',
        getDefaultPharmacyUrl: 'PharmacyUser/DefaultPharmacy',
        getPharmacyStaffIdUrl: 'PharmacyUser/PharmacyStaffId',
    };

    returnRequest = {
        url: 'ReturnRequest',
        approve: 'ReturnRequest/Approve',
        reject: 'ReturnRequest/Reject',
        byPatientIdAndEncounterId: 'ReturnRequest/ByPatientIdAndEncounterId',
        pendingReturnRequestCount: 'ReturnRequest/PendingReturnRequestCount',
        byOrderNoAndActionId: 'ReturnRequest/ByOrderNoAndActionId',
        inforRetryUrl: 'ReturnRequest/InforRetry',
        approveMultiRequest: 'ReturnRequest/approveMultiRequest',
    };


    store = {
        url: 'Store',
    };

    medicalLabelLog = {
        url: 'MedicalLabelLog',
    };

    medicalPrescriptionLog = {
        url: 'MedicalPrescriptionLog',
    };
}
