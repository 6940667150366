import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ListDetail } from '../../../models/lab/list-detail';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ListDetailsService extends ServiceBase {

    private _actionUrl: string;
    private _getByResultListIdUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.listDetail.url;
        this._getByResultListIdUrl = this._actionUrl + _configuration.listDetail.getByResultListId;
    }

    get = (): Observable<ListDetail[]> => {
        return this._http.get<any>(this._url);
    }

    getById = (id: number): Observable<ListDetail> => {
        const url = `${this._url}/${id}`;
        return this._http.get<any>(url);
    }

    getByResultListId = (resultListId: number): Observable<ListDetail[]> => {
        const url = `${this._getByResultListIdUrl}/?resultListId=${resultListId}`;
        return this._http.get<any>(url);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
