import { Order } from 'app/shared/models/pharmacy/order';
import { OrderAction } from 'app/shared/models/pharmacy/order-action';
import { PatientTypes } from 'app/shared/models/pharmacy/enums/patient-types';
export class ReturnRequest {
    Id: number;
    OrderNo: number;
    ActionId: number;
    CreatedDate: Date;
    FacilityId: number;
    Quantity: number;
    Unit: number;
    Reason: string;
    Status: number;
    PatientType: PatientTypes;
    ItemCode: string;
    ItemId: number;
    EncounterDate: Date;
    Order: Order;
    OrderAction: OrderAction;
}
