import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from 'app/shared/infrastructure';
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';


@Injectable()
export class InPatientTimelineHistoryService extends ServiceBase {

    private actionUrl: string;
    private _historyInformation: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._historyInformation = this.actionUrl + _configuration.inpatientTimelineHistory.inpatientHistory;
    }

    public get = (patientId: number): Observable<any> => {
        return this._http.get<any>(`${this._historyInformation}?patientId=${patientId}`);
    }
}
