import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DatePipe } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';

// App is our top level component
import { App as AppComponent } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { LoaderComponent } from './loader/loader.component';
import { AppSettings } from './app.settings';
import { AuthGuard } from './shared/services';

// Application wide providers
const APP_PROVIDERS = [
    AppState,
    GlobalState,
];

export type StoreType = {
    state: InternalStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void,
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}


/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */

declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        LoaderComponent,
        AppComponent
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        routing,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        SortablejsModule.forRoot({ animation: 150 }),
        SharedModule.forRoot(),
        PagesModule,
        ToastrModule.forRoot(
            {
                timeOut: 10000,
                positionClass: 'toast-top-right',
                preventDuplicates: true,
                closeButton: true
            }
        ),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        AuthGuard,
        APP_PROVIDERS,
        AppSettings,
        ToastrService,
        DatePipe
    ],
})

export class AppModule {
    /**
     * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
     * This is good to prevent injecting the service as constructor parameter.
     */
    static injector: Injector;

    constructor(public appState: AppState, injector: Injector) {
        AppModule.injector = injector;
    }
}

