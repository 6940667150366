export class SpecimenTestFilterModel {
    SpecimenNumber?: number;
    PatientCode: string;
    LabSectionId?: number;
    OrderNumber?: number;
    TestName: string;
    OrderPhysicianId?: number; 
    OrderFrom: Date;
    OrderTo: Date;
    ExistResultStatusId?: number;
    ResultTypeId?: number; 
    CultureResultId?: number; 
    SpecimenTypeId?: number;
    AccessionNumber: string;
    PatientName: string;
    SpecimenTestStatusId?: number;
    TestCode: string;
    CivilId : number;
}

