import { SelectItem } from 'primeng/api';
import { SmartFormConfigurationType } from '../enums';

export class SmartFormConfigurationTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Pre-Anesthesia Evaluation', value: { URL: SmartFormConfigurationType.PreAnesthesiaEvaluation, Title: 'Pre-Anesthesia Evaluation' } },
            { label: 'Intra-Anesthesia Evaluation', value: { URL: SmartFormConfigurationType.IntraAnesthesiaEvaluation, Title: 'Intra-Anesthesia Evaluation' } },
            { label: 'Post-Anesthesia Evaluation', value: { URL: SmartFormConfigurationType.PostAnesthesiaEvaluation, Title: 'Post-Anesthesia Evaluation' } },
        ];
    }
}
