import { Pipe, PipeTransform } from '@angular/core';
import { layoutPaths } from '../../../shared';

@Pipe({ name: 'baSystemSettings' })
export class BaSystemSettingsPipe implements PipeTransform {

    transform(input: string): string {
        return layoutPaths.images.root + input;
    }
}
