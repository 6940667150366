import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientInfectious } from 'app/shared/models/core/patient-infectious';

@Injectable()
export class PatientInfectiousService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byEncountersUrl: string;
    private _isPatientInfectiousUrl: string;
    private _isPatientInfectiousByPatientIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientInfectious.url;
        this._byEncountersUrl = this.actionUrl + _configuration.patientInfectious.byEncounters;
        this._isPatientInfectiousUrl = this.actionUrl + _configuration.patientInfectious.isPatientInfectious;
        this._isPatientInfectiousByPatientIdUrl = this.actionUrl + _configuration.patientInfectious.isPatientInfectiousByPatientId;
    }

    getByEncounters = (encounterIds: string): Observable<PatientInfectious[]> => {
        return this._http.get<PatientInfectious[]>(`${this._byEncountersUrl}?encounterIds=${encounterIds}`);
    }

    getByPatientEncounterInformation = (patientId: number, encounterId: number, patientType: number): Observable<any> => {
        const url = `${this._url}/${patientId}/${encounterId}/${patientType}`;
        return this._http.get<any>(url);
    }

    getIsPatientInfectious = (patientId: number, encounterId: number, patientType: number): Observable<any> => {
        const url = `${this._isPatientInfectiousUrl}?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`;
        return this._http.get<any>(url);
    }

    getIsPatientInfectiousByPatientId = (patientId: number): Observable<any> => {
        const url = `${this._isPatientInfectiousByPatientIdUrl}?patientId=${patientId}`;
        return this._http.get<any>(url);
    }
}