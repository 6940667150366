import { Deposit } from './deposit.model';
import { Fund } from './fund';
import { PatientStatement } from './patient-statement';
import { Staff } from './staff.model';

export class StatementTransaction {

    Id: number;
    PaymentCode: number;
    VisitType: number;
    PatientId: number;
    EncounterId: number;
    PaymentType: number;
    DiscountType: number;
    Amount: number;
    RemainAmount: number;
    VisaRefNumber: string;
    BankId: number;
    ChequeNo: string;
    FundId: number;
    Note: string;
    Status: number;
    PatientStatementId: number;
    DueDate?: Date;
    VisaTypeId?: number;

    Fund: Fund[] = [];
    PatientStatement: PatientStatement;
    CreatedByStaff: Staff = new Staff();
    ApprovedByStaff: Staff = new Staff();
    DepositDeatils: Deposit[] = [];
    UniqueId: number;
    VisaTypeTitle: string;
    TransactionAttachment?: {};
    CurrencyId?: number;
    CurrencyLog?: {};

    constructor() {

    }
}