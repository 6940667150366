import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { PatientBasicSearchFilter } from 'app/shared/models/queryModel';
import { PatientBasicSearchService } from 'app/shared/services/queryModel/patientBasicSearch';
@Component({
    selector: 'advance-search-registration-information-cmp',
    styleUrls: ['./advanceSearchRegistrationInformation.scss'],
    templateUrl: './advanceSearchRegistrationInformation.html',
})

export class AdvanceSearchRegistrationInformationComponent implements OnInit, OnDestroy {

    @Output() onSearch = new EventEmitter<any>();
    @Input() searchByOrder: boolean;
    patientBasicSearchFilter: PatientBasicSearchFilter = new PatientBasicSearchFilter();

    constructor(private _patientBasicSearchService: PatientBasicSearchService) {
    }

    ngOnInit() {


    }

    onSubmit() {
        // get active user and not merged 
        this._patientBasicSearchService.getSearchResult(this.patientBasicSearchFilter, true)
            .subscribe((data) => {
                this.onSearch.emit({ patientBasicSearchResult: data });

            });
    }

    ngOnDestroy() {
    }
}
