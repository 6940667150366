import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from 'app/shared/infrastructure';
import { ReceiveOrderSearch } from 'app/shared/models/orders-and-billing/receive-order-search.model';
import { ItemCategory } from 'app/shared/models/orders-and-billing/enums/item-category';

// Services
import { PatientService } from 'app/shared/services/core/patient';
import { FacilityUnitService } from 'app/shared/services/core/facilityUnit';
import { ItemService } from 'app/shared/services/order-and-billing/item';
import { LookUpService } from 'app/shared/services/core/lookup/lookup.service';
import { OrderActionAuditSearchService } from 'app/shared/services/queryModel/order-action-audit-search/order-action-audit-search.service';
import { ReceiveOrderStatusLookup } from 'app/shared/models/orders-and-billing/lookup/receive-order-status-lookup';
import { GenderTypeLookup } from 'app/shared/models';
@Component({
    selector: 'advance-search-receive-order-cmp',
    templateUrl: './advanceSearchReceiveOrder.html',
})

export class AdvanceSearchReceiveOrder extends BaseComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();
    receiveOrderStatus: SelectItem[];
    receiveOrderSearch: ReceiveOrderSearch = new ReceiveOrderSearch();

    patients: any;

    items: any;

    sections: any;

    genderTypes: any = [];

    ngOnInit() {

        this.getAllMedicalCategoryUserSections();
        this.receiveOrderStatus = ReceiveOrderStatusLookup.getAllLookupWithAll();
        this.receiveOrderSearch.fromOrderDate = new Date();
        this.receiveOrderSearch.toOrderDate = new Date();
        this.genderTypes = GenderTypeLookup.getLookup();
        this.genderTypes.unshift({ label: 'All', value: null });
    }

    constructor(private patientService: PatientService, private facilityUnitService: FacilityUnitService, private itemService: ItemService, private lookUpService: LookUpService, private orderActionAuditSearchService: OrderActionAuditSearchService) {
        super();
    }


    filterPatientByQuery(event) {
        const query = event.query;
        this.patientService.searchPatient(query)
            .subscribe((data: any[]) => {
                this.patients = data;
            });
    }

    setPatient(event) {
        this.receiveOrderSearch.patientId = event.PatientId;
    }

    checkIfValidPatient(event) {
        if (!this.receiveOrderSearch.patientId) {
            event.target.value = '';
        }
    }

    clearPatient(event) {
        this.receiveOrderSearch.patientId = null;
    }

    filterItemByQuery(event) {
        const query = event.query;
        const itemCategories: number[] = [];
        itemCategories.push(ItemCategory.MedicalProcedures);
        itemCategories.push(ItemCategory.Radiology);
        itemCategories.push(ItemCategory.SurgeryOperations);
        itemCategories.push(ItemCategory.EndoscoptProcedures);
        itemCategories.push(ItemCategory.CathLab);
        itemCategories.push(ItemCategory.Pharmacy);
        this.itemService.getServiceAndParentItems(query, itemCategories.join(','))
            .subscribe((data: any[]) => {
                this.items = data;
            });
    }

    setItem(event) {
        this.receiveOrderSearch.itemId = event.Id;
        this.receiveOrderSearch.itemTypeId = event.Type;
    }

    checkIfValidItem(event) {
        if (!this.receiveOrderSearch.itemId) {
            event.target.value = '';
        }
    }

    clearItem(event) {
        this.receiveOrderSearch.itemId = null;
    }

    getAllMedicalCategoryUserSections() {
        this.facilityUnitService.getAllNursesSections().subscribe((data: any[]) => {
            this.sections = [];
            data.forEach(element => {
                this.sections.push({ label: element.Title, value: element.Id })
            });
        });
    }

    onSubmit() {
        if (this.receiveOrderSearch.fromOrderDate && !this.receiveOrderSearch.toOrderDate) {
            this.showError('Please set To Order Date if you want to search between two dates');
            return;
        }
        if (this.receiveOrderSearch.toOrderDate && !this.receiveOrderSearch.fromOrderDate) {
            this.showError('Please set From Order Date if you want to search between two dates');
            return;
        }
        if (this.receiveOrderSearch.fromOrderDate > this.receiveOrderSearch.toOrderDate) {
            this.showError('From Date should be less than or equal To Date');
            return;
        }
        const filter = Object.assign({}, this.receiveOrderSearch);
        this.onSearch.emit({ receiveOrderSearch: filter });
    }

    resetSearchParameters() {
        this.receiveOrderSearch = new ReceiveOrderSearch();
    }
}
