export class FreqLookup {
    Id: number;
    Title: string;
    ForeignTitle: string;
    ValidHours: number;
    ValidDays: number;
    ValidMonths: number;
    CategoryId: number;

    constructor() {
    }
}