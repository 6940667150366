import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { NormalResultQuery } from '../../../models/lab/normalResultQuery';
import { BaseComponent } from 'app/shared/infrastructure';
import { NumericListTextResultEntryScreenService } from 'app/shared/services';
@Component({
    selector: 'advance-search-normal-result-entry-cmp',
    styleUrls: ['./advanceSearchNormalResultEnty.scss'],
    templateUrl: './advanceSearchNormalResultEnty.html'
})

export class AdvanceSearchNormalResultEntry extends BaseComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    specimenTransactionViewModel: NormalResultQuery = new NormalResultQuery();
    ngOnInit() {
        this.specimenTransactionViewModel = new NormalResultQuery();
    }

    constructor(private _numericListTextResultEntryScreenService: NumericListTextResultEntryScreenService) {
        super();
    }

    onSubmit() {
        if (this.specimenTransactionViewModel.OrderNumber == null || this.specimenTransactionViewModel.OrderNumber == undefined || this.specimenTransactionViewModel.OrderNumber.toString() == "") {
            if (this.specimenTransactionViewModel.AccessionNumber == null || this.specimenTransactionViewModel.AccessionNumber == undefined || this.specimenTransactionViewModel.AccessionNumber == "") {
                this.showError('Order number or Accession number shold be provided..!');
                return;
            }
        }
        if (this.specimenTransactionViewModel.OrderFrom != undefined)
            this.specimenTransactionViewModel.OrderFrom = new Date(this.specimenTransactionViewModel.OrderFrom);
        if (this.specimenTransactionViewModel.OrderTo != undefined)
            this.specimenTransactionViewModel.OrderTo = new Date(this.specimenTransactionViewModel.OrderTo);

        this._numericListTextResultEntryScreenService.filterNormalResultEntry(this.specimenTransactionViewModel)
            .subscribe((data) => {
                this.onSearch.emit({ data });
            });
    }

    ngOnDestroy() {
    }
}
