import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ErAreaBedsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byERAreaId: string;
    private _getAvailableErBedById: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.erAreaBeds.url;
        this._byERAreaId = this.actionUrl + _configuration.erAreaBeds.byERAreaUrl;
        this._getAvailableErBedById = this.actionUrl + _configuration.erAreaBeds.getAvailableErBedById;

    }

    getByAreaId = (areaId: number): Observable<any> => {
        return this._http.get<any>(this._byERAreaId + '?areaId=' + areaId);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    getAvailableErBedById = (areaId: number): Observable<any> => {
        return this._http.get<any>(this._getAvailableErBedById + '?areaId=' + areaId);
    }

}