import { TurnaroundTimeTypes } from '../enums';

export class TurnaroundTimeTypesLookup {
    static getLookup() {
        return [
            { label: 'All', value: null },
            { label: 'Within Range', value: TurnaroundTimeTypes.withinRange, class: "palette-Green-500" },
            { label: 'Above Average', value: TurnaroundTimeTypes.aboveAverage, class: 'palette-Red-500' }];
    }

    static getTitleById(id: number) {
        const lookup = this.getLookup().find((f: any) => f.value === id);
        return lookup ? lookup.label : '';
    }

    static getById(id: number) {
        const lookup = this.getLookup().find((f: any) => f.value === id);
        return lookup;
    }

}
