import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

import { DoctorSettlementDetail } from 'app/shared/models/orders-and-billing/doctor-settlement-detail';

@Injectable()
export class DoctorSettlementDetailService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byDoctorSettlementUrl: string;
    private deleteDoctorSettlementById: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorSettlementDetail.url;
        this.byDoctorSettlementUrl = this.actionUrl + _configuration.doctorSettlementDetail.byDoctorSettlement;
        this.deleteDoctorSettlementById = this.actionUrl + _configuration.doctorSettlementDetail.deleteDoctorSettlementById;
    }

    getByDoctorSettlement = (doctorSettlementId: number): Observable<DoctorSettlementDetail[]> => {
        return this._http.get<any>(this.byDoctorSettlementUrl
            + '?doctorSettlementId=' + doctorSettlementId);
    }
    
    deleteDoctorSettlement = (doctorSettlementId, invoiceId) => {
        return this._http.delete(`${this.deleteDoctorSettlementById}?DoctorSettlementId=${doctorSettlementId}&&InvoiceId=${invoiceId}`);
    }

    updateDoctorSettlement = (value: any) => {
        return this._http.put<any>(`${this.url}`, JSON.stringify(value));
    }
}
