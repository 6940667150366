import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PendingAreaInfoNote } from 'app/shared/models/orders-and-billing/pending-area-info-note';

@Injectable()
export class PendingAreaInfoNoteService extends ServiceBase {


    private actionUrl: string;
    private bySearchUrl: string;
    private addUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.bySearchUrl = this.actionUrl + _configuration.pendingAreaInfoNote.bySearch;
        this.addUrl = this.actionUrl + _configuration.pendingAreaInfoNote.add;
    }

    getBySearch = (senderId: number, receiverId: number, orderDetailId: number): Observable<PendingAreaInfoNote[]> => {
        return this._http.get<PendingAreaInfoNote[]>(`${this.bySearchUrl}?senderId=${senderId}&receiverId=${receiverId}&orderDetailId=${orderDetailId}`);
    }

    add = (pendingAreaInfoNote: PendingAreaInfoNote): Observable<any> => {
        return this._http.post<any>(this.addUrl, JSON.stringify(pendingAreaInfoNote));
    }
}
