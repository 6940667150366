import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { SentExternalLaboratory, SentExternalLaboratoryDocument, SpecimenTransactionViewModel } from 'app/shared/models';
import { PatientVisitSearchService } from 'app/shared/services/queryModel';
import { ConfirmationService } from 'primeng/api';
import { SentExternalLaboratoryService, SignalrService, SpecimenTransactionService } from '../../services';
import { SourceModule } from 'app/shared/models/enum/source-module';
import { NotificationType } from 'app/shared/models/enum/notification-type';
import { ReceiverModule } from 'app/shared/models/enum/receiver-module';
import { Notification } from 'app/shared/models/hubs/enums/notification'
import { PatientRelease } from 'app/shared/models/hubs/enums/patient-release';
import * as printJS from 'print-js';

@Component({
    selector: 'lab-view-attachment-cmp',
    templateUrl: './lab-view-attachment.html'
})


export class LabViewAttachmentComponent extends BaseComponent implements OnInit {

    externalLabSpecimenItem: SentExternalLaboratory = new SentExternalLaboratory();
    searchModel: SpecimenTransactionViewModel = new SpecimenTransactionViewModel();
    orderDocuments: any;
    canChangeIsComplete: boolean = false;
    canChangeToRelease: boolean = false;
    canChangeToUnRelease: boolean = false;
    preventUserCompletesResultToReleaseIt: boolean = false;
    filterByStatus: boolean = false;
    isAllowUserToRemoveExternalAttachment: boolean;
    selectedFiles: any[] = [];
    sentExternalLaboratoryDocument = new SentExternalLaboratoryDocument();
    numberOfUploadedDocuments: number;
    submitInProgress = false;

    @Output() onCompleteOrder = new EventEmitter<any>();
    @Output() onReleaseOrder = new EventEmitter<any>();
    @Output() onGetData = new EventEmitter<any>();

    ngOnInit() {
        this.canChangeIsComplete = this.validatePermission('EnableCompleteNormalResult');
        this.canChangeToRelease = this.validatePermission('EnableReleaseNormalResult');
        this.canChangeToUnRelease = this.validatePermission('EnableUnReleaseNormalResult');
        this.preventUserCompletesResultToReleaseIt = this.validatePermission('PreventUserWhoCompletesNormalResultToReleaseIt');
        this.isAllowUserToRemoveExternalAttachment = this.validatePermission("AllowUserToRemoveExternalAttachment");
    }

    constructor(private sentExternalLaboratoryService: SentExternalLaboratoryService,
        private _signalrService: SignalrService,
        private _patientVisitSearchService: PatientVisitSearchService,
        private _confirmationService: ConfirmationService,
        private specimenTransactionService: SpecimenTransactionService,) {
        super();

    }

    viewAttachment(externalLabSpecimenItem: SentExternalLaboratory, searchModel: SpecimenTransactionViewModel = null, filterByStatus: boolean) {
        // Remove previous loaded documents..if any
        this.orderDocuments = null;
        this.externalLabSpecimenItem = externalLabSpecimenItem;
        this.searchModel = searchModel;
        this.filterByStatus = filterByStatus;
        this.getDocuments();
    }

    getDocuments() {
        let specimenTransactionId = 0;
        if (this.externalLabSpecimenItem !== undefined && this.externalLabSpecimenItem !== null
            && this.externalLabSpecimenItem.SpecimenTransactionId !== undefined && this.externalLabSpecimenItem.SpecimenTransactionId !== null) {
            specimenTransactionId = this.externalLabSpecimenItem.SpecimenTransactionId;
        }

        this.sentExternalLaboratoryService.getDocuments(specimenTransactionId, this.searchModel, this.filterByStatus)
            .subscribe((data: any) => {
                if (!data?.length) {
                    this.showError('No Attachment Found');
                }
                this.orderDocuments = data;
                this.onGetData.emit(data.length);
            },
                error => this.showError(error));
    }


    changeIsComplete(event, item: SentExternalLaboratory) {
        this.completeResult(item, event);
    }

    completeResult(item: SentExternalLaboratory, isComplete: boolean) {
        this._confirmationService.confirm({
            message: 'Do you want to change complete status of this normal result?',
            header: 'Change Confirmation',
            key: 'ok-confirm',
            icon: 'fa-solid fa-triangle-exclamation',
            accept: () => {
                this.sentExternalLaboratoryService.changeIsCompleteStatus(item.SpecimenTransactionId, isComplete)
                    .subscribe(() => {
                        item.IsComplete = isComplete;
                        this.patientReleaseResult(item.SpecimenTransactionId, item.OrderDate);
                        this.onCompleteOrder.emit({ isSuccess: true });
                    },
                        error => {
                            item.IsComplete = false;
                            this.onCompleteOrder.emit({ isSuccess: false });
                        });
            },
            reject: () => {
                item.IsComplete = !isComplete;
            },
        });
    }

    changeIsRelease(event, item: SentExternalLaboratory) {
        this.releaseResult(item, event);
    }

    releaseResult(item: SentExternalLaboratory, isRelease: boolean) {
        this._confirmationService.confirm({
            message: 'Do you want to release this result?',
            header: 'Change Confirmation',
            key: 'ok-confirm',
            icon: 'fa-solid fa-triangle-exclamation',
            accept: () => {
                if (this.preventUserCompletesResultToReleaseIt && (item.CompleteBy == this.user.staffId) && isRelease == true) {
                    this.showError('Cannot Release Result, Please Call Administrator');
                    item.IsRelease = false;
                    return;
                }
                this.sentExternalLaboratoryService.changeIsReleaseStatus(item.SpecimenTransactionId, isRelease)
                    .subscribe(() => {
                        item.IsRelease = isRelease;
                        if (isRelease) {
                            this.sendNotification(item);
                        }

                        this.patientReleaseResult(item.SpecimenTransactionId, item.OrderDate);
                        this.onReleaseOrder.emit({ isSuccess: true });
                    },
                        error => {
                            this.onReleaseOrder.emit({ isSuccess: false });
                        });
            },
            reject: () => {
                item.IsRelease = !isRelease;
            },
        });
    }

    sendNotification(item: SentExternalLaboratory) {
        if (!item || !(item.SpecimenTransactionId > 0)) {
            // Specimen transaction action must be defined please check it
            return;
        }
        let notification = {
            Body: `${item.PatientName} have result released`,
            Url: ``,
            Module: SourceModule.Lab,
            MarkAsRead: false,
            CreatedBy: +this._signalrService.connectedStaff()?.HisUserId,
            FacilityId: +item.FacilityId,
            PayLoad: '',
            CreatedDate: new Date(),
        };
        this.notificationToDoctor(notification, item.OrderDoctorId?.toString());
        this.notificationToNurses(notification); // permission
    }

    // returns css class to load the correct icon that represets the file
    getFileTypeClass(fileName: string): string {

        // extract file extension
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);

        // supported file types are the following:
        // .pdf

        switch (ext) {
            case 'pdf':
                return 'fa-regular fa-file-pdf palette-Red-A400 text';
        }
        // return a generic icon that represet a file in general
        return 'fa-regular fa-file';
    }

    downloadDocument(documentId: number, documentName: string) {

        // call the api to downlod the document
        this.sentExternalLaboratoryService.downloadFile(documentId)
            .subscribe((data: Blob) => {
                // create blob object from received bytes
                const file = {} = new Blob([data], { type: 'application/octet-stream' });

                // create a new object URL that represents the specified blob object
                const url = window.URL.createObjectURL(file);

                // the url above,if invoked,will show a download dialog with some random file name without extension
                // since this is not desired, the workaround is using an html anchor and invoke click event
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', documentName);
                a.click();

                // release the object url since it's not needed
                // though browsers release object urls automatically,it's for optimal performance
                // and memory usage to explicitly release it
                window.URL.revokeObjectURL(url);

                return true;
            },
                error => this.showError(error));
    }


    printDocument(documentData) {
        // call the api to downlod the document
        this.sentExternalLaboratoryService.downloadFile(documentData.DocumentId)
            .subscribe((data: Blob) => {
                if (documentData.DocumentName.indexOf('.pdf') > 0) {
                    // create blob object from received bytes
                    const file = {} = new Blob([data], { type: 'application/pdf' });

                    // create a new object URL that represents the specified blob object
                    const url = URL.createObjectURL(file);
                    printJS(url);

                    window.URL.revokeObjectURL(url);
                }

                return true;
            },
                error => this.showError(error));
    }

    private notificationToDoctor(notification: any, ReceiverId: string) {
        notification.NotificationType = NotificationType.User,
            notification.ReceiverModule = ReceiverModule.Emr;
        notification.ReceiverId = ReceiverId;
        this._signalrService.send(Notification.Name, Notification.SendMessageToUser, notification);
    }

    private notificationToNurses(notification: any) {
        notification.NotificationType = NotificationType.User,
            notification.ReceiverModule = ReceiverModule.Nurse;

        this._patientVisitSearchService.getPatientNurses(this.externalLabSpecimenItem.PatientId, this.externalLabSpecimenItem.PatientTypeId, this.externalLabSpecimenItem.EncounterId).subscribe((data: []) => {
            data?.forEach((f: any) => {
                if (f.StaffId) {
                    notification.ReceiverId = `${f.StaffId}`;
                    this._signalrService.send(Notification.Name, Notification.SendMessageToUser, notification);
                }
            });
        });
    }

    private patientReleaseResult(SpecimenTransactionId: number, orderDate?: Date) {
        if (SpecimenTransactionId > 0) {
            this.specimenTransactionService.allSpecimenStatusCanceledOrReleased(this.externalLabSpecimenItem.PatientId).subscribe(value => {
                if (value) {
                    const patientResult = {
                        PatientName: this.externalLabSpecimenItem.PatientName,
                        PatientId: this.externalLabSpecimenItem.PatientId,
                        EncounterId: this.externalLabSpecimenItem.EncounterId,
                        PatientTypeId: this.externalLabSpecimenItem.PatientTypeId,
                        OrderDate: orderDate,
                        ReleaseDate: new Date(),
                        CreatedDate: new Date(),
                    };
                    this._signalrService.send(PatientRelease.Name, PatientRelease.SendPatientReleaseResult, patientResult);
                }
                else {
                    this._signalrService.send(PatientRelease.Name, PatientRelease.RemovePatientReleaseResult, this.externalLabSpecimenItem.PatientId);
                }
            });
        }
    }

    changeDocumentStatus(SpecimenTransactionId: number, documentData) {
        if (SpecimenTransactionId > 0) {
            let model = {
                specimenTransactionId: SpecimenTransactionId,
                documentId: documentData.DocumentId
            };
            this.sentExternalLaboratoryService.changeDocumentStatus(model)
                .subscribe({
                    next: () => {
                        this.getDocuments();
                    },
                    error: (error) => this.showError(error)
                });
        }
    }

    uploadDocument() {
        this.submitInProgress = true;
        for (const file of this.selectedFiles) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.sentExternalLaboratoryDocument.SentExternalLaboratoryId = this.externalLabSpecimenItem.SpecimenTransactionId;
                // extract base64 content without the header before the comma
                this.sentExternalLaboratoryDocument.Base64File = String(reader.result).split(',')[1];

                // extract file name
                this.sentExternalLaboratoryDocument.DocumentName = file.name;
                this.sentExternalLaboratoryService.uploadDocument(this.sentExternalLaboratoryDocument).subscribe({
                    next: () => {
                        this.getDocuments();
                    },
                    error: (error) => this.showError(error)
                });
            }, false);
            reader.readAsDataURL(file);
        }
    }

    selectDocuments(event) {
        for (let file of event.files) {
            this.selectedFiles.push(file);
        }
        this.submitInProgress = false
    }
    clearDocuments() {
        this.selectedFiles = [];
    }
    removeDocument(event) {
        var index = this.selectedFiles.indexOf(event.file);
        if (index > -1) {
            this.selectedFiles.splice(index, 1);
        }
    }
}
