import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';


export class Base64Validator {

  public static validate: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {

    let v: string = control.value;
    return /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i.test(v) ? null : { 'base64': true };
  };
}