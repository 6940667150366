import { Component, Input } from '@angular/core';
import { ItemStructureBase } from "app/shared/infrastructure/item-structure-base.component";

@Component({
    selector: 'item-structure-shortcut-cmp',
    templateUrl: './item-structure-shortcut.html',
    styleUrls: ['item-structure-shortcut.scss']
})

export class ItemStructureShortcutComponent extends ItemStructureBase {
    @Input('items') items: number;

    patientRegistrationLink: any;

    constructor() {
        super()
    }
}