import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class NormalResultService extends ServiceBase {

    private _actionUrl: string;
    private _labResultsUrl: string;
    private _labResultByEncountersUrl: string;
    private _itemByNormalResultUrl: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._labResultsUrl = this._actionUrl + _configuration.normalResult.getLabResults;
        this._labResultByEncountersUrl = this._actionUrl + _configuration.normalResult.getLabResultByEncounters;
        this._itemByNormalResultUrl = this._actionUrl + _configuration.normalResult.getItemByNormalResult;
    }

    getLabResults = (patientId: number, encounterId: number): Observable<any[]> => {

        const url = `${this._labResultsUrl}/?patientId=${patientId}&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }

    getLabResultsByEncounters = (data: any): Observable<any[]> => {

        const url = `${this._labResultByEncountersUrl}`;
        return this._http.post<any>(url, data);
    }

    getItemByNormalResult = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._itemByNormalResultUrl}?id=${id}`);
    }
}
