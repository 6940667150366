import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { InvoiceSettlementFilter } from 'app/shared/models/orders-and-billing/invoice-settlement-filter';
import { map } from 'rxjs/operators';

@Injectable()
export class ClaimDetailsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getEClaimStatusUrl: string;
    private _cancelEClaimUrl: string;
    private _exportToExcel: string;
    private _settlementLinkedInvoices: string;
    private _settlementNoneLinkedInvoices: string;
    private _getNoneLinkedInvoicesForExcel: string;
    private _importLinkInvoices: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.claimDetail.url;
        this._getEClaimStatusUrl = this.actionUrl + _configuration.claimDetail.getEClaimStatusUrl;
        this._cancelEClaimUrl = this.actionUrl + _configuration.claimDetail.cancelEClaimUrl;
        this._exportToExcel = this.actionUrl + _configuration.claimDetail.exortToExcel;
        this._settlementLinkedInvoices = this.actionUrl + _configuration.claimDetail.settlementLinkedInvoices;
        this._settlementNoneLinkedInvoices = this.actionUrl + _configuration.claimDetail.settlementNoneLinkedInvoices;
        this._getNoneLinkedInvoicesForExcel = this.actionUrl + _configuration.claimDetail.getNoneLinkedInvoicesForExcel;
        this._importLinkInvoices = this.actionUrl + _configuration.claimDetail.importLinkInvoices;
    }

    get = (id: number): Observable<any> => {
        let queryString: string = '';

        queryString += '?claimId=';
        queryString += id;

        return this._http.get<any>(this._url + queryString);
    }

    cancelEClaim = (id: number): Observable<any> => {
        return this._http.put<any>(`${this._cancelEClaimUrl}?id=${id}`, null);
    }

    getEClaimStatus = (id: number): Observable<any> => {
        let queryString: string = '';

        queryString += '?claimId=';
        queryString += id;

        return this._http.get<any>(this._getEClaimStatusUrl + queryString);
    }

    delete = (id: number) => {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }

    exportToExcel = (id: number): Observable<any> => {
        const url = `${this._exportToExcel}?claimId=${id}`;

        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }
                    return response;
                }));
    }

    getSettlementInvoices = (invoiceSettlementFilter: InvoiceSettlementFilter): Observable<any> => {
        let queryString: string = this.serializeToQueryString(invoiceSettlementFilter);

        return this._http.get<any>(this._settlementLinkedInvoices + queryString);
    }

    getSettlementNoneLinkedInvoices = (invoiceSettlementFilter: InvoiceSettlementFilter): Observable<any> => {
        let queryString: string = this.serializeToQueryString(invoiceSettlementFilter);

        return this._http.get<any>(this._settlementNoneLinkedInvoices + queryString);
    }

    
    getNoneLinkedInvoicesForExcel = (invoiceSettlementFilter: InvoiceSettlementFilter): Observable<any> => {
        let queryString: string = this.serializeToQueryString(invoiceSettlementFilter);
        return this._http.get<any>(this._getNoneLinkedInvoicesForExcel + queryString);
    }
    
    importLinkInvoices = (file, settlementId): Observable<any> => {
        return this._http.post<number>(`${this._importLinkInvoices}?settlementId=${settlementId}`, JSON.stringify(file));
    }
}
