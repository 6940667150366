import { Injectable } from '@angular/core';

import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { QueryBuilderApiConfiguration } from '../query-builder-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class GenerateTableService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    constructor(private _http: HttpClient, private _configuration: QueryBuilderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.generateTable.url;

    }
    
    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }
}