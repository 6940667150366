import { SelectItem } from 'primeng/api';
import { HeartRateSource } from '../enums';

export class HeartRateSourceLookup {
    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Wrist', value: HeartRateSource.Wrist },
            { label: 'Inside Of An Elbow', value: HeartRateSource.InsideOfAnElbow },
            { label: 'Side Of The Neck', value: HeartRateSource.SideOfTheNeck },
            { label: 'Top Of The Foot', value: HeartRateSource.TopOfTheFoot },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Wrist', value: HeartRateSource.Wrist },
            { label: 'Inside Of An Elbow', value: HeartRateSource.InsideOfAnElbow },
            { label: 'Side Of The Neck', value: HeartRateSource.SideOfTheNeck },
            { label: 'Top Of The Foot', value: HeartRateSource.TopOfTheFoot },
        ];
    }

}
