export class BillServicesSearchFilter {
    itemCode?: string;
    serviceName?: string;
    quantity?: number;
    unit?: string;
    price?: number;
    netDiscount?: number;
    netAmount?: number;
    actionDate?: Date;

    constructor() {

    }
}