import { StaffCategory } from './staff-category.model';
import { Speciality } from 'app/shared/models/core/speciality';
export class Staff {
    Id: number = 0;
    Code: string;
    LastName: string;
    OtherLastName: string = '';
    MidName: string;
    OtherMidName: string = '';
    FirstName: string;
    FatherName: string;
    OtherFirstName: string = '';
    OtherFatherName: string = '';
    Status: number = 0;
    FullName: string = '';
    OtherFullName: string = '';
    FacilityId: number = 0;
    ApId: string;
    CashApAccount: string;
    IsAdmissionAllowed: boolean = false;
    IsDoctor: boolean = false;
    IsNurse: boolean = false;
    NationalId: number;
    TaxNo: number;
    StaffCategories: StaffCategory[];
    Speciality: Speciality[];
    SalesPersonCode: number;
    SalesPersonName: string;
    Mobile:string;
    Section:number;
    BirthDate:Date;
    SignatureFile:string;
    
    constructor() {
        this.Code = '';
        this.ApId = '';
        this.CashApAccount = '';
        this.FirstName = '';
        this.FatherName = '';
        this.MidName = '';
        this.LastName = '';
    }
}
