import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

// import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { AppModule } from 'app/app.module';
import { throwError, EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { LoaderService } from 'app/loader/_services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    authService: AuthService;
    systemSetting

    constructor(private _loaderService: LoaderService) {
        this.authService = AppModule.injector.get(AuthService);

    }

    authReq(req: HttpRequest<any>, authToken: string, responseType: any): HttpRequest<any> {
        // Content Type is needed for cases like when adding permissions to a role
        // responseType is needed because of custom reponseType added in other services
        // TODO: We should think of a better way than overriding responseType
        return req.clone({
            setHeaders: {
                'Authorization': authToken,
                'Content-Type': 'application/json'
            },
            responseType: responseType
        });
    }

    setDimensionHeader(request, responseType) {
        return request.clone({
            setHeaders: {
                'Accept': "application/json",
                'Content-Type': 'application/json',
                'username': environment.dimensionUsername,
                'password': environment.dimensionPassword,
            }, withCredentials: true, responseType: responseType
        });
    }

    setDimensionAttachementHeader(request) {
        return request.clone({
            setHeaders: {
                'Accept': "text/plain",
                'username': environment.dimensionUsername,
                'password': environment.dimensionPassword,
            }, withCredentials: true
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tokenType = environment?.useAuthService ? this.authService.getTokenType() : null;

        // Check if token is ready
        if (!req.url.includes('/assets/')) {

            if (environment?.useAuthService) {
                if (tokenType === null || tokenType.match(/^ *$/) !== null) {
                    return EMPTY;
                }
            }
        }

        let isAppointment = false;
        let isSilent = false;

        // Validate response type and handle spinner loader
        let responseType: any = req.responseType;
        if (responseType == 'silent' as 'json' || responseType == 'noCredential' as 'json') {
            if (responseType == 'noCredential' as 'json') {
                isAppointment = true;
                document.body.classList.add("disable-loader");
            } else if (responseType == 'silent' as 'json') {
                isSilent = true;
                document.body.classList.add("silent-loader");
            }

            responseType = 'json';
        } else {

            document.body.classList.add("disable-items");
        }

        // Check Repsponse Type and set header
        let isDimension = false;
        let isDimensionAttachement = false;

        if (responseType == 'dimension' as 'json') {
            isDimension = true;
            responseType = 'json';
        }

        if (responseType == 'dimensionAttachement' as 'json') {
            isDimensionAttachement = true;
            responseType = 'json';
        }
        if (responseType == 'appointment' as 'json') {
            isAppointment = true;
            responseType = 'json';
        }

        if (isDimension) {
            req = this.setDimensionHeader(req, responseType);
        } else if (isDimensionAttachement) {
            req = this.setDimensionAttachementHeader(req);
        } else {
            req = this.authReq(req, tokenType, responseType);
        }

        // extend server response observable with logging
        return this.onHandleRequest(next, req, isSilent);
    }

    onHandleRequest(next, request, isSilent) {
        if (!isSilent) {
            this._loaderService.requestsList++;
        }

        return next.handle(request)
            .pipe(
                tap({
                    // Succeeds when there is a response; ignore other events
                    next: (event) => { },
                    // Operation failed; error is an HttpErrorResponse
                    error: (error) => {
                        if (error.status === 401) {
                            this.authService = AppModule.injector.get(AuthService);
                            this.authService.signinSilent();
                            //this._auth.signOut();
                            throw new Error('Please wait for sign in...');
                        } else {
                            throw error;
                        }
                    }
                }),
                // Log when response observable either completes or errors
                finalize(() => {
                    if (isSilent) {
                        document.body.classList.remove('silent-loader')
                    }
                    else {
                        this._loaderService.requestsList--;
                    }
                })
            );
    }
}