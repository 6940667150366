import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { Unit } from 'app/shared/models/orders-and-billing/unit';
import { GroupUnitDto } from 'app/shared/models/orders-and-billing/group-unit-dto';

@Injectable()
export class UnitService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private getByGroupIdUrl: string;
    private getByGroupIdsUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.unit.url;
        this.getByGroupIdUrl = this.actionUrl + _configuration.unit.byGroupId;
        this.getByGroupIdsUrl = this.actionUrl + _configuration.unit.byGroupIds;
    }

    get = (): Observable<Unit[]> => {
        return this._http.get<any>(this.getUrl)

    }

    getByUnitsByGroupId = (unitGroupId: number): Observable<Unit[]> => {
        return this._http.get<any>(this.getByGroupIdUrl + '?unitGroupId=' + unitGroupId, { responseType: 'silent' as 'json' })

    }

    getByGroupIds = (groupIds: string): Observable<GroupUnitDto[]> => {
        return this._http.get<any>(this.getByGroupIdsUrl + '?groupIds=' + groupIds)

    }
}
