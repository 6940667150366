import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { SpecimenTransactionService } from 'app/shared/services/lab/specimen-transaction/specimen-transaction.service';
import { SpecimenTransactionViewModel } from 'app/shared/models/lab/specimenTransactionViewModel';
import { BaseComponent } from 'app/shared/infrastructure';
@Component({
    selector: 'advance-search-sensitivity-result-entry-cmp',
    styleUrls: ['./advanceSearchSensitivityResultEntry.scss'],
    templateUrl: './advanceSearchSensitivityResultEntry.html',
})

export class AdvanceSearchSensitivityResultEntry extends BaseComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    specimenTransactionViewModel: SpecimenTransactionViewModel = new SpecimenTransactionViewModel();
    ngOnInit() {
        this.specimenTransactionViewModel = new SpecimenTransactionViewModel();
    }

    constructor(private _specimenTransactionService: SpecimenTransactionService) {
        super();
    }

    onSubmit() {
        if (this.specimenTransactionViewModel.OrderNumber === null || this.specimenTransactionViewModel.OrderNumber === undefined || this.specimenTransactionViewModel.OrderNumber.toString() === '') {
            if (this.specimenTransactionViewModel.AccessionNumber === null || this.specimenTransactionViewModel.AccessionNumber === undefined || this.specimenTransactionViewModel.AccessionNumber === '') {
                this.showError('Order number or Accession number shold be provided..!');
                return;
            }
        }

        if (this.specimenTransactionViewModel.OrderFrom !== undefined) {
            this.specimenTransactionViewModel.OrderFrom = new Date(this.specimenTransactionViewModel.OrderFrom);
        }

        if (this.specimenTransactionViewModel.OrderTo !== undefined) {
            this.specimenTransactionViewModel.OrderTo = new Date(this.specimenTransactionViewModel.OrderTo);
        }

        this._specimenTransactionService.filterSensitivityResultEntry(this.specimenTransactionViewModel)
            .subscribe((data) => {
                this.onSearch.emit({ searchResult: data });
            });
    }

    ngOnDestroy() {
    }
}
