import { SelectItem } from 'primeng/api';
import { DocumentAlertSetupPageType } from '../enums';

export class DocumentAlertSetupPageTypeLookup {

    static get(): SelectItem[] {
        return [
            { label: 'Patient Order', value: DocumentAlertSetupPageType.PatientOrder },
            { label: 'Physician Order', value: DocumentAlertSetupPageType.PhysicianOrder },
            { label: 'Lab Order Management', value: DocumentAlertSetupPageType.LabOrderManagement },
        ];
    }
}
