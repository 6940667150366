import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientPackageSession } from '../../../models/orders-and-billing/patient-package-session';

@Injectable()
export class PatientPackageSessionService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientPackageSession.url;
    }

    getByPackage = (pkgId, patientId): Observable<any> => {
        const query = `/ByPackage?pkgId=${pkgId}&&patientId=${patientId}`;
        return this._http.get<any>(this.url + query);
    }

    getByPeriod = (pkgPeriodId, patientId): Observable<any> => {
        const query = `/ByPeriod?pkgPeriodId=${pkgPeriodId}&&patientId=${patientId}`;
        return this._http.get<any>(this.url + query);
    }

    addPatientPackage = (patientPackageSession: PatientPackageSession[]): Observable<any> => {
        return this._http.post<any>(this.url, patientPackageSession);
    }

    deletePatientPackage = (id): Observable<any> => {
        const query = `?pkgId=${id}`;
        return this._http.delete(this.url + query);
    }
}
