import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class ResearchBillService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    save = (data): Observable<any> => {
        return this._http.post<any>(`${environment.order}/ResearchBill/Save`, data);
    }
}
