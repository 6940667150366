import { RoomType } from '../enums';

export class RoomTypeLookup {

    static getLookup(): any[] {
        return [
            { label: 'OT', value: RoomType.OT },
            { label: 'Recocery', value: RoomType.Recocery },
        ];
    }
}
