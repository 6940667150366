import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';

const uuids = {
  '3': /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  '4': /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  '5': /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  'all': /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
};

export class UuidValidator {
  
  public static validate  = (version?: string): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} => {
      let v: string = control.value;
      let pattern = uuids[version] || uuids.all;
      
      return (new RegExp(pattern)).test(v) ? null : {uuid: true};
    };
  }
}