import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';

import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { Patient } from 'app/shared/models/core/patient.model';
import { PatientVisitSearch } from 'app/shared/models/queryModel/patient-visit-search';

@Injectable()
export class PatientVisitSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private patientNursesUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientVisitSearch.url;
        this.patientNursesUrl = this.actionUrl + _configuration.patientVisitSearch.patientNurses;
    }

    public getPatientVisit = (patientVisitSearch: PatientVisitSearch): Observable<Patient[]> => {

        const patientCode = patientVisitSearch.PatientCode ? patientVisitSearch.PatientCode : '';
        const patientName = patientVisitSearch.PatientName ? patientVisitSearch.PatientName : '';
        const telCode = patientVisitSearch.TelCode ? patientVisitSearch.TelCode : '';
        const nationalCode = patientVisitSearch.NationalCode ? patientVisitSearch.NationalCode : '';
        var visitDate = patientVisitSearch.VisitDate ? patientVisitSearch.VisitDate.toDateString() : '';
        const sectionId = patientVisitSearch.SectionId ? patientVisitSearch.SectionId : '';
        const doctorId = patientVisitSearch.DoctorId ? patientVisitSearch.DoctorId : '';
        const sourceId = patientVisitSearch.SourceId ? patientVisitSearch.SourceId : '';

        return this._http.get<any>(this.url
            + '?PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&TelCode=' + telCode
            + '&&NationalCode=' + nationalCode
            + '&&VisitDate=' + visitDate
            + '&&SectionId=' + sectionId
            + '&&DoctorId=' + doctorId
            + '&&SourceId=' + sourceId
        );
    }

    public getPatientNurses = (patientId: number, patientTypeId: number, encounterId: number): Observable<any> => {
        return this._http.get<any>(`${this.patientNursesUrl}?patientId=${patientId}&patientTypeId=${patientTypeId}&encounterId=${encounterId}`);
    }
}
