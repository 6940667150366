export class HospitalPharmacy {

    Id: number;
    Title: string;
    ForeignTitle: string;
    StoreNo: string;
    StoreId: number;
    PharmacyType: number;
    FacilityId: number;
    Status: boolean;
    IsDischargePrescriptionPharmacy: boolean;
    constructor() { }
}
