import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from './order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';

@Injectable()
export class ChargingSetupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byFacilityUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this._url = this.actionUrl + configuration.chargingSetup.url;
        this._byFacilityUrl = this.actionUrl + configuration.chargingSetup.byFacility;
    }

    getByFacility = (): Observable<any> => {
        return this.http.get<any>(this._byFacilityUrl);
    }

    add = (data): Observable<any> => {
        return this.http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this.http.put<any>(this._url, data);
    }
}