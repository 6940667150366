import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class QueryBuilderApiConfiguration {
    serverUrl: string;
    server: string;
    dimensionAttachementServer: string;

    constructor() {
        this.server = environment.queryBuilder;
    }

    public executeQuery = {
        url: '/ExecuteQuery',
        live: '/ExecuteQuery/live',
    }

    public queryGenerator = {
        url: '/QueryGenerator',
    }
    public assignChart = {
        url: '/AssignChart',
    }

    public generateTable = {
        url: '/GenerateTable',
    }

    public assignCard = {
        url: '/AssignCard',
    }

    public dashboardGenerator = {
        url: '/DashboardGenerator',
        byRoleIds: '/DashboardGenerator/ByRoleIds',
    }
}
