export class PharmacyLookup {
    Id: number;
    Title: string;
    ForeignTitle: string;
    CreatedDate: Date;
    CreatedBy: number;
    ModifiedDate: Date;
    ModifiedBy: number;
    FacilityId: number;
    DoseTime: Date;
    CategoryId: number;
    Status: boolean;
    MediSpanRouteId?: number;
    constructor() { }
}