import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from "app/shared/infrastructure";
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { VitalSigns } from "app/shared/models/core/vital-Signs";

@Injectable()
export class VitalSignNormalRangService extends ServiceBase {
    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _getNormalRangeById: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.vitalSignNormalRang.url;
        this._getNormalRangeById = this.actionUrl + _configuration.vitalSignNormalRang.getNormalRange;
    }


    insert = (data: any): Observable<string> => {
        return this._http.post<any>(this._url, data);
    }

   
    update = (data: any): Observable<any> => {
        return this._http.put<any>(`${this._url}?id=${data.Id}`, data);
    }

    getVitalSignNormalRange = (vitalSignsId, vitalSingTypeId): Observable<any> => {
        const query = `${this._getNormalRangeById}?vitalSingId=${vitalSignsId}&vitalSingTypeId=${vitalSingTypeId}`;
        return this._http.get<any>(query);
    }

    getByVitalSignId = (vitalSignId): Observable<any> => {
        const query = `${this._url}/ByVitalSignId?vitalSignId=${vitalSignId}`;
        return this._http.get<any>(query);
    }
}