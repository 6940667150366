import { Component, OnInit } from '@angular/core';

export class AdmissionBed {

    Id: number;
    FacilityId: number;
    SectionId: number;
    RoomId: number;
    Code: string;
    Telephone: string;
    Status: string;
    PatientId: number;
    AdmissionId: number;
    RequestType: string;
    IsCalculated: boolean;
    constructor() {
    }
}