import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdmissionStatus } from 'app/shared/models';
import { AdmissionStatusLookup } from 'app/shared/models/admission/lookup/admission-status-lookup';
import { PatientBasicSearchFilter } from 'app/shared/models/queryModel';
import { PatientBasicSearchService } from 'app/shared/services/queryModel/patientBasicSearch';

@Component({
    selector: 'patient-information-order-search-cmp',
    styleUrls: ['./patient-information-order-search.scss'],
    templateUrl: './patient-information-order-search.html',
})

export class PatientInformationOrderSearchComponent {

    @Output() onSearch = new EventEmitter<any>();
    patientBasicSearchFilter: PatientBasicSearchFilter = new PatientBasicSearchFilter();
    stauses = AdmissionStatusLookup.getOrderSearchLookup();
    visitStatus = new FormControl(AdmissionStatus.InHospital);

    constructor(private _patientBasicSearchService: PatientBasicSearchService) {
    }

    onSubmit() {
        this._patientBasicSearchService.getSearchResult(this.patientBasicSearchFilter, true)
            .subscribe((data) => {
                this.onSearch.emit({ patientBasicSearchResult: data, statusFilter: this.visitStatus?.value });
            });
    }
}
