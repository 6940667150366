import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class DoctorNoteService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.doctorNote.url;
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
