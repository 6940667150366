export class PatientEncounterInfo {
    
    EncounterId: number;
    PatientId: number;
    PatientTypeId: number;
    EncounterDate: string;
    EncounterDoctorFullName: string;
    EncounterSectionTitle: string;

    constructor() { }
}
