import { SelectItem } from 'primeng/api';
import { ExistResultStatus } from '../enums/exist-result-status';

export class ExistResultStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Both', value: null },
            { label: 'Exist Result', value: ExistResultStatus.existResult },
            { label: 'Not Exist Result', value: ExistResultStatus.notExistResult }];
    }

}
