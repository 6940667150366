import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()

export class ConsultationPendingAreaInfoNoteService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getBySearch: string;
    private _add: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.consultationRequest.url;
        this._getBySearch = this.actionUrl + _configuration.consultationPendingAreaInfoNotes.bySearch;
        this._add = this.actionUrl + _configuration.consultationPendingAreaInfoNotes.add;
    }

    getBySearch = (senderId: number, receiverId: number, requestId: number): Observable<any[]> => {
        return this._http.get<any[]>(`${this._getBySearch}?senderId=${senderId}&receiverId=${receiverId}&requestId=${requestId}`);
    }

    add(data) {
        return this._http.post<any>(this._add, data)
    }
}
