import { SelectItem } from 'primeng/api';
import { TemperatureSource } from '../enums';

export class TemperatureSourceLookup {
    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Oral', value: TemperatureSource.Oral },
            { label: 'Rectal', value: TemperatureSource.Rectal },
            { label: 'Axillary', value: TemperatureSource.Axillary },
            { label: 'Tympanic', value: TemperatureSource.Tympanic },
        ];
    }

    static getLookup(): SelectItem[] {
        return [
            { label: 'Oral', value: TemperatureSource.Oral },
            { label: 'Rectal', value: TemperatureSource.Rectal },
            { label: 'Axillary', value: TemperatureSource.Axillary },
            { label: 'Tympanic', value: TemperatureSource.Tympanic },
        ];
    }

}
