import { SelectItem } from 'primeng/api';
import { MedicationOrderType } from '../enums';

export class MedicationOrderTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Both', value: MedicationOrderType.Both },
            { label: 'Generic', value: MedicationOrderType.Generic },
            { label: 'Brand', value: MedicationOrderType.Brand },
        ];
    }
}
