import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { map } from 'rxjs/operators';

@Injectable()
export class RevenueService extends ServiceBase {

    private actionUrl: string;
    // EXPORT Revenues By Elements
    private _csvRevenues: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
       
        // EXPORT Revenues
        this._csvRevenues = this.actionUrl + _configuration.revenue.url;
    }

    public exportRevenues = (query: string): Observable<any> => {
        return this._http.get<any>(this._csvRevenues + query,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
}
