import { SelectItem } from 'primeng/api';
import { OccursType } from '../enums';

export class OccursLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Daily', value: OccursType.Daily },
            { label: 'Weekly', value: OccursType.Weekly },
            { label: 'Monthly', value: OccursType.Monthly },
        ];
    }
}
