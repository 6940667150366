import { SelectItem } from 'primeng/api';
import { FeesType } from '../enums/amount-type';

export class FeesTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Percentage', value: FeesType.percentage },
            { label: 'Amount ', value: FeesType.Amount },
        ];
    }

    static getLookupWithNone(): SelectItem[] {
        return [
            { label: 'None', value: FeesType.None },
            { label: 'Percentage', value: FeesType.percentage },
            { label: 'Amount ', value: FeesType.Amount },
        ];
    }
}
