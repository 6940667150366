export enum ResultType {
    DrugToDrug = 1,
    DrugToFood = 2,
    DrugToAlcohol = 3,
    Allergy = 4,
    Pregnancy = 5,
    Lactation = 6,
    Age = 7,
    Gender = 8,
}
