import { KitSetup } from './kitSetup';
import { Lookup } from './lookup';
import { Section } from './section';
import { SensitiveMicrobiology } from './sensitiveMicrobiology';

export class SensitiveResult {

    Id: number;
    OrderId: number;
    SpecimenNo: number;
    ItemNo: number;
    LabSection: number;
    LabAccessionNo: string;
    UnitId: number;
    KitNo: number;
    MachineId: number;
    IsComplete: boolean;
    CompleteDate: Date;
    CompleteBy: number;
    IsRelease: boolean;
    ReleaseDate: Date;
    ReleaseBy: number;
    RejectDate: Date;
    RejectBy: number;
    RejectReason: number;
    PatientId: number;
    FacilityId: number;

    KitSetup: KitSetup;
    UnitLookup: Lookup;
    Section: Section;
    SensitiveMicrobiologies: SensitiveMicrobiology[];

    constructor() {

    }
}