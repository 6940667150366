import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { OrderApiConfiguration } from '../order-api-configuration';


@Injectable()
export class PatientBalanceService extends ServiceBase {

    private _url: string;
    private actionUrl: string;
    private getPatientBalanceUrl: string;
    private getPatientBalanceDetailsUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.PatientBalance.url;
        this.getPatientBalanceUrl = this.actionUrl + _configuration.PatientBalance.getPatientBalance;
        this.getPatientBalanceDetailsUrl = this.actionUrl + _configuration.PatientBalance.getPatientBalanceDetails;
    }

    getPatientBalance(payerId): Observable<any> {
        const url = `${this.getPatientBalanceUrl}?payerId=${payerId}`;
        return this._http.get<any>(url);
    }

    getPatientBalanceDetails(approvalNo): Observable<any> {
        const url = `${this.getPatientBalanceDetailsUrl}?approvalNo=${approvalNo}`;
        return this._http.get<any>(url);
    }

}
