import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class SpecialityWidgetService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _add: string;
    private _getAllUrl: string;
    private _getBySpecialityUrl: string;
    private _getByStaffIdUrl: string;


    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.specialityWidget.url;
        this._add = this.actionUrl + _configuration.specialityWidget.add;
        this._getAllUrl = this.actionUrl + _configuration.specialityWidget.getAll;
        this._getBySpecialityUrl = this.actionUrl + _configuration.specialityWidget.getBySpeciality;
        this._getByStaffIdUrl = this.actionUrl + _configuration.specialityWidget.getByStaffId;
    }

    getAll = (): Observable<any[]> => {
        return this._http.get<any>(this._getAllUrl);
    }

    getBySpeciality = (specialityId: number): Observable<any[]> => {
        return this._http.get<any>(`${this._getBySpecialityUrl}?specialityId=${specialityId}`);
    }

    getByStaffId = (): Observable<any[]> => {
        return this._http.get<any>(this._getByStaffIdUrl);
    }

    add(data: any) {
        return this._http.post<any>(this._add, data);
    }
}