import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ParentPayerSession } from 'app/shared/models/orders-and-billing/parent-payer-session';

@Injectable()
export class ParentPayerSessionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private allUrl: string;
    private allActiveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.parentPayerSession.url;
        this.allUrl = this.actionUrl + _configuration.parentPayerSession.all;
        this.allActiveUrl = this.actionUrl + _configuration.parentPayerSession.allActive;
    }

    getAll = (payerId: number): Observable<ParentPayerSession[]> => {
        return this._http.get<any>(`${this.allUrl}?payerId=${payerId}`);
    }

    getAllActive = (payerId: number): Observable<ParentPayerSession[]> => {
        return this._http.get<any>(`${this.allActiveUrl}?payerId=${payerId}`);
    }

    public add(parentPayerSession: ParentPayerSession) {
        return this._http.post<any>(this.url, JSON.stringify(parentPayerSession));
    }

    public update(parentPayerSession: ParentPayerSession) {
        return this._http.put<any>(`${this.url}`, JSON.stringify(parentPayerSession));
    }
}