import { SelectItem } from 'primeng/api';
import { CoverageType } from 'app/shared/models/orders-and-billing/enums/coverage-type';
export class CoverageTypeLookup {

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Approval', value: CoverageType.Approval },
            { label: 'Covered', value: CoverageType.Covered },
            { label: 'Not Covered', value: CoverageType.NotCovered },
        ];
    }
}
