import { SelectItem } from 'primeng/api';
import { MedicalSourceType } from '../enums';
export class MedicalSourceTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Diagnosis', value: MedicalSourceType.diagnosis },
            { label: 'VitalSign', value: MedicalSourceType.vitalSign },
            { label: 'Allergy', value: MedicalSourceType.allergy },
            { label: 'Chief Complaint', value: MedicalSourceType.chiefComplaint },
        ];
    }
}
