import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { Fund } from 'app/shared/models/orders-and-billing/fund';

@Injectable()
export class FundsService extends ServiceBase {

    private actionUrl: string;
    private getAllUrl: string;
    private getActiveUrl: string;
    private getLookUpUrl: string;
    private getCashierFundsUrl: string;
    private getMainFundUrl: string;
    private isMainFundUrl: string;
    private loggedInFundUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getAllUrl = this.actionUrl + configuration.funds.all;
        this.getActiveUrl = this.actionUrl + configuration.funds.active;
        this.getLookUpUrl = this.actionUrl + configuration.funds.getLookUp;
        this.getCashierFundsUrl = this.actionUrl + configuration.funds.getCashierFundsUrl;
        this.getMainFundUrl = this.actionUrl + configuration.funds.mainFund;
        this.isMainFundUrl = this.actionUrl + configuration.funds.isMainFund;
        this.loggedInFundUrl = this.actionUrl + configuration.funds.loggedInFund;
    }

    get = (): Observable<any> => {
        return this.http.get<any>(this.getAllUrl);
    }

    getActive = (): Observable<any> => {
        return this.http.get<any>(this.getActiveUrl);
    }

    getLookUp = (): Observable<any> => {
        return this.http.get<any>(this.getLookUpUrl);
    }

    getMainFund = (): Observable<any> => {
        return this.http.get<any>(this.getMainFundUrl);
    }

    isMainFund = (): Observable<boolean> => {
        return this.http.get<any>(this.isMainFundUrl);
    }

    getCashierFunds = (userId: number): Observable<Fund[]> => {
        const url = `${this.getCashierFundsUrl}?userId=${userId}`;

        return this.http.get<any>(url);
    }

    getLoggedInFund = (): Observable<Fund[]> => {
        const url = `${this.loggedInFundUrl}`;
        return this.http.get<any>(url);
    }
}