export enum ItemCategory {
    none = 0,
    Package = 1,
    MedicalProcedures = 2,
    lab = 3,
    Radiology = 4,
    SurgeryOperations = 5,
    Accommodation = 11,
    InventoryItem = 13,
    Pharmacy = 19,
    CompanionService = 10,
    DoctorFees = 12,
    NurseSupervision = 9,
    EndoscoptProcedures = 24,
    CathLab = 28,
    Disposible = 13,
}
