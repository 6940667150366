import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { TemplateMasterCode } from 'app/shared/models/orders-and-billing/template-master-code';
import { OrderApiConfiguration } from '../order-api-configuration';

@Injectable()

export class TemplateMasterCodeService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _getByPayerTemplateIdUrl: string;
    private _exportTemplateMasterCodesURL: string;
    private _importPayerTemplateUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.templateMasterCode.url;
        this._getByPayerTemplateIdUrl = this.actionUrl + _configuration.templateMasterCode.getByPayerTemplateIdUrl;
        this._exportTemplateMasterCodesURL = this.actionUrl + _configuration.templateMasterCode.exportTemplateMasterCodes;
        this. _importPayerTemplateUrl = this.actionUrl + _configuration.templateMasterCode.importPayerTemplateCodes;
    }

    get = (): Observable<any> => {

        return this._http.get<TemplateMasterCode[]>(this._url);
    }

    add = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: TemplateMasterCode): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }  

    getByPayerTemplateId = (payerTemplateId: number): Observable<TemplateMasterCode[]> => {
        return this._http.get<TemplateMasterCode[]>(this._getByPayerTemplateIdUrl + `?PayerTemplateId=${payerTemplateId}`);
    }

    getByTemplateIdToExportExcel = (payerTemplateId: number): Observable<TemplateMasterCode[]> => {
        return this._http.get<TemplateMasterCode[]>(this._exportTemplateMasterCodesURL + `?PayerTemplateId=${payerTemplateId}`);
    }
    
    importPayerTemplate(payerTemplateId, file) {
        return this._http.post<any>(`${this._importPayerTemplateUrl}?PayerTemplateId=${payerTemplateId}`, JSON.stringify(file));
    }
}
