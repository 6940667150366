export class SpecimenViewModel {
    SpecimenNumber?: number;
    SpecimenType?: number;
    PatientCode: string;
    PatientName: string;
    PatientSection: string;
    OrderDoctor: string;
    OrderNumber?: number;
    OrderDate?: Date;
    Status?: number;
    CivilId?:string;
};
