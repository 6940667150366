export class OrganizationSection {

    Id: number;
    Code: string;
    Title: string;
    ForeignTitle: string;
    ParentNo?: number;
    IsLeaf: boolean;
    EmailAddress: string;
    TelephoneCode: string;
    IsActive: boolean;
    CostCenter?: number;
    SectionHead?: number;
    Note: string;
    WarehouseCode: string;
    StoreId: number;
    constructor() {
    }
}