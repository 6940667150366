export class DoctorPerofrmanceFilter {

    fromDate?: Date = null;
    toDate?: Date = null;
    doctorId?: number = null;
    doctorName: string = null;
    pageIndex: number = 0;

    constructor() {

    }
}
