import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { SystemCurrency } from 'app/shared/models/orders-and-billing/system-currency';
import { Subject, firstValueFrom, of } from 'rxjs';

@Injectable()
export class SystemCurrencyService extends ServiceBase {

    public defaultCode;
    private actionUrl: string;
    private url: string;
    private getDefaultCodeUrl: string;
    private getAllUrl: string;
    private getDefaultUrl: string;
    private _addSystemCurrencyUrl: string;
    private _updateSystemCurrencyUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.systemCurrency.url;
        this.getDefaultCodeUrl = this.actionUrl + configuration.systemCurrency.defaultCode;
        this.getDefaultUrl = this.actionUrl + configuration.systemCurrency.default;
        this.getAllUrl = this.actionUrl + configuration.systemCurrency.all;
        this._addSystemCurrencyUrl = this.actionUrl + configuration.systemCurrency.add;
        this._updateSystemCurrencyUrl = this.actionUrl + configuration.systemCurrency.update;
        this.getDefaultCode();
    }

    getDefaultCode = async () => {
        this.http.get<any>(this.getDefaultCodeUrl)
            .subscribe(value => {
                this.defaultCode = value;
            });
    }

    getDefault = (): Observable<SystemCurrency> => {
        return this.http.get<any>(this.getDefaultUrl);
    }

    getAll = (): Observable<SystemCurrency[]> => {
        return this.http.get<any>(this.getAllUrl);
    }

    add = (data: any): Observable<any> => {
        return this.http.post<any>(this._addSystemCurrencyUrl, data);
    }

    update = (data: any): Observable<any> => {
        return this.http.put<any>(this._updateSystemCurrencyUrl, data);
    }
}
