import { Component, OnInit, Input } from '@angular/core';

import { Icons } from 'app/pages/icons';
import { BaseComponent } from 'app/shared/infrastructure/base.component';

@Component({
    selector: 'ba-main-menu',
    styleUrls: ['baMainMenu.component.scss'],
    templateUrl: './baMainMenu.html',
})

export class baMainMenu extends BaseComponent implements OnInit {

    @Input() menuHeight: number;

    constructor() {
        super();
    }

    ngOnInit() {

    }

    authorityServer() {
        return this.authorityServiceConstantUrl();
    }

    setIcon($event) {
        const icons = Icons;
        const founded = icons.find(i => i.label === $event);
        return founded.value;
    }
}
