import { Component, OnInit } from '@angular/core';

export class AdmissionRoom {

    Id: number;
    FacilityId: number;
    SectionId: number;
    Code: string;
    BedCount: number;
    Gender: number;
    IsIsolated: boolean;
    Status: string;
    Location: string;
    ClassId: number;
    ClassTitle: string;
    ClassForeignTitle: string;
    constructor() {
    }
}