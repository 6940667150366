import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { Unit } from 'app/shared/models/orders-and-billing/unit';
import { environment } from 'environments/environment';

@Injectable()
export class UnitGroupService {

    constructor(private _http: HttpClient) { }

    get = (): Observable<Unit[]> => {
        return this._http.get<any>(`${environment.order}UnitGroup`)
    }
}
