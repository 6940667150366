import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from "app/shared/infrastructure";
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { VitalSigns } from "app/shared/models/core/vital-Signs";

@Injectable()
export class VitalSignsService extends ServiceBase {
    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _getAll: string;
    private _getVitalSignTypes: string;


    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.vitalSigns.url;
        this._getAll = this.actionUrl + _configuration.vitalSigns.getAll;
        this._getAll = this.actionUrl + _configuration.vitalSigns.getAll;
        this._getVitalSignTypes = this.actionUrl + _configuration.vitalSigns.getVitalSignTypes;
    }

    getAll = (): Observable<VitalSigns[]> => {
        return this._http.get<any>(this._getAll);
    }

    insert = (data: any): Observable<string> => {
        return this._http.post<any>(this._url, data);
    }

   
    update = (data: any): Observable<any> => {
        return this._http.put<any>(`${this._url}?id=${data.Id}`, data);
    }

    getVitalSignTypes = (): Observable<any[]> => {
        return this._http.get<any>(this._getVitalSignTypes);
    }
  
}