import { Component, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from 'app/shared/infrastructure';

import { ClaimFilter } from 'app/shared/models/queryModel/claim-filter';
import { PatientTypesLookup } from 'app/shared/models/orders-and-billing/lookup/patient-types-lookup';
import { PayerService, ClaimService, CoverageContractService, CoverageClassService } from 'app/shared/services/order-and-billing';
import { ClaimStatusLookUp } from '../../../models/orders-and-billing/lookup/claim-status-lookup';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'advance-search-claim-management-tracking-cmp',
    styleUrls: ['./advance-search-claim-management-tracking.scss'],
    templateUrl: './advance-search-claim-management-tracking.html'
})

export class AdvanceSearchClaimManagementTrackingComponent extends BaseComponent {
    @Output() onSearch = new EventEmitter<any>();

    patientTypes: any[] = [];
    insuranceProviders: any[] = [];
    claim: ClaimFilter = new ClaimFilter();
    payers: SelectItem[] = [];
    statusLookup: any[] = [];
    coverageContracts: SelectItem[] = [];
    coverageClasses: SelectItem[] = [];

    searchForm = new FormGroup({
        patientName: new FormControl(null),
        patientCode: new FormControl(null),
        patientType: new FormControl(null),
        coverageContract: new FormControl(null),
        coverageClass: new FormControl(null),
        fromClaimDate: new FormControl(null),
        toClaimDate: new FormControl(null),
        payer: new FormControl(null),
        claimNo: new FormControl(null),
        billNo: new FormControl(null),
        status: new FormControl(null),
    });

    get patientName(): any { return this.searchForm.get('patientName'); };
    get patientCode(): any { return this.searchForm.get('patientCode'); };
    get patientType(): any { return this.searchForm.get('patientType'); };
    get payer(): any { return this.searchForm.get('payer'); };
    get coverageContract(): any { return this.searchForm.get('coverageContract'); };
    get coverageClass(): any { return this.searchForm.get('coverageClass'); };
    get fromClaimDate(): any { return this.searchForm.get('fromClaimDate'); };
    get toClaimDate(): any { return this.searchForm.get('toClaimDate'); };
    get claimNo(): any { return this.searchForm.get('claimNo'); };
    get billNo(): any { return this.searchForm.get('billNo'); };
    get status(): any { return this.searchForm.get('status'); };

    constructor(
        private _payerService: PayerService,
        private _claimService: ClaimService,
        private _coverageContractService: CoverageContractService,
        private _coverageClassService: CoverageClassService) {
        super();
        this.patientTypes = PatientTypesLookup.getLookupWithBoth();
        this.statusLookup = ClaimStatusLookUp.getLookup();
        this.getPayer();
        this.payer.valueChanges.subscribe(payerId => { if(payerId > 0) { this.getCoverageContractByPayer(payerId); } });
        this.coverageContract.valueChanges.subscribe(contractId => { if(contractId > 0) { this.getCoverageClassByContract(contractId); } });
    }

    getPayer() {
        this._payerService.getPayersHasParent()
            .subscribe((data: any[]) => {
                this.payers = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    getCoverageContractByPayer(payerId: number) {
        this.coverageContracts = [];
        this._coverageContractService.getInfoByPayer(payerId)
            .subscribe((data: any[]) => {
                this.coverageContracts = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    getCoverageClassByContract(contractId: number) {
        this.coverageClasses = [];
        this._coverageClassService.getInfoByContract(contractId)
            .subscribe((data: any[]) => {
                this.coverageClasses = data.map((m) => ({ label: m.Title, value: m.Id }));
            });
    }

    onSubmit() {
        this.claim.PatientCode = this.patientCode.value;
        this.claim.PatientName = this.patientName.value;
        this.claim.PatientType = this.patientType.value;
        this.claim.PayerID = this.payer.value;
        this.claim.FromClaimDate = this.fromClaimDate.value;
        this.claim.CoverageContractId = this.coverageContract.value;
        this.claim.CoverageClassId = this.coverageClass.value;
        this.claim.ToIClaimDate = this.toClaimDate.value;
        this.claim.BillNo = this.billNo.value;
        this.claim.ClaimNo = this.claimNo.value;
        this.claim.Status = this.status.value;

        this._claimService.get(this.claim).subscribe((data: any[]) => {
            this.onSearch.emit({ claims: data, filter: this.claim });
            this.onClear();
        })
    }

    onClear() {
        this.claim = new ClaimFilter();
    }

}
