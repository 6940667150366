import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ExcludedSection } from 'app/shared/models/orders-and-billing/excluded-sections';

@Injectable()
export class ExcludedSectionsService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.excludedSections.url;
    }

    getExcludedSections = (contractId: number, classId: number): Observable<ExcludedSection[]> => {
        return this._http.get<any>(`${this._url}/AllExcludedSections?contractId=${contractId}&&classId=${classId}`)
    }

    add(data: ExcludedSection) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (data: ExcludedSection): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
 
}