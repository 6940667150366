export class AppointmentPatientDataViewModel {
    FirstName: string;
    LastName: string;
    OtherFirstName: string;
    OtherLastName: string;
    GenderId: number;
    TelCode: string;
    Email: string;
    Address: string;
    constructor() {
    }
}

