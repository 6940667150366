import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from 'app/shared/infrastructure';
import { environment } from 'environments/environment';


@Injectable()
export class OrderIssueStockLogService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    getByFilter = (filter, pageIndex: number,
        pageSize: number): Observable<any> => {

            let queryString = `?pageIndex=${pageIndex}&pageSize=${pageSize}`;
            queryString += `&id=${filter?.id || ''}`;
            queryString += `&patientCode=${filter?.patientCode || ''}`;
            queryString += `&transactionNumber=${filter?.transactionNumber || ''}`;
            queryString += `&fromDate=${filter?.fromDate.toDateString()}&endDate=${filter?.endDate.toDateString()}`;
            queryString += `&status=${filter?.status ? filter?.status : filter?.status === 0 ? 0 : ''}`;

        const url = `${environment.httpAggregatorApi}OrderIssueStockLog/GetByFilter${queryString}`
        return this._http.get<any>(url);
    }

    getById = (id: number): Observable<any> => {
        const url = `${environment.httpAggregatorApi}OrderIssueStockLog/GetById?id=${id}`;

        return this._http.get<any>(url);
    }
}
