import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { QueryModelApiConfiguration } from '../../queryModel';
@Injectable()

export class ConsultationRequestService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getByRequestId: string;
    private _changeTransferStatus: string;
    private _transferConsultation: string;
    private _changeChargeableStatus: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration
        , private _orderConfig: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.ConsultationStation.url;
        this._getByRequestId = this.actionUrl + _configuration.ConsultationStation.byRequestId;
        this._changeTransferStatus = _orderConfig.server + _orderConfig.consultationRequest.changeTransferStatus;
        this._transferConsultation = _orderConfig.server + _orderConfig.consultationRequest.transferConsultation;
        this._changeChargeableStatus = _orderConfig.server + _orderConfig.consultationRequest.changeChargeableStatus;
    }

    getconsultationRequestById = (requestId: number): Observable<any> => {
        const url = `${this._getByRequestId}?requestId=${requestId}`;
        return this._http.get<any>(url);
    }

    changeTransferStatus = (id: number): Observable<any> => {
        return this._http.put<any>(`${this._changeTransferStatus}?id=${id}`, id);
    }

    transferConsultation = (id: number, requestType: number): Observable<any> => {
        return this._http.put<any>(`${this._transferConsultation}?id=${id}&requestType=${requestType}`, id);
    }

    changeChargeableStatus = (id, isChargeable): Observable<any> => {
        return this._http.put<any>(`${this._changeChargeableStatus}?id=${id}&isChargeable=${Boolean(isChargeable)}`, id);
    }
}
