import { SelectItem } from 'primeng/api';
import { PaidStatus } from '../enums';

export class PaidStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: PaidStatus.All },
            { label: 'Paid', value: PaidStatus.Paid },
            { label: 'Not Paid', value: PaidStatus.NotPaid }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
