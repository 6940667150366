import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { DiagnosisGroup } from 'app/shared/models/core/diagnosis_group';

@Injectable()
export class PayerDiagnosisGroupService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;

    private _getAllUrl: string;
    private _getAllActiveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.payerDiagnosisGroup.url;
        this._getAllUrl = this.actionUrl + _configuration.payerDiagnosisGroup.getAll;
        this._getAllActiveUrl = this.actionUrl + _configuration.payerDiagnosisGroup.getAllActive;

    }

    getAll = (): Observable<DiagnosisGroup[]> => {
        return this._http.get<any>(this._getAllUrl);
    }

    getAllActive = (): Observable<DiagnosisGroup[]> => {
        return this._http.get<any>(this._getAllActiveUrl);
    }

    insert = (data: DiagnosisGroup): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: DiagnosisGroup): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(this._getAllUrl + "?id=" + id);
    }
}
