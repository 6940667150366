import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PackageSessionService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.packageSession.url;
    }

    public getByFilter = (packageId, patientId, isActive): Observable<any> => {
        if (isActive == 1) {
            isActive = true
        } else {
            isActive = false
        }
        const query = `/getByFilter?pkgId=${packageId}&&patientId=${patientId}`
            + `&&isActive=${isActive}`;
        return this._http.get<any>(this.url + query);
    }

    public getAllByPkgAndPatient = (packageId, patientId): Observable<any> => {
        const query = `/AllByPkgAndPatient?pkgId=${packageId}&&patientId=${patientId}`;
        return this._http.get<any>(this.url + query);
    }

    public addPackage = (pkg): Observable<any> => {
        return this._http.post<any>(this.url, pkg);
    }

    public getByPatientIdAndEncounterDate = (patientId, encounterDate, isActive): Observable<any> => {
        if (isActive == 1) {
            isActive = true
        } else {
            isActive = false
        }
        const query = `/byPatientIdEncounterDate?patientId=${patientId}&&encounterDate=${encounterDate}`
            + `&&isActive=${isActive}`;

        return this._http.get<any>(this.url + query);
    }

    public haveIssuedStatment = (id): Observable<any> => {
        const query = `/HaveIssuedStatment?id=${id}`;
        return this._http.get<any>(this.url + query);
    }

    public getPatientPackageSessionCount = (id): Observable<any> => {
        const query = `/PatientPackageSessionCount?id=${id}`;
        return this._http.get<any>(this.url + query);
    }

    public updatePackage = (pkg): Observable<any> => {
        return this._http.put<any>(this.url, pkg);
    }
}