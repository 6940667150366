import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PagedResults } from 'app/shared/interfaces/paged-results.interface';
import { CashierDiscount } from "app/shared/models/orders-and-billing/cashier-discount";

@Injectable()
export class CashierDiscountService extends ServiceBase {
    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private privateActiveUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.cashierDiscount.url;
        this.privateActiveUrl = this.actionUrl + configuration.cashierDiscount.privateActiveUrl;
    }

    getPagedResult = (cashierNameFilter: string, pageIndex: number, pageSize: number): Observable<PagedResults<CashierDiscount>> => {
        var params = { cashierName: cashierNameFilter, pageIndex: pageIndex, pageSize: pageSize };

        return this.http.get<any>(`${this.getUrl}${this.serializeToQueryString(params)}`);
    }

    update = (cashierDiscount: CashierDiscount): Observable<any> => {
        return this.http.put<any>(`${this.getUrl}?staffId=${cashierDiscount.StaffId}`, cashierDiscount);
    }

    addNewSetup = (cashierDiscount: CashierDiscount): Observable<any> => {
        return this.http.post<any>(`${this.getUrl}`, cashierDiscount);
    }

    getMyDiscountSetup = (): Observable<any> => {
        return this.http.get<any>(`${this.privateActiveUrl}`);
    }
}
