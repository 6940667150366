import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';


export class GreaterThanNullableValidator {

  public static validate =  (gt: number): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: boolean} => {
      if(control.value != null){
        let v: number = +control.value;
        return v > +gt ? null : {gt: true};
      } else {
        return null;
      }
    };
  }
}