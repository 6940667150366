import { AdmissionStatus } from '../enums/admission-status';
import { SelectItem } from 'primeng/api';

export class AdmissionStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: AdmissionStatus.InHospital },
            { label: 'Doctor Release', value: AdmissionStatus.ReleasePermission },
            { label: 'Drug Card Closed', value: AdmissionStatus.DrugCardClosed },
            { label: 'Finance Settled', value: AdmissionStatus.FinanceSettled },
            { label: 'Final Discharge', value: AdmissionStatus.FinalDischarge },
            { label: 'Cancelled', value: AdmissionStatus.Canceled },
            { label: 'Halted', value: AdmissionStatus.Halted }];
    }

    static getInHospitalStatusesLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: AdmissionStatus.InHospital },
            { label: 'Doctor Release', value: AdmissionStatus.ReleasePermission },
            { label: 'Drug Card Closed', value: AdmissionStatus.DrugCardClosed },
            { label: 'Finance Settled', value: AdmissionStatus.FinanceSettled }
        ];
    }

    static getHospitalRoomStatusesLookup(): SelectItem[] {
        return [
            { label: 'All Statuses', value: null },
            { label: 'Open', value: AdmissionStatus.InHospital },
            { label: 'Doctor Release', value: AdmissionStatus.ReleasePermission },
            { label: 'Drug Card Closed', value: AdmissionStatus.DrugCardClosed },
            { label: 'Finance Settled', value: AdmissionStatus.FinanceSettled }
        ];
    }

    static getOrderSearchLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: AdmissionStatus.InHospital },
            { label: 'Halted', value: AdmissionStatus.Halted }
        ];
    }

}
