import { SelectItem } from 'primeng/api';
import { Gender } from '../enums';
export class GenderTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
            { label: 'Both', value: Gender.Both }];
    }

    static getLookupWithUnspecified(): SelectItem[] {
        return [
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
            { label: 'Unspecified', value: Gender.Both }];
    }

    static getTitleById(id) {
        const lookup = this.getLookup().find(f => f.value === id);
        return lookup?.label || '';
    }

    static getByType(item) {
        const items = this.getLookupWithUnspecified();
        return items.find(f => f.value === item)?.label;
    }
}
