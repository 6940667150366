export class PendingAreaInfo {
    OrderDetailId: number;
    PkgId?: number;
    Qty: number;
    ItemPrice?: number;
    CoverageAmount ?: number;
    Total?: number;
    Audit: string;
    Status: number;
    ApprovedBy?: number;
    ApprovedDate?: Date;
    CanceledBy?: number;
    CanceledDate?: Date;
}