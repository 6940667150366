import { SelectItem } from 'primeng/api';
import { EClaimCode } from '../enums';

export class EClaimCodeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'HPortal', value: EClaimCode.HPortal },
            { label: 'Waseel', value: EClaimCode.Waseel },
            { label: 'Nphies', value: EClaimCode.Nphies }];
    }
}
