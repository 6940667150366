export class TextResultTemplate {

    Id: number;
    TemplateDescription: string;
    ClinicalHistory: string;
    Investigation: string;
    Notes: string;
    FinalResult: string;
    
    constructor(init?: Partial<TextResultTemplate>) {
        Object.assign(this, init);
    }

}
