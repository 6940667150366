export class NormalResultQuery {

    SpecimenNumber: number;
    PatientCode: string;
    AccessionNumber: string;
    LabSection: number;
    SpecimenType: number;
    OrderNumber: number;
    OrderFrom: Date;
    OrderTo: Date;
    Status: number;
    CivilId: string;
};