import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class CheckListSetupService {

    constructor(private _http: HttpClient) {

    }

    get = (): Observable<number> => {
        return this._http.get<number>(`${environment.operation}/CheckListSetup`);
    }

    add = (data): Observable<number> => {
        return this._http.post<number>(`${environment.operation}/CheckListSetup`, data);
    }

    update = (data): Observable<number> => {
        return this._http.put<number>(`${environment.operation}/CheckListSetup`, data);
    }
}
