import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { MedispanServiceApiConfiguration } from '../medispan-service-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { MediSpanViewModel } from 'app/shared/models/medispan/medispan-view-model';
import { MediSpanOutput } from 'app/shared/models/medispan/medispan-output';
import { MediSpanDto } from 'app/shared/models/medispan/medispan-dto';
import { LogDrugDto } from 'app/shared/models/medispan/log-drug-dto';

@Injectable()
export class MediSpanService extends ServiceBase {

    private actionUrl: string;
    private _mediSpanResultUrl: string;
    private _mediSpanLogUrl: string;
    private _mediSpanLogResultUrl: string;

    constructor(private _http: HttpClient, private _configuration: MedispanServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._mediSpanResultUrl = this.actionUrl + _configuration.mediSpan.mediSpanResult;
        this._mediSpanLogUrl = this.actionUrl + _configuration.mediSpan.mediSpanLog;
        this._mediSpanLogResultUrl = this.actionUrl + _configuration.mediSpan.mediSpanLogResult;
    }

    getMediSpanResultUrl = (mediSpanViewModel: MediSpanViewModel): Observable<MediSpanOutput[]> => {
        return this._http.post<any>(this._mediSpanResultUrl, mediSpanViewModel)
    }

    postMediSpanLog = (mediSpanDto: MediSpanDto): Observable<MediSpanOutput[]> => {
        return this._http.post<any>(this._mediSpanLogUrl, mediSpanDto)
    }

    getMediSpanLogResult = (orderId: number, medicationDefinitionId: number, medicationProductId: number): Observable<LogDrugDto> => {
        const url = `${this._mediSpanLogResultUrl}?orderId=${orderId}&medicationDefinitionId=${medicationDefinitionId}&medicationProductId=${medicationProductId}`;
        return this._http.get<any>(url);
    }

}