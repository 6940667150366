import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable';
import { DrugStatus } from '../../../models/pharmacy/enums/drug-statuses';
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { observable } from 'rxjs';
import { OrderAction } from 'app/shared/models/pharmacy/order-action';
import { MedicationChartDispense } from 'app/shared/models/pharmacy/medication-chart-dispense';
import { ActiveMedicationDto } from 'app/shared/models/pharmacy/active-medication-dto';
import { MedicineOrder } from 'app/shared/models/pharmacy/medicine-order';
import { ChangePharmacyOrderStatus } from 'app/shared/models/pharmacy/change-pharmacy-order-status';
import { environment } from 'environments/environment';

@Injectable()
export class PharmacyOrderActionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private action: string;
    private byPatientIdAndStatus: string;
    private orderStatus: string;
    private orderIndex: string;
    private orderByEncounter: string;
    private byOrderId: string;
    private dispenseScheduledDrugUrl: string;
    private _medicationChartInfo: string;
    private _orderActionByMedicationCharts: string;
    private _activeMedicationUrl: string;
    private _isMedicineSafeToOrderUrl: string;
    private _changeOrderReceiveStatusUrl: string;
    private _getReceivedOrderLogUrl: string;
    private _sendPyxisMessagesUrl: string;
    private dispenseBatchScheduledDrugsUrl: string;
    private _getDrugIntervalInformationUrl: string;

    constructor(private http: HttpClient, private configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.pharmacyOrderAction.url;
        this.byPatientIdAndStatus = this.actionUrl + configuration.pharmacyOrderAction.byPatientIdAndStatus;
        this.orderStatus = this.actionUrl + configuration.pharmacyOrderAction.orderStatus;
        this.orderIndex = this.actionUrl + configuration.pharmacyOrderAction.orderIndex;
        this.orderByEncounter = this.actionUrl + configuration.pharmacyOrderAction.orderByEncounter;
        this.byOrderId = this.actionUrl + configuration.pharmacyOrderAction.byOrderId;
        this.dispenseScheduledDrugUrl = this.actionUrl + configuration.pharmacyOrderAction.dispenseScheduledDrugUrl;
        this._medicationChartInfo = this.actionUrl + configuration.pharmacyOrderAction.medicationChartInfo;
        this._orderActionByMedicationCharts = this.actionUrl + configuration.pharmacyOrderAction.orderActionByMedicationCharts;
        this._activeMedicationUrl = this.actionUrl + configuration.pharmacyOrderAction.activeMedication;
        this._isMedicineSafeToOrderUrl = this.actionUrl + configuration.pharmacyOrderAction.isMedicineSafeToOrder;
        this._changeOrderReceiveStatusUrl = this.actionUrl + configuration.pharmacyOrderAction.changeOrderReceiveStatus;
        this._getReceivedOrderLogUrl = this.actionUrl + configuration.pharmacyOrderAction.receivedOrderLog;
        this._sendPyxisMessagesUrl = this.actionUrl + configuration.pharmacyOrderAction.sendPyxisMessages;
        this.dispenseBatchScheduledDrugsUrl = this.actionUrl + configuration.pharmacyOrderAction.dispenseBatchScheduledDrugsUrl;
        this._getDrugIntervalInformationUrl = this.actionUrl + configuration.pharmacyOrderAction.getDrugIntervalInformation;
    }

    // get by patient id and drug status
    getByPatientIdAndStatus = (patientId, encounterId, pageIndex, filter): Observable<any> => {

        let queryString = `?patientId=${patientId}&encounterId=${encounterId}&pageIndex=${pageIndex}`;
        queryString += `&status=${filter.status}`;
        queryString += `&drugId=${filter.drugId}`;
        queryString += `&pharmacyId=${filter.pharmacyId}`;
        queryString += `&dispenseQuantity=${filter.dispenseQuantity || 0}`;
        queryString += `&returnQuantity=${filter.returnQuantity || 0}`;
        queryString += `&returnReason=${filter.returnReason || ''}`;
        queryString += `&unitId=${filter.unitId}`;
        queryString += `&orderDate=${filter.orderDate?.toDateString() || ''}`;
        queryString += `&createdBy=${filter.createdBy}`;
        queryString += `&orderId=${filter.orderId || 0}`;
        queryString += `&doseTime=${filter.doseTime?.toDateString() || null}`;
        return this.http.get<any>(`${this.byPatientIdAndStatus}${queryString}`);
    }

    getOrderStatus = (orderIds: string): Observable<any> => {
        return this.http.get<any>(this.orderStatus + '?orderIds=' + orderIds);
    }

    getOrderIndex = (patientId, patientType, encounterId, rowNo, pageIndex, itemCode, itemTitle, orderBy, orderDate, quantity, unitId, orderId): Observable<any> => {

        return this.http.get<any>(`${this.orderIndex}?patientId=${patientId}&&patientType=${patientType}&&encounterId=${encounterId}&&rowNo=${rowNo}&&pageIndex=${pageIndex}
        &&itemCode=${itemCode}&&itemTitle=${itemTitle}&&orderBy=${orderBy}&&orderDate=${orderDate}&&quantity=${quantity}&&unitId=${unitId}&&orderId=${orderId}`);
    }

    getOrderByEncounter = (patientId, patientType, encounterId): Observable<any> => {
        return this.http.get<any>(`${this.orderByEncounter}?patientId=${patientId}&&patientType=${patientType}&&encounterId=${encounterId}`);
    }
    getByOrder = (id: number): Observable<OrderAction[]> => {
        return this.http.get<any>(this.byOrderId + '?orderId=' + id);
    }

    dispenseScheduledDrug(medicationChartDispense: MedicationChartDispense): Observable<any> {
        return this.http.post<any>(this.dispenseScheduledDrugUrl, medicationChartDispense);
    }

    medicationChartInfo(id: number, storeId: number = null): Observable<any> {
        return this.http.get<any>(`${this._medicationChartInfo}?orderActionId=${id}&&storeId=${storeId}`);
    }

    setOrderActionByMedCharts(data: any): Observable<any> {
        return this.http.post<any>(`${this._orderActionByMedicationCharts}`, data);
    }

    getActiveMedication = (patientId: number, encounterId: number, patientTypeId: number): Observable<ActiveMedicationDto[]> => {
        return this.http.get<any>(`${this._activeMedicationUrl}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`
        );
    }

    isMedicineSafeToOrder = (medicineOrders: MedicineOrder[]): Observable<MedicineOrder[]> => {
        return this.http.post<MedicineOrder[]>(`${this._isMedicineSafeToOrderUrl}`, medicineOrders);
    }

    changeOrderStatus = (data: ChangePharmacyOrderStatus): Observable<any> => {
        return this.http.patch<any>(this._changeOrderReceiveStatusUrl, data);
    }

    getReceivedOrderLog = (orderActionId: number): Observable<any> => {
        let url = `${this._getReceivedOrderLogUrl}?orderActionId=${orderActionId}`;
        return this.http.get<any>(url);
    }

    sendPyxisMessages = (medicationChartDispense: MedicationChartDispense[]): Observable<any> => {
        return this.http.post<MedicineOrder[]>(`${this._sendPyxisMessagesUrl}`, medicationChartDispense);
    }

    dispenseBatchScheduledDrugs(dispenseMedications: MedicationChartDispense[]): Observable<any> {
        return this.http.post<any>(this.dispenseBatchScheduledDrugsUrl, dispenseMedications);
    }

    getDrugIntervalInformation = (orderActionIds): Observable<any> => {
        return this.http.post<MedicineOrder[]>(`${this._getDrugIntervalInformationUrl}`, orderActionIds);
    }

    cancelAllByActionId = (data): Observable<any> => {
        return this.http.post<any>(`${environment.pharmacy}/OrderAction/CancelAllByActionId`, data);
    }

    releaseAllByActionId = (data): Observable<any> => {
        return this.http.post<any>(`${environment.pharmacy}/OrderAction/ReleaseAllByActionId`, data);
    }

    createVisitAndStatement = (actionId): Observable<any> => {
        return this.http.post<any>(`${environment.pharmacy}/OrderAction/CreateVisitAndStatement?actionId=${actionId}`, null);
    }
}
