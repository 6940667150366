import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
    // Callback
    { path: 'callback', loadChildren: () => import('app/pages/callback/callback.module').then(m => m.CallbackModule) },

    // Silent
    { path: 'silent', loadChildren: () => import('app/pages/silent/silent.module').then(m => m.SilentModule) },

  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/dashboard' },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
