import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class PrintersetupService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    getByUser = (): Observable<any> => {
        return this._http.get<any>(`${environment.queryModel}PrinterSetup/GetByUser`);
    }
}
