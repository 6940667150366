import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class OperationApiConfiguration {

    constructor() {
        this.server = environment.operation;
    }

    server: string;

    operation = {
        url: 'RoomBooked',
    };
}
