import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PayerExcludedSection } from 'app/shared/models/orders-and-billing/payer-excluded-section';

@Injectable()
export class PayerExcludedSectionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private allPayerExcludedSectionUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.payerExcludedSection.url;
        this.allPayerExcludedSectionUrl = this.actionUrl + _configuration.payerExcludedSection.allPayerExcludedSection;
    }

    getAllPayerExcludedSection = (payerId: number): Observable<PayerExcludedSection[]> => {
        return this._http.get<any>(`${this.allPayerExcludedSectionUrl}?payerId=${payerId}`);
    }

    insert = (data: PayerExcludedSection): Observable<PayerExcludedSection> => {
        return this._http.post<any>(this.url, data);
    }

    update = (data: PayerExcludedSection): Observable<PayerExcludedSection> => {
        return this._http.put<any>(this.url, data);
    }
}