import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { AdmissionSearchFilter } from '../../../models/queryModel';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { TodayCashBox } from 'app/shared/models/queryModel/today-cash-box';
@Injectable()
export class ConsultationTypesDetailSearchService extends ServiceBase {

    private actionUrl: string;
    private _getAllByConsultationTypeURL: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._getAllByConsultationTypeURL = this.actionUrl + _configuration.consultationTypesDetail.allByConsultationType;

    }

    public getAllByConsultationType = (consultationTypeId): Observable<any> => {
        return this._http.get<any>(`${this._getAllByConsultationTypeURL}?consultationTypeId=${consultationTypeId || ''}`
        );
    }
}
