import { SelectItem } from 'primeng/api';
import { AlternativeByType } from '../enums';
export class AlternativeByTypesLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Generic', value: AlternativeByType.Generic },
            { label: 'Ingredient', value: AlternativeByType.Ingredient },
        ];
    }
}
