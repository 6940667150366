import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class PrinterOrderService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }
   
    save = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.order}/PrinterOrder/Save`, data);
    } 
    
    saveAll = (data: any): Observable<any> => {
        return this._http.post<any>(`${environment.order}/PrinterOrder/SaveAll`, data);
    } 
}
