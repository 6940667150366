export class TextResult {

    Id: number;
    PatientId: number;
    OrderId: number;
    SpecimenNo: number;
    ItemNo: number;
    LabSection: number;
    LabAccessionNo: string;
    TextResultType: number;
    ClinicalHistory: string;
    Investigation: string;
    Notes: string;
    DiagnosisResult: string;
    DiagnosisNo: number;
    IsComplete: boolean;
    CompleteDate: Date;
    CompleteBy: number;
    IsRelease: boolean;
    ReleaseDate: Date;
    ReleasBy: number;
    RejectDate: Date;
    RejectBy: number;
    RejectReason: string;
    FinalResult: string;
    Remark: string;

    constructor(init?: Partial<TextResult>) {
        Object.assign(this, init);
    }
}