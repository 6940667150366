import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { map } from 'rxjs/operators';

@Injectable()
export class DiclosureInvoiveService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.DisclosureInvoice.url;
    }



    public exportIssuedInvoice = (query: string): Observable<any> => {

        return this._http.get<any>(this.url + '/CsvIssuedInvoice' + query,
            { responseType: 'blob' as 'json', observe: 'response', withCredentials: false }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }


    public exportUnIssuedInvoice = (query: string): Observable<any> => {

        return this._http.get<any>(this.url + '/CsvUnIssuedInvoice' + query,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
}
