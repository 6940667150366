import { SelectItem } from 'primeng/api';
import { PriceMethodType } from '../enums';

export class PriceMethodTypeLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Batch', value: PriceMethodType.Batch },
            { label: 'FDA Cost', value: PriceMethodType.FDACost },
            { label: 'Public', value: PriceMethodType.Public },
            { label: 'Contract Price list', value: PriceMethodType.ContractPricelist },

        ];
    }
}
