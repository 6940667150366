import { ItemCategory } from '../enums';

export class ItemCategoryLookup {
    static getAll(): number[] {
        return [
            ItemCategory.none,
            ItemCategory.lab,
            ItemCategory.Radiology,
            ItemCategory.MedicalProcedures,
            ItemCategory.Accommodation,
            ItemCategory.InventoryItem,
            ItemCategory.Pharmacy,
            ItemCategory.CompanionService,
            ItemCategory.DoctorFees,
            ItemCategory.NurseSupervision,
            ItemCategory.SurgeryOperations,
            ItemCategory.EndoscoptProcedures,
            ItemCategory.CathLab,
        ]
    }

    static getLookup(): number[] {
        return [
            ItemCategory.MedicalProcedures,
            ItemCategory.Radiology,
            ItemCategory.SurgeryOperations,
            ItemCategory.EndoscoptProcedures,
            ItemCategory.CathLab,
            ItemCategory.Pharmacy
        ]
    }

    static checkCategoryForItemService(id): boolean {
        return [
            ItemCategory.Package,
            ItemCategory.MedicalProcedures,
            ItemCategory.lab,
            ItemCategory.Radiology,
            ItemCategory.SurgeryOperations,
            ItemCategory.Accommodation,
            ItemCategory.DoctorFees,
            ItemCategory.InventoryItem,
        ].includes(id)
    }

}