import {
    Component, ViewContainerRef, ViewChild,
    Input, ComponentRef, ComponentFactoryResolver, ChangeDetectorRef,
    EventEmitter, Output, AfterViewChecked
} from '@angular/core'

import { AdmissionStationSearchFilter } from 'app/shared/models/queryModel/admission-station-search-filter';
import { ShortcutsComponent } from 'app/shared/components/shortcuts/shortcuts.component';
import { isMobile } from 'app/shared';
import { SmartConfigService } from '../../services/core/smartconfig/smartconfig.service';

@Component({
    selector: 'sidebar-notification-cmp',
    styleUrls: ['./sidebar-notification.scss'],
    templateUrl: './sidebar-notification.html',
})

export class SidebarNotificationComponent implements AfterViewChecked {
    @ViewChild('searchTarget', { read: ViewContainerRef }) searchTarget;
    @ViewChild('favoriteTarget', { read: ViewContainerRef }) favoriteTarget;
    @ViewChild('notificationTarget', { read: ViewContainerRef }) notificationTarget;
    @ViewChild('shortcutTarget', { read: ViewContainerRef }) shortcutTarget;
    @ViewChild('informationTarget', { read: ViewContainerRef }) informationTarget;
    @ViewChild('moreResultTarget', { read: ViewContainerRef }) moreResultTarget;
    @Input() searchType;
    @Input() notificationType;
    @Input() favoriteType;
    @Input() shortcutType = ShortcutsComponent;
    @Input() informationType;
    @Input() moreResultType;

    // view or hide search or result
    @Input() hideSearch: boolean = false;
    @Input() hideInformation: boolean = false;
    @Input() hideMoreInformation: boolean = true;
    @Input() searchByOrder: boolean = false;

    _hideSearchResult: any;
    @Input() set hideSearchResult(value: boolean) {
        this._hideSearchResult = value;
    }

    _informationResult: any;
    @Input() set informationResult(value: any) {
        this._informationResult = value;
        if (this._informationResult?.length > 0) {
            this.endSearch();
        }
    }

    _informationOrderResult: any;
    @Input() set informationOrderResult(value: any) {
        this._informationOrderResult = value;
        if (this._informationOrderResult != null) {
            this.endSearch();
        }
    }

    _shortcutFileName: any = 'home';
    @Input() set shortcutFileName(value: string) {
        this._shortcutFileName = value;
        if (this._shortcutFileName.length > 0) {

        }
    }
    _patientId: number;
    @Input() set patientId(value: number) {
        this._patientId = value;
        if (this._patientId) {
        }
    }

    _refreshInformation: AdmissionStationSearchFilter;
    @Input() set refreshInformation(value: AdmissionStationSearchFilter) {
        this._refreshInformation = value;
        if (this._refreshInformation) {
        }
    }

    @Output() onSearch = new EventEmitter<any>();
    @Output() onSelect = new EventEmitter<any>();
    @Output() onSelectShortcuts = new EventEmitter<any>();
    @Output() onSelectMoreResult = new EventEmitter<any>();

    searchComponentRef: ComponentRef<any>;
    notificationComponentRef: ComponentRef<any>;
    favoriteComponentRef: ComponentRef<any>;
    shortcutComponentRef: ComponentRef<any>;
    informationComponentRef: ComponentRef<any>;
    moreResultComponentRef: ComponentRef<any>;

    private isViewInitialized: boolean = false;
    hasResult: boolean = false;

    forms: any[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private _changeDetectorRef: ChangeDetectorRef,
        private smartConfigService: SmartConfigService) {

    }
    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return;
        }
        if (this.searchComponentRef) {
            this.searchComponentRef.destroy();
        }
        //if (this.notificationComponentRef) {
        //    this.notificationComponentRef.destroy();
        //}
        if (this.shortcutComponentRef) {
            this.shortcutComponentRef.destroy();
        }
        if (this.informationComponentRef) {
            this.informationComponentRef.destroy();
        }
        //if (this.favoriteComponentRef) {
        //    this.favoriteComponentRef.destroy();
        //}
        if (this.moreResultComponentRef) {
            this.moreResultComponentRef.destroy();
        }

        if (this.searchType) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(this.searchType);
            this.searchComponentRef = this.searchTarget.createComponent(factory);
            this.searchComponentRef.instance.searchByOrder = this.searchByOrder;
            if (this._refreshInformation) {
                this.searchComponentRef.instance.refreshInformation = this._refreshInformation;
            }

            this.searchComponentRef.instance.onSearch.subscribe(result => {
                this.hasResult = true;

                if (this.hideInformation) {
                    this.endSearch();
                }
                this.onSearch.emit({ result });
            });
        }

        if (this.informationType) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(this.informationType);
            this.informationComponentRef = this.informationTarget.createComponent(factory);
            this.informationComponentRef.instance.result = this._informationResult || this._informationOrderResult;
            this.informationComponentRef.instance.refreshedValue = this._refreshInformation;
            this.informationComponentRef.instance.onSetResult.subscribe(result => {
                this.removeBarResult();
                this.onSelect.emit({ result });
            });
        }

        if (this.moreResultType) {

            const factory = this.componentFactoryResolver.resolveComponentFactory(this.moreResultType);
            this.moreResultComponentRef = this.moreResultTarget.createComponent(factory);
            this.moreResultComponentRef.instance.id = this._patientId;
            this.moreResultComponentRef.instance.onSetResult.subscribe(result => this.onSelectMoreResult.emit({ result }));
        }

        if (this._hideSearchResult) {
            this.hideSearchResultBar();
        }
    }

    startSearch() {
        jQuery('.ng2').addClass('scrollable-container');
        jQuery('.ng2').find('sidebar-notification-cmp').parent('div').find('#main').addClass('startSearch');
    }

    endSearch() {
        jQuery('.ng2').removeClass('scrollable-container');
        jQuery('.ng2').find('sidebar-notification-cmp').parent('div').find('#main').removeClass('startSearch');
        jQuery('.information-bar').addClass('active');
        jQuery('.settings').addClass('active-result');
    }

    closeSearch() {
        jQuery('.ng2').removeClass('scrollable-container');
        jQuery('.ng2').find('sidebar-notification-cmp').parent('div').find('#main').removeClass('startSearch');
    }

    activeBar(item, barItem, resultType) {
        if (item === 'shortcut-bar') {
            if (this.shortcutType) {
                var url = window.location.href;
                var parser = document.createElement("a");
                parser.href = url;
                var pageUrl = parser.pathname.toString();
                this.smartConfigService.getAllFormsByPageUrl(pageUrl).subscribe((result) => {

                    if (result !== null) {
                        if (result.SmartFormLinks !== null) {
                            this.forms = result.SmartFormLinks;
                        }
                    }
                });
            }
        }
        jQuery('#' + barItem).addClass('active-item');
        jQuery('#' + item).addClass('active');
    }

    removeActiveBar() {
        jQuery('.bar').removeClass('active');
    }

    activeBarResult() {
        if (!this.hasResult) {
            return;
        }
        if (jQuery('.information-bar').hasClass('active') && jQuery('.settings').hasClass('active-result')) {
            this.removeBarResult();
        } else {
            jQuery('.information-bar').addClass('active');
            jQuery('.settings').addClass('active-result');
        }
    }

    removeBarResult() {
        jQuery('.information-bar').removeClass('active');
        jQuery('.settings').removeClass('active-result');
    }

    hideSearchResultBar() {
        jQuery('.ng2').removeClass('scrollable-container');
        jQuery('.ng2').find('sidebar-notification-cmp').parent('div').find('#main').removeClass('startSearch');
        jQuery('.information-bar').removeClass('active');
        jQuery('.settings').removeClass('active-result');
    }

    ngOnChanges() {
        this.updateComponent();
    }

    ngAfterViewInit() {
        this.isViewInitialized = true;
        this.updateComponent();
    }

    ngOnDestroy() {
        if (this.informationComponentRef) {
            this.informationComponentRef.destroy();
        }
        if (this.searchComponentRef) {
            this.searchComponentRef.destroy();
        }
        //if (this.notificationComponentRef) {
        //    this.notificationComponentRef.destroy();
        //}
        //if (this.favoriteComponentRef) {
        //    this.favoriteComponentRef.destroy();
        //}
        //if (this.shortcutComponentRef) {
        //    this.shortcutComponentRef.destroy();
        //}
        if (this.moreResultComponentRef) {
            this.moreResultComponentRef.destroy();
        }
    }

    validateMobile() {
        if (isMobile()) {
            return true;
        }
        return false;
    }
}
