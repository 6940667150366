import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class ResourceService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    allActive = (): Observable<any> => {
        return this._http.get<any>(`${environment.appointment}Resource/Active`,
            { responseType: 'appointment' as 'json', withCredentials: false, })
    }    
}
