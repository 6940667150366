export enum OperationManagementLink {
    EquipmentDefinition = '/pages/equipmentDefinition',
    RoomDefinition = '/pages/roomDefinition',
    OperationProcedureDefinition = '/pages/procedureDefinition',
    // OperationTracking = '/OperationTracking',
    OperationSlate = '/pages/operationSlate',
    Booking = '/pages/booking',
    OperationInbox = '/pages/operationInbox',
    // OperationDashboard = '/OperationDashboard',
}
