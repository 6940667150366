import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { ConsultationType } from 'app/shared/models/admission';

@Injectable()
export class ConsultationTypeService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _activeByFilter: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.consultationType.url;
        this._activeByFilter = this.actionUrl + _configuration.consultationType.activeByFilter;
    }

    public get = (visitSource, isActive): Observable<ConsultationType[]> => {
        return this._http.get<any>(this._url + '?VisitSource=' + visitSource + '&isActive=' + isActive);
    }

    public getActiveByFilter = (visitSource, visitType): Observable<ConsultationType[]> => {
        return this._http.get<any>(this._activeByFilter + '?visitSource=' + visitSource + '&visitType=' + visitType);
    }


    public getActive = (): Observable<ConsultationType[]> => {
        return this._http.get<any>(this._url + '/Active');
    }

    public getById = (id): Observable<any> => {
        return this._http.get<any>(this._url + '?id=' + id);
    }

    public add(data: any) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update(id: number, data: any) {
        return this._http.put<any>(this._url + '?id=' + id, JSON.stringify(data));
    }

    public getGroupedByVisit = (): Observable<any> => {
        return this._http.get<any[]>(this._url + '/GroupedByVisit');
    }

    public getAll = (): Observable<ConsultationType[]> => {
        return this._http.get<any>(this._url + '/All');
    }
}