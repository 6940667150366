import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class DocumentAlertSetupService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    get = (): Observable<any> => {
        return this._http.get<any>(`${environment.core}/DocumentAlertSetup`);
    }

    add = (data): Observable<any> => {
        return this._http.post<any>(`${environment.core}/DocumentAlertSetup`, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(`${environment.core}/DocumentAlertSetup`, data);
    }

    checkMissedDocument = (data): Observable<any> => {
        const url = `${environment.core}/DocumentAlertSetup/checkMissedDocument`;
        return this._http.post<any>(url, data);
    }
}
