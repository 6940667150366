import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { Frequency } from '../../../models/pharmacy/frequency';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class FrequencyService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;
    private _getAllByActiveFilterUrl: string;
    private _getByIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.frequency.url;
        this._getAllByActiveFilterUrl = this._actionUrl + _configuration.frequency.getAllByActiveFilterUrl;
        this._getByIdUrl = this._actionUrl + _configuration.frequency.getByIdUrl;
    }

    getAll = (isActive: boolean = null): Observable<Frequency[]> => {
        return this._http.get<any>(`${this._getAllByActiveFilterUrl}?isActive=${isActive !== null ? isActive : ''}`)
    }


    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
