import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';

import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { environment } from 'environments/environment';

@Injectable()
export class PendingAreaManagementService extends ServiceBase {

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
    }

    get = (filter, page: number = 0, rows: number = 20): Observable<any> => {
        let queryString: string = `?OrderNumber=${filter.orderNumber || ''}` +
            `&&Patient=${filter.patient || ''}&&Payer=${filter.payer || ''}` +
            `&&Item=${filter.item || ''}&&Location=${filter.location || ''}` +
            `&&OrderDoctor=${filter.orderDoctor || ''}&&FromOrderDate=${filter.fromOrderDate?.toDateString() || ''}` +
            `&&ToOrderDate=${filter.toOrderDate?.toDateString() || ''}&&Status=${filter.status || ''}` +
            `&&GroupReference=${filter.groupReference || ''}&&NationalId=${filter.nationalId || ''}&&Page=${page}&&Rows=${rows}`;
        return this._http.get<any>(`${environment.queryModel}PendingAreaManagement` + queryString);
    }

    getPharmacy = (filter, page: number = 0, rows: number = 10): Observable<any> => {
        let queryString: string = `?OrderNumber=${filter.orderNumber || ''}` +
            `&&Patient=${filter.patient || ''}&&Payer=${filter.payer || ''}` +
            `&&Item=${filter.item || ''}&&Location=${filter.location || ''}` +
            `&&OrderDoctor=${filter.orderDoctor || ''}&&FromOrderDate=${filter.fromOrderDate?.toDateString() || ''}` +
            `&&ToOrderDate=${filter.toOrderDate?.toDateString() || ''}&&Status=${filter.status || ''}` +
            `&&GroupReference=${filter.groupReference || ''}&&NationalId=${filter.nationalId || ''}&&Page=${page}&&Rows=${rows}`;

        return this._http.get<any>(`${environment.queryModel}PharmacyPendingAreaManagement` + queryString);
    }
}

