import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class BedAvailabilityService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.bedAvailability.url;
    }


    insert(data: any) {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    update(data: any) {
        return this._http.put<any>(this.url, JSON.stringify(data));

    }
}
