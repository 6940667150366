export class ReceiverNotification {
    id: string;
    body: string;
    url: string;
    module: number;
    notificationType: number;
    receiverModule: number;
    markAsRead: boolean;
    createdBy: number;
    receiverId: string;
    facilityId: number;
    payLoad: string;
    createdDate: Date;
    notificationAction: any;
}
