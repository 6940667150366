export class SentExternalLaboratory {
    SpecimenTransactionId: number;
    OrderId: number;
    PatientName: string;
    ItemCode: string;
    SentExternalLaboratoryStatus?: number;
    SendDate?: Date;
    ExternalLaboratoryId?: number;
    ExternalLabTitle: string;
    ExternalLabForeignTitle: string;
    DefaultExternalLab?: number;
    TestDefinitionCost?: number;
    ExternalLabCost?: number;
    EncounterId: number;
    PatientCode: string;
    PatientId?: number;
    PatientTypeId: number;
    OrderDoctorId?: number;
    OrderDate?: Date;
    ResultType?: number;
    IsComplete?: boolean;
    IsRelease?: boolean;
    CompleteBy?: number;
    FacilityId?: number;
    DocumnetsCount: number;
}
