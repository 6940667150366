import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class HangFireApiConfiguration {
    server: string;

    constructor() {
        this.server = environment.hangFire;
    }

    claimAttachment = {
        url: 'ClaimAttachment',
        retry: 'ClaimAttachment/Retry',
    }
}
