import {
    OncologyServiceApiConfiguration,
    OncologyService,

} from './services/oncology-service';

export const NGA_ONCOLOGY_SERVICES = [
    OncologyServiceApiConfiguration,
    OncologyService,
];
