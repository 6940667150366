import { DosesTime } from ".";

export class Frequency {

    Id: number;
    Title: string;
    ForeignTitle: string;
    Code: string;
    Repetition: number;
    Duration: number;
    DurationType: number;
    FacilityId: number;
    Status: boolean;
    DosesTimes: DosesTime[] = [];
    OnceADay: boolean;
    SecondDoseGap?: number;

    constructor() { }
}
