import {
    CheckListSetupService,
    InstrumentService,
    OperationApiConfiguration,
    RoomBookedService,
    OperationBedService
} from './services/operation';

export const NGA_OPERATION_SERVICES = [
    CheckListSetupService,
    InstrumentService,
    OperationApiConfiguration,
    RoomBookedService,
    OperationBedService
];
