import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { MainFundTransaction } from 'app/shared/models/queryModel/main-fund-transaction';
@Injectable()
export class MainFundSearchService extends ServiceBase {

    private actionUrl: string;
    private _get: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.mainFund.url;
    }

    getMainFundTransaction = (fromDate, toDate): Observable<MainFundTransaction[]> => {
        return this._http.get<any>(this._get
            + '?fromDate=' + fromDate.toLocaleDateString()
            + '&&toDate=' + toDate.toLocaleDateString());
    }
}
