import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable';
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

import { NumericTestsDefinition } from 'app/shared/models/lab/numeric-tests-definition';
import { TestDefinition } from '../../../models/lab/testDefinition';
// models
import { MachineTest } from '../../../models/lab/machine-test';
import { Machine } from '../../../models/lab/machine';
import { of } from 'rxjs';

@Injectable()
export class TestsMappingService extends ServiceBase {

    private actionUrl: string;
    private _getById: string;
    private _url: string;

    private _getMachines: string;
    private _getMachineTests: string;
    private _addTestCode: string;
    private _updateTestCode: string;
    // to be deleted
    private _getTestdefinitionGroup: string;
    private _getTestsDefinitionSingle: string;
    private _getCalculatedTests: string;
    private _getNumericTests: string;
    private _getIncludeTestNormalRange: string;
    private _getIncludeResultFormulaTests: string;
    private getTestsFormulasUrl: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.testMapping.url;

        this._getMachines = this.actionUrl + _configuration.testMapping.getMachines;
        this._getMachineTests = this.actionUrl + _configuration.testMapping.getMachineTests;
        this._addTestCode = this.actionUrl + _configuration.testMapping.addTestCode;
        this._updateTestCode = this.actionUrl + _configuration.testMapping.updateTestCode;
        // to be deleted 
        this._getById = this.actionUrl + _configuration.testDefinition.url;
        this._getTestsDefinitionSingle = this.actionUrl + _configuration.testDefinition.getTestsDefinitionSingle;
        this._getTestdefinitionGroup = this.actionUrl + _configuration.testDefinition.getTestsDefinitionGroup;
        this._getCalculatedTests = this.actionUrl + _configuration.testDefinition.getCalculatedTests;
        this.getTestsFormulasUrl = this.actionUrl + _configuration.testDefinition.getTestsFormulasUrl;
        this._getNumericTests = this.actionUrl + _configuration.testDefinition.getNumericTests;
        this._getIncludeTestNormalRange = this.actionUrl + _configuration.testDefinition.getIncludeTestNormalRange;
        this._getIncludeResultFormulaTests = this.actionUrl + _configuration.testDefinition.getIncludeResultFormulaTests;
    }

    getMachines = (): Observable<Machine[]> => {
        return this._http.get<any>(this._getMachines)
    }

    getMachineTests = (machineId: number): Observable<MachineTest[]> => {
        const url = `${this._getMachineTests}?Id=${machineId}`;
        return this._http.get<any>(url)
    }

    addTestCode(data: MachineTest) {
        return this._http.post<any>(this._addTestCode, JSON.stringify(data));
    }

    updateTestCode = (data: MachineTest): Observable<any> => {
        return this._http.put<any>(this._updateTestCode, data);
    }

    // to be deleted 
    get = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getTestsDefinitionGroup = (): Observable<any> => {
        return this._http.get<any>(this._getTestdefinitionGroup);
    }

    getTestsDefinitionSingle = (labSection: number, specimenContainer: number, specimenType: number, description: string): Observable<any> => {
        const url = `${this._getTestsDefinitionSingle}?labSection=${labSection}&&specimenContainer=${specimenContainer}&&specimenType=${specimenType}&&description=${description}`;
        return this._http.get<any>(url);
    }

    getCalculatedTests = (): Observable<any> => {
        return this._http.get<any>(this._getCalculatedTests);
    }

    getTestsFormulas = (): Observable<any> => {
        return this._http.get<any>(this.getTestsFormulasUrl);
    }

    getNumericTests = (id: number): Observable<NumericTestsDefinition[]> => {
        const url = `${this._getNumericTests}/${id}`;
        return this._http.get<any>(url)
    }

    getIncludeResultFormulaTests = (id: number): Observable<TestDefinition> => {
        const url = `${this._getIncludeResultFormulaTests}/${id}`;
        return this._http.get<any>(url)
    }

    getTestTypes = (): Observable<any> => {
        let testTypes = [];
        testTypes.push({ id: 1, title: 'Group' });
        testTypes.push({ id: 2, title: 'Single' });

        return of(testTypes);
    }

    getRequireReview = (): Observable<any> => {
        let requireReview = [];
        requireReview.push({ id: 1, title: 'Always' });
        requireReview.push({ id: 2, title: 'Non Normal Result' });
        requireReview.push({ id: 3, title: 'Never' });

        return of(requireReview);
    }

    getGender = (): Observable<any> => {
        let gender = [];
        gender.push({ id: 1, title: 'Male' });
        gender.push({ id: 2, title: 'Female' });
        gender.push({ id: 3, title: 'Both' });

        return of(gender);
    }

    getTestLocations = (): Observable<any> => {
        let locations = [];
        locations.push({ id: 1, title: 'Local' });
        locations.push({ id: 2, title: 'External' });
        locations.push({ id: 3, title: 'Both' });
        return of(locations);
    }

    getById = (id): Observable<any> => {
        const url = `${this._getById}/${id}`;
        return this._http.get<any>(url);
    }

    add(data: any) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    put(id: number, data: any) {
        const url = `${this._url}/${id}`;
        return this._http.put<any>(url, JSON.stringify(data));
    }

    updateFormula(id: number, formula: string) {
        const url = `${this._url}?id=${id}&&formula=${encodeURIComponent(formula)}`;
        return this._http.patch(url, null);
    }

    delete(id: number) {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }

    getIncludeTestNormalRange = (testId: number): Observable<TestDefinition> => {
        const url = `${this._getIncludeTestNormalRange}?testId=${testId}`;
        return this._http.get<any>(url)
    }
}
