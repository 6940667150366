import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificationService } from 'app/shared/services/notificationService/notification.service';
import { BaseComponent } from 'app/shared/infrastructure';
import { DynamicComponentDirective } from 'app/shared/directives';
import { ReceiverNotification } from 'app/shared/models/hubs';
import { ReceiveNotificationService } from 'app/shared/services';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'notification-dynamic-cmp',
    template: '<ng-template dynamicComponent></ng-template>',
    encapsulation: ViewEncapsulation.None,
})

export class NotificationDynamicComponent extends BaseComponent implements OnDestroy {

    @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponentDirective!: DynamicComponentDirective;

    constructor(
        private _notificationService: NotificationService,
        private _receiveNotificationService: ReceiveNotificationService) {
        super();
        this._notificationService.onOpenNotification.subscribe((value: ReceiverNotification | string) => {
            if (typeof value === 'string') {
                this.getNoteById(value);
            }
            else {
                this.onOpenNote(value);
            }
        });
    }

    async getNoteById(id: string) {
        const note = await firstValueFrom(this._receiveNotificationService.getById(id));
        if (!note?.id) {
            return;
        }
        this.onOpenNote(note);
    }

    onOpenNote(note) {
        if (!note?.notificationAction) {
            return;
        }
        const viewContainerRef = this.dynamicComponentDirective.viewContainerRef;
        viewContainerRef.clear();
        this.dynamicComponentDirective.viewContainerRef.clear();
        import(`./components/${note.notificationAction.component}/${note.notificationAction.component}.component`).then((component: any) => {
            const viewContainerRef = this.dynamicComponentDirective.viewContainerRef;
            const componentRef = viewContainerRef.createComponent<any>(component[Object.keys(component)[0]]);
            componentRef.instance.payload = note.payLoad;
        });
    }

    ngOnDestroy() {
        this._notificationService.onOpenNotification.unsubscribe();
    }
}
