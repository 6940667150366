import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DiagnoseService } from 'app/shared/services';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'patient-visit-diagnosis-cmp',
    templateUrl: './patient-visit-diagnosis.html'
})

export class PatientVisitDiagnosisComponent {

    @ViewChild('diagnosisModal') public diagnosisModal: ModalDirective;
    @Output() setDiagnoses = new EventEmitter<any>();

    items: any[] = [];
    selectedItems: any[] = [];
    diagnosticObject: any = '';

    constructor(private diagnoseService: DiagnoseService) {
    }

    filterDiagnosis(event) {
        this.diagnoseService.getBySearch(event.query)
            .subscribe((data: any[]) => {
                this.items = data;
            });
    }

    setDiagnosis(event: any) {
        const duplicateItem = this.selectedItems.find(f => f.Id === event.Id);
        if(event.Id && !duplicateItem) {
            this.selectedItems.push(event);
        }
        this.clearDiagnosis();
    }

    clearDiagnosis() {
        this.diagnosticObject = null;
    }

    onToggleDelete(item) {
        this.selectedItems = this.selectedItems.filter(f => f !== item);
    }

    onSetDiagnoses() {
        this.diagnosisModal.hide();
        this.setDiagnoses.emit(this.selectedItems.map(m => m.Id));
    }
}
