import { SelectItem } from 'primeng/api';
import { NormalRangeListEnum } from 'app/shared/models/lab/enums/normal-range-list';

export class NormalRangeListTypeLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Normal ', value: NormalRangeListEnum.Normal },
            { label: 'Abnormal High ', value: NormalRangeListEnum.AbnormalHigh },
            { label: 'Abnormal Low ', value: NormalRangeListEnum.AbnormalLow },
            { label: 'Panic ', value: NormalRangeListEnum.Panic },
            { label: 'Absurd ', value: NormalRangeListEnum.Absurd }];
    }

}