export class ApprovalInvoiceDetails{
    Id:number;
    ActionId:number;
    Title:string;
    TotalAmount:number = 0;
    DiscountAmount:number = 0;
    CashAmount:number = 0;
    CreditAmount:number = 0;
    CoveragePercentage:number = 0;
    CoverageLimit:number = 0;
}