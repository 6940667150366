import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemRelation } from '../../../models/orders-and-billing/item-relation';
import { ItemTreeNode } from '../../../models/orders-and-billing/item-tree-node';
import { ParentItemSearchFilter } from '../../../models/orders-and-billing/parent-item-search-filter';

@Injectable()
export class ParentItemService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private bySearchUrl: string;
    private leafUrl: string;
    private treeUrl: string;
    private suggestedChildItemUrl: string;
    private itemBySearchUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.parentItem.url;
        this.leafUrl = this.actionUrl + _configuration.parentItem.leaf;
        this.treeUrl = this.actionUrl + _configuration.parentItem.tree;
        this.suggestedChildItemUrl = this.actionUrl + _configuration.parentItem.suggestedChildItem;
        this.itemBySearchUrl = this.actionUrl + _configuration.parentItem.itemBySearch;
    }

    public getLeaf = (): Observable<Item[]> => {
        return this._http.get<any>(this.leafUrl)


    }

    getTree = (parentId: number = null, query: string = null): Observable<ItemTreeNode[]> => {
        const url = `${this.treeUrl}?parentId=${parentId || ''}&query=${query || ''}`;

        return this._http.get<any>(url)


    }

    public getSuggestedChildItem = (query: string): Observable<Item[]> => {
        return this._http.get<any>(this.suggestedChildItemUrl
            + '?query=' + query
        )
    }

    public getItemBySearch = (parentItemSearchFilter: ParentItemSearchFilter): Observable<any> => {
        parentItemSearchFilter.Code = parentItemSearchFilter.Code ? parentItemSearchFilter.Code : "";
        parentItemSearchFilter.Description = parentItemSearchFilter.Description ? parentItemSearchFilter.Description : "";

        return this._http.get<any>(this.itemBySearchUrl
            + '?ParentId=' + parentItemSearchFilter.ParentId
            + '&&Code=' + parentItemSearchFilter.Code
            + '&&Description=' + parentItemSearchFilter.Description
            + '&&IsOrderable=' + parentItemSearchFilter.IsOrderable
            + '&&IsChargable=' + parentItemSearchFilter.IsChargable
            + '&&IsVariablePrice=' + parentItemSearchFilter.IsVariablePrice
        )


    }
}