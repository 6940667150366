import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from 'app/shared/infrastructure';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorPatientService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    checkExpireDocuments = (id: number, isRaise: boolean): Observable<any> => {
        return this._http.get<any>(`${environment.httpAggregatorApi}Patient/CheckExpireDocuments?PatientId=${id}&IsRaise=${isRaise}`)
    }

    CheckEligibilityRequest = (payerId: number, documentNumber: string, documentType: number, purposeId: number): Observable<any> => {
        return this._http.get<any>(`${environment.httpAggregatorApi}Patient/CheckEligibilityRequest/${payerId}/${documentNumber}/${documentType}/${purposeId}`)
    }

    NaphiseCheckEligibility = (data): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}Patient/NaphiseCheckEligibility`, JSON.stringify(data));
    }

    WaseelCheckEligibility = (data): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}Patient/WaseelCheckEligibilityByDocument`, JSON.stringify(data));
    }

    WaseelCheckEligibilityByPatient = (data): Observable<any> => {
        return this._http.post<any>(`${environment.httpAggregatorApi}Patient/WaseelCheckEligibilityByPatientId`, JSON.stringify(data));
    }
}