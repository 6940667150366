import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ServiceBase } from 'app/shared/infrastructure';

// Models

@Injectable()
export class UserService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    userValidator(username: string, password: string): Observable<any> {
        const url = `${this.authorityUrl}/api/User/ValidateUser`;
        let data = {
            UserName: username,
            Password: password
        }
        return this._http.post<any>(url, JSON.stringify(data))
            .pipe(map(result => { return result; }));
    }

    getUserByUserName(username: string): Observable<any> {
        const url = `${this.authorityUrl}/api/User/ByUserName/${username}`;
        return this._http.get<any>(url)
            .pipe(map(result => { return result; }));
    }
}
