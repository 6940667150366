export class Section {

    Id: number;
    SectionNo: number;
    Title: string;
    ForeignTitle: string;
    ShowPreviousResult?: number;
    AccessionNoReset?: number;
    AccessionNoPrefix: string;
    AccessionResetDate?: Date;
    ItemId:number;
    ItemCode:string;

    constructor() { }
}