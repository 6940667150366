import {
    AdvanceSearchRegistrationInformationComponent,
    AdvanceSearchRegistrationInformationAllStatusComponent,
    AdvanceSearchPreAdmissionComponent,
    AdvanceSearchAdmissionComponent,
    AdvanceSearchAuditOrderComponent,
    AdvanceSearchReservationComponent,
    AdvanceSearchEmergencyStationComponent,
    AdvanceSearchTriageComponent,
    AdvanceSearchOutPatientStationComponent,
    AdvanceSearchPriceListItemComponent,
    AdvanceSearchTransferComponent,
    AdvanceSearchDischargeComponent,
    AdvanceSearchSpecimenTransactionTextResult,
    AdvanceSearchSensitivityResultEntry,
    AdvanceSearchNormalResultEntry,
    AdvanceSearchLabOrderSpecimenTest,
    AdvanceSearchExternalOrderStationComponent,
    ItemCodeComponent,
    AdvanceSearchTatMonitorComponent,
    AdvanceSearchClaimManagementComponent,
    VisitAdmissionsComponent,
    GeneralMedicalShortcutComponent,
    SidebarNotificationComponent,
    AdvanceSearchClaimManagementTrackingComponent,
    AdvanceSearchStatementTransactionComponent,
    AdvanceSearchSapAccountReceivableInvoiceComponent,
    NotificationsMediaBodyComponent,
    AdvanceSearchClaimSettlementStationComponent,
    PatientDiagnosisInformationComponent,
    MedispanResultComponent,
    AdvanceSearchReceiveOrder,
    LabResultComponent,
    LabResultByEncountersComponent,
    MedispanLogResultComponent,
    LabViewAttachmentComponent,
    NationalCodeInputComponent,
    PatientDemographicInfoComponent,
    PatientShortcutComponent,
    ItemStructureShortcutComponent,
    MOHContractSetupComponent,
    PatientInformationOrderSearchComponent,
} from './components';


import {
    AdmissionResultComponent,
    EmergencyStationResultComponent,
    VisitAdmissionsResultComponent,
    VisitAdmissionsBarcodeResultComponent,
    VisitAdmissionsOrderResultComponent

} from './component-results';

export const NGA_BUSINESS_COMPONENTS = [
    AdvanceSearchRegistrationInformationComponent,
    AdvanceSearchRegistrationInformationAllStatusComponent,
    AdvanceSearchPreAdmissionComponent,
    AdvanceSearchAdmissionComponent,
    AdvanceSearchAuditOrderComponent,
    AdvanceSearchReservationComponent,
    AdvanceSearchEmergencyStationComponent,
    AdvanceSearchTriageComponent,
    AdvanceSearchOutPatientStationComponent,
    AdvanceSearchPriceListItemComponent,
    AdvanceSearchTransferComponent,
    AdvanceSearchDischargeComponent,
    AdvanceSearchSpecimenTransactionTextResult,
    AdvanceSearchSensitivityResultEntry,
    AdvanceSearchNormalResultEntry,
    AdvanceSearchLabOrderSpecimenTest,
    AdvanceSearchExternalOrderStationComponent,
    AdvanceSearchStatementTransactionComponent,
    ItemCodeComponent,
    AdvanceSearchTatMonitorComponent,
    AdvanceSearchClaimManagementComponent,
    VisitAdmissionsComponent,
    GeneralMedicalShortcutComponent,
    SidebarNotificationComponent,
    AdvanceSearchClaimManagementTrackingComponent,
    AdvanceSearchSapAccountReceivableInvoiceComponent,
    AdmissionResultComponent,
    EmergencyStationResultComponent,
    VisitAdmissionsResultComponent,
    NotificationsMediaBodyComponent,
    VisitAdmissionsBarcodeResultComponent,
    AdvanceSearchClaimSettlementStationComponent,
    PatientDiagnosisInformationComponent,
    MedispanResultComponent,
    AdvanceSearchReceiveOrder,
    LabResultComponent,
    LabResultByEncountersComponent,
    MedispanLogResultComponent,
    LabViewAttachmentComponent,
    NationalCodeInputComponent,
    PatientDemographicInfoComponent,
    PatientShortcutComponent,
    ItemStructureShortcutComponent,
    MOHContractSetupComponent,
    VisitAdmissionsOrderResultComponent,
    PatientInformationOrderSearchComponent
];
