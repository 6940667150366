import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlApiConfiguration } from '../apiConfiguration';
import { StorageService } from '../../storage.service';


@Injectable()
export class AuthorizeService {

    private actionUrl: string;
    private url: string;
    public permissions;

    constructor(private _http: HttpClient
        , private configuration: ControlApiConfiguration
        , private storageService: StorageService) {
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.rolePermission.userPermissions;
    }

    async getUserPermission() {
        const response = await this._http.get<any>(this.url, { withCredentials: true }).toPromise();
        this.storageService.secureStorage.setItem('permissions', JSON.stringify(response));
        return response;
    }

    hasPermission = (permission: string): boolean => {

        const permissions = JSON.parse(this.storageService.secureStorage.getItem('permissions'));
        if (permissions && (permissions.length > 0)) {
            return permissions.findIndex(p => p === permission) > -1;
        }
        return false;
    }

    clearPermissions() {
        return this.storageService.secureStorage.clear();
    }
}

