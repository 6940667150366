export enum AdmissionStatus {
    /**
     * Open
     */
    InHospital = 1,
    /**
     * Doctor Release
     */
    ReleasePermission = 2,
    DrugCardClosed = 3,
    FinanceSettled = 4,
    FinalDischarge = 5,
    Canceled = 6,
    Halted = 7,
}
