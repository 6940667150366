import { Menu } from 'app/shared/models/control/menu';
import { NursingManagementLink } from 'app/shared/models/enum/nursing-management-link';
import { OperationManagementLink } from 'app/shared/models/enum/operation-management-link';
import { CpoeManagementLink } from 'app/shared/models/enum/cpoe-management-link';
import { OncologyManagementLink } from 'app/shared/models/enum/oncology-management-link';
import { AppointmentLinks } from 'app/shared/models/enum/appointment-link';
import { CateringLinks } from 'app/shared/models/enum';

export const PagesMenu: Menu[] = [

    {
        id: 1,
        title: 'Certacure Control',
        path: 'control',
        icon: 'fa-solid fa-house fs-11',
        isMain: true,
        children: [
            {
                path: 'userManagement',
                id: 2,
                url: '/pages/control/userManagement',
                title: 'User Management',
                lastChild: true,
            },
            {
                id: 2000,
                path: 'userReport',
                title: 'User Report',
                lastChild: true,
                reportTitle: 'dyn_security',
                children: [
                    {
                        id: 2001,
                        path: 'privilegesReport',
                        url: '/pages/userReports/PrivilegeReport',
                        title: 'Privilege Report',
                        icon: 'Reports',
                        description: 'PrivilegeReportDescription',
                    },
                ],
            },
            {
                path: 'systemParameter',
                id: 3,
                title: 'System Parameter',
                lastChild: true,
                children: [
                    {
                        id: 5,
                        path: 'diagnosis',
                        url: '/pages/control/diagnosis',
                        title: 'Diagnosis',
                        icon: 'Diagnosis',
                        description: 'DiagnosisDescription',
                    },
                    {
                        id: 451,
                        path: 'allergy',
                        url: '/pages/control/allergy',
                        title: 'Allergy',
                        icon: 'Allegry',
                        description: 'CompanionDescription',
                    },
                    {
                        id: 6,
                        path: 'facility',
                        url: '/pages/control/facility',
                        title: 'Facility',
                        icon: 'Facility',
                        description: 'FacilityDescription',
                    },
                    {
                        id: 7,
                        path: 'medicalCategories',
                        url: '/pages/control/medicalCategories',
                        title: 'Medical Categories',
                        icon: 'MedicalCategory',
                        description: 'MedicalCategoryDescription',
                    },
                    {
                        id: 8,
                        path: 'systemCodes',
                        url: '/pages/control/systemCodes',
                        title: 'System Codes',
                        icon: 'SystemCodes',
                        description: 'SystemCodesDescription',
                    },
                    {
                        id: 10,
                        path: 'admissionReason',
                        url: '/pages/administrative/generalDefinition/admissionReason',
                        title: 'Admission Reason',
                        icon: 'AdmissionReason',
                        description: 'AdmissionReasonDescription',
                    },
                    {
                        id: 11,
                        path: 'admissionClass',
                        url: '/pages/administrative/generalDefinition/admissionClass',
                        title: 'Classes',
                        icon: 'Classes',
                        description: 'AdmissionClassesDescription',
                    },
                    {
                        id: 12,
                        path: 'admissionRoom',
                        url: '/pages/administrative/generalDefinition/admissionRoom',
                        title: 'Rooms',
                        icon: 'Rooms',
                        description: 'AdmissionRoomDescription',
                    },
                    {
                        id: 13,
                        path: 'consultationTypes',
                        url: '/pages/administrative/medicalDefinition/consultationTypes',
                        title: 'Consultation Types',
                        icon: 'ConsultationTypes',
                        description: 'ConsultationTypesDescription',
                    },
                    {
                        id: 14,
                        path: 'hospitalClinicAndDoctor',
                        url: '/pages/administrative/medicalDefinition/hospitalClinicAndDoctor',
                        title: 'Clinic & Doctor',
                        icon: 'ClinicalAndDoctor',
                        description: 'ClinicalAndDoctorDescription',
                    },
                    {
                        id: 15,
                        path: 'staffCategoriesItem',
                        url: '/pages/control/staffCategoriesItem',
                        title: 'Item Staff Configuration',
                        icon: 'Items',
                        description: 'ClinicalAndDoctorDescription',
                    },
                    {
                        id: 220,
                        path: 'userSections',
                        url: '/pages/control/userSections',
                        title: 'User Sections',
                        icon: 'SystemCodes',
                        description: 'UserSectionsDescription',
                    },
                    {
                        id: 16,
                        path: 'staffDefinition',
                        url: '/pages/control/staffDefinition',
                        title: 'Staff Definition',
                        icon: 'ClinicalNotes',
                        description: 'ClinicalAndDoctorDescription',
                    },
                    {
                        id: 17,
                        path: 'organizationSection',
                        url: '/pages/control/organizationSection',
                        title: 'Organization Section',
                        icon: 'ConfigurationDetails',
                        description: 'PblSetupDescription',
                    },
                    {
                        id: 18,
                        path: 'Bank',
                        url: '/pages/control/Bank',
                        title: 'Bank',
                        icon: 'ConfigurationDetails',
                        description: 'BankDescription',
                    },
                    {
                        id: 19,
                        path: 'revenueSectionDefinition',
                        url: '/pages/control/revenueSectionDefinition',
                        title: 'Revenue Section Definition',
                        icon: 'ConfigurationDetails',
                        description: 'RevenueSectionDefinitionDescription',
                    },
                    {
                        id: 20,
                        path: 'assignment',
                        url: '/pages/control/assignment',
                        title: 'Assignment',
                        icon: 'ConfigurationDetails',
                        description: 'AssignmentDescription',
                    },
                    {
                        id: 21,
                        path: 'carePlanShortcut',
                        url: '/pages/control/carePlanShortcut',
                        title: 'Care Plan Shortcuts',
                        icon: 'ConfigurationDetails',
                        description: 'CarePlanShortcutDescription',
                    },
                    {
                        id: 22,
                        path: 'claimManagementMapping',
                        url: '/pages/control/claimManagementMapping',
                        title: 'claim Management Mapping',
                        icon: 'ConfigurationDetails',
                        description: 'claimManagementMappingDescription',
                    },
                    {
                        id: 23,
                        path: 'medispanSetting',
                        url: '/pages/control/medispanSetting',
                        title: 'Medi-Span Setting',
                        icon: 'ConfigurationDetails',
                        description: 'medispanSettingDescription',
                    },
                    {
                        id: 24,
                        path: 'smartconfig',
                        url: '/pages/control/smartconfig',
                        title: 'Smart Form Linkage Configuration',
                        icon: 'ConfigurationDetails',
                        description: 'smartFormLinkageConfigurationDescription',
                    },
                    {
                        id: 25,
                        path: 'itemInstruction',
                        url: '/pages/control/itemInstruction',
                        title: 'Item Instruction',
                        icon: 'ConfigurationDetails',
                        description: 'itemInstructionDescription',
                    },
                    {
                        id: 26,
                        path: 'doctorsSpecialty',
                        url: '/pages/control/doctorsSpecialty',
                        title: 'Doctors Specialties',
                        icon: 'ConfigurationDetails',
                        description: 'doctorsSpecialtiesDescription',
                    },
                    {
                        id: 27,
                        path: 'passwordPolicy',
                        url: '/pages/control/passwordPolicy',
                        title: 'Password Policy',
                        icon: 'ConfigurationDetails',
                        description: 'passwordPolicyDescription',
                    },

                    {
                        id: 28,
                        path: 'diagnosisGroups',
                        url: '/pages/control/diagnosisGroups',
                        title: 'diagnosis Groups',
                        icon: 'ConfigurationDetails',
                        description: 'PayerDiagnosisGroups',
                    },
                    {
                        id: 29,
                        path: 'payerTemplate',
                        url: '/pages/control/payerTemplate',
                        title: 'Payer Template',
                        icon: 'ConfigurationDetails',
                        description: 'payerTemplatesDescription',
                    },
                    {
                        id: 30,
                        path: 'settlementDeductionSetup',
                        url: '/pages/control/settlementDeductionSetup',
                        title: 'Settlement Deduction Setup',
                        icon: 'ConfigurationDetails',
                        description: 'settlementDeductionSetupDescription',
                    },
                    {
                        id: 32,
                        path: 'notificationRoles',
                        url: '/pages/control/notificationRoles',
                        title: 'Notification Roles',
                        icon: 'ConfigurationDetails',
                        description: 'notificationRolesDescription',
                    },
                    {
                        id: 34,
                        path: 'receiveOrderSetup',
                        url: '/pages/control/receiveOrderSetup',
                        title: 'Receive Order Setup',
                        icon: 'ConfigurationDetails',
                        description: 'receiveOrderSetupDescription',
                    },
                    {
                        id: 35,
                        path: 'patientProblem',
                        url: '/pages/control/patientProblem',
                        title: 'Patient Problem',
                        icon: 'ConfigurationDetails',
                        description: 'Patient Problems',
                    },
                    {
                        id: 36,
                        path: 'doctorExperienceSetup',
                        url: '/pages/control/doctorExperienceSetup',
                        title: 'Doctor Experience Setup',
                        icon: 'ConfigurationDetails',
                        description: 'Doctor Experience Setup',
                    },
                ],
            },
            {
                path: 'itemStructure',
                id: 128,
                title: 'Item Structure',
                lastChild: true,
                children: [
                    {
                        id: 129,
                        path: 'items',
                        url: '/pages/itemStructure/items',
                        title: 'Items',
                        icon: 'Items',
                        description: 'ItemDescription',
                    },
                    {
                        id: 130,
                        path: 'parentItems',
                        url: '/pages/itemStructure/parentItems',
                        title: 'Parent Items',
                        icon: 'ParentItems',
                        description: 'PatrentItemdescription',
                    },
                ],
            },
            {
                path: 'dashboard',
                id: 135,
                title: 'Dashboard',
                lastChild: true,
                children: [
                    {
                        id: 140,
                        path: 'hospitalPerformanceDashboard',
                        url: '/pages/hospitalPerformanceDashboard',
                        title: 'Hospital Performance Dashboard',
                        icon: 'TransactionType',
                        description: 'CompanionDescription',
                    },
                    {
                        id: 141,
                        path: 'newDoctorPerformanceDashboard',
                        url: '/pages/control/newDoctorPerformanceDashboard',
                        title: 'Doctor Performance Dashboard V2',
                        icon: 'TransactionType',
                        description: 'CompanionDescription',
                    },
                ],
            },
            {
                path: 'integrationControl',
                id: 138,
                title: 'Integration Control',
                lastChild: true,
                children: [
                    {
                        id: 139,
                        path: 'sapControl',
                        url: '/pages/control/sapControl',
                        title: 'SAP Control',
                        icon: 'TransactionType',
                        description: 'SAPDescription',
                    },
                    {
                        id: 142,
                        path: 'lisControl',
                        url: '/pages/control/lisControl',
                        title: 'LIS Control',
                        icon: 'TransactionType',
                        description: 'LISDescription',
                    },
                    {
                        id: 143,
                        path: 'sapUnPostedTransactions',
                        url: '/pages/control/sapUnPostedTransactions',
                        title: 'Sap UnPosted Transactions',
                        icon: 'TransactionType',
                        description: 'SapUnPostedTransactionsDescription',
                    },
                ],
            },
            {
                id: 1000,
                title: 'Setup',
                path: 'certacureSetup',
                children: [
                    {
                        id: 1001,
                        path: 'pblSetup',
                        url: '/pages/systemSetup/pblSetup',
                        title: 'PBL Setup',
                        lastChild: true,
                    },
                    {
                        id: 1002,
                        path: 'cpoeSetup',
                        title: 'CPOE Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 1,
                                path: 'cpoeSetup',
                                url: '/pages/systemSetup/cpoeSetup',
                                title: 'Main Cpoe Setup',
                                icon: 'SystemCodes',
                                description: 'Main Setup',
                            },
                            {
                                id: 2,
                                path: 'ordersPreRequisiteMedicalEntrySetup',
                                url: '/pages/systemSetup/ordersPreRequisiteMedicalEntrySetup',
                                title: 'Orders Pre-requisite Medical Entry Setup',
                                icon: 'SystemCodes',
                                description: 'Orders Pre-requisite Medical Entry Setup',
                            },
                        ]
                    },
                    {
                        id: 1003,
                        path: 'billingSetup',
                        title: 'Billing Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 99,
                                path: 'cashierDiscount',
                                url: '/pages/patientAccounting/setup/cashierDiscount',
                                title: 'Cashier Discount',
                                icon: 'CashierDiscount',
                                description: 'CashierDiscountDescription',
                            },
                            {
                                id: 101,
                                path: 'cashierDefinition',
                                url: '/pages/patientAccounting/setup/cashierDefinition',
                                title: 'Cashier Definition',
                                icon: 'CashierDefintion',
                                description: 'CashierDefinitionDescription',
                            },
                            {
                                id: 102,
                                path: 'elementConfiguration',
                                url: '/pages/patientAccounting/setup/elementConfiguration',
                                title: 'Element Configuration',
                                icon: 'ElementConfiguration',
                                description: 'ElementConfigurationDescription',
                            },
                            {
                                id: 104,
                                path: 'visaType',
                                url: '/pages/patientAccounting/setup/visaType',
                                title: 'Visa Types',
                                icon: 'TransactionType',
                                description: 'VisaTypeDescription',
                            },
                            {
                                id: 105,
                                path: 'fund',
                                url: '/pages/patientAccounting/setup/fund',
                                title: 'Fund',
                                icon: 'TransactionType',
                                description: 'fundDiscription',
                            },
                            {
                                id: 106,
                                path: 'chargingSetup',
                                url: '/pages/patientAccounting/setup/chargingSetup',
                                title: 'Charging Setup',
                                icon: 'TransactionType',
                                description: 'ChargingSetupDescription',
                            },
                            {
                                id: 107,
                                path: 'autoCashDiscountSetup',
                                url: '/pages/patientAccounting/setup/autoCashDiscountSetup',
                                title: 'Auto Cash Discount Setup',
                                icon: 'TransactionType',
                                description: 'ChargingSetupDescription',
                            },
                            {
                                id: 108,
                                path: 'billingSetupPage',
                                url: '/pages/patientAccounting/setup/billingSetupPage',
                                title: 'Billing Setup',
                                icon: 'TransactionType',
                                description: 'Billing Setup Description',
                            },
                            {
                                id: 109,
                                path: 'autoUpdatePatientVisitAndStatementSetup',
                                url: '/pages/patientAccounting/setup/autoUpdatePatientVisitAndStatementSetup',
                                title: 'AutoUpdate Patient Visit And Statement Setup',
                                icon: 'TransactionType',
                                description: 'AutoUpdate Patient Visit And Statement Setup Description',
                            },
                            {
                                id: 110,
                                path: 'documentAlertSetup',
                                url: '/pages/patientAccounting/setup/documentAlertSetup',
                                title: 'Document Alert Setup',
                                icon: 'TransactionType',
                                description: 'Document Alert Setup Description',
                            },
                            {
                                id: 111,
                                path: 'systemCurrencySetup',
                                url: '/pages/patientAccounting/setup/systemCurrencySetup',
                                title: 'System Currency Setup',
                                icon: 'TransactionType',
                                description: 'System Currency Setup Description',
                            },
                            {
                                id: 805,
                                path: 'printerSetup',
                                url: '/pages/patientAccounting/setup/printerSetup',
                                title: 'Printer Setup',
                                icon: 'TransactionType',
                                description: 'Printer Setup Description',
                            },
                            {
                                id: 806,
                                path: 'linkServicesSectionsSetup',
                                url: '/pages/patientAccounting/setup/linkServicesSectionsSetup',
                                title: 'link Services Sections Setup',
                                icon: 'TransactionType',
                                description: 'link Services Sections Setup Description',
                            },
                        ],
                    },
                    {
                        id: 1004,
                        path: 'clinicalDocumentaionSetup',
                        url: '/pages/systemSetup/clinicalDocumentaionSetup',
                        title: 'Clinical Documentaion Setup',
                        lastChild: true,
                    },
                    {
                        path: 'OncologySetup',
                        id: 298,
                        title: 'Oncology Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 299,
                                path: 'oncologyLookupDefinition',
                                url: OncologyManagementLink.LookupDefinition,
                                isOncologyManagement: true,
                                title: 'Lookup Definition',
                                icon: 'SystemCodes',
                                description: 'Oncology Lookup Definitions',
                            },
                            {
                                id: 301,
                                path: 'oncologyProtocolDefinition',
                                url: OncologyManagementLink.ProtocolDefinition,
                                isOncologyManagement: true,
                                title: 'Protocol Definition',
                                icon: 'Items',
                                description: 'Oncology Protocol Definitions',
                            },
                        ],
                    },
                    {
                        id: 1005,
                        path: 'radiologySetups',
                        title: 'Radiology Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 302,
                                path: 'radiologyTemplateSetup',
                                url: '/pages/systemSetup/radiologyTemplateSetup',
                                title: 'Radiology Template Setup',
                                icon: 'SystemCodes',
                                description: 'Radiology Template Setup',
                            },
                            {
                                id: 303,
                                path: 'radiologySetup',
                                url: '/pages/systemSetup/radiologySetup',
                                title: 'Radiology Setup',
                                icon: 'SystemCodes',
                                description: 'Radiology Setup',
                            },
                        ],
                    },
                    {
                        id: 1006,
                        path: 'printTemplateSetup',
                        url: '/pages/systemSetup/printTemplateSetup',
                        title: 'Print Template Setup',
                        lastChild: true,
                    },
                    {
                        id: 1007,
                        path: 'emergencyRoomSetup',
                        title: 'Emergency Room Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 304,
                                path: 'triageScale',
                                url: '/pages/systemSetup/triageScale',
                                title: 'Triage Scale',
                                icon: 'SystemCodes',
                                description: 'Triage Scale Setup',
                            },
                        ],
                    },
                    {
                        id: 1008,
                        path: 'mrnSetup',
                        url: '/pages/systemSetup/mrnSetup',
                        title: 'MRN Setup',
                        lastChild: true,
                    },
                    {
                        id: 1009,
                        path: 'pharmacySetup',
                        title: 'Pharmacy Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 304,
                                path: 'pharmacySetup',
                                url: '/pages/systemSetup/pharmacySetup',
                                title: 'Pharmacy Setup',
                                icon: 'SystemCodes',
                                description: 'Pharmacy Setup',
                            },
                            {
                                id: 305,
                                path: 'pharmacyRegisterationSetup',
                                url: '/pages/systemSetup/pharmacyRegisterationSetup',
                                title: 'Pharmacy Registeration Setup',
                                icon: 'SystemCodes',
                                description: 'Pharmacy Registeration Setup',
                            },
                            {
                                id: 306,
                                path: 'fdaSetup',
                                url: '/pages/systemSetup/fdaSetup',
                                title: 'FDA Setup',
                                icon: 'SystemCodes',
                                description: 'FDA Setup',
                            },
                            {
                                id: 307,
                                path: 'discontinueTransferMedicationSetup',
                                url: '/pages/systemSetup/discontinueTransferMedicationSetup',
                                title: 'Discontinue Transfer Medication Setup',
                                icon: 'SystemCodes',
                                description: 'Discontinue Transfer Medication Setup',
                            },
                        ],
                    },
                    {
                        id: 1008,
                        path: 'mobileSetup',
                        url: '/pages/systemSetup/mobileSetup',
                        title: 'Mobile Setup',
                        isMobile: true,
                        lastChild: true,
                    },
                    {
                        id: 1009,
                        path: 'medicalFileSetup',
                        url: '/pages/systemSetup/medicalFileSetup',
                        title: 'Medical File Setup',
                        lastChild: true,
                    },
                    {
                        id: 1010,
                        path: 'vitalSignSetup',
                        url: '/pages/systemSetup/vitalSignSetup',
                        title: 'Vital Sign Setup',
                        lastChild: true,
                    },
                    {
                        id: 1011,
                        path: 'admissionSetup',
                        url: '/pages/systemSetup/admissionSetup',
                        title: 'Admission Setup',
                        lastChild: true,
                    },
                    {
                        id: 1012,
                        path: 'specialityWidgetSetup',
                        url: '/pages/systemSetup/specialityWidgetSetup',
                        title: 'Speciality Widget Setup',
                        lastChild: true,
                    },
                    {
                        id: 1013,
                        path: 'eclaimSetup',
                        url: '/pages/systemSetup/eclaimSetup',
                        title: 'E-Claim Setup',
                        lastChild: true,
                    },
                    {
                        id: 1013,
                        path: 'appointmentSetup',
                        url: '/pages/systemSetup/appointmentSetup',
                        title: 'Appointment Setup',
                        lastChild: true,
                    },
                    {
                        id: 1014,
                        path: 'smsSetup',
                        title: 'SMS Setup',
                        lastChild: true,
                        children: [
                            {
                                id: 304,
                                path: 'smsSetup',
                                url: '/pages/systemSetup/smsSetup',
                                title: 'SMS Setup',
                                icon: 'ConfigurationDetails',
                                description: 'smsSetupDescription Setup',
                            },
                            {
                                id: 305,
                                path: 'smsSetup/manualSMSSendingSetup',
                                url: '/pages/systemSetup/smsSetup/manualSMSSendingSetup',
                                title: 'Manual SMS Sending Setup',
                                icon: 'ConfigurationDetails',
                                description: 'Manual SMS Sending Setup',
                            },
                        ]
                    },
                ],
            },
            {
                id: 100,
                title: 'Audit',
                path: 'audit',
                children: [{
                    path: 'supplyChain',
                    id: 1,
                    title: 'Supply Chain',
                    lastChild: true,
                    children: [
                        {
                            id: 100,
                            path: 'issueStock',
                            url: '/pages/supplyChain/orderIssueStock',
                            title: 'Issue Stock',
                            icon: 'ConfigurationDetails',
                            description: 'Issue Stock'
                        },
                        {
                            id: 101,
                            path: 'returnRequest',
                            url: '/pages/supplyChain/orderReturnRequest',
                            title: 'Return Request',
                            icon: 'ConfigurationDetails',
                            description: 'Return Request'
                        },
                    ],
                }
                ]
            },
        ],
    },
    {
        id: 15,
        title: 'Patient Administration System',
        path: 'adminstrative',
        icon: 'fa-solid fa-tv fs-10',
        isMain: true,
        children: [
            {
                id: 501,
                path: 'patientMasterIndex',
                title: 'Patient Master Index',
                lastChild: true,

                children: [
                    {
                        id: 1,
                        path: 'patientRegistration',
                        url: '/pages/administrative/patient/patientRegistration',
                        title: 'Patient Registration',
                        icon: 'PatientRegestration',
                        description: 'PatientRegistrationDescription',
                    },
                    {
                        id: 2,
                        path: 'checkEligibility',
                        url: '/pages/administrative/patient/checkEligibility',
                        title: 'Check Eligibility',
                        icon: 'PatientRegestration',
                        description: 'Check Eligibility',
                    },
                    {
                        id: 3,
                        path: 'patientMasterIndexPage',
                        url: '/pages/administrative/patient/patientMasterIndexPage',
                        title: 'patient Master Index',
                        icon: 'PatientRegestration',
                        description: 'patient Master Index',
                    },
                ],
            },
            {
                id: 16,
                path: 'admissionDischargeAndTransfer',
                title: 'In-Patient Management',
                lastChild: true,

                children: [
                    {
                        id: 19,
                        path: 'newAdmission',
                        url: '/pages/administrative/admission/newAdmission',
                        title: 'New Admission',
                        icon: 'NewAdmission',
                        description: 'NewAdmissionDescription',
                    },
                    {
                        id: 20,
                        path: 'preAdmission',
                        url: '/pages/administrative/admission/preAdmission',
                        title: 'Schedule an Admission',
                        icon: 'Reservation',
                        description: 'ReservationDescription',
                    },
                    {
                        id: 21,
                        path: 'companion',
                        url: '/pages/administrative/admission/companion',
                        title: 'Companion',
                        icon: 'Companions',
                        description: 'CompanionDescription',
                    },
                    {
                        id: 22,
                        path: 'transfer',
                        url: '/pages/administrative/transfer',
                        title: 'Transfer',
                        icon: 'Transfer',
                        description: 'TransferDescription',
                    },
                    {
                        id: 23,
                        path: 'discharge',
                        url: '/pages/administrative/discharge',
                        title: 'Discharge',
                        icon: 'Discharge',
                        description: 'DischargeDescription',
                    },
                    {
                        id: 25,
                        path: 'cancelAdmission',
                        url: '/pages/administrative/admission/cancelAdmission',
                        title: 'Cancel Admission',
                        icon: 'CancelAdmission',
                        description: 'CancelAdmissionDescription',
                    },
                    {
                        id: 26,
                        path: 'admissionStation',
                        url: '/pages/administrative/admissionStation',
                        title: 'Admission Station',
                        icon: 'AdmissionStation',
                        description: 'AdmissionStationDescription',
                    },
                    {
                        id: 27,
                        path: 'doctorRelease',
                        url: '/pages/administrative/doctorRelease',
                        title: 'Doctor Release',
                        icon: 'Reports',
                        description: 'DoctorReleaseDescription',
                    },
                    {
                        id: 28,
                        path: 'hospitalRooms',
                        url: '/pages/administrative/hospitalRooms',
                        title: 'Hospital Rooms',
                        icon: 'Discharge',
                        description: 'hospitalRoomDescription',
                    },
                    {
                        id: 29,
                        path: 'admissionReportSetup',
                        url: '/pages/administrative/admissionReportSetup',
                        title: 'Admission Report Setup',
                        icon: 'ConfigurationDetails',
                        description: 'AdmissionReportSetupDescription',
                    },
                    {
                        id: 30,
                        path: 'consultingDoctor',
                        url: '/pages/administrative/consultingDoctor',
                        title: 'Consulting Doctor',
                        icon: 'ConfigurationDetails',
                        description: 'Doctors Admission Consulting ',
                    },
                    {
                        id: 31,
                        path: 'transferConsultationRequest',
                        url: '/pages/administrative/admission/transferConsultationRequest',
                        title: 'Transfer Consultation Request',
                        icon: 'ConfigurationDetails',
                        description: 'Transfer Consultation Request',
                    },
                ],

            },
            {
                id: 2000,
                path: 'admissionReport',
                title: 'In-Patient Report',
                lastChild: true,
                reportTitle: 'dyn_admission',
                children: [
                    {
                        id: 2001,
                        path: 'admissionReport',
                        url: '/pages/admissionReports/report',
                        title: 'Admission Report',
                        icon: 'Reports',
                        description: 'AdmissionReportDescription',
                    },
                    {

                        id: 2002,
                        path: 'activitiesReport',
                        url: '/pages/admissionReports/activitiesReport',
                        title: 'Daily Occupancy Rate Report',
                        icon: 'Reports',
                        description: '',
                    },
                    {

                        id: 2006,
                        path: 'monthlyAdmissionsStatisticsReport',
                        url: '/pages/admissionReports/monthlyAdmissionsStatisticsReport',
                        title: 'Monthly Statistics Report',
                        icon: 'Reports',
                        description: 'MonthlyAdmissionsStatisticsReportDescription',
                    },
                    {

                        id: 2007,
                        path: 'medicalDiagnosisSearchEngine',
                        url: '/pages/admissionReports/medicalDiagnosisSearchEngine',
                        title: 'Medical diagnosis Search Engine',
                        icon: 'Reports',
                        description: 'medicalDiagnosisSearchEngineDescription',
                    },
                ],
            },
            {
                id: 27,
                path: 'scheduleAndAppointment',
                title: 'Schedule And Appointment',
                lastChild: true,
                isAppointment: true,
                children: [
                    {
                        id: 28,
                        path: 'resourceManagement',
                        url: AppointmentLinks.ResourceAndPeriods,
                        title: 'Resource And Periods',
                        isAppointment: true,
                        icon: 'appointmentResource',
                        description: 'ResourceDescription',
                    },
                    {
                        id: 29,
                        path: 'appointmentTracking',
                        url: AppointmentLinks.AppointmentTracking,
                        isAppointment: true,
                        title: 'Appointment Tracking',
                        icon: 'appointmentTracking',
                        description: 'ScheduleDescription',
                    },
                    {
                        id: 30,
                        path: 'appointmentManagement',
                        url: AppointmentLinks.AppointmentStation,
                        isAppointment: true,
                        title: 'Appointment Station',
                        icon: 'appointmentStation',
                        description: 'AppointmentDescription',
                    },
                ],
            },
            {
                id: 28,
                path: 'scheduleAndAppointmentReport',
                title: 'Appointment Reports',
                isAppointment: true,
                lastChild: true,
                children: [
                    {
                        id: 29,
                        path: 'appointmentListReport',
                        url: AppointmentLinks.AppointmentReportList,
                        title: 'Appointment List',
                        isAppointment: true,
                        icon: 'Reports',
                        description: 'ResourceDescription',
                    },
                ],
            },
            {
                id: 31,
                path: 'emergencyManagement',
                title: 'Emergency Management',
                lastChild: true,
                children: [
                    {
                        id: 32,
                        path: 'emergencyStation',
                        url: '/pages/clinical/emergencyManagement/emergencyStation',
                        title: 'Emergency Station',
                        icon: 'EmeregnecyStation',
                        description: 'EmergencyStationDescription',
                    },
                    {
                        id: 33,
                        path: 'emergencyVisit',
                        url: '/pages/clinical/emergencyManagement/emergencyVisit',
                        title: 'Emergency Visit',
                        icon: 'EmergencyVisit',
                        description: 'EmergencyVisitDescription',
                    },
                    {
                        id: 3335,
                        path: 'emergencyArea',
                        url: '/pages/clinical/emergencyManagement/emergencyArea',
                        title: 'Emergency Area',
                        icon: 'EmergencyArea',
                        description: 'EmergencyAreaDescription',
                    },
                    {
                        id: 3336,
                        path: 'receiveEROrders',
                        url: '/pages/clinical/emergencyManagement/receiveEROrders',
                        title: 'Receive ER Orders',
                        icon: 'EmergencyArea',
                        description: 'Receive Emergency Orders',
                    },
                ],
            },
            {
                id: 2015,
                path: 'emergencyReports',
                title: 'Emergency Reports',
                lastChild: true,
                reportTitle: 'dyn_emergency',
                children: [
                ],
            },
            {
                path: 'outPatientManagement',
                id: 34,
                title: 'Outpatient Management',
                lastChild: true,
                children: [
                    {
                        id: 35,
                        path: 'outPatientTransaction',
                        url: '/pages/clinical/outPatientManagement/outPatientTransaction',
                        title: 'OPD Station',
                        icon: 'Transactions',
                        description: 'TransactionsDescription',
                    },
                    {
                        id: 36,
                        path: 'fileRequest',
                        url: '/pages/clinical/outPatientManagement/fileRequest',
                        title: 'File Request',
                        icon: 'FileRequest',
                        description: 'FileRequestDescription',
                    },
                    {
                        id: 37,
                        path: 'outPatientOrderManagement',
                        url: '/pages/outPatientOrderManagement',
                        title: 'Order Management',
                        icon: 'PatientOrder',
                        description: 'PatientOrder',
                    },
                    {
                        id: 38,
                        path: 'outPatientMonitor',
                        url: '/pages/clinical/outPatientManagement/outPatientMonitor',
                        title: 'Out Patient Monitor',
                        icon: 'PatientOrder',
                        description: 'Out Patient Monitor',
                    },
                    {
                        id: 39,
                        path: 'doctorsCapacityManagement',
                        url: AppointmentLinks.DoctorsCapacity,
                        title: 'Doctors Capacity',
                        isAppointment: true,
                        icon: 'ReceivePrescription',
                        description: 'Doctors Capacity Description',
                    },
                ],
            },
            {
                path: 'outPatientReports',
                id: 2014,
                title: 'Outpatient Reports',
                reportTitle: 'dyn_outpatient',
                lastChild: true,
                children: [
                ],
            },
            {
                id: 500,
                title: 'Medical Records',
                path: 'medicalRecordsManagement',
                children: [
                    {
                        id: 44,
                        path: 'mergeFile',
                        title: 'Merge File',
                        lastChild: true,
                        children: [
                            {
                                id: 45,
                                path: 'patientFileMerge',
                                url: '/pages/administrative/mergeFile/patientFileMerge',
                                title: 'Patient File Merge',
                                icon: 'PatientFileMerge',
                                description: 'PatientFileMergeDescription',
                            },
                        ],
                    },
                    {
                        id: 46,
                        lastChild: true,
                        path: 'sickLeaveStation',
                        url: '/pages/administrative/sickLeaveStation',
                        title: 'Sick Leave Station',
                    },
                ],
            },
            {
                path: 'cateringManagement',
                id: 600,
                title: 'Catering Management',
                lastChild: true,
                isCateringManagement: true,
                children: [
                    {
                        id: 1,
                        path: 'mealDefinitions',
                        url: CateringLinks.MealDefinitions,
                        title: 'Meal Definitions',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Meal Definitions',
                    },
                    {
                        id: 2,
                        path: 'medicalServicesAndRestrictions',
                        url: CateringLinks.MedicalServicesAndRestrictions,
                        title: 'Medical Services & Restrictions',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Medical Services & Restrictions',
                    },
                    {
                        id: 3,
                        path: 'scheduledMealServices',
                        url: CateringLinks.ScheduledMealServices,
                        title: 'Scheduled Meal Services',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Scheduled Meal Services',
                    },
                    {
                        id: 4,
                        path: 'scheduleMeal',
                        url: CateringLinks.ScheduleMeal,
                        title: 'Schedule Meal',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Schedule Meal',
                    },
                    {
                        id: 5,
                        path: 'sectionMealSchedulePlan',
                        url: CateringLinks.SectionMealSchedulePlan,
                        title: 'Section Meal Schedule Plan',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Section Meal Schedule Plan',
                    },
                    {
                        id: 6,
                        path: 'scheduledPatientStation',
                        url: CateringLinks.ScheduledPatientStation,
                        title: 'Scheduled Patient Station',
                        isCateringManagement: true,
                        icon: 'PatientOrder',
                        description: 'Scheduled Patient Station',
                    },
                ],
            },
            {
                path: 'queueManagementSystem',
                id: 700,
                title: 'Queue Management System',
                lastChild: true,
                children: [
                    {
                        id: 1,
                        path: 'queueMonitoring',
                        url: '/pages/clinical/queueManagementSystem/queueMonitoring',
                        title: 'Queue Monitoring',
                        icon: 'TATMonitor',
                        description: 'Queue Monitoring Definitions',
                    },
                    {
                        id: 2,
                        path: 'educationalAttachment',
                        url: '/pages/clinical/queueManagementSystem/educationalAttachment',
                        title: 'Educational Attachment',
                        icon: 'TATMonitor',
                        description: 'Educational Attachment Definitions',
                    },
                    {
                        id: 3,
                        path: 'nextPatient',
                        url: '/pages/clinical/queueManagementSystem/nextPatient',
                        title: 'Next Patient',
                        icon: 'TATMonitor',
                        description: 'Next Patient Definitions',
                    }
                ],
            },
        ],
    },
    {
        path: 'clinical',
        title: 'Clinical Information System',
        icon: 'fa-solid fa-cloud fs-10',
        id: 47,
        isMain: true,
        children: [
            {
                path: 'operationTheaterManagement',
                id: 48,
                title: 'Operation Theater Management',
                children: [
                    {
                        id: 2023,
                        title: 'Setup',
                        path: 'operationSetup',
                        lastChild: true,
                        children: [
                            {
                                id: 807,
                                path: 'checkListDefinition',
                                url: '/pages/clinical/operationManagement/setup/checkListDefinition',
                                title: 'Check List Definition',
                                icon: 'CoverageLetter',
                                description: 'Check List Definition',
                            },
                            {
                                id: 808,
                                path: 'instruments',
                                url: '/pages/clinical/operationManagement/setup/instruments',
                                title: 'Instruments',
                                icon: 'CoverageLetter',
                                description: 'Instruments',
                            },
                            {
                                id: 809,
                                path: 'operationBeds',
                                url: '/pages/clinical/operationManagement/setup/beds',
                                title: 'Beds',
                                icon: 'CoverageLetter',
                                description: 'Beds',
                            },
                        ],
                    },
                    {
                        id: 400,
                        title: 'Management',
                        path: 'management',
                        lastChild: true,
                        children: [
                            {
                                id: 49,
                                path: 'equipmentDefinition',
                                url: OperationManagementLink.EquipmentDefinition,
                                isOperationManagement: true,
                                title: 'Equipment Definition',
                                icon: 'CoverageLetter',
                                description: 'OperationEquipmentDefinitionDescription',
                            },
                            {
                                id: 50,
                                path: 'roomDefinition',
                                url: OperationManagementLink.RoomDefinition,
                                isOperationManagement: true,
                                title: 'Room Definition',
                                icon: 'Discharge',
                                description: 'RoomDefinitionDescription',
                            },
                            {
                                id: 51,
                                path: 'procedureDefinition',
                                url: OperationManagementLink.OperationProcedureDefinition,
                                isOperationManagement: true,
                                title: 'Procedure Definition',
                                icon: 'Definitions',
                                description: 'ProcedureDefinitionDescription',
                            },
                            // {
                            //     id: 52,
                            //     path: 'operationTracking',
                            //     url: OperationManagementLink.OperationTracking,
                            //     isOperationManagement: true,
                            //     title: 'Operation Tracking',
                            //     icon: 'Reports',
                            //     description: 'OperationTrackingDescription',
                            // },
                            {
                                id: 53,
                                path: 'operationSlate',
                                url: OperationManagementLink.OperationSlate,
                                isOperationManagement: true,
                                title: 'Operation Slate',
                                icon: 'OTSlate',
                                description: 'OperationSlateDescription',
                            },
                            {
                                id: 54,
                                path: 'booking',
                                url: OperationManagementLink.Booking,
                                isOperationManagement: true,
                                title: 'Booking Management',
                                icon: 'PatientOrder',
                                description: 'BookingManagementDescription',
                            },
                            {
                                id: 55,
                                path: 'operationInbox',
                                url: OperationManagementLink.OperationInbox,
                                isOperationManagement: true,
                                title: 'Inbox',
                                icon: 'CheckOutFromWard',
                                description: 'OperationInboxDescription',
                            },
                            // {
                            //     id: 56,
                            //     path: 'operationDashboard',
                            //     url: OperationManagementLink.OperationDashboard,
                            //     isOperationManagement: true,
                            //     title: 'Dashboard',
                            //     icon: 'ParentItems',
                            //     description: 'OperationDashboardDescription',
                            // },
                        ],
                    },
                    {
                        id: 2022,
                        title: 'Operation Reports',
                        reportTitle: 'dyn_operation',
                        path: 'operationReport',
                        lastChild: true,
                        children: [
                        ],
                    },
                ],
            },
            {
                path: 'nursingManagement',
                id: 51,
                title: 'Nursing Management',
                lastChild: true,
                children: [
                    {
                        id: 53,
                        path: 'nursingStationConfiguration',
                        url: '/pages/nursing/nursingStationConfiguration',
                        title: 'NursingStationConfigurationTitle',
                        icon: 'NursingStation',
                        description: 'NursingStationConfigurationDescription',
                    },
                    {
                        id: 54,
                        path: 'nursingPatientDiagnosis',
                        url: '/pages/nursing/nursingPatientDiagnosis',
                        title: 'PatientDiagnosisTitle',
                        icon: 'NursingStation',
                        description: 'PatientDiagnosisDescription',
                    },
                    {
                        id: 55,
                        path: 'nursingSupervisor',
                        url: NursingManagementLink.NursingSupervisor,
                        inNursingManagement: true,
                        title: 'NursingSupervisorTitle',
                        icon: 'NursingStation',
                        description: 'NursingSupervisorDescription',
                    },
                    {
                        id: 56,
                        path: 'nursingTimeline',
                        url: NursingManagementLink.NursingTimeline,
                        inNursingManagement: true,
                        title: 'NursingTimelineTitle',
                        icon: 'NursingStation',
                        description: 'NursingTimelineDescription',
                    },
                ],
            },
            {
                path: 'pharmacyManagement',
                id: 53,
                title: 'Pharmacy Management',
                children: [
                    {
                        path: 'definition',
                        id: 54,
                        title: 'Definition',
                        lastChild: true,
                        children: [
                            {
                                id: 55,
                                path: 'drugsIndexAndDefinition',
                                url: '/pages/clinical/pharmacyManagement/drugsIndexAndDefinition',
                                title: 'Definitions',
                                icon: 'Definitions',
                                description: 'PharmacyDefninitionDescription',
                            },
                            {
                                id: 56,
                                path: 'frequency',
                                url: '/pages/clinical/pharmacyManagement/frequency',
                                title: 'Frequencies',
                                icon: 'Frequencies',
                                description: 'PharmacyFrequencyDescription',
                            },
                        ],
                    },
                    {
                        id: 57,
                        title: 'Management',
                        path: 'management',
                        lastChild: true,
                        children: [
                            {
                                id: 58,
                                path: 'dispenseMedication',
                                url: '/pages/clinical/pharmacyManagement/dispenseMedication',
                                title: 'Dispense Medication',
                                icon: 'DispenseMedication',
                                description: 'PharmacyDescpenseMedicationDescription',
                            },
                            {
                                id: 59,
                                path: 'receiveDoctorsPrescription',
                                url: '/pages/clinical/pharmacyManagement/receiveDoctorsPrescription',
                                title: 'Receive Prescription',
                                icon: 'ReceivePrescription',
                                description: 'PharmacyReceivePrescriptionDescription',
                            },
                            {
                                id: 60,
                                path: 'returnRequest',
                                url: '/pages/clinical/pharmacyManagement/returnRequest',
                                title: 'Return Request',
                                icon: 'ReturnRequest',
                                description: 'PharmacyReturnRequestDescription',
                            },
                            {
                                id: 61,
                                path: 'adminUser',
                                url: '/pages/clinical/pharmacyManagement/adminUser',
                                title: 'Sections',
                                icon: 'Sections',
                                description: 'PharmacySectionsDescription',
                            },
                            {
                                id: 64,
                                path: 'approveReturnRequest',
                                url: '/pages/clinical/pharmacyManagement/approveReturnRequest',
                                title: 'Return Approve',
                                icon: 'ReturnApprove',
                                description: 'PharmacySectionsDescription',
                            },
                            {
                                id: 65,
                                path: 'closeReopenDrugCard',
                                url: '/pages/clinical/pharmacyManagement/closeReopenDrugCard',
                                title: 'Pharmacy Release',
                                icon: 'PharmacyRelease',
                                description: 'PharmacyReleaseDescription',
                            },
                            {
                                id: 66,
                                path: 'medicationProduct',
                                url: '/pages/clinical/pharmacyManagement/medicationProduct',
                                title: 'Medication Product',
                                icon: 'MedicationProduct',
                                description: 'PharmacyMedicationProducteDescription',
                            },
                            {
                                id: 116,
                                path: 'pharmacyDailyWorkIndex',
                                url: '/pages/clinical/pharmacyManagement/pharmacyDailyWorkIndex',
                                title: 'Pharmacy Daily Work Index',
                                icon: 'Reports',
                                description: 'PharmacyDailyWorkIndexDescription',
                            },
                            {
                                id: 117,
                                path: 'pharmacyOrderManagement',
                                url: '/pages/pharmacyOrderManagement',
                                title: 'Pharmacy Order Management',
                                icon: 'CommentTemplate',
                                description: 'PharmacyOrderManagementDescription',
                            },
                            {
                                id: 118,
                                path: 'unitDoseDrugDistribution',
                                url: '/pages/clinical/pharmacyManagement/unitDoseDrugDistribution',
                                title: 'Unit Dose Drug Distribution',
                                icon: 'ReturnApprove',
                                description: 'UnitDoseDrugDistDescription',
                            },
                            {
                                id: 119,
                                path: 'pharmacyPendingAreaManagement',
                                url: '/pages/clinical/pharmacyManagement/pharmacyPendingAreaManagement',
                                title: 'Pending Area Management',
                                icon: 'PatientOrder',
                                description: 'PharmacyPendingAreaManagement',
                            },
                        ],
                    },
                    {
                        id: 58,
                        title: 'Reports',
                        reportTitle: 'dyn_phr',
                        path: 'pharmacyReports',
                        lastChild: true,
                        children: [
                        ],
                    },
                ],
            },
            {
                id: 500,
                path: 'labManagement',
                title: 'Laboratory Management',
                children: [
                    {
                        path: 'masterDefinition',
                        id: 67,
                        title: 'Master Definition',
                        lastChild: true,
                        children: [
                            {
                                id: 68,
                                path: 'mainResultsList',
                                url: '/pages/clinical/laboratoryManagement/mainResultsList',
                                title: 'Result List',
                                icon: 'ResultsList',
                                description: 'ResultListDescription',
                            },
                            {
                                id: 69,
                                path: 'lookups',
                                url: '/pages/clinical/laboratoryManagement/lookups',
                                title: 'Look Ups',
                                icon: 'Lookups',
                                description: 'LookupsDescription',
                            },
                            {
                                id: 70,
                                path: 'sections',
                                url: '/pages/clinical/laboratoryManagement/sections',
                                title: 'Sections',
                                icon: 'Sections',
                                description: 'laboratorySectionDescription',
                            },
                            {
                                id: 72,
                                path: 'specimenRejectionReason',
                                url: '/pages/clinical/laboratoryManagement/specimenRejectionReason',
                                title: 'Rejection Reason',
                                icon: 'RejectionReason',
                                description: 'RejectionReasonsDescription',
                            },
                            {
                                id: 73,
                                path: 'externalLaboratory',
                                url: '/pages/clinical/laboratoryManagement/externalLaboratory',
                                title: 'External Laboratory',
                                icon: 'ExternalLaboratory',
                                description: 'ExternalLaboratoryDescription',
                            },
                            {
                                id: 74,
                                path: 'antibiotic',
                                url: '/pages/clinical/laboratoryManagement/antibiotic',
                                title: 'Antibiotics',
                                icon: 'Antibiotics',
                                description: 'AntibioticDescription',
                            },
                            {
                                id: 75,
                                path: 'groupTests',
                                url: '/pages/clinical/laboratoryManagement/groupTests',
                                title: 'Group Test Profile',
                                icon: 'GroupTestProfile',
                                description: 'GroupTestsProfileDescription',
                            },
                            {
                                id: 76,
                                path: 'testsDefinition',
                                url: '/pages/clinical/laboratoryManagement/testsDefinition',
                                title: 'Tests Definition',
                                icon: 'TestsDefinition',
                                description: 'TestDefinitionsDescription',
                            },
                            {
                                id: 77,
                                path: 'kitSetup',
                                url: '/pages/clinical/laboratoryManagement/kitSetup',
                                title: 'Kit Set up',
                                icon: 'KitSetup',
                                description: 'KitsetupDescription',
                            },
                            {
                                id: 78,
                                path: 'microbiologyPanel',
                                url: '/pages/clinical/laboratoryManagement/microbiologyPanel',
                                title: 'Microbiology Panel',
                                icon: 'MicrobiologyPanel',
                                description: 'MicrobiologyPanelDescription',
                            },
                            {
                                id: 79,
                                path: 'commentTemplate',
                                url: '/pages/clinical/laboratoryManagement/commentTemplate',
                                title: 'Comment Template',
                                icon: 'CommentTemplate',
                                description: 'CommentTemplateDescription',
                            },
                            {
                                id: 80,
                                path: 'resultFormulaTests',
                                url: '/pages/clinical/laboratoryManagement/resultFormulaTests',
                                title: 'Test Result Formula',
                                icon: 'TestResultFormula',
                                description: 'TestResultformulaDescription',
                            },
                            {
                                id: 81,
                                path: 'testInstruction',
                                url: '/pages/clinical/laboratoryManagement/testInstruction',
                                title: 'Test Instructions',
                                icon: 'TestInstructions',
                                description: 'TestInstructionDescription',
                            },
                            {
                                id: 82,
                                path: 'textResultsTemplates',
                                url: '/pages/clinical/laboratoryManagement/textResultsTemplates',
                                title: 'Text Result Template',
                                icon: 'TextResultTemplate',
                                description: 'TextResultTemplateDescription',
                            },
                            {
                                id: 83,
                                path: 'testMapping',
                                url: '/pages/clinical/laboratoryManagement/testMapping',
                                title: 'Test Mapping',
                                icon: 'TestsDefinition',
                                description: 'TestMappingDescription',
                            },
                            {
                                id: 84,
                                path: 'reportTemplate',
                                url: '/pages/clinical/laboratoryManagement/reportTemplate',
                                title: 'Report Template',
                                icon: 'TestsDefinition',
                                description: 'ReportTemplategDescription',
                            },
                        ],
                    },
                    {
                        path: 'transaction',
                        id: 709,
                        title: 'Transactions',
                        lastChild: true,
                        children: [
                            {
                                id: 81,
                                path: 'collectSpecimen',
                                url: '/pages/clinical/laboratoryManagement/collectSpecimen',
                                title: 'Collect Specimen',
                                icon: 'CollectSpecimen',
                                description: 'CollectSpecimensDescription',
                            },
                            {
                                id: 82,
                                path: 'sensitivityResultEntryScreen',
                                url: '/pages/clinical/laboratoryManagement/sensitivityResultEntryScreen',
                                title: 'Culture Result',
                                icon: 'CultureResult',
                                description: 'CultureResultDescription',
                            },
                            {
                                id: 83,
                                path: 'numericListTextResultEntryScreen',
                                url: '/pages/clinical/laboratoryManagement/numericListTextResultEntryScreen',
                                title: 'Normal Result',
                                icon: 'NormalResult',
                                description: 'NormalResultDescription',
                            },
                            {
                                id: 84,
                                path: 'labTechnicianWorkbench',
                                url: '/pages/clinical/laboratoryManagement/labTechnicianWorkbench',
                                title: 'Workbench',
                                icon: 'Workbench',
                                description: 'WorkbenchstationDescription',
                            },
                            // {
                            //     id: 85,
                            //     path: 'tatMonitor',
                            //     url: '/pages/clinical/laboratoryManagement/tatMonitor',
                            //     title: 'TAT Monitor',
                            //     icon: 'TATMonitor',
                            //     description: 'TATMonitorDescription',
                            // },
                            {
                                id: 87,
                                path: 'externalOrderStation',
                                url: '/pages/clinical/laboratoryManagement/externalOrderStation',
                                title: 'External Order',
                                icon: 'ExternalOrder',
                                description: 'ExternalOrderStationDescription',
                            },
                            {
                                id: 88,
                                path: 'labOrderSpecimenTestSearch',
                                url: '/pages/clinical/laboratoryManagement/labOrderSpecimenTestSearch',
                                title: 'Search Engine',
                                icon: 'SearchEngine',
                                description: 'SearchEngineDescription',
                            },
                            {
                                id: 89,
                                path: 'textReportResultEntry',
                                url: '/pages/clinical/laboratoryManagement/textReportResultEntry',
                                title: 'Report Result',
                                icon: 'ReportResult',
                                description: 'ReportResultDescription',
                            },
                            {
                                id: 90,
                                path: 'labOrderManagement',
                                url: '/pages/labOrderManagement',
                                title: 'Lab Order Management',
                                icon: 'CommentTemplate',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 91,
                                path: 'labMonitor',
                                url: '/pages/labMonitor',
                                title: 'Lab Monitor',
                                icon: 'TestsDefinition',
                                description: 'LabMonitorDescription',
                            },
                            {
                                id: 92,
                                path: 'tatMonitor',
                                url: '/pages/admissionReports/tatMonitor',
                                title: 'TAT Monitoring Screen',
                                icon: 'Reports',
                                description: 'Turnaround Time Monitoring',
                            },
                        ],
                    },
                    {
                        path: 'laboratoryReports',
                        id: 90,
                        title: 'Reports',
                        lastChild: true,
                        reportTitle: 'dyn_lab',
                        children: [
                            {
                                id: 91,
                                path: 'laboratoryTestsReport',
                                url: '/pages/laboratoryReports/laboratoryTestsReport',
                                title: 'Laboratory Tests Report',
                                icon: 'Reports',
                                description: 'LaboratoryTestsReportDescription',
                            },
                            {
                                id: 94,
                                path: 'microOrganismResultsReport',
                                url: '/pages/laboratoryReports/microOrganismResultsReport',
                                title: 'Microorganism Report',
                                icon: 'Reports',
                                description: 'micro Organism Results Report',
                            },
                            {
                                id: 96,
                                path: 'sectionRevenueByDoctorReport',
                                url: '/pages/laboratoryReports/sectionRevenueByDoctor',
                                title: 'Section Revenue By Doctor Report',
                                icon: 'Reports',
                                description: 'LaboratoryTestsReportDescription',
                            },
                            {
                                id: 97,
                                path: 'patientLaboratoryVisitReport',
                                url: '/pages/laboratoryReports/patientLaboratoryVisitReport',
                                title: 'Laboratory Patient Visit Report',
                                icon: 'Reports',
                                description: 'LaboratoryPatientVisitReport',
                            },
                            {
                                id: 98,
                                path: 'specimenReport',
                                url: '/pages/laboratoryReports/specimenReport',
                                title: 'Specimen Report',
                                icon: 'Reports',
                                description: 'LaboratoryTestsReportDescription',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'radiologyReports',
                id: 2012,
                title: 'Radiology Reports',
                lastChild: true,
                reportTitle: 'dyn_radiology',
                children: [
                    {
                        id: 2013,
                        path: 'radiologyItemReport',
                        url: '/pages/radiologyReports/radiologyItemReport',
                        title: 'Radiology Item Report',
                        icon: 'Reports',
                        description: 'RadiologyItemReportDescription',
                    },
                ],
            },
            {
                path: 'CPOE',
                id: 530,
                title: 'Order Management',
                lastChild: true,
                children: [
                    {
                        id: 132,
                        path: 'patientOrderManagement',
                        url: '/pages/patientOrderManagement',
                        title: 'PatientOrderTitle',
                        icon: 'PatientOrder',
                        description: 'PatientOrderDescription',
                    },
                    {
                        id: 133,
                        path: 'cancelOrder',
                        url: '/pages/order/cancelOrder',
                        title: 'CancelOrderTitle',
                        icon: 'CancelOrder',
                        description: 'CancelOrderDescription',
                    },
                    {
                        id: 134,
                        path: 'userFavorite',
                        url: '/pages/order/userFavorite',
                        title: 'UserFavoriteTitle',
                        icon: 'UserFavorite',
                        description: 'UserFavoriteDescription',
                    },
                    {
                        id: 91,
                        path: 'patientStation',
                        url: CpoeManagementLink.PatientStation,
                        isCpoeManagement: true,
                        title: 'CPOE',
                        icon: 'PatientOrder',
                        description: 'CPOEDescription',
                    },
                    {
                        id: 92,
                        path: 'postPendingTransaction',
                        url: '/pages/order/postPendingTransaction',
                        title: 'PostPendingTransactionTitle',
                        icon: 'ExternalOrder',
                        description: 'PostPendingTransactionDescription',
                    },
                    {
                        id: 135,
                        path: 'auditOrder',
                        url: '/pages/order/auditOrder',
                        title: 'AuditOrderTitle',
                        icon: 'CancelOrder',
                        description: 'AuditOrderDescription',
                    },
                    {
                        id: 136,
                        path: 'receiveOrder',
                        url: '/pages/order/receiveOrder',
                        title: 'ReceiveOrderTitle',
                        icon: 'PatientOrder',
                        description: 'ReceiveOrderDescription',
                    },
                    {
                        id: 137,
                        path: 'pendingArea',
                        url: '/pages/order/pendingArea',
                        title: 'PendingAreaTitle',
                        icon: 'PatientOrder',
                        description: 'PendingAreaDescription',
                    },
                    {
                        id: 138,
                        path: 'pendingAreaManagement',
                        url: '/pages/order/pendingAreaManagement',
                        title: 'PendingAreaManagementTitle',
                        icon: 'PatientOrder',
                        description: 'PendingAreaManagementDescription',
                    },
                ],
            },
            {
                path: 'homeCare',
                id: 531,
                url: '/pages/homeCare',
                title: 'Home Care',
                lastChild: true,
            },
        ],
    },


    {
        id: 94,
        path: 'patientAccounting',
        title: 'Patients Billing Service',
        icon: 'fa-solid fa-chart-pie',
        isMain: true,
        children: [
            {
                id: 700,
                path: 'patientBilling',
                title: 'Patient Billing',
                children: [
                    {
                        path: 'insuranceOffice',
                        id: 95,
                        title: 'Business Center',
                        lastChild: true,
                        children: [
                            {
                                id: 96,
                                path: 'approvalFollowUp',
                                url: '/pages/patientAccounting/approvalFollowUp',
                                title: 'Approval Follow-up',
                                icon: 'ApprovalFollowUp',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 97,
                                path: 'coverageLetter',
                                url: '/pages/patientAccounting/coverageLetter',
                                title: 'Coverage Letter',
                                icon: 'CoverageLetter',
                                description: 'CoverageLetterDescription',
                            },
                            {
                                id: 98,
                                path: 'authorizationTransaction',
                                url: '/pages/patientAccounting/authorizationTransaction',
                                title: 'Authorization Transaction',
                                icon: 'CoverageLetter',
                                description: 'AuthorizationTransactionDescription',
                            },
                        ],
                    },
                    {
                        path: 'billing',
                        id: 104,
                        title: 'Invoice Management',
                        lastChild: true,
                        children: [
                            {
                                id: 104,
                                path: 'accommodationFees',
                                url: '/pages/patientAccounting/billing/accommodationFees',
                                title: 'Accommodation Fees',
                                icon: 'AccommodationFees',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 105,
                                path: 'businessCenter',
                                url: '/pages/patientAccounting/billing/businessCenter',
                                title: 'Billing Center',
                                icon: 'BusinessCenter',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 106,
                                path: 'encounterDetails',
                                url: '/pages/administrative/encounterDetails',
                                title: 'Encounter Details',
                                icon: 'EncounterDetails',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 108,
                                path: 'accommodation',
                                url: '/pages/administrative/accommodation',
                                title: 'Accommodation',
                                icon: 'Accommodation',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 109,
                                path: 'packageSession',
                                url: '/pages/patientAccounting/billing/packageSession',
                                title: 'Package Session',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 112,
                                path: 'mainFund',
                                url: '/pages/patientAccounting/billing/mainFund',
                                title: 'Main Fund',
                                icon: 'BillSerials',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 113,
                                path: 'trackingMainFund',
                                url: '/pages/patientAccounting/billing/trackingMainFund',
                                title: 'Tracking Main Fund',
                                icon: 'BillSerials',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 116,
                                path: 'copyPayerDetails',
                                url: '/pages/patientAccounting/billing/copyPayerDetails',
                                title: 'Copy Payer Details',
                                icon: 'BillSerials',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 114,
                                path: 'statementTransactions',
                                url: '/pages/patientAccounting/billing/statementTransactions',
                                title: 'Statement Transactions',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 115,
                                path: 'patientSession',
                                url: '/pages/patientAccounting/billing/patientSession',
                                title: 'Patient Session',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 116,
                                path: 'approveRequests',
                                url: '/pages/patientAccounting/approveRequests',
                                title: 'Approve Requests',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 117,
                                path: 'patientDeposit',
                                url: '/pages/patientAccounting/patientDeposit',
                                title: 'Patient Deposit',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 118,
                                path: 'depositTransactionManagement',
                                url: '/pages/patientAccounting/depositTransactionManagement',
                                title: 'Deposit Transaction Management',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 119,
                                path: 'closeInvoiceManagement',
                                url: '/pages/patientAccounting/closeInvoiceManagement',
                                title: 'close Invoice Management',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 120,
                                path: 'payerBalance',
                                url: '/pages/patientAccounting/payerBalance',
                                title: 'Payer Balance',
                                icon: 'PackageSession',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 120,
                                path: 'donorStation',
                                url: '/pages/patientAccounting/donorStation',
                                title: 'Donor Station',
                                icon: 'PackageSession',
                                description: 'DonorStationDescription',
                            },
                        ],
                    },
                    {
                        path: 'billingReport',
                        id: 95,
                        title: 'Reports',
                        reportTitle: 'dyn_billing',
                        lastChild: true,
                        children: [
                            {
                                id: 112,
                                path: 'disclosureIssuedInvoice',
                                url: '/pages/patientAccounting/billingReport/disclosureIssuedInvoice',
                                title: 'Issued Invoices',
                                icon: 'Reports',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 113,
                                path: 'disclosureUnissuedInvoice',
                                url: '/pages/patientAccounting/billingReport/disclosureUnissuedInvoice',
                                title: 'Unissued Invoices',
                                icon: 'Reports',
                                description: 'CompanionDescription',
                            },
                            {
                                id: 120,
                                path: 'incomeHospitalReport',
                                url: '/pages/patientAccounting/billingReport/incomeHospitalReport',
                                title: 'Income Hospital Per Date',
                                icon: 'Reports',
                                description: 'JodAndNonJodReportDescription',
                            },
                            {
                                id: 121,
                                path: 'operationReport',
                                url: '/pages/patientAccounting/billingReport/operationReport',
                                title: 'Operation Report',
                                icon: 'Reports',
                                description: 'JodAndNonJodReportDescription',
                            },
                            {
                                id: 122,
                                path: 'doctorRevenuePerPatientReport',
                                url: '/pages/patientAccounting/billingReport/doctorRevenuePerPatientReport',
                                title: 'Doctor Revenue Per Patient Report',
                                icon: 'Reports',
                                description: 'JodAndNonJodReportDescription',
                            },
                            {
                                id: 123,
                                path: 'incomePerOutPatientSectionReport',
                                url: '/pages/patientAccounting/billingReport/incomePerOutPatientSectionReport',
                                title: 'Income Per Out-Patient Section',
                                icon: 'Reports',
                                description: 'incomePerOutPatientSectionReportDescription',
                            },
                            {
                                id: 124,
                                path: 'orderSummaryItemReport',
                                url: '/pages/patientAccounting/billingReport/orderSummaryItemReport',
                                title: 'Order Summary By Item',
                                icon: 'Reports',
                                description: 'orderSummaryItemReportDescription',
                            },
                            {
                                id: 125,
                                path: 'revenueReport',
                                url: '/pages/patientAccounting/billingReport/revenueReport',
                                title: 'Revenue',
                                icon: 'Reports',
                                description: 'revenueReportDescription',
                            },
                            {
                                id: 126,
                                path: 'doctorCommission',
                                url: '/pages/patientAccounting/billingReport/doctorCommission',
                                title: 'Doctor Commission',
                                icon: 'Reports',
                                description: 'Doctor Commission',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'pricingManagement',
                id: 111,
                title: 'Pricing Management',
                lastChild: true,
                children: [
                    {
                        id: 108,
                        path: 'priceList',
                        url: '/pages/patientAccounting/pricingManagement/priceList',
                        title: 'Price List',
                        icon: 'PricingList',
                        description: 'CompanionDescription',
                    },
                    {
                        id: 109,
                        path: 'itemPrice',
                        url: '/pages/patientAccounting/pricingManagement/itemPrice',
                        title: 'Item Pricing',
                        icon: 'ItemPricing',
                        description: 'CompanionDescription',
                    },
                    {

                        id: 111,
                        path: 'copyPriceList',
                        url: '/pages/patientAccounting/pricingManagement/CopyPriceList',
                        title: 'Copy Price List',
                        icon: 'CopyPriceList',
                        description: 'CompanionDescription',
                    },
                    {

                        id: 112,
                        path: 'profitMarginConfiguration',
                        url: '/pages/patientAccounting/pricingManagement/profitMarginConfiguration',
                        title: 'Profit Margin Configuration',
                        icon: 'ItemPricing',
                        description: 'CompanionDescription',
                    },
                    {

                        id: 113,
                        path: 'priceListItemReport',
                        url: '/pages/patientAccounting/pricingManagement/priceListItemReport',
                        title: 'Price List Item Report',
                        icon: 'PricingList',
                        description: 'CompanionDescription',
                    },
                    {
                        id: 114,
                        path: 'pricingAuditManagement',
                        url: '/pages/patientAccounting/pricingManagement/pricingAuditManagement',
                        title: 'Pricing Audit Management',
                        icon: 'ItemPricing',
                        description: 'CompanionDescription',
                    },
                ],
            },
            {
                id: 112,
                path: 'doctorSettlement',
                title: 'Doctor Settlement',
                children: [
                    {
                        path: 'paymentManagement',
                        id: 1,
                        title: 'Payment Management',
                        lastChild: true,
                        children: [
                            {
                                id: 1,
                                path: 'doctorFeesStation',
                                url: '/pages/doctorSettlement/paymentManagement/doctorFeesStation',
                                title: 'Doctor Fees Station',
                                icon: 'BillingReports',
                                description: 'doctorFeesStationDescription',
                            },
                            {
                                id: 2,
                                path: 'paymentTracking',
                                url: '/pages/doctorSettlement/paymentManagement/paymentTracking',
                                title: 'Payment Tracking',
                                icon: 'BillSerials',
                                description: 'paymentTrackingDescription',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'insurance',
                id: 700,
                title: 'Insurance',
                children: [
                    {
                        path: 'contractStation',
                        id: 701,
                        url: '/pages/insurance/contract',
                        lastChild: true,
                        title: 'Contract Station',
                    },


                ],
            },
            {
                path: 'claimManagement',
                id: 800,
                title: 'Claim Management',
                lastChild: true,
                children: [
                    {
                        path: 'claimManagementStation',
                        id: 801,
                        url: '/pages/claimManagement/claimManagementStation',
                        title: 'Claim Management Station',
                        icon: 'AccommodationFees',
                        description: 'ClaimManagementStation',
                    },
                    {
                        path: 'claimManagementTracking',
                        id: 802,
                        url: '/pages/claimManagement/claimManagementTracking',
                        title: 'Claim Management Tracking',
                        icon: 'AccommodationFees',
                        description: 'ClaimManagementTracking',
                    },
                    {
                        path: 'remittanceTransaction',
                        id: 803,
                        url: '/pages/claimManagement/remittanceTransaction',
                        title: 'remittanceTransactionDescription',
                        icon: 'AccommodationFees',
                        description: 'RemittanceTransaction',
                    },
                    {
                        path: 'claimAttachment',
                        id: 804,
                        url: '/pages/claimManagement/claimAttachment',
                        title: 'Claim Attachment',
                        icon: 'AccommodationFees',
                        description: 'ClaimAttachmentDescription',
                    },
                ],
            },
            {
                id: 500,
                title: 'Settlement Management',
                path: 'claimSettlement',
                children: [
                    {
                        path: 'claimSettlementsStation',
                        id: 803,
                        url: '/pages/claimManagement/claimSettlementsStation',
                        lastChild: true,
                        title: 'Settlements Station',
                    },
                    {
                        path: 'receipt',
                        id: 804,
                        url: '/pages/claimManagement/receipt',
                        lastChild: true,
                        title: 'Receipt Station',
                    },
                ],
            },
        ],
    },
];
