import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';
import { HighAlertLog } from 'app/shared/models/pharmacy/high-alert-log';

@Injectable()
export class HighAlertService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;
    private _insertBulk: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.highAlertLog.url;
        this._insertBulk = this._actionUrl + _configuration.highAlertLog.insertBulk;
    }

    insertBulk = (highAlertLog: HighAlertLog[]): Observable<any> => {
        return this._http.post<any>(this._insertBulk, highAlertLog);
    }
}
