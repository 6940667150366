export class PatientBasicSearchFilter {

    patientCode: string = '';
    patientName: string = '';
    nationalID: number = null;
    phoneNumber: number = null;
    registrationDate: Date = null;
    badgeNumber: string = '';
    orderNumber: number = null;
   
    constructor() { }
}
