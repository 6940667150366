import { SelectItem } from 'primeng/api';
import { PaymentTypes } from '../enums';

export class PaymentTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Cash', value: PaymentTypes.Cash },
            { label: 'Visa', value: PaymentTypes.Visa },
        ]
    }
}