import { Component, OnInit } from '@angular/core';

export class Diagnosis {

    Id: number;
    FacilityId: number;
    Title: string;
    ForeignTitle: string;
    IsLeaf: boolean;
    ParentId: number;
    IsActive: boolean;
    DiagnosisCode: string;
    constructor() {
    }
}