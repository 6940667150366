import { CheckListType } from '../enums';

export class CheckListTypeLookup {

    static getLookup(): any[] {
        return [
            { label: 'OT', value: CheckListType.OT },
            { label: 'OR', value: CheckListType.OR },
        ];
    }
}
