import { SelectItem } from 'primeng/api';
import { DailyFrequencyOccursType } from '../enums';

export class DailyFrequencyOccursLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Once', value: DailyFrequencyOccursType.Once },
            { label: 'Every', value: DailyFrequencyOccursType.Every },
        ];
    }
}
