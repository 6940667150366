import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PricingProcessOutput } from 'app/shared/models/orders-and-billing/pricing-process-output';

@Injectable()
export class PriceService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = `${this.actionUrl}/Price`;
    }

    getItemPrice = (encounterId: number, patientType: number, patientId: number, itemId: number,
        quantity: number, sectionId: number, unitId?: number, variablePrice?: number,
        orderBy?: number, totalMohValue?: number,
        isDischargePrescription: boolean = false, orderDiagnoses: string = '',
        isVariablePrice: boolean = false, itemCategoryId?: number, duration?: number, durationType?: number): Observable<PricingProcessOutput[]> => {

        return this._http.get<any>(`${this.getUrl}/ItemPrice?itemId=${itemId}` +
            `&&encounterId=${encounterId}` +
            `&&patientType=${patientType}` +
            `&&patientId=${patientId}` +
            `&&unitId=${unitId}` +
            `&&quantity=${quantity}` +
            `&&variablePrice=${variablePrice}` +
            `&&orderBy=${orderBy}` +
            `&&totalMohValue=${totalMohValue || 0}` +
            `&&isDischargePrescription=${isDischargePrescription}` +
            `&&sectionId=${sectionId}` +
            `&&orderDiagnoses=${orderDiagnoses}` +
            `&&isVariablePrice=${isVariablePrice || false}` +
            `&&itemCategoryId=${itemCategoryId}` +
            `&&duration=${duration}` +
            `&&durationType=${durationType}`)
    }

    getItemPriceByFda = (encounterId: number, patientType: number, patientId: number, itemId: number,
        quantity: number, sectionId: number, unitId?: number, variablePrice?: number,
        orderBy?: number, totalMohValue?: number,
        isDischargePrescription: boolean = false, orderDiagnoses: string = '',
        isVariablePrice: boolean = false, itemCategoryId?: number, duration?: number, durationType?: number): Observable<PricingProcessOutput[]> => {

        return this._http.get<any>(`${this.getUrl}/ItemPriceByFda?itemId=${itemId}&&encounterId=${encounterId}
            &&patientType=${patientType}&&patientId=${patientId}&&unitId=${unitId}&&quantity=${quantity}
            &&variablePrice=${variablePrice}&&orderBy=${orderBy}&&totalMohValue=${totalMohValue || 0}&&isDischargePrescription=${isDischargePrescription}
            &&sectionId=${sectionId}&&orderDiagnoses=${orderDiagnoses}&&isVariablePrice=${isVariablePrice || false}
            &&itemCategoryId=${itemCategoryId}&&duration=${duration}s&&durationType=${durationType}`)
    }

    getCashPrice = (itemId: number, sectionId?: number, orderBy?: number): Observable<number> => {
        return this._http.get<any>(`${this.getUrl}/CashPrice?itemId=${itemId}&&sectionId=${sectionId}&&orderBy=${orderBy}`)
    }

    getStandardCashPrice = (itemId: number, patientType: number, sectionId?: number, orderBy?: number): Observable<number> => {
        return this._http.get<any>(`${this.getUrl}/StandardCashPrice?itemId=${itemId}&&patientType=${patientType}&&sectionId=${sectionId}&&orderBy=${orderBy}`)
    }
}
