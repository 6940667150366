import { Item } from './item';

export class ContractStationCoverageService {
    Id: number;
    ContractId: number;
    CoverageContract?: number;
    ServiceId: number;

    IPCoverage?: number;
    IPCoverageLimit?: number;
    IPCashLimit?: number;
    IPExceedLimitAction?: number;
    IPParticipantPercentage?: number;
    IPBeneficiaryPercentage?: number;
    IPSponsorPercentage?: number;
    InPatientApplyLimitOn: number;
    IPCoveragePer?: number;
    IPCashPer?: number;
    InPatientStatus: boolean;
    InPatientServiceChargeLimit?: number;
    InPatientMinCashAmount?: number;
    
    OPCoverage?: number;
    OPCoverageLimit?: number;
    OPCashLimit?: number;
    OPExceedLimitAction?: number;
    OPParticipantPercentage?: number;
    OPBeneficiaryPercentage?: number;
    OPSponsorPercentage?: number;
    OutPatientApplyLimitOn: number;
    OPCoveragePer?: number;
    OPCashPer?: number;
    OutPatientStatus: boolean;
    OutPatientServiceChargeLimit?: number;
    OutPatientMinCashAmount?: number;

    ERCoverage?: number;
    ERCoverageLimit?: number;
    ERCashLimit?: number;
    ERExceedLimitAction?: number;
    ERParticipantPercentage?: number;
    ERBeneficiaryPercentage?: number;
    ERSponsorPercentage?: number;
    EmergencyPatientApplyLimitOn: number;
    ERCoveragePer?: number;
    ERCashPer?: number;
    EmergencyPatientStatus: boolean;
    EmergencyPatientServiceChargeLimit?: number;
    EmergencyMinCashAmount?: number;

    CoverageType: number;
    Status: boolean;
    PatientType: string;
    Item: Item = new Item();
    Priority?: number;
}
