import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PendingAreaInfoService extends ServiceBase {

    private actionUrl: string;
    private recalculatePriceInfoUrl: string;
    private recalculatePriceByOrerDetailIdsUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.recalculatePriceInfoUrl = this.actionUrl + _configuration.pendingAreaInfo.recalculatePriceInfo;
        this.recalculatePriceByOrerDetailIdsUrl = this.actionUrl + _configuration.pendingAreaInfo.recalculatePriceByOrerDetailIds;
    }

    recalculatePriceInfo = (orderDetailId: number): Observable<any> => {
        return this._http.put<any>(`${this.recalculatePriceInfoUrl}?orderDetailId=${orderDetailId}`, null);
    }

    recalculatePriceByOrerDetailIds = (orderActionIds: any[]): Observable<any> => {
        return this._http.put<any>(`${this.recalculatePriceByOrerDetailIdsUrl}`, orderActionIds);
    }
}
