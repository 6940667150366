import { ConsultationTransferStatus } from '../enums';

export class ConsultationTransferStatusLookup {

    static get(): any[] {
        return [
            { label: 'Pending', value: ConsultationTransferStatus.Pending },
            { label: 'Approved', value: ConsultationTransferStatus.Approved }];
    }

    static getTitleById(id) {
        const lookup = this.get().find(f => f.value === id);
        return lookup?.label || '';
    }
}
