import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PatientKinService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientKin.url;
    }

    public getKinById = (kinId: number): Observable<any> => {
        return this._http.get<any>(this._url + '?kinId=' + kinId);
    }

    public getKinByPatientId = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '?patientId=' + patientId);
    }

    public add = (data: any): Observable<number> => {
        return this._http.post<any>(this._url, data);
    }

    public update(id: number, data: any) {
        return this._http.put<any>(this._url + '?id=' + id, JSON.stringify(data));
    }
}