import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { environment } from 'environments/environment';
@Injectable()
export class BrandDoseService {

    constructor(private _http: HttpClient) { }

    save = (data): Observable<any> => {
        return this._http.post<any>(`${environment.pharmacy}/BrandDose/Save`, data);
    }

    getByProductId = (id: number): Observable<any> => {
        return this._http.get<any>(`${environment.pharmacy}/BrandDose/GetByProductId?id=${id}`);
    }
}
