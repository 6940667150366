import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { environment } from 'environments/environment';

@Injectable()
export class CoverageAprovalSearchService {

    constructor(private _http: HttpClient) { }

    get = (pageNo, rowNo, status?: number, patientCode?: string,
        startDate?: Date, endDate?: Date, approvalPatientType?: number,
        encounterId?: any, payerId: number = null, sectionIds: string = ''): Observable<any> => {

        const start = startDate ? startDate.toISOString() : '';
        const end = endDate ? endDate.toISOString() : '';
        patientCode = patientCode ? patientCode : '';

        const url = `${environment.queryModel}CoverageApprovalSearch?Status=${status || ''}&` +
            `PatientCode=${patientCode || ''}&StartDate=${start}&EndDate=${end}&` +
            `PageNo=${pageNo}&RowNo=${rowNo}&ApprovalPatientType=${approvalPatientType || ''}&` +
            `EncounterId=${encounterId || ''}&PayerId=${payerId || ''}&SectionIds=${sectionIds || ''}`;

        return this._http.get<any>(url);
    }
}