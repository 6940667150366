import { SelectItem } from 'primeng/api';
import { CalculatedPerType } from '../enums';

export class CalculatedPerTypeLookup {
    static getInPatientLookup(): SelectItem[] {
        return [
            { label: 'Encounter', value: CalculatedPerType.Encounter },
        ];
    }

    static getAllOutLookup(): SelectItem[] {
        return [
            { label: 'Encounter', value: CalculatedPerType.Encounter },
            { label: 'Episode', value: CalculatedPerType.Episode },
        ];
    }

    static getOutExceptEpisodeLookup(): SelectItem[] {
        return [
            { label: 'Encounter', value: CalculatedPerType.Encounter },
        ];
    }
}
