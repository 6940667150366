import { Component, Input } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';

@Component({
    selector: 'patient-shortcut-cmp',
    templateUrl: './patient-shortcut.html',
})

export class PatientShortcutComponent extends BaseComponent {
    @Input('patientId') patientId: number;

    newAdmissionLink: any;
    reservationLink: any;
    constructor() {
        super();
    }

    ngOnChanges(changes: any) {
        if (this.patientId) {
            this.newAdmissionLink = ['/pages/administrative/admission/newAdmission', this.patientId];
            this.reservationLink = ['/pages/administrative/admission/reservation', this.patientId];
        }
        else {
            this.newAdmissionLink = ['/pages/administrative/admission/newAdmission'];
            this.reservationLink = ['/pages/administrative/admission/reservation'];
        }
    }
}
