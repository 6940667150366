import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { Order } from 'app/shared/models/pharmacy/order';
import { ServiceBase } from 'app/shared/infrastructure';

import { ReceiveDoctorsPrescriptionFilter } from 'app/shared/models/pharmacy/receive-doctor-prescription_filter';
import { ReceiveDoctorsPrescription } from 'app/shared/models/pharmacy/receive-doctors-prescription';
import { environment } from 'environments/environment';

@Injectable()
export class PharmacyOrderService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private allUrl: string;
    private byId: string;
    private doctorsPrescriptionUrl: string;
    private drugDiscontinueUrl: string;
    private action: string;
    private receivePrescriptionUrl: string;

    constructor(private http: HttpClient, private configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.pharmacyOrder.url;
        this.allUrl = this.actionUrl + configuration.pharmacyOrder.all;
        this.byId = this.actionUrl + configuration.pharmacyOrder.byId;
        this.drugDiscontinueUrl = this.actionUrl + configuration.pharmacyOrder.drugDiscontinue;
        this.doctorsPrescriptionUrl = this.actionUrl + configuration.pharmacyOrder.doctorsPrescription;
        this.receivePrescriptionUrl = this.actionUrl + configuration.pharmacyOrder.receivePrescription;
    }

    getAll = (): Observable<Order[]> => {
        return this.http.get<any>(this.allUrl);
    }

    getById = (id: number): Observable<Order> => {
        return this.http.get<any>(this.byId + '?id=' + id);
    }

    insert = (order: Order, preventOrder: boolean): Observable<any> => {
        return this.http.post<Order>(`${this.url}?preventOrderIfStockIsEmpty=${preventOrder}`, order);
    }

    update = (order: Order, preventOrder: boolean): Observable<any> => {
        return this.http.put<Order>(`${this.url}?preventOrderIfStockIsEmpty=${preventOrder}`, order);
    }

    getDoctorsPrescription = (receiveDoctorsPrescriptionFilter: ReceiveDoctorsPrescriptionFilter, page: number, rows: number): Observable<ReceiveDoctorsPrescription[]> => {
        const patientCode = receiveDoctorsPrescriptionFilter.PatientCode ? receiveDoctorsPrescriptionFilter.PatientCode : '';
        const patientName = receiveDoctorsPrescriptionFilter.PatientName ? receiveDoctorsPrescriptionFilter.PatientName : '';
        const doctorName = receiveDoctorsPrescriptionFilter.DoctorName ? receiveDoctorsPrescriptionFilter.DoctorName : '';

        const pharmacyId = receiveDoctorsPrescriptionFilter.PharmacyId ? receiveDoctorsPrescriptionFilter.PharmacyId : null;
        const sectionId = receiveDoctorsPrescriptionFilter.SectionId ? receiveDoctorsPrescriptionFilter.SectionId : null;
        const doctorId = receiveDoctorsPrescriptionFilter.DoctorId ? receiveDoctorsPrescriptionFilter.DoctorId : null;

        var fromOrderDate = receiveDoctorsPrescriptionFilter.FromOrderDate ? receiveDoctorsPrescriptionFilter.FromOrderDate.toDateString() : null;
        var toOrderDate = receiveDoctorsPrescriptionFilter.ToOrderDate ? receiveDoctorsPrescriptionFilter.ToOrderDate.toISOString() : null;

        const statusId = receiveDoctorsPrescriptionFilter.StatusId ? receiveDoctorsPrescriptionFilter.StatusId : null;
        const documentId = receiveDoctorsPrescriptionFilter.DocumentId ? receiveDoctorsPrescriptionFilter.DocumentId : '';
        const mobile = receiveDoctorsPrescriptionFilter.Mobile ? receiveDoctorsPrescriptionFilter.Mobile : '';
        const prescriptionType = receiveDoctorsPrescriptionFilter.PrescriptionType ? receiveDoctorsPrescriptionFilter.PrescriptionType : null;

        return this.http.get<any>(this.doctorsPrescriptionUrl
            + '?PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&DoctorName=' + doctorName
            + '&&PharmacyId=' + pharmacyId
            + '&&SectionId=' + sectionId
            + '&&DoctorId=' + doctorId
            + '&&FromOrderDate=' + fromOrderDate
            + '&&ToOrderDate=' + toOrderDate
            + '&&StatusId=' + statusId
            + '&&DocumentId=' + documentId
            + '&&Mobile=' + mobile
            + '&&Page=' + page
            + '&&Rows=' + rows
            + '&&PrescriptionType=' + prescriptionType
        )
    }

    drugDiscontinue = (orderId: number, itemId: number, stopDate: Date): Observable<any> => {
        return this.http.put<any>(`${this.drugDiscontinueUrl}?orderId=${orderId}&itemId=${itemId}&stopDate=${stopDate.toISOString()}`, null);
    }

    receivePrescription = (receiveDoctorsPrescriptionIds: ReceiveDoctorsPrescription[]): Observable<any> => {
        return this.http.put<Order>(`${this.receivePrescriptionUrl}`, receiveDoctorsPrescriptionIds);
    }

    enableKhcc = (): Observable<boolean> => {
        return this.http.get<any>(`${environment.pharmacy}Order/EnableKHCC`);
    }

    checkBalanceStatus = (): Observable<any> => {
        return this.http.get<any>(`${environment.pharmacy}Order/PreventOrderStatus`);
    }
}
