import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Modals
import { LogDrugDto } from 'app/shared/models/medispan/log-drug-dto';
import { ResultType } from 'app/shared/models/medispan/enums/result-type';

// Services
import { MediSpanService } from 'app/shared/services';
import { BaseComponent } from 'app/shared/infrastructure';
import { ResultSeverity } from 'app/shared/models/medispan/enums/result-severity';
import { Subscription } from 'rxjs';

@Component({
    selector: 'medispan-log-result-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./medispan-log-result.scss'],
    templateUrl: './medispan-log-result.html',
})

export class MedispanLogResultComponent extends BaseComponent implements OnInit {
    resultSeverity: typeof ResultSeverity = ResultSeverity;
    drug: any;
    notes: any[] = [];
    note: string;
    finalStatusId: number;
    display: boolean = false;
    selectedDrugTitle: string;
    isLinkAvailable: boolean = false;
    moreDetailsHtml: SafeHtml;

    mediSpanDto: LogDrugDto = new LogDrugDto();

    @Input() IsMaximaizable: boolean = true;
    @Output() OutMediSpanDto = new EventEmitter<any>();

    resultType: typeof ResultType = ResultType;

    constructor(private _sanitizer: DomSanitizer, private _medspainService: MediSpanService) {
        super();
    }

    ngOnInit() {
    }

    showDialog(drug, drugTitle) {
        if(drug.finalStatus !== ResultSeverity.Success){
            const items: any[] = drug.severityResult;
            const haveMissingInfoMessage = drug.haveMissingInfoMessage;
            const missingInfoMessage = drug.missingInfoMessage;
            this.isLinkAvailable = false;
            if (items) {
                this.note = null;
                this.finalStatusId = null;
                this.notes = items;
                this.display = true;
                this.selectedDrugTitle = drugTitle;
            } else if (haveMissingInfoMessage) {
                this.notes = null;
                this.note = missingInfoMessage;
                this.display = true;
                this.finalStatusId = drug.finalStatus;
                this.selectedDrugTitle = drugTitle;
            }
        }
    }

    viewLinkDetails(item) {
        if(item.innerHtml){
            this.isLinkAvailable = true;
            this.moreDetailsHtml = this._sanitizer.bypassSecurityTrustHtml(item.innerHtml);
        }
    }

    getMedispanLogResults(orderId: number, medicationDefinitionId: number, medicationProductId: number) {
        this.drug = null;
        this._medspainService.getMediSpanLogResult(orderId, medicationDefinitionId, medicationProductId)
            .subscribe((data: LogDrugDto) => {
                if (data) {
                    this.mapResult(data);
                    this.OutMediSpanDto.emit();
                }
            },
                error => this.showError(error));
    }

    mapResult(data) {
        const resultByType = [];
        if (data.DrugToDrugInteractionResult) {
            const drugToDrugInteractionResult = this.addDrugByType(data.DrugToDrugInteractionResult, ResultType.DrugToDrug);
            resultByType.push(drugToDrugInteractionResult);
        }

        if (data.DrugToFoodInteractionResult) {
            const drugToFoodInteractionResult = this.addDrugByType(data.DrugToFoodInteractionResult, ResultType.DrugToFood);
            resultByType.push(drugToFoodInteractionResult);
        }

        if (data.DrugToAlcoholInteractionResult) {
            const drugToAlcoholInteractionResult = this.addDrugByType(data.DrugToAlcoholInteractionResult, ResultType.DrugToAlcohol);
            resultByType.push(drugToAlcoholInteractionResult);
        }

        if (data.AllergyResult) {
            const allergyResult = this.addDrugByType(data.AllergyResult, ResultType.Allergy);
            resultByType.push(allergyResult);
        }

        if (data.PregnancyResult) {
            const pregnancyResult = this.addDrugByType(data.PregnancyResult, ResultType.Pregnancy);
            resultByType.push(pregnancyResult);
        }

        if (data.LactationResult) {
            const lactationResult = this.addDrugByType(data.LactationResult, ResultType.Lactation);
            resultByType.push(lactationResult);
        }

        if (data.AgeResult) {
            const ageResult = this.addDrugByType(data.AgeResult, ResultType.Age);
            resultByType.push(ageResult);
        }

        if (data.GenderResult) {
            const genderResult = this.addDrugByType(data.GenderResult, ResultType.Gender);
            resultByType.push(genderResult);
        }

        if (resultByType.length > 0) {
            this.drug = {
                id: data.DrugId,
                title: data.DrugTitle,
                resultsByType: resultByType,
                prescriptionDate: data.LogDate
            };
        }
    }

    addDrugByType(element, resultType) {
        const drug: any = {};
        drug.resultType = resultType;
        drug.finalStatus = element.SeverityId;
        if(resultType === ResultType.DrugToDrug || resultType === ResultType.DrugToFood || resultType === ResultType.DrugToAlcohol || resultType === ResultType.Allergy){
            if (element && element.ResultDetails && element.ResultDetails.length > 0) {
                drug.severityResult = [];
                element.ResultDetails.forEach(item => {
                    drug.numberOfNotes = element.ResultDetails.length;
                    drug.severityResult.push({
                        status: item.SeverityId,
                        notes: item.Message,
                        innerHtml: item.ProfessionalHtml,
                    });
                });
            }
        }
        else{
            drug.severityResult = [];
            drug.severityResult.push({
                status: element.SeverityId,
                notes: element.Message,
                innerHtml: element.ProfessionalHtml,
            });
        }
        drug.haveMissingInfoMessage = element.HaveMissingInfoMessage;
        drug.missingInfoMessage = element.MissingInfoMessage;
        return drug;
    }
}