import { Antibiotic } from './antibiotic';

export class MicrobiologyPanelAntibiotic {

    Id: number;
    MicrobiologyPanelId: number;
    AntibioticId: number;
    SensitiveMin: number;
    SensitiveMax: number;
    IntermediateMin: number;
    IntermediateMax: number;
    ResistantMin: number;
    ResistantMax: number;
    Antibiotic: Antibiotic = new Antibiotic();

    constructor() {
    }
}