export enum SourceModule {
    Core = 1,
    Admission = 2,
    Lab = 3,
    OrderAndBilling = 4,
    Nurse = 5,
    Pharmacy = 6,
    Operation = 7,
    Appointment = 8,
}
