import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Message } from 'primeng/api';
// Services
import { AdmissionService } from 'app/shared/services/admissionService/admission/admission.service';
import { PatientVisitService } from 'app/shared/services/core/patientVisit/patientVisit.service';
import { VisitAdmissionsSearchResult } from 'app/shared/models/queryModel/visitAdmissionsSearchResult';
import { AdmissionVisitType } from 'app/shared/models/queryModel/enums/admission-visit-type';
import { AdmissionStationSearchService } from 'app/shared/services/queryModel/admission-station-search/admission-station-search.service'
import { AdmissionStatus } from "app/shared/models/admission/enums/admission-status";
import { Patient } from '../../models/core/patient.model';
import { Subscription } from 'rxjs';

// Models
@Component({
    selector: 'visit-admissions-barcode-result-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./visit-admissions-barcode-result.scss'],
    templateUrl: './visit-admissions-barcode-result.html',
})

export class VisitAdmissionsBarcodeResultComponent {
    @Output() onSetResult = new EventEmitter<any>();
    @Output() totalVisitResults = new EventEmitter<any>();

    patient: Patient = new Patient();
    admissions: any[] = [];
    visits: any[] = [];


    constructor(private _admissionService: AdmissionService
        , private _patientVisitService: PatientVisitService
        , private _admissionStationSearchService: AdmissionStationSearchService) {

    }

    getpatientInformation(patientItem: Patient) {
        this.patient = new Patient();
        this.patient = patientItem;
        this.getAdmissionVisit();

    }


    private getPaymentTypeLabel(status: number) {
        status = Number(status) || 0;
        let label: string = '';
        switch (status) {
            case AdmissionStatus.InHospital:
                label = 'In Hospital';
                break;
            case AdmissionStatus.Canceled:
                label = 'Canceled';
                break;
            case AdmissionStatus.DrugCardClosed:
                label = 'Drug Card Closed';
                break;
            case AdmissionStatus.FinalDischarge:
                label = 'Final Discharge';
                break;
            case AdmissionStatus.FinanceSettled:
                label = 'Finance Settled';
                break;
            case AdmissionStatus.Halted:
                label = 'Halted';
                break;
            case AdmissionStatus.ReleasePermission:
                label = 'Release Permission';
                break;
        }

        return label;
    }

    totalVisits: number = 0;
    totalAdmissions: number = 0;
    getAdmissionVisit() {


        this.admissions = [];
        this.visits = [];


        // get admission info 
        this._admissionStationSearchService.getAdmittedPatient(this.patient.Id).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data !== null) {
                for (const row of data) {
                    row.AdmissionDate = new Date(row.AdmissionDate);
                    row.Type = AdmissionVisitType.Admission;
                }

                this.admissions = data;
                const visits = this.visits;
                const admissions = this.admissions;
                admissions.sort((a, b) => b.AdmissionDate.getTime() - a.AdmissionDate.getTime());
                this.totalVisitResults.emit({ visits: visits, admissions: admissions });
            }
        });

        /// get visit info 
        this._patientVisitService.getOpenVisitByPatientId(this.patient.Id).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data.length > 0) {


                data.forEach((a) => {
                    a.Type = AdmissionVisitType.PatientVisit;
                    a.VisitDate = new Date(a.VisitDate);
                    this.visits.push(a);
                });
            }

            const visits = this.visits;
            const admissions = this.admissions;
            visits.sort((a, b) => b.VisitDate.getTime() - a.VisitDate.getTime());
            this.totalVisitResults.emit({ visits: visits, admissions: admissions });

        })

    }
    onSelect(event) {
        const item = event;
        this.onSetResult.emit({ item });
    }


}
