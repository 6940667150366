import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { PharmacyControl } from '../../../models/pharmacy/pharmacy-control';
import { ServiceBase } from 'app/shared/infrastructure';
@Injectable()
export class PharmacyLookupCategoryService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.lookupCategory.url;
    }

    getAll = (): Observable<PharmacyControl[]> => {
        return this._http.get<any>(this._url);
    }
}
