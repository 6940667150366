import {
    ApiConfiguration,
    ReceiveNotificationService,
    PatientReleaseResultService,
    QMSTicketResultService

} from './services/his-notification';

export const NGA_HI_NOTIFICATION_SERVICES = [
    ApiConfiguration,
    ReceiveNotificationService,
    PatientReleaseResultService,
    QMSTicketResultService
];
