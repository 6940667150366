import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { environment } from 'environments/environment';

@Injectable()
export class EligibiltyTransactionService {

    constructor(private http: HttpClient) { }

    add = (data: any): Observable<any> => {
        return this.http.post<any>(`${environment.order}EligibiltyTransaction`, data);
    }
}