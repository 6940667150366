export class NormalResultView {

    OrderDate: Date;
    ItemTitle: string;
    Result: string;
    ResultType: number;
    ResultTypeDescription: string;
    ListDetailTitle: string;
    ListDetailForeignTitle: string;
    ListDetailValue: number;
    NormalRangeMin: number;
    NormalRangeMax: number;
    PositiveAbnormalRangeMin: number;
    PositiveAbnormalRangeMax: number;
    NegativeAbnormalRangeMin: number;
    NegativeAbnormalRangeMax: number;
    PositivePanicRangeMin: number;
    PositivePanicRangeMax: number;
    NegativePanicRangeMin: number;
    NegativePanicRangeMax: number;
    PositiveAbsurdRangeMin: number;
    PositiveAbsurdRangeMax: number;
    NegativeAbsurdRangeMin: number;
    NegativeAbsurdRangeMax: number;
    ReferenceRange: number;
    CustomStyle: any;
    IsMatch: boolean;

    constructor() {
    }
}