import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { PriceList } from 'app/shared/models/orders-and-billing/price-list';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class ItemPriceApproveService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _addItemPriceList: string;
    private _updateItemPriceList: string;
    private _addItemPriceService: string;
    private _updateItemPriceService: string;
    private _rejectItemPriceApprove: string;
    private _approveItemPriceApprove: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this._url = this.actionUrl + configuration.itemPriceApprove.url;
        this._addItemPriceList = this.actionUrl + configuration.itemPriceApprove.addItemPriceList;
        this._updateItemPriceList = this.actionUrl + configuration.itemPriceApprove.updateItemPriceList;
        this._addItemPriceService = this.actionUrl + configuration.itemPriceApprove.addItemPriceService;
        this._updateItemPriceService = this.actionUrl + configuration.itemPriceApprove.updateItemPriceService;
        this._rejectItemPriceApprove = this.actionUrl + configuration.itemPriceApprove.rejectItemPriceApprove;
        this._approveItemPriceApprove = this.actionUrl + configuration.itemPriceApprove.approveItemPriceApprove;
    }

    get = (): Observable<any[]> => {
        return this.http.get<any>(this._url)
    }
    
    addItemPriceList(data: any) {
        return this.http.post<any>(this._addItemPriceList, JSON.stringify(data));
    }

    updateItemPriceList(data: any) {
        return this.http.put<any>(this._updateItemPriceList, JSON.stringify(data));
    }

    addItemPriceService(data: any) {
        return this.http.post<any>(this._addItemPriceService, JSON.stringify(data));
    }  

    updateItemPriceService(data: any) {
        return this.http.put<any>(this._updateItemPriceService, JSON.stringify(data));
    }

    rejectItemPriceApprove(id: number, comment: string) {
        return this.http.put<any>(`${this._rejectItemPriceApprove}?id=${id}&&comment=${comment}`, JSON.stringify(id));
    }

    approveItemPriceApprove(id: number) {
        return this.http.post<any>(`${this._approveItemPriceApprove}?id=${id}`, JSON.stringify(id));
    }
}
