import { SelectItem } from 'primeng/api';
import { StatementTransactionStatuses } from '../enums/statement-transaction-statuses';

export class StatementTransactionStatuseLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: StatementTransactionStatuses.Active },
            { label: 'Canceled', value: StatementTransactionStatuses.Canceled },
            { label: 'GlPosted', value: StatementTransactionStatuses.GlPosted },
            { label: 'Pending', value: StatementTransactionStatuses.Pending },
            { label: 'Rejected', value: StatementTransactionStatuses.Rejected },
        ];
    }

    static getBasicLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Pending', value: StatementTransactionStatuses.Pending },
            { label: 'Rejected', value: StatementTransactionStatuses.Rejected },
        ];
    }
    static getTitleById(id) {
        const lookup = this.getLookup().find(f => f.value === id);
        return lookup?.label || '';
    }
}
