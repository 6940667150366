import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { Router } from '@angular/router';

import { LabApiConfiguration } from '../apiConfiguration';
import { ReportTemplate } from 'app/shared/models/lab/report-template';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ReportTemplateService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;
    private _allUrl: string;
    private _allActiveUrl: string;

    constructor(private http: HttpClient, private configuration: LabApiConfiguration, private router: Router) {
        super();
        this._actionUrl = configuration.server;
        this._url = this._actionUrl + configuration.reportTemplate.url;
        this._allUrl = this._actionUrl + configuration.reportTemplate.all;
        this._allActiveUrl = this._actionUrl + configuration.reportTemplate.allActive;
    }

    getAll = (): Observable<any> => {
        return this.http.get<any>(this._allUrl);
    }

    getAllActive = (): Observable<any> => {
        return this.http.get<any>(this._allActiveUrl);
    }

    insert = (reportTemplate: ReportTemplate): Observable<any> => {
        return this.http.post<any>(this._url, reportTemplate);
    }

    update = (reportTemplate: ReportTemplate): Observable<any> => {
        return this.http.put<any>(this._url, reportTemplate);
    }
}
