export class DosesTime {

    Id: number;
    FrequencyId: number;
    DoseNumber: number;
    DoseTime: Date = new Date();
    FacilityId: number=1;
    CreatedBy: number = 1;
    CreatedDate: Date = new Date();

    constructor() { }
}
