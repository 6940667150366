import { SelectItem } from 'primeng/api';
import { ChargeType } from '../enums';

export class ChargeTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Value', value: ChargeType.Value },
            { label: 'Percentage', value: ChargeType.Percentage }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
