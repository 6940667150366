import { SelectItem } from 'primeng/api';
import { CoverageAction } from '../enums';

export class CoverageActionLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Approval', value: CoverageAction.Approval },
            { label: 'Cash', value: CoverageAction.Cash },
        ];
    }
}