import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { ControlApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { ControlMenu } from 'app/shared/models/control/control-menu';
import { Permission } from 'app/shared/models/control/permission';
import { RolePermission } from 'app/shared/models/control/role-permission';

@Injectable()
export class RolePermissionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getControlMenuPermissionsUrl: string;
    private getRolePermissionsUrl: string;
    private updateRolePermissionsUrl: string;

    results: any;

    constructor(private http: HttpClient, private configuration: ControlApiConfiguration, private router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.rolePermission.url;
        this.getControlMenuPermissionsUrl = this.actionUrl + configuration.rolePermission.getControlMenuPermissionsUrl;
        this.getRolePermissionsUrl = this.actionUrl + configuration.rolePermission.getRolePermissionsUrl;
        this.updateRolePermissionsUrl = this.actionUrl + configuration.rolePermission.updateRolePermissionsUrl;
    }

    getRolePermissions(roleId: string): Observable<Permission[]> {
        return this.http.get<any>(`${this.getRolePermissionsUrl}?roleId=${roleId}`, { withCredentials: true });
    }

    updateRolePermissions(roleId: string, permissions: Permission[]) {
        let url = `${this.updateRolePermissionsUrl}?roleId=${roleId}`;
        return this.http.put<any>(url, JSON.stringify(permissions), { withCredentials: true });
    }

}
