import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';


export class LessThanOrEqualNullableValidator {

  public static validate =  (lte: number): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: boolean} => {
      if(control.value != null){
        let v: number = +control.value;
        return v <= +lte ? null : {lte: true};
      } else {
        return null;
      }
    };
  }
}