export class PatientInfectious {

    Id: number;
    PatientId: number;
    EncounterId: number;
    PatientTypeId: number;
    DoctorId: number;
    SourceId: number;
    ReferenceId?: number;
    Comment: string;
    Status: boolean;

    constructor() {
    }
}