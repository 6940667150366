import { DiagnosisType } from '../enums';
import { SelectItem } from 'primeng/api';

export class DiagnosisTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Principle', value: DiagnosisType.Principle },
            { label: 'Final', value: DiagnosisType.Final },
            { label: 'Secondary', value: DiagnosisType.Secondary },
            { label: 'Admitting', value: DiagnosisType.Admitting },
            { label: 'Discharge', value: DiagnosisType.Discharge },
            { label: 'Differential', value: DiagnosisType.Differential },
        ];
    }

    static getAllLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Principle', value: DiagnosisType.Principle },
            { label: 'Final', value: DiagnosisType.Final },
            { label: 'Secondary', value: DiagnosisType.Secondary },
            { label: 'Admitting', value: DiagnosisType.Admitting },
            { label: 'Discharge', value: DiagnosisType.Discharge },
            { label: 'Differential', value: DiagnosisType.Differential },
        ];
    }
}
