import { animate, keyframes, style, transition, trigger } from "@angular/animations";

export const FadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        animate(
            '2000ms ease-out',
            keyframes([
                style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
            ])
        )
    ]),
]);