import { SelectItem } from 'primeng/api';
import { StatementType } from '../enums';

export class StatementTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: StatementType.All },
            { label: 'Cash', value: StatementType.Cash },
            { label: 'partially Covered', value: StatementType.partiallyCovered },
            { label: 'Fully Covered', value: StatementType.FullyCovered }];
    }

    static getLookupWithoutAll(): SelectItem[] {
        return [
            { label: 'Cash', value: StatementType.Cash },
            { label: 'partially Covered', value: StatementType.partiallyCovered },
            { label: 'Fully Covered', value: StatementType.FullyCovered }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id) || null;
    }
}
