import { SelectItem } from 'primeng/api';
import { PatientTaskStatus } from '../enum/patient-task-status.enum';

export class PatientTaskStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'New', value: PatientTaskStatus.New },
            { label: 'In-Progress', value: PatientTaskStatus.InProgress },
            { label: 'Completed', value: PatientTaskStatus.Completed },
            { label: 'Hold', value: PatientTaskStatus.Hold },
            { label: 'Canceled', value: PatientTaskStatus.Canceled },
        ];
    }
    static getLookupWithoutDelay(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'New', value: PatientTaskStatus.New },
            { label: 'In-Progress', value: PatientTaskStatus.InProgress },
            { label: 'Completed', value: PatientTaskStatus.Completed },
            { label: 'Hold', value: PatientTaskStatus.Hold },
            { label: 'Canceled', value: PatientTaskStatus.Canceled },
        ];
    }
}
