import { MainComplaintStatus } from "../enums";

export class MainComplaintStatusesLookup {

    static getLookup(): any[] {
        return [
            { label: 'Active', value: MainComplaintStatus.Active },
            { label: 'Resolved', value: MainComplaintStatus.Resolved },
        ];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }

    static getById(id) {
        return this.getLookup().find(f => f.value === id)|| null;
    }
}
