import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'emergency-station-result-cmp',
    templateUrl: './emergency-station-result.html',
})

export class EmergencyStationResultComponent {
    @Output() onSetResult = new EventEmitter<any>();
    @Input() result;

    constructor() {

    }

    onSelect(event) {
        const item = event;
        this.onSetResult.emit({ item });
    }
}
