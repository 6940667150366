import { Section } from './section';
import { Lookup } from './lookup';
import { TestDefinition } from './testDefinition';

export class SpecimenTransaction {

     Id: number;
     OrderId: number;
     SpecimenNo: number;
     GroupTestNo?: number;
     Status: number;
     StatusDescription: string;
     LabSection: number;
     ResultType: number;
     SpecimenType: number;
     CollectDate?: Date;
     CollectBy?: number;
     ReceivedDate?: Date;
     ReceivedBy?: number;
     AcceptDate?: Date;
     AcceptBy?: number;
     RejectDate?: Date;
     RejectBy?: number;
     RejectReason?: number;
     CancelDate?: Date;
     CancelBy?: number;
     CancelReason?: number;
     ReleaseDate?: Date;
     ReleaseBy?: number;
     EntryDate: Date;
     EntryUserId: number;
     AccessionMiddleSerial?: number;
     AccessionLastSerial?: number;
     LabSectionPrefix: string;
     PatientId?: number;
     PatientCode: string;
     PatientName: string;
     PatientOtherName: string;
     ItemId?: number;
     ItemTitle: string;
     ItemForigenTitle: string;
     OrderDate?: Date;
     SectionId?: number;
     ItemCode: string;
     VisitDate?: Date;
     VisitSectionTitle: string;
     VisitSectionForigenTitle: string;
     LastActionDate: Date;
     AccessionNumber: string;
     TestsDefinitionId?: number;
     OrderDoctorId?: number;
     OrderDoctorName: string;
     OrderDoctorOtherName: string;
     GroupTestName: string;
     GroupTestOtherName: string;
     OrderComments: string;
     DoctorInfectiousComment: string;
     IsDoctorInfectious: boolean;
     SpecimenTransactionLabSection?: any;
     Section: Section;
     SpecimenTypeLookup: Lookup;
     TestDefinition: TestDefinition;
     canCancelTest: boolean;
     TestCode: any;
     TestTitle: any;
     TotalRowCount: number;
     IsInfictiousContained: boolean;
     PatientTypeId: number;
     EncounterId: number;
     OrderNumber: number;
     PriorityId: string;
     FacilityId: number;
     Location?: number;
     IsComplete?: boolean;
     IsRelease?: boolean;
     TestDefinitionCost?: number;
     DefaultExternalLab?: number;
     DocumentCount: number;
     CompleteBy?: number;
     IsPatientInfectious: boolean;
     PatientSourceId?: number;
}
