import { SelectItem } from 'primeng/api';
import { Status } from '../enum';

export class StatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: true },
            { label: 'InActive', value: false }];
    }

    static getLookupByValue(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: Status.Active },
            { label: 'InActive', value: Status.InActive },
        ];
    }

    static getTitleById(id) {
        return this.getLookupByValue().find(f => f.value === id)?.label || '';
    }
}
