import { SelectItem } from 'primeng/api';
import { PriceListProfitMarginStatus } from 'app/shared/models/orders-and-billing/enums/priceListProfitMarinStatus';

export class PriceListProfitMarginStatusLookup {

    static getLookup(): SelectItem[] {
        return [

            { label: 'Active', value: PriceListProfitMarginStatus.Active },
            { label: 'InActive', value: PriceListProfitMarginStatus.InActive }];
    }

}
