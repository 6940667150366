import { SelectItem } from 'primeng/api';
import { FinancialStatus } from '../enums/financial-status';

export class FinancialStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Paid' , value: FinancialStatus.Paid },
            { label: 'Un paid', value: FinancialStatus.Unpaid }
        ];
    }

    static getTitleById(id): string {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }
}
