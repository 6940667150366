import { SelectItem } from 'primeng/api';
import { VitalSignAgeRange } from '../enums';

export class VitalSignAgeTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Day', value: VitalSignAgeRange.Day },
            { label: 'Week', value: VitalSignAgeRange.Week },
            { label: 'Month', value: VitalSignAgeRange.Month },
            { label: 'Year', value: VitalSignAgeRange.Year },
        ];
    }
}
