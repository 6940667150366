import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'app/shared/infrastructure/base.component';
import { UserService } from 'app/shared/services/auth/user.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-confirm-password',
    templateUrl: './confirm-password.component.html',
})
export class ConfirmPasswordComponent extends BaseComponent {


    @ViewChild('confirmPasswordModal') modal: ModalDirective;
    @ViewChild('closeModal') closeModal: any;

    onConfirm: Subject<boolean> = new Subject();

    form = new FormGroup({
        password: new FormControl('', Validators.required),
    });
    get password(): AbstractControl { return this.form.get('password'); }

    constructor(private _service: UserService) {
        super();
    }

    async onSubmit() {
        if (!this.form.valid) {
            this.validateAllFormFields(this.form);
            return;
        }
        const value = await firstValueFrom(this._service.userValidator(this.user?.name, this.password.value)).catch(() => null);
        if (!value) {
            this.showError('Password not correct , kindly enter the correct password!');
            return;
        }
        this.onReset();
        this.modal.hide();
        this.onConfirm.next(true);
    }

    onReset() {
        this.password.reset();
        this.modal.hide();
    }

    async confirm(): Promise<boolean> {
        this.modal.show();
        return firstValueFrom(this.onConfirm);
    }











    // async onSubmit() {
    //     if (!this.form.valid) {
    //         this.validateAllFormFields(this.form);
    //         return;
    //     }
    //     const user = {
    //         userName: this.user?.name,
    //         password: this.password.value,
    //     }

    //     const value = await firstValueFrom(this._securityService.user.validateUser(JSON.stringify(user))).catch(() => null);
    //     if (!value) {
    //         this.showError('Password not correct , kindly enter the correct password!');
    //         return;
    //     }
    //     this.closeModal.nativeElement.click();
    //     this.onConfirm.emit(true);
    // }
}
