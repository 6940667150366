import { SelectItem } from 'primeng/api';
import { NormalResultTypeEnum } from '../enums/normal-result-type';

export class NormalResultTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Nothing', value: null },
            { label: 'Abnormal', value: NormalResultTypeEnum.Abnormal },
            { label: 'Normal', value: NormalResultTypeEnum.Normal },
            { label: 'Panic', value: NormalResultTypeEnum.Panic },
            { label: 'Absurd', value: NormalResultTypeEnum.Absurd }
        ];
    }

}
