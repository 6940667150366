import { SelectItem } from 'primeng/api';
import { Gender } from '../enums';

export class GenderLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
        ]
    }
}
