import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class ItemService extends ServiceBase {

    private actionUrl: string;
    private _isDentalParent: string;
    private _searchAllItems: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._isDentalParent = this.actionUrl + _configuration.Item.isDentalParent;
        this._searchAllItems = this.actionUrl + _configuration.Item.searchAllItems;
    }

    getIsDentalParent = (data): Observable<any> => {
        return this._http.post<any>(`${this._isDentalParent}`, data);
    }

    searchAllItems = (query: string): Observable<any> => {
        return this._http.get<any>(`${this._searchAllItems}?query=${query}`);
    }
}
