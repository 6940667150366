export class PatientDocument {
    Id: number;
    PatientId: number;
    DocumentType: number;
    DocumentNo: string;
    IssueDate: Date;
    ExpiryDate: Date;
    CountryId: number;
    Notes: string;
    IsActive: boolean;
    Base64File: string;
    FileName: string;
    IsScanned: boolean;
    CountryName?: string;
    Extention: string;    
    PatientType:number;
    EncounterId:number;
}