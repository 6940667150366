
export class Reservation {
    Id: number;
    DoctorId: number;
    RequiredDate: Date;
    AdmissionReasonId?: number;
    PatientId: number;
    DealingType: number;
    Status: number;
    SectionId: number;
    ClassId: number;
    ApprovalNo?: number;
    PatientDietId?: number;
    LengthOfStay: number;
    TelephoneNo: string;
    TreatmentPlane: string;
    CancelReason: string;
    ReservedMethod: number;
    ReservedBy: number;
    ReservedByText: string;
    constructor() {
    }
}