import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ContractStationCoverageService } from 'app/shared/models/orders-and-billing/contract-station-coverage-service';

@Injectable()
export class ContractStationCoverageServiceService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _servicesByContractId: string;

    constructor(private _http: HttpClient, _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.contractStationCoverageService.url;
        this._servicesByContractId = this._actionUrl + _configuration.contractStationCoverageService.servicesByContractId;
    }

    getServicesByContractId = (contractId: number): Observable<ContractStationCoverageService[]> => {
        return this._http.get<any>(`${this._servicesByContractId}?contractId=${contractId}`)
    }

    add(data: ContractStationCoverageService) {
        return this._http.post<any>(this._url, JSON.stringify(data))
    }

    update = (data: ContractStationCoverageService): Observable<any> => {
        return this._http.put<any>(this._url, data)
    }
}
