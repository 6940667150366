export class SpecimenTransactionChangeStatusViewModel {
    SpecimenTransactionId: number;
    NewStatus: number;
    CancellationReason?: number;
    RejectionReason?: number;
    SectionId: number;
    OrderId: number;
    SpecimenNo: number;
    GroupId?: number;
};
















