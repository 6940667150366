import { SelectItem } from 'primeng/api';
import { TestResultType } from '../enums/test-result-type';

export class TestResultTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Text', value: TestResultType.Text },
            { label: 'List', value: TestResultType.List },
            { label: 'Numeric', value: TestResultType.Numeric },
            { label: 'Culture', value: TestResultType.Culture },
            { label: 'Text Report', value: TestResultType.TextReport },
            { label: 'Image', value: TestResultType.Image }];
    }

}
