import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SectionTemplate } from '../../../models/lab/sectionTemplate';
import { ServiceBase } from 'app/shared/infrastructure';
@Injectable()
export class SectionTemplateService extends ServiceBase {

    private actionUrl: string;
    private _getTemplateBySectionId: string;
    private _getById: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._getTemplateBySectionId = this.actionUrl + _configuration.sectionTemplate.getBySectionId;
        this._getById = this.actionUrl + _configuration.sectionTemplate.getById;
        this._url = this.actionUrl + _configuration.sectionTemplate.url;
    }
    getTemplateBySectionId = (sectionId): Observable<SectionTemplate[]> => {
        const url = `${this._getTemplateBySectionId}/${sectionId}`;
        return this._http.get<any>(url);
    }

    getTemplateById = (id): Observable<SectionTemplate> => {
        const url = `${this._getById}/${id}`;
        return this._http.get<any>(url);
    }

    add = (data: SectionTemplate): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    put = (id: number, data: SectionTemplate): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.put<any>(url, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }
}
