import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class RadiologyReportTemplateService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    private _byActionId:string;
    private _logsByTemplateId:string;
    private _logsByActionId:string;
    private _reopenRadiologyReportIfApprovedUser:string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.radiologyReportTemplate.url;
        this._byActionId = this.actionUrl + _configuration.radiologyReportTemplate.byActionId;
        this._logsByTemplateId = this.actionUrl + _configuration.radiologyReportTemplate.logByTemplateId;
        this._logsByActionId = this.actionUrl + _configuration.radiologyReportTemplate.logByActionId;
        this._reopenRadiologyReportIfApprovedUser = this.actionUrl + _configuration.radiologyReportTemplate.reopenRadiologyReportIfApprovedUser;
    }

    getLogsByReportId = (id:any): Observable<any> => {
        const url = `${this._logsByTemplateId}?id=${id}`;
        return this._http.get<any>(url);
    }

    getLogsByActionId = (actionId:any): Observable<any> => {
        const url = `${this._logsByActionId}?actionId=${actionId}`;
        return this._http.get<any>(url);
    }
    getById = (id): Observable<any> => {
        const url = `${this._url}?id=${id}`;
        return this._http.get<any>(url);
    }
    getByActionId = (id): Observable<any> => {
        const url = `${this._byActionId}?id=${id}`;
        return this._http.get<any>(url);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: any): Observable<any> => {
        const url = `${this._url}`;
        return this._http.put<any>(url, data);
    }

    reopenRadiologyReportIfApprovedUser = (actionId:number) :Observable<boolean> => {
        const url:string = `${this._reopenRadiologyReportIfApprovedUser}?actionId=${actionId}`
        return this._http.get<boolean>(url);
    }
}
