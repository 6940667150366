import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class OrderClaimPatientAttachmentService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _getById: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.claimPatientAttachment.url;
        this._getById = this._actionUrl + _configuration.claimPatientAttachment.getById;
    }

    public getById = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._getById}?id=${id}`)
    }
}
