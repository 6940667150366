import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ApiConfiguration } from 'app/shared/services/his-notification/apiConfiguration/app.constant.service';
@Injectable()
export class PatientReleaseResultService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _patientReleaseTodayUrl: string;

    constructor(private _http: HttpClient, private _configuration: ApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientReleaseResult.url;
        this._patientReleaseTodayUrl = this.actionUrl + _configuration.patientReleaseResult.patientReleaseToday;
    }

    patientReleaseToday = (): Observable<any> => {
        return this._http.get<any>(this._patientReleaseTodayUrl, { responseType: 'silent' as 'json', withCredentials: false })
    }
}
