import { Injectable } from '@angular/core';
import { PatientSources } from 'app/shared/models';
import { PatientTypes } from 'app/shared/models/lab/enums/patient-types';
import { LabInvestigationType, MedicalProceduresType, RadiologyProceduresType } from 'app/shared/models/orders-and-billing';
import { firstValueFrom } from 'rxjs';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { PBLSetupService } from '../core';
import { BillServicesService } from './bill-services';
import { ChargingSetupService } from './charging-setup.service';
import { PatientStatmentService } from './patient-statment';

@Injectable()
export class ChargingOrderHelperService extends ServiceBase {

    chargingSetup: any;
    pblSetup: any;
    labInvestigationId: LabInvestigationType;
    radiologyProcedureId: RadiologyProceduresType;
    medicalProcedureId: MedicalProceduresType;

    constructor(
        private _chargingSetupService: ChargingSetupService,
        private _pblSetupService: PBLSetupService,
        private _patinetStatmentService: PatientStatmentService,
        private _billServicesService: BillServicesService) {
        super();
        this.getChargingSetup();
        this.getPblSetup();
        this.reset();        
    }

    async check(patientTypeId: PatientTypes, visitSourceId: PatientSources, patientId: number, encounterId: number, orderId: number) {
        if (patientTypeId !== PatientTypes.outPatient || !this.validateChargingSetup()) {
            return true;
        }

        let generateInvoicePerOrder = visitSourceId === PatientSources.emergency ?
            this.pblSetup?.GenerateERInvoicePerOrder :
            this.pblSetup?.GenerateOPDInvoicePerOrder;

        let invoiceNet: number = null;
        if (generateInvoicePerOrder) {
            invoiceNet = await this.getPatientStatementNetAmount(patientId, encounterId, orderId);
        }
        else {
            invoiceNet = await this.getBillServiceNetAmount(patientId, encounterId, orderId);
        }

        return invoiceNet <= 0;
    }

    async getPatientStatementNetAmount(patientId: number, encounterId: number, orderId: number) {
        return await firstValueFrom(this._patinetStatmentService.getCashStatementInvoiceNet(patientId, encounterId, orderId));        
    }

    async getBillServiceNetAmount(patientId: number, encounterId: number, orderId: number) {
        return await firstValueFrom(this._billServicesService.getCashStatementInvoiceNet(patientId, encounterId, orderId));
         
    }

    private validateChargingSetup() {
        if (this.chargingSetup?.LabInvestigationId === this.labInvestigationId ||
            this.chargingSetup?.RadiologyProcedureId === this.radiologyProcedureId ||
            this.chargingSetup?.MedicalProcedureId === this.medicalProcedureId) {
            return true;
        }
        return false;
    }

    private getChargingSetup() {
        this._chargingSetupService.getByFacility()
            .subscribe({
                next: (value) => {
                    this.chargingSetup = value;
                }
            });
    }

    private getPblSetup() {
        this._pblSetupService.getCurrentFacilityPBL()
            .subscribe({
                next: (value) => {
                    this.pblSetup = value;
                }
            });
    }

    reset(){
        this.labInvestigationId = -99;
        this.radiologyProcedureId = -99;
        this.medicalProcedureId = -99;
    }
}