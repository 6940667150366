import { SelectItem } from 'primeng/api';
import { ServiceStatusOptions } from 'app/shared/models/orders-and-billing/enums/service-status';

export class ServiceStatusLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Open', value: ServiceStatusOptions.Open },
            { label: 'Canceled', value: ServiceStatusOptions.Canceled }];
    }

}
