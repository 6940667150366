import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()

export class PatientDonorService extends ServiceBase {

    private actionUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
    }

    getDonorVisits = (patientId: number): Observable<any> => {
        return this._http.get<any>(`${this.actionUrl}PatientDonor/DonorVisits?patientId=${patientId}`);
    }
}