import { MedicalSource } from "./enums/MedicalSource";

export class MedicalFileSetup {

    Id: number;
    MedicalSourceId?: MedicalSource;
    Query: string;
    Forms: string;
    PatientType?: number;
    CreatedBy: number;
    CreatedDate: Date;
    FacilityId:number;
    StaffName:string;
    PatientTypeLabel:string;
    Staff:any;

    constructor() {
    }
}