import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { HospitalRooms } from 'app/shared/models/queryModel/hospital-rooms-filter';
@Injectable()
export class BedService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.bed.url;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(this.url);
    }

    getBedByRoomId = (id): Observable<any> => {
        return this._http.get<any>(`${this.url}/?id=${id}`);
    }

    insert = (data: any): Observable<any> => {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this.url, JSON.stringify(data));
    }

    getTotalVacantBeds = (): Observable<any> => {
        return this._http.get<any>(`${this.url}/TotalVacantBeds`);
    }

    GetBedStatisticsChart = (): Observable<any> => {
        return this._http.get<any>(`${this.url}/GetBedStatisticsChart`);
    }

    getBedStatistics = (filter: HospitalRooms = new HospitalRooms()): Observable<any> => {
        const bedStatisticsUrl: string = `${this.url}/GetBedStatistics?pageNo=${filter.pageNumber}&rowNo=${filter.pageSize}&sectionId=${filter.sectionId}&roomId=${filter.roomId}&classId=${filter.classId}&admissionStatus=${filter.admissionStatus}&patientName=${filter.patientName}&patientCode=${filter.patientCode}&doctorId=${filter.doctorId}`;
        return this._http.get<any>(bedStatisticsUrl);
    }
}
