import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable()
export class DimensionServiceApiConfiguration {
    serverUrl: string;
    server: string;
    dimensionAttachementServer: string;
    deomgraphicInfoServerUrl:string;
    constructor() {
        this.server = environment.dimension;
        this.deomgraphicInfoServerUrl = environment.dimensionDemographicInfo;
        this.dimensionAttachementServer = environment.dimensionAttachement;
        this.serverUrl = environment.dimensionUrl;
    }

    public remittance = {
        getNewRemittanceAdvice: 'Claim/GetNew',
        getRemittanceAdviceDetails: 'Claim/View',
        setRemittanceAdviceDownloaded: 'Claim/SetDownloaded',
        remittanceClaim: 'remittanceClaim',
        setRemittanceDownloadedLocal: 'RemittanceClaim/SetRemittanceDownloaded',
        getDownloadedClaims: 'RemittanceClaim/GetDownloadedClaims',
        getByClaimId: 'RemittanceClaim/GetByClaimId',
        setRemittanceStatusAsPending: 'RemittanceClaim/SetRemittanceStatusAsPending',
        setRemittanceStatusAsClose: 'RemittanceClaim/SetRemittanceStatusAsClose',
    }

    remittanceAttachement = {
        uploadAttachment: 'Attachments/UploadAttachment'
    }

    public itemMapping = {
        url: 'Item',
        getBySearch: 'Item/BySearch',
    }

    public payerMapping = {
        url: 'Payer',
        getBySearch: 'Payer/BySearch',
    }

    public doctorMapping = {
        url: 'Doctor',
        getBySearch: 'Doctor/BySearch',
    }

    public diagnosisMapping = {
        url: 'DiagnosisMapping',
        getBySearch: 'DiagnosisMapping/BySearch',
    }

    submissionClaim = {
        url: 'SubmissionClaim',
        resubmission: 'SubmissionClaim/Resubmission',
        submissionByClaim: 'SubmissionClaim/SubmissionByClaim',
        submissionByStatment: 'SubmissionClaim/SubmissionByStatment',
        cancelCalimInvoice: 'SubmissionClaim/cancelCalimInvoice',
    };
    demographicInfo = {
        patientInfo : "DemographicInfo/ById"
    } 

    approvalClaim = {
        url: 'ApprovalClaim',
        claimApproval: 'ApprovalClaim/ClaimApproval',
    }
}
