import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class CodeService extends ServiceBase {

    private actionUrl: string;
    private _getCodesByGroupKey: string;
    private _getCodeGroups: string;
    private _url: string;
    private _getActiveCodesByGroupKey: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._getCodesByGroupKey = this.actionUrl + _configuration.code.getCodesByGroupKey;
        this._getCodeGroups = this.actionUrl + _configuration.code.getCodeGroups;
        this._url = this.actionUrl + _configuration.code.url;
        this._getActiveCodesByGroupKey = this.actionUrl + _configuration.code.getActiveCodesByGroupKey;
    }

    getCodeGroups = (): Observable<any> => {
        return this._http.get<any>(this._getCodeGroups);
    }

    getCodesByGroupKey = (key): Observable<any> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + key);
    }

    getActiveCodesByGroupKey = (key): Observable<any> => {
        return this._http.get<any>(this._getActiveCodesByGroupKey + '/?Key=' + key);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    delete = (id, isActive): Observable<any> => {
        return this._http.delete(this._url + '/?id=' + id + '&isActive=' + isActive);
    }
}