import { Component, AfterViewChecked, OnInit, ChangeDetectorRef } from '@angular/core';
import { GlobalState } from 'app/global.state';
import { NotificationService } from 'app/shared/services/notificationService/notification.service';
import { isMobile } from 'app/shared';
import { BaseComponent } from 'app/shared/infrastructure';
import { FavoritePageStaffService } from 'app/shared/services';

@Component({
    selector: 'ba-notification-favorite',
    templateUrl: './notification-favorite.html',
    styleUrls: ['./notification-favorite.scss']
})
export class NotificationAndFavoriteComponent extends BaseComponent implements OnInit, AfterViewChecked {

    unReadNotificationCount: number;
    canViewSqlBuilder: boolean = false;
    selectedNotification: any;
    constructor(
        private _notificationService: NotificationService,
        private _favoritePageStaffService: FavoritePageStaffService,
        private _changeDetectorRef: ChangeDetectorRef
        ) {
        super();
    }

    ngAfterViewChecked(): void {
        this.canViewSqlBuilder = this.validatePermission('ViewSqlBuilder');
        this._changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this._notificationService.unreadCount
            .subscribe(state => {
                this.unReadNotificationCount = state;
            });
    }

    activeBar(item, barItem) {
        jQuery('#' + barItem).addClass('active-item');
        jQuery('#' + item).addClass('active');
    }

    removeActiveBar() {
        jQuery('.bar').removeClass('active');
    }

    validateMobile() {
        if (isMobile()) {
            return true;
        }
        return false;
    }

    onToggleSqlBuilder() {
        this.openLinkInNewTab(this.SqlBuilderEngineUrl());
    }

    openFavorite() {
        this._favoritePageStaffService.isOpen.next(true);
    }
}

