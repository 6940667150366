import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class LabPathologyService extends ServiceBase {

    private actionUrl: string;
    private _labPathology: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._labPathology = this.actionUrl + _configuration.labPathology.getPathologyInfo;
    }

    getPathologyInfo = (orderId = null, itemNumber = null, patientCode = null): Observable<any> => {
        let queryString = '';
        queryString += `?orderId=${orderId ? orderId : ''}`;
        queryString += `&itemNo=${itemNumber ? itemNumber : ''}`;
        queryString += `&patientCode=${patientCode ? patientCode : ''}`;

        return this._http.get<any>(this._labPathology + queryString);
    }
}