import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientPayerBasicInformation } from 'app/shared/models/orders-and-billing/PatientPayerBasicInformation'
import { PreferencePatientPayerContractInfo } from "app/shared/models/orders-and-billing/preference-patient-payer-contract-info";
import { PatientPayer } from 'app/shared/models/orders-and-billing/patient-payer';
import { MobilePatientPayerInfo } from 'app/shared/models/orders-and-billing/mobile-patient-payer-info';

@Injectable()
export class PatientPayerService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _getById: string;
    private _getPreferencePatientPayerInformation: string;
    private _getByPatientId: string;
    private _getPayerContainContract: string;
    private _isHavePayer: string;
    private _getPayerInfo: string;
    private _getPreferencePatientPayerAndContractInformation: string;
    private _getSessionPeriodUrl: string;
    private _patientsPayerInfoUrl: string;
    private _isHavePayerApproval: string;
    private _activePatientPayerBasiInformation: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.patientPayer.url;
        this._getById = this._actionUrl + _configuration.patientPayer.getById;
        this._isHavePayer = this._actionUrl + _configuration.patientPayer.isHavePayer;
        this._getByPatientId = this._actionUrl + _configuration.patientPayer.getByPatientId;
        this._getPreferencePatientPayerInformation = this._actionUrl + _configuration.patientPayer.getPreferencePatientPayerInformation;
        this._getPreferencePatientPayerAndContractInformation = this._actionUrl + _configuration.patientPayer.getPreferencePatientPayerAndContractInformation;
        this._getPayerInfo = this._actionUrl + _configuration.patientPayer.getPayerInfo;
        this._getSessionPeriodUrl = this._actionUrl + _configuration.patientPayer.sessionPeriod;
        this._patientsPayerInfoUrl = this._actionUrl + _configuration.patientPayer.patientsPayerInfo;
        this._isHavePayerApproval = this._actionUrl + _configuration.patientPayer.isHavePayerApproval;
        this._activePatientPayerBasiInformation = this._actionUrl + _configuration.patientPayer.activePatientPayerBasiInformation
    }

    public getById = (payerId: number): Observable<any> => {
        return this._http.get<any>(this._getById + payerId)


    }

    getPreferencePatientPayerInformation = (patientId: number): Observable<PatientPayerBasicInformation> => {
        return this._http.get<any>(`${this._getPreferencePatientPayerInformation}?id=${patientId}`)


    }

    public getPreferencePatientPayerAndContractInformation = (patientId: number): Observable<PreferencePatientPayerContractInfo> => {
        return this._http.get<any>(this._getPreferencePatientPayerAndContractInformation + '?patientId=' + patientId)


    }

    public getByPatientId = (patientId: number): Observable<PatientPayer[]> => {
        return this._http.get<any>(this._getByPatientId + patientId);
    }

    //check if patient have guarantor
    public isHavePayer = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._isHavePayer + patientId)


    }

    public getPayerInfo = (patientId: number): Observable<MobilePatientPayerInfo> => {
        return this._http.get<any>(this._getPayerInfo + patientId)


    }

    public insert = (data: any, patientTypeId?: number, encounterId?: number): Observable<any> => {
        return this._http.post(this._url + '?patientTypeId=' + patientTypeId + '&encounterId=' + encounterId, data)


    }

    public update = (data: any, patientTypeId?: number, encounterId?: number): Observable<any> => {
        return this._http.put<any>(this._url + '?patientTypeId=' + patientTypeId + '&encounterId=' + encounterId, data)

    }

    public updateByWizard = (data: PatientPayer, id): Observable<any> => {
        return this._http.put<any>(this._url + '?patientTypeId=' + null + '&encounterId=' + null, data)
    }

    public getSessionPeriod = (patientId: number, sectionId: number): Observable<any> => {
        return this._http.get<any>(this._getSessionPeriodUrl + '?patientId=' + patientId + '&sectionId=' + sectionId)
    }

    public getPatientPayerInfo = (patientIds: string): Observable<any> => {
        return this._http.get<any>(this._patientsPayerInfoUrl + '?patientIds=' + patientIds);
    }

    public isHavePayerApproval = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._isHavePayerApproval + '?patientId=' + patientId)
    }

    public getActivePatientPayerBasiInformation = (patientId: string): Observable<any> => {
        return this._http.get<any>(this._activePatientPayerBasiInformation + '?patientId=' + patientId);
    }

    public getActivePatientPayers = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + "/ActivePatientPayers?patientId=" + patientId);
    }
    public getActivePatientPayer = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + "/ActivePatientPayer?patientId=" + patientId);
    }
}
