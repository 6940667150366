import { SelectItem } from 'primeng/api';
import { ApprovalStatus } from '../enums';

export class ApprovalStatusesLookup {
    static getLookup() {
        return [
            { label: 'Pending', id: ApprovalStatus.Pending, class: 'fas fa-circle fa-sm palette-Amber text' },
            { label: 'Approved', id: ApprovalStatus.Approved, class: 'fas fa-circle fa-sm palette-Green-500 text' },
            { label: 'Rejected', id: ApprovalStatus.Rejected, class: 'fas fa-circle fa-sm palette-Red text' },
            { label: 'Canceled', id: ApprovalStatus.Canceled, class: 'fas fa-circle fa-sm palette-Grey-500 text' },
        ]
    }

    static getClassById(id) {
        const lookup = this.getLookup().find(f => f.id === id)?.class;
        return lookup;
    }
}
