import { SelectItem } from 'primeng/api';
import { IssueDateType } from '../enums';

export class IssueDateLookup {

    static getLookup(): SelectItem[] {
        return [
            { label: 'Encounter', value: IssueDateType.Encounter },
            { label: 'Current', value: IssueDateType.Current },
        ];
    }
}
