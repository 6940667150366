import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { PatientDiagnosis } from '../../../models/core/patient-diagnosis';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PatientDiagnosisService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byPatientEncounterUrl: string;
    private _allByPatientEncounterUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientDiagnosis.url;
        this._byPatientEncounterUrl = this.actionUrl + _configuration.patientDiagnosis.byPatientEncounter;
        this._allByPatientEncounterUrl = this.actionUrl + _configuration.patientDiagnosis.allByPatientEncounter;
    }

    getByPatientEncounter = (patientId: number, encounterId: number, patientTypeId: number, pageIndex: number, pageSize: Number, searchTypeId?: any): Observable<any> => {
        return this._http.get<any>(this._byPatientEncounterUrl +
            '?patientId=' + patientId +
            '&encounterId=' + encounterId +
            '&patientTypeId=' + patientTypeId +
            '&pageIndex=' + pageIndex +
            '&pageSize=' + pageSize +
            '&searchTypeId=' + searchTypeId
        );
    }

    getAllByPatientEncounter = (patientId: number, encounterId: number, patientTypeId: number): Observable<PatientDiagnosis[]> => {
        return this._http.get<any>(`${this._allByPatientEncounterUrl}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`
        );
    }

    add = (data: PatientDiagnosis): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (data: PatientDiagnosis): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }
}
