import { CoverageContract } from "./coverage-contract";
import { PatientEncounterInsurance } from "./patient-encounter-insurance";
import { Payer } from "./payer";

export interface IPatientStatementMetaData {
    Location: string;
    CreditAmount: number;
    TotalAmount: number;
    Net: number;
    GuarantorsName: string;
    GuarantorsOtherName: string;
    NetAmount: number;
    ProviderName: string;
}

export class PatientStatement implements IPatientStatementMetaData {

    Id: number;
    PatientId: number;
    EncounterId: number;
    PatientTypeId: number;
    IssueDate?: Date;
    Status: number;
    Deposit: number;
    Total: number;
    CloseDate?: Date;
    HaltReason: string;
    InvoiceId?: number;
    PayerId?: number;
    PatientFullName: string;
    PatientOtherFullName: string;
    GuarantorFullName: string;
    GuarantorOtherFullName: string;
    PatientCode: string;
    EncounterDate: Date;
    TotalDiscount: number;
    TotalPackageDiscount: number;
    PackageCoverageDiscount: number;
    PatientLocation: string;
    Remarks?: string;

    Location: string;
    CreditAmount: number;
    TotalAmount: number;
    Net: number;
    GuarantorsName: string;
    GuarantorsOtherName: string;
    SponsorName: string;
    InvoiceNet: number;
    DownPayment: number;
    EncounterStatus: number;

    CashPriceListId?: number;
    NetAmount: number;
    ProviderName: string;

    OrderId?: number;
    OrderSourceId?: number;
    PatientEncounterInsurance?: PatientEncounterInsurance;
    ParentPayer?: Payer;
    IsCloseDonor?: boolean;
    NetDepositAmount: number;
    RemainingAmount: number;
    UsedRemainingAmount: number;
    TotalDeposit: number;
    PrintInvoiceReportLogs: any[];

    constructor() {

    }
}