import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
// Services
import { AdmissionService } from 'app/shared/services/admissionService/admission/admission.service';
import { PatientVisitService } from 'app/shared/services/core/patientVisit/patientVisit.service';
import { VisitAdmissionsSearchResult } from 'app/shared/models/queryModel/visitAdmissionsSearchResult';
import { AdmissionVisitType } from 'app/shared/models/queryModel/enums/admission-visit-type';
import { AdmissionStationSearchService } from 'app/shared/services/queryModel/admission-station-search/admission-station-search.service'
import { AdmissionStatus } from "app/shared/models/admission/enums/admission-status";
// Models
@Component({
    selector: 'visit-admissions-cmp',
    styleUrls: ['./visit-admissions-result.scss'],
    templateUrl: './visit-admissions-result.html',
})


export class VisitAdmissionsResultComponent implements OnInit {
    @Output() onSetResult = new EventEmitter<any>();
    @Input() result;
    patients: any = [];

    ngOnInit() {
        this.getDetails();
    }
    constructor(private _admissionService: AdmissionService
        , private _patientVisitService: PatientVisitService
        , private _admissionStationSearchService: AdmissionStationSearchService) {

    }

    getDetails() {

        if (this.result.length > 0) {
            this.patients = [];
            this.result.map(patient => {
                const patientresult = {
                    PatientId: patient.Id,
                    FullName: patient.FullName,
                    MRN: patient.PatientCode,
                    EncounterId: patient.EncounterId,
                    Admissions: [],
                    Visits: []
                };
                this.patients.push(patientresult);
            });
        }
    }

    getPaymentTypeLabel(status: number) {
        status = Number(status) || 0;
        let label: string = '';
        switch (status) {
            case AdmissionStatus.InHospital:
                label = 'In Hospital';
                break;
            case AdmissionStatus.Canceled:
                label = 'Canceled';
                break;
            case AdmissionStatus.DrugCardClosed:
                label = 'Drug Card Closed';
                break;
            case AdmissionStatus.FinalDischarge:
                label = 'Final Discharge';
                break;
            case AdmissionStatus.FinanceSettled:
                label = 'Finance Settled';
                break;
            case AdmissionStatus.Halted:
                label = 'Halted';
                break;
            case AdmissionStatus.ReleasePermission:
                label = 'Release Permission';
                break;
        }

        return label;
    }


    getAdmissionVisit(item) {

        // get admission info 
        this._admissionStationSearchService.getAdmittedPatient(item.PatientId, item.EncounterId).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data !== null) {
                for (const row of data) {
                    row.AdmissionDate = new Date(row.AdmissionDate);
                    row.Type = AdmissionVisitType.Admission;

                }

                this.patients.map(patient => {
                    patient.Admissions = [];
                    if (patient.PatientId === item.PatientId) {
                        // reset admissions for this patient 
                        patient.Admissions = data;
                        this.sortAdmissionsAndVisits(patient);
                    }
                });
            }

        });

        /// get visit info 
        this._patientVisitService.getOpenVisitByPatientId(item.PatientId, item.EncounterId).subscribe((data: VisitAdmissionsSearchResult[]) => {
            if (data.length > 0) {
                const selectedPatient = this.patients.find(e => e.PatientId === item.PatientId);
                selectedPatient.Visits = [];

                data.forEach((a) => {
                    a.Type = AdmissionVisitType.PatientVisit;
                    a.VisitDate = new Date(a.VisitDate);

                    if (selectedPatient) {
                        selectedPatient.Visits.push(a);
                        this.sortAdmissionsAndVisits(selectedPatient);
                    }
                });
            }
        })
    }

    onSelect(event) {
        console.log('item selected', event);
        const item = event;
        this.onSetResult.emit({ item });
    }

    sortAdmissionsAndVisits(patient) {
        if (patient.Admissions && patient.Admissions.length > 0) {
            patient.Admissions.sort((a, b) => b.AdmissionDate.getTime() - a.AdmissionDate.getTime());
        }
        if (patient.Visits && patient.Visits.length > 0) {
            patient.Visits.sort((a, b) => b.VisitDate.getTime() - a.VisitDate.getTime());
        }
    }

}
