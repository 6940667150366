export class ClaimFilter {

    PatientCode: string = "";
    PatientName: string = "";
    PayerID?: number;
    PatientType: number;
    FromClaimDate?: Date;
    ToIClaimDate?: Date;
    PageNo: number;
    RowNo: number;
    BillNo: number;
    ClaimNo: number;
    Status: number;
    CoverageContractId?: number;
    CoverageClassId?: number;
    constructor() { }
}
