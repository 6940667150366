import { SelectItem } from 'primeng/api';
import { DischargePrescriptionType } from '../enums';

export class DischargePrescriptionTypeLookup {

    static get(): SelectItem[] {
        return [
            { label: 'Halt', value: DischargePrescriptionType.Halt },
            { label: 'Open', value: DischargePrescriptionType.Open },
            { label: 'Doctor Release', value: DischargePrescriptionType.DoctorRelease },
        ];
    }
}