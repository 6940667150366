import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientOpenedHaltedEncountersService extends ServiceBase {

    private _actionUrl: string;
    private _getOpenedHaltedEncounters: string;
    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._getOpenedHaltedEncounters = this._actionUrl + _configuration.patienEncounterInsurance.getOpenedHaltedEncounters;
    }

    getOpenedHaltedEncounters = (patientId: number, payerId: number, patientType: number): Observable<any> => {
        return this._http.get<any>(`${this._getOpenedHaltedEncounters}?patientId=${patientId}&payerId=${payerId}&patientType=${patientType}`);
    }
}