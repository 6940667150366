import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PendingOrder extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.pendingOrder.url;
        this.postUrl = this.actionUrl + _configuration.pendingOrder.url;
    }

    addPendingOrder = (data: any): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    getPendingOrders = (pageNo, rowNo, patientName, patientCode, orderNo, transactionType, orderDate): Observable<any> => {
        patientName = patientName ? patientName : '';
        patientCode = patientCode ? patientCode : '';
        orderNo = orderNo ? orderNo : '';
        transactionType = transactionType ? transactionType : '';
        const fromorderDate = orderDate ? orderDate.toISOString() : '';
        return this._http.get<any>(this.getUrl + '?pageNo=' + pageNo + '&rowNo=' + rowNo
            + '&patientName=' + patientName + '&patientCode=' + patientCode
            + '&orderNo=' + orderNo + '&transactionType=' + transactionType
            + '&orderDate=' + fromorderDate);
    }

    deActivePendingOrder = (id): Observable<any> => {
        return this._http.put<any>(this.getUrl + `/SuccessTransaction?id=${id}`, null);
    }

}
