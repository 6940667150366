import { SelectItem } from 'primeng/api';
import { PostPendingTransactionTypes } from "../enums/post-pending-order-transaction-types";

export class PostPendingTransactionTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Add Stock', value: PostPendingTransactionTypes.AddStock },
            { label: 'Return Stock', value: PostPendingTransactionTypes.ReturnStock }];
    }
}