export * from './admissionServiceApiConfiguration';
export * from './consultationType';
export * from './adtClass';
export * from './admissionReason';
export * from './room';
export * from './bed';
export * from './reservation';
export * from './companion';
export * from './companionPeriods';
export * from './admission';
export * from './transfer';
export * from './bedStatus';
export * from './inpatient-timeline-history';
export * from './bed-availability';
export * from './admission-report-setup';
export * from './consultation-types-detail';
export * from './consultingDoctor';