import { TestNormalRange } from './test-normal-range';
import { ResultFormulaTest } from './resultFormulaTest';
import { GroupTest } from './groupTest';

export class TestDefinition {
    ItemId: number;
    TestCode: string;
    Description: string;
    OtherDescription: string;
    LabSection: number;
    Abbrev: string;
    TestType: number;
    SpecContainer: any;
    SpecVolume: any;
    TatPeriod: number;
    TatPeriodHours: number;
    TatPeriodMinutes: number;

    TimeZone: any;
    TestGender?: number;
    Location: any;
    Cost: any;
    DefaultExtLab: any;
    IsRequested: any;
    IsPrintable: any;
    IsCalculated: any;
    IsSecureResults: any;
    IsPartialRelease: any;
    ResultType: number;
    ResultUnits: number;
    ResultList: number;
    ResultNormalRanges: string;
    RequireReview: number;
    ReportTemplateId?: number;

    ParentId?: number;
    TestSpecimen?: number;
    SIUnit?: number;
    Factor?: number;
    TestNormalRanges: TestNormalRange[] = [];
    ResultFormulaTests: ResultFormulaTest[] = [];
    Formula: string;
    TimeSpan: number;
    Comment: string;
    SINormalRange: string;
    SINormalValue: string;
    SINormalGeneralValue: string;
    IsDifferential: boolean;
    DifferentialFrom?: number;
    DifferentialTo?: number;
    RelatedGroupTests: GroupTest[];
    IsClinicalInfo: boolean;
    IsRounded: boolean;
    Order: number;
};
