import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { PatientReservationSearchService } from "../../../services/queryModel/patientReservationSearch";
import { PatientReservationSearchFilter } from "../../../models/queryModel";
@Component({
    selector: 'advance-search-reservation-cmp',
    styleUrls: ['./advanceSearchReservation.scss'],
    templateUrl: './advanceSearchReservation.html'
})

export class AdvanceSearchReservationComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    patientReservationSearchFilter: PatientReservationSearchFilter = new PatientReservationSearchFilter();
    hasReserved: boolean = false;
    ngOnInit() {
        this.patientReservationSearchFilter = new PatientReservationSearchFilter();
    }
    constructor(private _patientReservationSearchService: PatientReservationSearchService) {
    }

    onSubmit() {
        this._patientReservationSearchService.getSearchResult(this.patientReservationSearchFilter)
            .subscribe((data) => {
                this.onSearch.emit({ patientReservationSearchResult: data });
            });
    }

    ngOnDestroy() {
    }
}
